export function sCreateGroupIdForUser({ playerId = 0 }) {
  return `self-${playerId}`;
}

export function sCreateGroupIdForGame({ gameId = 0 }) {
  return `game-${gameId}`;
}

export function performWhenConnectionAvailable({ connection, cb, retry = 10 }) {
  if (retry < 0) return;
  if (!connection || !cb || typeof cb !== "function") {
    console.error("No connection or no function provided");
    return;
  }
  if (connection.state !== "Connected") {
    setTimeout(() => {
      performWhenConnectionAvailable({ connection, cb, retry: retry - 1 });
    }, 1000);
    return;
  }
  cb();
}

export default null;
