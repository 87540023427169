import { getPlayerSummary } from "../../redux/slices/user.slice";
import {
  sCreateGroupIdForUser,
  performWhenConnectionAvailable,
} from "../../utils/signalr-utils";

export default function handlePlayerGroupJoin({ action, connection }) {
  // Only proceed for playerdetail result
  if (action.type !== getPlayerSummary.fulfilled().type) return;
  performWhenConnectionAvailable({
    connection,
    cb: () => {
      console.log("handlePlayerGroupJoin", {
        type: action.type,
        playerSummaryType: getPlayerSummary.fulfilled().type,
      });
      const groupId = sCreateGroupIdForUser({
        playerId: action.payload.playerId,
      });
      connection.invoke("joinGroup", connection.connectionId, groupId);
    },
  });
}
