/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { AvatarRandomGetRequestToJSON, AvatarRandomGetResponseFromJSON, } from '../models';
/**
 *
 */
export class AvatarApi extends runtime.BaseAPI {
    /**
     */
    async avatarRandomGetRequestrandomgetCreateRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling avatarRandomGetRequestrandomgetCreate.');
        }
        const queryParameters = {};
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/avatar/randomget`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AvatarRandomGetRequestToJSON(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => AvatarRandomGetResponseFromJSON(jsonValue));
    }
    /**
     */
    async avatarRandomGetRequestrandomgetCreate(requestParameters, initOverrides) {
        const response = await this.avatarRandomGetRequestrandomgetCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async avatarRandomGetRequestrandomgetDeleteRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling avatarRandomGetRequestrandomgetDelete.');
        }
        const queryParameters = {};
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/avatar/randomget`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => AvatarRandomGetResponseFromJSON(jsonValue));
    }
    /**
     */
    async avatarRandomGetRequestrandomgetDelete(requestParameters, initOverrides) {
        const response = await this.avatarRandomGetRequestrandomgetDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async avatarRandomGetRequestrandomgetGetRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling avatarRandomGetRequestrandomgetGet.');
        }
        const queryParameters = {};
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/avatar/randomget`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => AvatarRandomGetResponseFromJSON(jsonValue));
    }
    /**
     */
    async avatarRandomGetRequestrandomgetGet(requestParameters, initOverrides) {
        const response = await this.avatarRandomGetRequestrandomgetGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async avatarRandomGetRequestrandomgetPostRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling avatarRandomGetRequestrandomgetPost.');
        }
        const queryParameters = {};
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/avatar/randomget`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AvatarRandomGetRequestToJSON(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => AvatarRandomGetResponseFromJSON(jsonValue));
    }
    /**
     */
    async avatarRandomGetRequestrandomgetPost(requestParameters, initOverrides) {
        const response = await this.avatarRandomGetRequestrandomgetPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
/**
    * @export
    * @enum {string}
    */
export var AvatarRandomGetRequestrandomgetCreateAcceptEnum;
(function (AvatarRandomGetRequestrandomgetCreateAcceptEnum) {
    AvatarRandomGetRequestrandomgetCreateAcceptEnum["ApplicationJson"] = "application/json";
})(AvatarRandomGetRequestrandomgetCreateAcceptEnum || (AvatarRandomGetRequestrandomgetCreateAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var AvatarRandomGetRequestrandomgetDeleteAcceptEnum;
(function (AvatarRandomGetRequestrandomgetDeleteAcceptEnum) {
    AvatarRandomGetRequestrandomgetDeleteAcceptEnum["ApplicationJson"] = "application/json";
})(AvatarRandomGetRequestrandomgetDeleteAcceptEnum || (AvatarRandomGetRequestrandomgetDeleteAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var AvatarRandomGetRequestrandomgetGetAcceptEnum;
(function (AvatarRandomGetRequestrandomgetGetAcceptEnum) {
    AvatarRandomGetRequestrandomgetGetAcceptEnum["ApplicationJson"] = "application/json";
})(AvatarRandomGetRequestrandomgetGetAcceptEnum || (AvatarRandomGetRequestrandomgetGetAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var AvatarRandomGetRequestrandomgetPostAcceptEnum;
(function (AvatarRandomGetRequestrandomgetPostAcceptEnum) {
    AvatarRandomGetRequestrandomgetPostAcceptEnum["ApplicationJson"] = "application/json";
})(AvatarRandomGetRequestrandomgetPostAcceptEnum || (AvatarRandomGetRequestrandomgetPostAcceptEnum = {}));
