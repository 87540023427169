/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { LocationDataFromJSON, LocationDataToJSON, } from './';
export function ContentCardListRequestFromJSON(json) {
    return ContentCardListRequestFromJSONTyped(json, false);
}
export function ContentCardListRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'locationData': !exists(json, 'LocationData') ? undefined : LocationDataFromJSON(json['LocationData']),
        'channel': !exists(json, 'Channel') ? undefined : json['Channel'],
    };
}
export function ContentCardListRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'LocationData': LocationDataToJSON(value.locationData),
        'Channel': value.channel,
    };
}
