import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import swopApi from "../../api";
// import { saveToken } from "../../services/ls";

const SLICE_NAME = "leaderboard";

export const requestLeaderboard = createAsyncThunk(
  `${SLICE_NAME}/requestLeaderboard`,
  async ({ GameId }, { getState }) => {
    // console.log(data);
    return swopApi.gameApi.leaderboardRequestleaderboardPost({
      accept: "application/json",
      gameId: GameId,
      followingOnly: getState().leaderboard.displayFollowings,
    });
  }
);

export const requestFollowingsToggle = createAsyncThunk(
  `${SLICE_NAME}/requestFollowingsToggle`,
  async (_, { dispatch, getState }) => {
    const state = getState();
    dispatch(
      requestLeaderboard({
        GameId: state.game.game.detail.GameId,
        FollowingOnly: state.leaderboard.displayFollowings,
      })
    );
  }
);

const initialState = {
  isLoading: false,
  displayFollowings: false,
  response: null,
};

const leaderboardSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetFollowingsToggle: (state) => {
      state.displayFollowings = false;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(requestLeaderboard.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(requestLeaderboard.fulfilled, (state, action) => {
        state.isLoading = false;
        state.response = action.payload;
      })
      .addCase(requestLeaderboard.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(requestFollowingsToggle.pending, (state) => {
        state.isLoading = true;
        state.displayFollowings = !state.displayFollowings;
      })
      .addCase(requestFollowingsToggle.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(requestFollowingsToggle.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { resetFollowingsToggle } = leaderboardSlice.actions;

export default leaderboardSlice.reducer;
