import { InfoOutlined, ReceiptOutlined } from "@mui/icons-material";
import { Box, Container, Divider, Typography } from "@mui/material";
import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { ReactComponent as FpCreditIcon } from "../graphics/fpCreditIcon.svg";
import { ReactComponent as Ticket } from "../graphics/ticket/ticket.svg";
import TicketListItem from "./TicketListItem";
import { OrderDialogType, OrderType } from "../../utils/types";
import ConfirmPurchaseButton from "./ConfirmPurchaseButton";
import ConfirmPurchaseMessage from "./ConfirmPurchaseMessage";
import {
  updateReceipt,
  updateFullTicketViewer,
} from "../../redux/slices/dialog.slice";
import { updateBuyDialog } from "../../redux/slices/game.slice";
import { getOrderDialogTypeFromOrderTypeId } from "../../utils/common-functions";
import { IconButton } from "@mui/material";
import { Clear } from "@mui/icons-material";
import { longFormNum } from "../../utils/common-functions";

function Receipt({ onClickCheckTicket, closeOtherDrawers }) {
  const { response: ticketOrder, ticketDetailsLoading } = useSelector(
    (state) => state.dialog.receipt
  );
  // const { orderType } = useSelector((state) => state.dialog.confirmationDialog);
  // const { detail: gameDetail } = useSelector((state) => state.game.game);
  const dispatch = useDispatch();
  if (!ticketOrder) return <div>Loading...</div>;
  const orderType = getOrderDialogTypeFromOrderTypeId(
    ticketOrder.TicketOrder.OrderTypeId
  );

  const getText = () => {
    switch (orderType) {
      case OrderDialogType.BUY:
        return { header: "Ticket Purchase Complete" };
      case OrderDialogType.CASHOUT:
        return { header: "Ticket Fold Complete" };
      case OrderDialogType.SWOP:
        return { header: "Ticket Swop Complete" };
      case OrderDialogType.SUPERSWOP:
        return { header: "Ticket Swop Complete" };
      case OrderDialogType.OUTCOMECASHOUT:
        return { header: "Ticket Fold Complete" };
      default:
        return {};
    }
  };

  const showTicketDetailByOrderType = () => {
    return (
      ticketOrder.TicketOrder.OrderTypeId !==
        OrderType.PlayerPrepurchaseFromPool &&
      ticketOrder.TicketOrder.OrderTypeId !== OrderType.CashoutToBank
    );
  };

  const { header } = getText();

  const onClose = () => {
    dispatch(updateReceipt({ show: false }));
    dispatch(updateFullTicketViewer({ show: false }));
    dispatch(updateBuyDialog(false));
    if (closeOtherDrawers) closeOtherDrawers();
  };

  return (
    <Box sx={{ bgcolor: "background.dark", color: "text.contrastText", py: 2 }}>
      <Container>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="subtitle1" component="p" sx={{ px: 2 }}>
            {header}
          </Typography>
          <IconButton onClick={onClose}>
            <Clear
              sx={{
                color: "text.contrastText",
              }}
            />
          </IconButton>
        </Box>
      </Container>

      <Divider light sx={{ my: 1 }} />
      <Container>
        <Typography component="p" variant="subtitle2" sx={{ px: 2 }}>
          {ticketOrder.GameName}
        </Typography>
      </Container>

      <Divider light sx={{ my: 1 }} />

      <Container>
        <Box sx={{ px: 2, "& svg": { fill: "white" } }}>
          <TicketListItem
            icon={<InfoOutlined sx={{ fontSize: "20px" }} />}
            title="GameID"
            value={ticketOrder.TicketOrder.GameId}
          />
          <TicketListItem
            icon={<ReceiptOutlined sx={{ fontSize: "20px" }} />}
            title="Receipt"
            value={ticketOrder.TicketOrder.TicketOrderId}
          />
          <TicketListItem
            icon={<Ticket />}
            title={ticketOrder.TicketOrder.Quantity > 1 ? "Tickets" : "Ticket"}
            value={longFormNum(ticketOrder.TicketOrder.Quantity)}
          />
          <TicketListItem
            icon={<FpCreditIcon width={16} height={16} />}
            title={
              orderType === OrderDialogType.CASHOUT ||
              orderType === OrderDialogType.OUTCOMECASHOUT
                ? "Total Paid"
                : "Total Cost"
            }
            value={longFormNum(ticketOrder.Amount.toFixed(2))}
          />
          {!ticketDetailsLoading && showTicketDetailByOrderType() && (
            <Box
              sx={{ textAlign: "center", px: 2, "& svg": { fill: "white" } }}
            >
              <Typography
                variant="body2"
                component="p"
                sx={{
                  color: "text.contrastText",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() =>
                  onClickCheckTicket(
                    ticketOrder.TicketOrder.GameId,
                    ticketOrder.TicketOrder.Quantity
                  )
                }
              >
                Check Ticket Details
              </Typography>
            </Box>
          )}
        </Box>
      </Container>

      <Divider light sx={{ my: 1 }} />
      <ConfirmPurchaseMessage state="success" orderType={orderType} />
      <Box sx={{ textAlign: "center", px: 2 }}>
        <ConfirmPurchaseButton
          state="success"
          onClose={onClose}
          iconType="cart"
        />
      </Box>
      {/* <Typography
        component="p"
        variant="caption"
        sx={{ opacity: 0.5, m: 1, textAlign: "center" }}
      >
        All tickets purchased in accordance with&nbsp;
        <Typography
          component="a"
          href="/#"
          sx={{ color: "primary.main", fontSize: "inherit" }}
        >
          SwopStakes Terms of Service
        </Typography>
        <br />
        If gambling&apos;s a problem call 1800 858 858
      </Typography> */}
    </Box>
  );
}

export default Receipt;
