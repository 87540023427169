import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { IsSwopStakeWeb } from "../services/ls";

function SwopStakesOnlyRoute({ children, redirectUrl = "/" }) {
  if (!IsSwopStakeWeb()) {
    console.log(`Detecting non swopstakes redirecting to ${redirectUrl}`);
    return <Navigate to={redirectUrl} replace />;
  }
  return children;
}

SwopStakesOnlyRoute.propTypes = {
  children: PropTypes.node.isRequired,
  redirectUrl: PropTypes.string,
};

SwopStakesOnlyRoute.defaultProps = {
  redirectUrl: "/",
};

export default SwopStakesOnlyRoute;
