/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function LinkPartnerAccountFromJSON(json) {
    return LinkPartnerAccountFromJSONTyped(json, false);
}
export function LinkPartnerAccountFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'partnerId': !exists(json, 'PartnerId') ? undefined : json['PartnerId'],
        'partnerUserId': !exists(json, 'PartnerUserId') ? undefined : json['PartnerUserId'],
        'partnerUserName': !exists(json, 'PartnerUserName') ? undefined : json['PartnerUserName'],
        'channel': !exists(json, 'Channel') ? undefined : json['Channel'],
    };
}
export function LinkPartnerAccountToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'PartnerId': value.partnerId,
        'PartnerUserId': value.partnerUserId,
        'PartnerUserName': value.partnerUserName,
        'Channel': value.channel,
    };
}
