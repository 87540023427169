/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { TBAccountFromJSON, TBAccountToJSON, } from './';
export function PartnerDetailFromJSON(json) {
    return PartnerDetailFromJSONTyped(json, false);
}
export function PartnerDetailFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'partnerId': !exists(json, 'PartnerId') ? undefined : json['PartnerId'],
        'partnerUserName': !exists(json, 'PartnerUserName') ? undefined : json['PartnerUserName'],
        'partnerName': !exists(json, 'PartnerName') ? undefined : json['PartnerName'],
        'partnerUserId': !exists(json, 'PartnerUserId') ? undefined : json['PartnerUserId'],
        'partnerSignUpDate': !exists(json, 'PartnerSignUpDate') ? undefined : (new Date(json['PartnerSignUpDate'])),
        'accountInformation': !exists(json, 'AccountInformation') ? undefined : TBAccountFromJSON(json['AccountInformation']),
        'bPayRef': !exists(json, 'BPayRef') ? undefined : json['BPayRef'],
    };
}
export function PartnerDetailToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'PartnerId': value.partnerId,
        'PartnerUserName': value.partnerUserName,
        'PartnerName': value.partnerName,
        'PartnerUserId': value.partnerUserId,
        'PartnerSignUpDate': value.partnerSignUpDate === undefined ? undefined : (value.partnerSignUpDate.toISOString()),
        'AccountInformation': TBAccountToJSON(value.accountInformation),
        'BPayRef': value.bPayRef,
    };
}
