import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import swopApi from "../../api";

const SLICE_NAME = "gameResult";

export const getGameResults = createAsyncThunk(
  `${SLICE_NAME}/getGameResults`,
  () => {
    return swopApi.gameApi.getGameResultsRequestresultsPost({
      accept: "application/json",
      body: {
        utcOffSet: moment().utcOffset(),
      },
    });
  }
);

export const getGameResultsWithDate = createAsyncThunk(
  `${SLICE_NAME}/getGameResultsWithDate`,
  ({ filterStartDate, filterEndDate }) => {
    return swopApi.gameApi.getGameResultsRequestresultsPost({
      accept: "application/json",
      body: {
        filterStartDate,
        filterEndDate,
      },
    });
  }
);

const gameResultSlice = createSlice({
  name: SLICE_NAME,
  initialState: {
    results: [],
    todayResults: [],
    yesterdayResults: [],
    loading: false,
    errorMessage: "",
    searchResults: [],
  },
  extraReducers(builder) {
    builder
      .addCase(getGameResults.pending, (state) => {
        state.loading = true;
      })
      .addCase(getGameResults.fulfilled, (state, action) => {
        state.loading = false;
        state.results = action.payload.gameResults;

        state.todayResults = action.payload.gameResults.filter((r) =>
          moment(r.advertisedStartTime).isSame(new Date(), "day")
        );
        state.yesterdayResults = action.payload.gameResults.filter((r) =>
          moment(r.advertisedStartTime).isSame(
            moment().subtract(1, "days"),
            "day"
          )
        );
      })
      .addCase(getGameResults.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
      })
      .addCase(getGameResultsWithDate.pending, (state) => {
        state.loading = true;
      })

      .addCase(getGameResultsWithDate.fulfilled, (state, action) => {
        state.loading = false;
        state.searchResults = action.payload.gameResults;
      })
      .addCase(getGameResultsWithDate.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
      });
  },
});

export default gameResultSlice.reducer;
