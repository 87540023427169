import React from "react";
import { ButtonBase, SvgIcon, Typography, Box } from "@mui/material";
import { ReactComponent as Tutorial } from "../graphics/tutorial.svg";
import { ReactComponent as Winners } from "../graphics/winners.svg";
import { ReactComponent as News } from "../graphics/news.svg";
import { ReactComponent as Facebook } from "../graphics/facebookFix.svg";
import { ReactComponent as Twitter } from "../graphics/twitter.svg";
import { ReactComponent as Youtube } from "../graphics/youtube.svg";

function Footer() {
  return (
    <footer style={{ width: "100%", marginTop: "24px" }}>
      <Box
        sx={{
          position: "relative",
          overflow: "hidden",
          height: 190,
          zIndex: 10,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            background: "linear-gradient(to bottom, #e9f1f3, #eeeeee)",
            // bottom: 5,
            bottom: 0,
            width: "100%",
            display: "block",
            height: 154,
            zIndex: 0,
          }}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1,
            "& > *": {
              mr: "16px !important",
              ml: "16px !important",
            },
          }}
        >
          <ButtonBase
            disableRipple
            disableTouchRipple
            sx={{
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                background: "linear-gradient(to bottom, #e9f1f3, #eeeeee)",
                border: "5px solid",
                borderColor: "background.default",
                width: 60,
                height: 60,
                borderRadius: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                "&:hover": {
                  background: (theme) => theme.palette.primary.main,
                },
              }}
            >
              <SvgIcon
                component={Tutorial}
                viewBox="0 0 18 32"
                sx={{
                  height: 24,
                }}
              />
            </Box>
            <Typography variant="body1" component="p">
              Tutorial
            </Typography>
          </ButtonBase>
          <ButtonBase
            disableRipple
            disableTouchRipple
            sx={{
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                background: "linear-gradient(to bottom, #e9f1f3, #eeeeee)",
                border: "5px solid",
                borderColor: "background.default",
                width: 60,
                height: 60,
                borderRadius: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                "&:hover": {
                  background: (theme) => theme.palette.primary.main,
                },
              }}
            >
              <SvgIcon
                component={Winners}
                viewBox="0 0 32 32"
                sx={{ height: 32 }}
              />
            </Box>
            <Typography variant="body1" component="p">
              Winners
            </Typography>
          </ButtonBase>
          <ButtonBase
            disableRipple
            disableTouchRipple
            sx={{
              display: { xs: "none", sm: "block" },
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                background: "linear-gradient(to bottom, #e9f1f3, #eeeeee)",
                border: "5px solid",
                borderColor: "background.default",
                width: 60,
                height: 60,
                borderRadius: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                "&:hover": {
                  background: (theme) => theme.palette.primary.main,
                },
              }}
            >
              <SvgIcon
                component={News}
                viewBox="0 0 34 32"
                sx={{ height: 32 }}
              />
            </Box>
            <Typography variant="body1" component="p">
              News
            </Typography>
          </ButtonBase>
        </Box>
        <Box sx={{ zIndex: 2, mt: 2 }}>
          <Typography
            variant="subtitle1"
            component="p"
            sx={{
              textAlign: "center",
              position: "relative",
              marginBottom: 1,
              fontWeight: 600,
            }}
          >
            Follow us on
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              "& > *": {
                mr: "8px !important",
                ml: "8px !important",
              },
            }}
          >
            <ButtonBase
              sx={{
                backgroundColor: (theme) => theme.palette.text.primary,
                borderRadius: "100%",
                width: 32,
                height: 32,
                "&:hover svg path": {
                  fill: (theme) => theme.palette.primary.main,
                },
              }}
            >
              <SvgIcon
                component={Facebook}
                viewBox="0 0 32 32"
                sx={{ height: 22 }}
              />
            </ButtonBase>
            <ButtonBase
              sx={{
                backgroundColor: (theme) => theme.palette.text.primary,
                borderRadius: "100%",
                width: 32,
                height: 32,
                "&:hover svg path": {
                  fill: (theme) => theme.palette.primary.main,
                },
              }}
            >
              <SvgIcon
                component={Twitter}
                viewBox="0 0 12 10"
                sx={{ height: 14 }}
              />
            </ButtonBase>
            <ButtonBase
              sx={{
                backgroundColor: (theme) => theme.palette.text.primary,
                borderRadius: "100%",
                width: 32,
                height: 32,
                "&:hover svg path": {
                  fill: (theme) => theme.palette.primary.main,
                },
              }}
            >
              <SvgIcon
                component={Youtube}
                viewBox="0 0 12 10"
                sx={{ height: 14 }}
              />
            </ButtonBase>
          </Box>
        </Box>
        <Box
          sx={{
            zIndex: "12",
            position: "absolute",
            left: "50%",
            bottom: 26,
            transform: "translateX(50%) translateX(64px)",
          }}
        >
          <Typography variant="body2" component="p">
            Env - {process.env.REACT_APP_ENV}
          </Typography>
        </Box>
      </Box>
    </footer>
  );
}

export default Footer;
