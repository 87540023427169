import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import swopApi from "../../api";
// import { saveToken } from "../../services/ls";

const SLICE_NAME = "playerFollowing";

const customPlayerFollowRequestPost = async (requestParameters) => {
  const headerParameters = {};
  headerParameters["Content-Type"] = "application/json";
  if (
    requestParameters.accept !== undefined &&
    requestParameters.accept !== null
  ) {
    headerParameters.Accept = String(requestParameters.accept);
  }

  const baseURL = swopApi.playerApi.configuration.configuration.basePath;
  headerParameters["x-ss-id"] =
    swopApi.playerApi.configuration.configuration.headers["x-ss-id"];

  const url = `${baseURL}/player/follow/{FollowingPlayerId}`.replace(
    `{${"FollowingPlayerId"}}`,
    encodeURIComponent(String(requestParameters.followingPlayerId))
  );

  return fetch(url, {
    method: "POST",
    headers: headerParameters,
    body: requestParameters.body,
  }).then((res) => res.json());
};

const customPlayerUnfollowRequestPost = async (requestParameters) => {
  const headerParameters = {};
  headerParameters["Content-Type"] = "application/json";
  if (
    requestParameters.accept !== undefined &&
    requestParameters.accept !== null
  ) {
    headerParameters.Accept = String(requestParameters.accept);
  }

  const baseURL = swopApi.playerApi.configuration.configuration.basePath;
  headerParameters["x-ss-id"] =
    swopApi.playerApi.configuration.configuration.headers["x-ss-id"];

  const url = `${baseURL}/player/unfollow/{FollowingPlayerId}`.replace(
    `{${"FollowingPlayerId"}}`,
    encodeURIComponent(String(requestParameters.followingPlayerId))
  );

  return fetch(url, {
    method: "POST",
    headers: headerParameters,
    body: requestParameters.body,
  }).then((res) => res.json());
};

export const requestFollow = createAsyncThunk(
  `${SLICE_NAME}/requestFollow`,
  async (unfollowingPlayerId) => {
    // return swopApi.playerApi.playerFollowRequestfollowfollowingPlayerIdPost({
    //   accept: "application/json",
    //   FollowingPlayerId: ticket.ticketDetails.detail.owner.playerId,
    // });
    return customPlayerFollowRequestPost({
      accept: "application/json",
      followingPlayerId: unfollowingPlayerId,
    });
  }
);

export const requestUnfollow = createAsyncThunk(
  `${SLICE_NAME}/requestUnfollow`,
  async (followingPlayerId) => {
    return customPlayerUnfollowRequestPost({
      accept: "application/json",
      followingPlayerId,
    });
  }
);

export const toggleFollowing = createAsyncThunk(
  `${SLICE_NAME}/toggleFollowing`,
  async (_, { dispatch, getState }) => {
    const { ticket } = getState();

    if (ticket?.ticketDetails?.detail?.owner?.isFollowing) {
      dispatch(requestUnfollow(ticket.ticketDetails.detail.owner.playerId));
    } else {
      dispatch(requestFollow(ticket.ticketDetails.detail.owner.playerId));
    }
  }
);

export const requestFollowingList = createAsyncThunk(
  `${SLICE_NAME}/requestFollowingList`,
  async () => {
    return swopApi.playerApi.playerGetFollowingListRequestfollowingPost({
      accept: "application/json",
    });
  }
);

export const requestSearch = createAsyncThunk(
  `${SLICE_NAME}/requestSearch`,
  async (nickname) => {
    return swopApi.playerApi.playerSearchRequestsearchPost({
      accept: "application/json",
      body: {
        playerNickname: nickname,
      },
    });
  }
);

const initialState = {
  isLoading: false,
  isSearching: false,
  changingFollowing: false,
  nickname: null,
  playerId: null,
  resultList: null,
  followingList: null,
  errorMessage: null,
};

const playerFollowingSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset: (state) => {
      state.resultList = undefined;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(toggleFollowing.pending, (state) => {
        state.changingFollowing = true;
      })
      .addCase(toggleFollowing.fulfilled, (state) => {
        state.changingFollowing = false;
      })
      .addCase(toggleFollowing.rejected, (state) => {
        state.changingFollowing = false;
      })
      .addCase(requestFollow.pending, (state) => {
        state.changingFollowing = true;
      })
      .addCase(requestFollow.fulfilled, (state) => {
        state.changingFollowing = false;
      })
      .addCase(requestFollow.rejected, (state) => {
        state.changingFollowing = false;
      })
      .addCase(requestUnfollow.pending, (state) => {
        state.changingFollowing = true;
      })
      .addCase(requestUnfollow.fulfilled, (state) => {
        state.changingFollowing = false;
      })
      .addCase(requestUnfollow.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(requestFollowingList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(requestFollowingList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.followingList = action.payload.playerList;
      })
      .addCase(requestFollowingList.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(requestSearch.pending, (state) => {
        state.isSearching = true;
      })
      .addCase(requestSearch.fulfilled, (state, action) => {
        state.isSearching = false;
        state.resultList = action.payload.playerList;
      })
      .addCase(requestSearch.rejected, (state) => {
        state.isSearching = false;
      });
  },
});
export const { reset } = playerFollowingSlice.actions;
export default playerFollowingSlice.reducer;
