/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function TBAccountFromJSON(json) {
    return TBAccountFromJSONTyped(json, false);
}
export function TBAccountFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'verified': !exists(json, 'verified') ? undefined : json['verified'],
        'activated': !exists(json, 'activated') ? undefined : json['activated'],
        'block': !exists(json, 'block') ? undefined : json['block'],
        'dob': !exists(json, 'dob') ? undefined : json['dob'],
        'accountBalance': !exists(json, 'account_balance') ? undefined : json['account_balance'],
        'balanceToTurnover': !exists(json, 'balance_to_turnover') ? undefined : json['balance_to_turnover'],
        'userToken': !exists(json, 'user_token') ? undefined : json['user_token'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'username': !exists(json, 'username') ? undefined : json['username'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'dobDay': !exists(json, 'dob_day') ? undefined : json['dob_day'],
        'dobMonth': !exists(json, 'dob_month') ? undefined : json['dob_month'],
        'dobYear': !exists(json, 'dob_year') ? undefined : json['dob_year'],
        'postcode': !exists(json, 'postcode') ? undefined : json['postcode'],
        'street': !exists(json, 'street') ? undefined : json['street'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'msisdn': !exists(json, 'msisdn') ? undefined : json['msisdn'],
        'marketingOptInFlag': !exists(json, 'marketing_opt_in_flag') ? undefined : json['marketing_opt_in_flag'],
        'tbSource': !exists(json, 'tb_source') ? undefined : json['tb_source'],
        'tbBtag': !exists(json, '_tb_btag') ? undefined : json['_tb_btag'],
    };
}
export function TBAccountToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'verified': value.verified,
        'activated': value.activated,
        'block': value.block,
        'dob': value.dob,
        'account_balance': value.accountBalance,
        'balance_to_turnover': value.balanceToTurnover,
        'user_token': value.userToken,
        'email': value.email,
        'username': value.username,
        'password': value.password,
        'first_name': value.firstName,
        'last_name': value.lastName,
        'title': value.title,
        'dob_day': value.dobDay,
        'dob_month': value.dobMonth,
        'dob_year': value.dobYear,
        'postcode': value.postcode,
        'street': value.street,
        'city': value.city,
        'state': value.state,
        'country': value.country,
        'msisdn': value.msisdn,
        'marketing_opt_in_flag': value.marketingOptInFlag,
        'tb_source': value.tbSource,
        '_tb_btag': value.tbBtag,
    };
}
