/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function ResponseErrorFromJSON(json) {
    return ResponseErrorFromJSONTyped(json, false);
}
export function ResponseErrorFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'errorCode': !exists(json, 'ErrorCode') ? undefined : json['ErrorCode'],
        'fieldName': !exists(json, 'FieldName') ? undefined : json['FieldName'],
        'message': !exists(json, 'Message') ? undefined : json['Message'],
        'meta': !exists(json, 'Meta') ? undefined : json['Meta'],
    };
}
export function ResponseErrorToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'ErrorCode': value.errorCode,
        'FieldName': value.fieldName,
        'Message': value.message,
        'Meta': value.meta,
    };
}
