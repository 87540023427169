import moment from "moment";

class PurchaseOrderStore {
  log(msg) {
    return;
    console.log(`PurchaseOrderStore: ${msg}`);
  }
  constructor() {
    if (!window.purchaseOrders) {
      window.purchaseOrders = {};
    }
    if (!window.ticketOrders) {
      window.ticketOrders = {};
    }
  }

  getPendingPurchaseOrders() {
    this.log("getPendingPurchaseOrders", window.purchaseOrders);
    return window.purchaseOrders;
  }

  // BY - buy click
  // SG - signalr
  // PO - pending order from polling
  addPurchaseOrder({ purchaseOrderId, from }) {
    if (Object.keys(window.purchaseOrders).includes(purchaseOrderId)) {
      return;
    }
    this.log(`addPurchaseOrder, purchaseOrderId:${purchaseOrderId}`);
    window.purchaseOrders[purchaseOrderId] = {
      completed: false,
      addFrom: from,
      addTimeStamp: moment().format(),
    };
  }

  addTicketOrderId({ ticketOrderId }) {
    this.log(`addTicketOrderId, ticketOrderId:${ticketOrderId}`);
    window.ticketOrders[ticketOrderId] = true;
  }

  isPurchaseOrderProcessed({ purchaseOrderId }) {
    if (Object.keys(window.purchaseOrders).includes(purchaseOrderId)) {
      if (window.purchaseOrders[purchaseOrderId].completed === true) {
        return true;
      }

      // ignore it - it is added from signlar, but not removed by 'buy' API
      // e.g. signalr from another session - do nothing in pending
      if (window.purchaseOrders[purchaseOrderId].addFrom === "SG") {
        return true;
      }

      return false;
    }
    return true; // not found
  }

  isTicketOrderIdProcessed({ ticketOrderId }) {
    this.log("isTicketOrderIdProcessed", {
      ticketOrderId,
      keys: Object.keys(window.ticketOrders),
    });
    if (Object.keys(window.ticketOrders).includes(ticketOrderId)) {
      return window.ticketOrders[ticketOrderId];
    }
    return false;
  }

  // BY - buy click
  // SG - signalr
  // PO - pending order from polling
  removePurchaseOrder({ purchaseOrderId, from }) {
    window.purchaseOrders[purchaseOrderId] = {
      ...window.purchaseOrders[purchaseOrderId],
      completed: true,
      removeFrom: from,
      removeTimeStamp: moment().format(),
    };
  }

  isProcessed({ purchaseOrderId }) {
    if (Object.keys(window.purchaseOrders).includes(purchaseOrderId)) {
      return window.purchaseOrders[purchaseOrderId];
    }
    return false;
  }
}

export const purchaseOrderStore = new PurchaseOrderStore();
