import { Popover } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

function PopoverMenu(props) {
  const { children, sx, anchorEl, ...otherProps } = props;
  return (
    <Popover
      disablePortal
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      container={anchorEl ? anchorEl.parentNode : undefined}
      anchorEl={anchorEl}
      sx={{
        display: { xs: "none", sm: "block" },
        "& .MuiPaper-root": {
          width: "fit-content",
          boxShadow:
            "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px",
        },
        ...sx,
      }}
      {...otherProps}
    >
      {children}
    </Popover>
  );
}

PopoverMenu.propTypes = {
  children: PropTypes.node.isRequired,
  sx: PropTypes.shape({}).isRequired,
};

export default PopoverMenu;
