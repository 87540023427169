/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { ContentCardListRequestToJSON, ContentCardListResponseFromJSON, } from '../models';
/**
 *
 */
export class ContentCardApi extends runtime.BaseAPI {
    /**
     */
    async contentCardListRequestCreateRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling contentCardListRequestCreate.');
        }
        const queryParameters = {};
        if (requestParameters.locationData !== undefined) {
            queryParameters['LocationData'] = requestParameters.locationData;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/contentCard`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ContentCardListRequestToJSON(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ContentCardListResponseFromJSON(jsonValue));
    }
    /**
     */
    async contentCardListRequestCreate(requestParameters, initOverrides) {
        const response = await this.contentCardListRequestCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async contentCardListRequestDeleteRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling contentCardListRequestDelete.');
        }
        const queryParameters = {};
        if (requestParameters.locationData !== undefined) {
            queryParameters['LocationData'] = requestParameters.locationData;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/contentCard`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ContentCardListResponseFromJSON(jsonValue));
    }
    /**
     */
    async contentCardListRequestDelete(requestParameters, initOverrides) {
        const response = await this.contentCardListRequestDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async contentCardListRequestGetRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling contentCardListRequestGet.');
        }
        const queryParameters = {};
        if (requestParameters.locationData !== undefined) {
            queryParameters['LocationData'] = requestParameters.locationData;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/contentCard`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ContentCardListResponseFromJSON(jsonValue));
    }
    /**
     */
    async contentCardListRequestGet(requestParameters, initOverrides) {
        const response = await this.contentCardListRequestGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async contentCardListRequestPostRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling contentCardListRequestPost.');
        }
        const queryParameters = {};
        if (requestParameters.locationData !== undefined) {
            queryParameters['LocationData'] = requestParameters.locationData;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/contentCard`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ContentCardListRequestToJSON(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ContentCardListResponseFromJSON(jsonValue));
    }
    /**
     */
    async contentCardListRequestPost(requestParameters, initOverrides) {
        const response = await this.contentCardListRequestPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
/**
    * @export
    * @enum {string}
    */
export var ContentCardListRequestCreateAcceptEnum;
(function (ContentCardListRequestCreateAcceptEnum) {
    ContentCardListRequestCreateAcceptEnum["ApplicationJson"] = "application/json";
})(ContentCardListRequestCreateAcceptEnum || (ContentCardListRequestCreateAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ContentCardListRequestDeleteAcceptEnum;
(function (ContentCardListRequestDeleteAcceptEnum) {
    ContentCardListRequestDeleteAcceptEnum["ApplicationJson"] = "application/json";
})(ContentCardListRequestDeleteAcceptEnum || (ContentCardListRequestDeleteAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ContentCardListRequestGetAcceptEnum;
(function (ContentCardListRequestGetAcceptEnum) {
    ContentCardListRequestGetAcceptEnum["ApplicationJson"] = "application/json";
})(ContentCardListRequestGetAcceptEnum || (ContentCardListRequestGetAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ContentCardListRequestPostAcceptEnum;
(function (ContentCardListRequestPostAcceptEnum) {
    ContentCardListRequestPostAcceptEnum["ApplicationJson"] = "application/json";
})(ContentCardListRequestPostAcceptEnum || (ContentCardListRequestPostAcceptEnum = {}));
