import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

function CircularSurvivalMetre({ value, size, sx, fontSize }) {
  const [progress, setProgress] = React.useState(0);

  // eslint-disable-next-line consistent-return
  React.useEffect(() => {
    if (progress < (+value.toFixed(2) || -1)) {
      const timerFunc = setTimeout(() => {
        setProgress(progress + 1);
      }, 10);
      return () => clearTimeout(timerFunc);
    }
    if (progress > value.toFixed(2)) {
      setProgress(+value.toFixed(2));
    }
  }, [progress, value]);

  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
        ...sx,
      }}
    >
      <CircularProgress
        variant="determinate"
        thickness={4}
        size={size || 40}
        value={100}
        sx={{
          color: (theme) => theme.palette.grey[200],
        }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="subtitle2"
          component="div"
          color="primary"
          fontWeight="700"
          fontSize={fontSize || undefined}
        >
          {`${Math.round(progress)}`}
          <Typography sx={{ fontSize: 8 }} component="span">
            %
          </Typography>
        </Typography>
      </Box>
      <CircularProgress
        variant="determinate"
        color="primary"
        disableShrink
        thickness={4}
        size={size || 40}
        sx={{
          position: "absolute",
          left: 0,
        }}
        value={progress}
      />
    </Box>
  );
}

CircularSurvivalMetre.propTypes = {
  value: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
  sx: PropTypes.shape({}).isRequired,
  fontSize: PropTypes.number,
};

CircularSurvivalMetre.defaultProps = {
  fontSize: undefined,
};

export default CircularSurvivalMetre;
