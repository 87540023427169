import { Box, Divider, Stack, Typography, SvgIcon } from "@mui/material";
import React from "react";
import {
  Timeline,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  timelineItemClasses,
} from "@mui/lab";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import {
  parseTicketId,
  knockedOutEventIndex,
  getEventTypeIconStringFromEventType,
  getCurrentEvent,
} from "../../utils/common-functions";
import { ReactComponent as KnockOut } from "../graphics/market-status/knockOut.svg";
import { ReactComponent as Tick } from "../graphics/market-status/tick.svg";
import { ReactComponent as TickSimple } from "../graphics/market-status/tickSimple.svg";
import { ReactComponent as TickBlack } from "../graphics/market-status/tickBlack.svg";
import { EventStatus, OutcomeResult } from "../../utils/types";

import TabPanel from "./TabPanel";
import CircularSurvivalMetre from "./CircularSurvivalMetre";
import SportIcon from "../SportIcon";

function OutcomesTab({ value, index, ticketNum }) {
  const theme = useTheme();

  const { detail: ticketDetail } = useSelector(
    (state) => state.ticket.ticketDetails
  );

  const { detail: gameDetail } = useSelector((state) => state.game.game);

  const ticketId = parseTicketId(ticketDetail.ticketId);

  const ticketOutcomeData = ticketId.map((bracketId, eventIndex) => {
    const event = gameDetail.GameEvents.find(
      (e) => e.EventIndex === eventIndex
    );
    const bracket = event?.Brackets.find((b) => b.BracketId === bracketId);
    if (!bracket) return null;
    const outcomes = bracket.OutcomeIds.map((outcomeId) =>
      event?.Event.Outcomes.find((o) => o?.OutcomeId === outcomeId)
    );
    return { event, bracket, outcomes };
  });

  const currentEvent = getCurrentEvent(gameDetail);

  const knockedOutEventIdx = ticketDetail.isKnockedOut
    ? knockedOutEventIndex(gameDetail, ticketId)
    : null;

  const knockOutIcon = (
    <SvgIcon
      component={KnockOut}
      viewBox="0 0 148 148"
      sx={{ width: "24px", height: "24x" }}
    />
  );

  const winIcon = (
    <SvgIcon
      component={Tick}
      viewBox="0 0 24 24"
      sx={{ width: "24px", height: "24x" }}
    />
  );

  // Add a new state for this. After being knocked out, all future rounds show a black tick
  const FutureRoundsAfterKnockoutIcon = (
    <SvgIcon
      component={TickBlack}
      viewBox="0 0 24 24"
      sx={{ width: "24px", height: "24x" }}
    />
  );

  const indexIcon = (i, isCurrent = false) => (
    <Box
      sx={{
        width: 12,
        height: 12,
        fontSize: 12,
        display: "flex",
        fontWeight: "bold",
        alignItems: "center",
        justifyContent: "center",
        color: isCurrent ? "white" : "text.primary",
      }}
    >
      {i}
    </Box>
  );

  const lockIcon = (isCurrent = false) => {
    return (
      <Box
        sx={{
          width: isCurrent ? 20 : 16,
          height: isCurrent ? 20 : 16,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <svg
          fill={isCurrent ? "#FFFFFF" : "#000000"}
          viewBox="0 0 24 24"
          height={isCurrent ? "20" : "16"}
          width={isCurrent ? "20" : "16"}
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>
            <path d="M18 8.5h-1v-2c0-2.76-2.24-5-5-5s-5 2.24-5 5v2h-1c-1.1 0-2 0.9-2 2v10c0 1.1 0.9 2 2 2h12c1.1 0 2-0.9 2-2v-10c0-1.1-0.9-2-2-2zM8.9 6.5c0-1.71 1.39-3.1 3.1-3.1s3.1 1.39 3.1 3.1v2h-6.2v-2zM18 20.5h-12v-10h12v10z" />
          </g>
        </svg>
      </Box>
    );
  };

  const getConcurrentEventIndexArray = (gameEvents) => {
    const indexArr = [];
    let eventGroup = -1;
    let concurrentEventIndex = [];

    for (let i = 0; i < gameEvents.length; i += 1) {
      if (gameEvents[i].EventGroup === 0) {
        indexArr.push([i]);
      } else if (gameEvents[i].EventGroup !== eventGroup) {
        eventGroup = gameEvents[i].EventGroup;

        concurrentEventIndex = [];
        for (let j = i; j < gameEvents.length; j += 1) {
          if (gameEvents[j].EventGroup === eventGroup) {
            concurrentEventIndex.push(j);
          }
        }
        indexArr.push(concurrentEventIndex);
      }
    }

    return indexArr;
  };

  const stepperIcon = ({ data, knockedOutEvent, isCurrent = false }) => {
    console.log(knockedOutEvent);
    // const knockedOutEventIdx = ticketDetail.isKnockedOut
    //   ? knockedOutEventIndex(gameDetail, ticketId)
    //   : null;

    let retValue;

    const { event } = data;

    if (knockedOutEventIdx === event.EventIndex) {
      // knocked out
      retValue = knockOutIcon;
    }
    // Black tick for future rounds after being knocked out
    else if (
      knockedOutEventIdx !== null &&
      event.EventIndex > knockedOutEventIdx
    ) {
      if (isCurrent) {
        retValue = indexIcon(event.EventIndex + 1, isCurrent);
      } else {
        retValue = FutureRoundsAfterKnockoutIcon;
      }
    } else {
      switch (event?.Event?.EventStatusId) {
        case EventStatus.Open:
          retValue = indexIcon(event.EventIndex + 1, isCurrent);

          break;
        case EventStatus.Closed:
        case EventStatus.AwaitingApproval:
          retValue = lockIcon(isCurrent);
          // retValue = indexIcon(event.EventIndex + 1, isCurrent);

          break;
        case EventStatus.Settled:
          // if (game.GameStatusId === GameStatus.Final) {
          //   retValue = indexIcon(event.EventIndex + 1, isCurrent);
          // }
          // if (
          //   knockedOutEventIdx != null &&
          //   knockedOutEventIdx < event.EventIndex
          // ) {
          //   retValue = indexIcon(event.EventIndex + 1, isCurrent);
          // }
          retValue = winIcon;
          break;

        case EventStatus.Abandoned:
          // if (game.GameStatusId === GameStatus.Final) {
          //   retValue = indexIcon(event.EventIndex + 1, isCurrent);
          // }
          retValue = winIcon;
          break;
        default:
          retValue = null;
          break;
      }
    }

    return retValue;
  };

  const renderVerticalTimelineItem = ({
    data,
    game,
    isCurrent = false,
    isConcurrent = false,
    isFirstConcurrent = false,
    isLastConcurrent = false,
    knockedOutEvent = null,
  }) => {
    const dotContent = stepperIcon({ data, game, knockedOutEvent, isCurrent });

    const { event, outcomes } = data;
    return (
      <TimelineItem key={event.EventIndex + 1}>
        <TimelineSeparator
          sx={{
            minWidth: "36px",
            position: "relative",
            backgroundColor: "rgba(255,255,255,0.2)",
            // The before css element is used to map concurrent events
            "::before": {
              content: '""',
              position: "absolute",
              top: "-4px",
              left: 0,
              right: 0,
              height:
                isCurrent && isLastConcurrent
                  ? "36px"
                  : isLastConcurrent
                  ? "32px"
                  : "calc(100%)",
              backgroundColor: isConcurrent
                ? "rgba(25,41,47,0.15)"
                : "transparent",
              zIndex: 1,
              borderRadius: isFirstConcurrent
                ? "16px 16px 0 0"
                : isLastConcurrent
                ? "0 0 16px 16px"
                : "0",
            },
          }}
        >
          <TimelineDot
            sx={{
              padding: "2px",
              // add conditional rendering for current round
              // bgcolor: "text.primary",

              bgcolor: isCurrent ? "text.primary" : "transparent",
              borderColor: "text.primary",
              margin: "0px auto",
              height: isCurrent ? "30px" : "24px",
              width: isCurrent ? "30px" : "24px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {dotContent}
          </TimelineDot>
          {ticketOutcomeData.length - 1 !== data.event.EventIndex && (
            <TimelineConnector sx={{ bgcolor: "text.primary" }} />
          )}
        </TimelineSeparator>
        <TimelineContent
          sx={{
            pl: 2,
            pr: 0,
            py: 0,
            color: "text.primary",
          }}
        >
          {/* Below should only be rendered for current Event */}
          {isCurrent ? (
            <Typography
              variant="subtitle2"
              component="p"
              lineHeight="1"
              fontWeight="500"
              fontSize="0.75rem"
              sx={{
                color: theme.palette.secondary.main,
                marginBottom: "4px",
              }}
            >
              Current Event
            </Typography>
          ) : null}
          <Typography
            variant="subtitle1"
            component="p"
            lineHeight="1"
            fontWeight="600"
          >
            {event?.Event?.EventName}
          </Typography>
          {outcomes?.map((o, i) => {
            const imgEventType = getEventTypeIconStringFromEventType(
              event?.Event?.EventType?.EventType
            );

            const eventType = event?.Event?.EventType?.EventType;

            const onlyOneInBracket = outcomes.length === 1;

            const eventResulted =
              event?.Event?.EventStatusId === EventStatus.Settled ||
              event?.Event?.EventStatusId === EventStatus.Abandoned;

            const isWinnerInBracket = outcomes.find(
              (outcome) =>
                outcome.OutcomeResult.OutcomeResultId === OutcomeResult.Won
            );

            const isWinner =
              o.OutcomeResult.OutcomeResultId === OutcomeResult.Won;

            const isScratched =
              o.OutcomeResult.OutcomeResultId === OutcomeResult.Scratched ||
              o.OutcomeResult.OutcomeResultId === OutcomeResult.LateScratched;

            return (
              <>
                <Stack
                  direction="row"
                  alignItems="start"
                  pt={1}
                  key={o?.OutcomeId}
                  sx={{ justifyContent: "space-between" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDiretion: "row",
                      gap: "8px",
                      alignItems: "start",
                    }}
                  >
                    <SportIcon
                      imageURL={o?.SilksImageUrl}
                      alt={o?.SilksImageUrl ? "SilkImage" : eventType}
                      imgEventType={imgEventType}
                      eventType={eventType}
                      height={24}
                      width={24}
                      boxHeight={36}
                      boxWidth={36}
                      imgHeight={32}
                      imgWidth={32}
                      greyout={isScratched}
                      inverse
                    />
                    <Box>
                      {/* <Typography
                        variant="subtitle2"
                        component="p"
                        sx={{
                          lineHeight: "unset",
                        }}
                      >
                        {o?.ExternalName}{" "}
                      </Typography> */}
                      <Typography
                        variant="subtitle2"
                        component="p"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          opacity: isScratched
                            ? 0.5
                            : isWinner ||
                              onlyOneInBracket ||
                              !eventResulted ||
                              !isWinnerInBracket
                            ? 1
                            : 0.5,
                        }}
                      >
                        {o?.OutcomeName}
                        {!onlyOneInBracket && isWinner && (
                          <SvgIcon
                            component={TickSimple}
                            viewBox="0 0 24 24"
                            sx={{
                              width: "20px",
                              height: "20x",
                              marginLeft: "4px",
                            }}
                          />
                        )}
                      </Typography>
                      <Typography
                        variant="caption"
                        component="p"
                        color="text.secondary"
                        sx={{
                          lineHeight: "unset",
                          opacity: isScratched
                            ? 0.5
                            : isWinner ||
                              onlyOneInBracket ||
                              !eventResulted ||
                              !isWinnerInBracket
                            ? 1
                            : 0.5,
                        }}
                      >
                        {event?.Event?.EventTypeId > 3
                          ? event?.Event?.SecondaryName
                          : isScratched
                          ? "Scratched"
                          : o?.Jockey_Driver}
                      </Typography>
                    </Box>
                  </Box>
                  {/* Add value for survival meter */}
                  {i === 0 && (
                    <CircularSurvivalMetre
                      value={
                        outcomes.reduce(
                          (total, item) => total + (item.Probability || 0),
                          0
                        ) * 100
                      }
                      size={36}
                      fontSize={14}
                    />
                  )}
                </Stack>
                {outcomes.length === i + 1 && <Divider sx={{ my: 1.5 }} />}
              </>
            );
          })}
        </TimelineContent>
      </TimelineItem>
    );
  };

  const renderConcurrentItems = ({ indexArray }) => {
    return indexArray.map((concurrentIndex) => {
      const data = ticketOutcomeData.find(
        (ticketOutcome) => ticketOutcome.event.EventIndex === concurrentIndex
      );
      const isConcurrent = true;
      const isFirstConcurrent = concurrentIndex === indexArray[0];

      const isLastConcurrent =
        concurrentIndex === indexArray[indexArray.length - 1];

      if (data !== undefined) {
        return (
          <React.Fragment key={index}>
            {renderVerticalTimelineItem({
              data,
              game: gameDetail,
              isConcurrent,
              isFirstConcurrent,
              isLastConcurrent,
              isCurrent: currentEvent
                ? currentEvent.EventId === data.event?.EventId
                : false,
              knockedOutEvent: ticketDetail.IsKnockedOut
                ? knockedOutEventIndex(gameDetail, ticketId)
                : null,
            })}
          </React.Fragment>
        );
      }
      return null;
    });
  };

  const displayStepperVertical = () => {
    if (gameDetail.GameEvents && gameDetail.GameEvents.length !== 0) {
      if (
        gameDetail.GameEvents.find((event) => event?.EventGroup !== 0) !==
        undefined
      ) {
        // there are concurrent events in the event list
        const indexArray = getConcurrentEventIndexArray(gameDetail.GameEvents);

        return indexArray.map((concurrentIndex) => {
          if (concurrentIndex.length === 1) {
            const data = ticketOutcomeData.find(
              (ticketOutcome) =>
                ticketOutcome.event.EventIndex === concurrentIndex[0]
            );
            if (data !== undefined) {
              return renderVerticalTimelineItem({
                data,
                game: gameDetail,
                isCurrent: currentEvent
                  ? currentEvent.EventId === data.event?.EventId
                  : false,
                knockedOutEvent: ticketDetail.IsKnockedOut
                  ? knockedOutEventIndex(gameDetail, ticketId)
                  : null,
              });
            }
            return null;
          }
          return (
            <Box key={indexArray}>
              {renderConcurrentItems({
                indexArray: concurrentIndex,
              })}
            </Box>
          );
        });
      }
      return ticketOutcomeData.map(
        (data) =>
          data &&
          renderVerticalTimelineItem({
            data,
            game: gameDetail,
            isCurrent: currentEvent
              ? currentEvent.EventId === data.event?.EventId
              : false,
            knockedOutEvent: ticketDetail.IsKnockedOut
              ? knockedOutEventIndex(gameDetail, ticketId)
              : null,
          })
      );
    }
    return null;
  };

  const renderVerticalTimeline = () => {
    return (
      <TabPanel value={value} index={index}>
        <Timeline
          sx={{
            padding: 0,
            color: "text.contrastText",
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
          }}
        >
          {displayStepperVertical()}
        </Timeline>
        <Box
          sx={{
            margin: "16px 0 -16px 0",
            textAlign: "center",
            fontSize: "0.875rem",
          }}
        >
          <Typography
            variant="subtitle1"
            color="rgba(25,37,42,0.6)"
            component="p"
            sx={{
              fontSize: "0.875rem",
            }}
          >
            Ticket #: {ticketNum}
          </Typography>
        </Box>
      </TabPanel>
    );
  };

  return <>{renderVerticalTimeline()}</>;
}
OutcomesTab.propTypes = {
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

export default OutcomesTab;
