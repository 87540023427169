import React from "react";
import { Typography, Box } from "@mui/material";
import { Image } from "mui-image";

// Importing images
import image1 from "../graphics/display-banner-test/image1.png";
import image2 from "../graphics/display-banner-test/image2.png";
import image3 from "../graphics/display-banner-test/image3.png";
import image4 from "../graphics/display-banner-test/image4.png";
import image5 from "../graphics/display-banner-test/image5.png";
import image6 from "../graphics/display-banner-test/image6.png";

// eslint-disable-next-line no-unused-vars
const imageUrls = [image1, image2, image3, image4, image5, image6];

function ImageBanner({ imageUrl, destinationUrl }) {
  return (
    <Box
      sx={{
        width: "280px",
        marginBottom: "8px",
      }}
    >
      <a href={destinationUrl} rel="noreferrer" target="_blank">
        <Image
          alt="promotional banner"
          src={imageUrl}
          duration={1000}
          sx={{
            width: "100%",
            height: "auto", // change this to maintain the original aspect ratio of the image
            objectFit: "cover",
            borderRadius: "5px",
          }}
        />
      </a>
    </Box>
  );
}

function RightDisplayBannerBar({ sideBanner }) {
  return (
    <Box
      sx={{
        width: "300px",
        position: "fixed",
        top: "152px",
        right: 0,
        padding: "0",
        boxSizing: "border-box",
        height: "100%",
        zIndex: "1000",
        overflowY: "auto",
        whitespace: "nowrap",
        backgroundColor: "#ffffff",
        paddingLeft: "10px",
        boxShadow: "rgba(0, 0, 0, 0.05) -4px 0px 6px 0px",
      }}
    >
      <Box
        sx={{
          width: "280px",
          marginBottom: "8px",
          padding: "8px 16px",
          backgroundColor: "white",
          borderRadius: "5px",
          marginTop: "10px",
        }}
      >
        <Typography
          variant="subtitle1"
          gutterBottom
          sx={{
            color: "rgba(25, 41, 47, 0.70)",
            textAlign: "center",
            fontFamily: "Roboto",
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "normal",
            fontFeatureSettings: "'clig' off, 'liga' off",
            marginBottom: "8px",
          }}
        >
          Want to play in big money games?
        </Typography>
        <Typography
          variant="body2"
          gutterBottom
          sx={{
            color: "rgba(25, 41, 47, 0.70)",
            textAlign: "center",
            fontFamily: "Roboto",
            fontSize: "12px",
            fontWeight: 400,
            lineHeight: "12.95px",
            fontFeatureSettings: "'clig' off, 'liga' off",
            marginBottom: "0px",
          }}
        >
          Join any one of our amazing Swopstakes partner sites, offering free
          and pay-to-play games everyday.
        </Typography>
      </Box>
      {sideBanner.map(({ DesktopImageUrl, DestinationLinkUrl }) => (
        <ImageBanner
          key={DesktopImageUrl}
          imageUrl={DesktopImageUrl}
          destinationUrl={DestinationLinkUrl}
        />
      ))}
    </Box>
  );
}

export default RightDisplayBannerBar;
