import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Close,
} from "@mui/icons-material";
import { Box, Button, Drawer, IconButton, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

function BottomButtonLayout({
  children,
  PaperProps,
  paginate,
  secondaryText,
  onLeftClick,
  onRightClick,
  onClick,
  showLeftButton,
  showRightButton,
  buttonDisabled,
  onClose,
  ...props
}) {
  return (
    <Drawer
      variant="persistent"
      // PaperProps={{
      //   sx: PaperProps
      //     ? { ...PaperProps.sx, overflow: "initial" }
      //     : { overflow: "initial" },
      // }}
      PaperProps={{
        sx: {
          ...(PaperProps && PaperProps.sx),
          overflow: "initial",
          borderTop: "none",
        },
      }}
      anchor="bottom"
      hideBackdrop
      {...props}
    >
      <Box
        sx={{
          // bgcolor: "background.paper",
          bgcolor: "#19292F",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          px: 1,
          boxShadow: "0px -4px 4px 0px #0000001A",
        }}
        height="56px"
      >
        <Button
          variant="contained"
          onClick={onClick}
          disabled={buttonDisabled}
          sx={{
            position: "absolute",
            top: "0",
            transform: "translate(-50%, -50%)",
            left: "50%",
            textTransform: "unset",
            whiteSpace: "nowrap",
            color: "#fff",
            "&.Mui-disabled": {
              color: "#19292F",
              bgcolor: "#CED0D0",
            },
          }}
        >
          {children}
        </Button>
        {/* This button does not work functionality needs to be hooked up  
        - for now i've hidden it so it doesnt show when paginating happens. */}
        {!paginate && (
          <IconButton
            onClick={onClose}
            sx={{
              ml: "auto",
              position: "absolute",
              right: "8px",
              top: "50%",
              transform: "translateY(-50%)",
              color: "#fff",
            }}
          >
            <Close />
          </IconButton>
        )}

        {paginate && (
          <>
            {showLeftButton && (
              <IconButton onClick={onLeftClick} sx={{ color: "#fff" }}>
                <KeyboardArrowLeft />
              </IconButton>
            )}
            <Typography
              variant="caption"
              component="p"
              sx={{
                flex: "1",
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
                top: "24px",
                color: "#fff",
              }}
            >
              {secondaryText}
            </Typography>
            {showRightButton && (
              <IconButton
                onClick={onRightClick}
                sx={{ ml: "auto", color: "#fff" }}
              >
                <KeyboardArrowRight />
              </IconButton>
            )}
          </>
        )}
      </Box>
    </Drawer>
  );
}

BottomButtonLayout.propTypes = {
  children: PropTypes.node.isRequired,
  PaperProps: PropTypes.shape({ sx: PropTypes.shape({}) }),
  paginate: PropTypes.bool,
  secondaryText: PropTypes.string,
  onLeftClick: PropTypes.func,
  onRightClick: PropTypes.func,
  onClick: PropTypes.func.isRequired,
  showLeftButton: PropTypes.bool,
  showRightButton: PropTypes.bool,
  buttonDisabled: PropTypes.bool,
  onClose: PropTypes.func,
};

BottomButtonLayout.defaultProps = {
  PaperProps: {},
  paginate: false,
  secondaryText: null,
  showLeftButton: false,
  showRightButton: false,
  buttonDisabled: false,
  onLeftClick: () => {},
  onRightClick: () => {},
  onClose: null,
};

export default BottomButtonLayout;
