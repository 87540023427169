/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { OtherPlayerInfoFromJSON, OtherPlayerInfoToJSON, } from './';
export function TicketHistoryResultFromJSON(json) {
    return TicketHistoryResultFromJSONTyped(json, false);
}
export function TicketHistoryResultFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'ticketOrderId': !exists(json, 'TicketOrderId') ? undefined : json['TicketOrderId'],
        'ticketHistoryId': !exists(json, 'TicketHistoryId') ? undefined : json['TicketHistoryId'],
        'orderDateTime': !exists(json, 'OrderDateTime') ? undefined : (new Date(json['OrderDateTime'])),
        'orderTypeId': !exists(json, 'OrderTypeId') ? undefined : json['OrderTypeId'],
        'fromPlayerId': !exists(json, 'FromPlayerId') ? undefined : json['FromPlayerId'],
        'fromPlayer': !exists(json, 'FromPlayer') ? undefined : OtherPlayerInfoFromJSON(json['FromPlayer']),
        'toPlayerId': !exists(json, 'ToPlayerId') ? undefined : json['ToPlayerId'],
        'toPlayer': !exists(json, 'ToPlayer') ? undefined : OtherPlayerInfoFromJSON(json['ToPlayer']),
        'share': !exists(json, 'Share') ? undefined : json['Share'],
        'relatedEventId': !exists(json, 'RelatedEventId') ? undefined : json['RelatedEventId'],
        'bracketId': !exists(json, 'BracketId') ? undefined : json['BracketId'],
        'isLatest': !exists(json, 'IsLatest') ? undefined : json['IsLatest'],
        'price': !exists(json, 'Price') ? undefined : json['Price'],
        'value': !exists(json, 'Value') ? undefined : json['Value'],
        'probability': !exists(json, 'Probability') ? undefined : json['Probability'],
    };
}
export function TicketHistoryResultToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'TicketOrderId': value.ticketOrderId,
        'TicketHistoryId': value.ticketHistoryId,
        'OrderDateTime': value.orderDateTime === undefined ? undefined : (value.orderDateTime.toISOString()),
        'OrderTypeId': value.orderTypeId,
        'FromPlayerId': value.fromPlayerId,
        'FromPlayer': OtherPlayerInfoToJSON(value.fromPlayer),
        'ToPlayerId': value.toPlayerId,
        'ToPlayer': OtherPlayerInfoToJSON(value.toPlayer),
        'Share': value.share,
        'RelatedEventId': value.relatedEventId,
        'BracketId': value.bracketId,
        'IsLatest': value.isLatest,
        'Price': value.price,
        'Value': value.value,
        'Probability': value.probability,
    };
}
