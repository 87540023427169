/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function PlayerDeviceTokenFromJSON(json) {
    return PlayerDeviceTokenFromJSONTyped(json, false);
}
export function PlayerDeviceTokenFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'playerId': !exists(json, 'PlayerId') ? undefined : json['PlayerId'],
        'deviceType': !exists(json, 'DeviceType') ? undefined : json['DeviceType'],
        'deviceToken': !exists(json, 'DeviceToken') ? undefined : json['DeviceToken'],
        'culture': !exists(json, 'Culture') ? undefined : json['Culture'],
        'deviceDetails': !exists(json, 'DeviceDetails') ? undefined : json['DeviceDetails'],
    };
}
export function PlayerDeviceTokenToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'PlayerId': value.playerId,
        'DeviceType': value.deviceType,
        'DeviceToken': value.deviceToken,
        'Culture': value.culture,
        'DeviceDetails': value.deviceDetails,
    };
}
