import { SwipeableDrawer, useTheme } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

function BottomDrawer(props) {
  const { children, sx, ...otherProps } = props;
  const theme = useTheme();

  return (
    <SwipeableDrawer
      disableScrollLock
      anchor="bottom"
      disableSwipeToOpen={false}
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        "& .MuiDrawer-paper": {
          bgcolor: "unset",
          borderTopLeftRadius: "5px",
          borderTopRightRadius: "5px",
          [theme.breakpoints.up("sm")]: {
            width: "460px",
            position: "absolute",
            left: "auto",
            height: "auto",
            bottom: 0,
          },
        },
        ...sx,
      }}
      {...otherProps}
    >
      {children}
    </SwipeableDrawer>
  );
}

BottomDrawer.propTypes = {
  children: PropTypes.node.isRequired,
  sx: PropTypes.shape({}),
};

BottomDrawer.defaultProps = {
  sx: {},
};

export default BottomDrawer;
