/* eslint-disable no-unused-vars */
import { KeyboardArrowDown } from "@mui/icons-material";
import {
  Box,
  Container,
  Divider,
  IconButton,
  Slide,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { /* useState, */ useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { ReactComponent as TicketIcon } from "../graphics/ticket/ticket.svg";
import BottomButtonLayout from "../layouts/BottomButtonLayout";
import FullTicketItem from "./FullTicketItem";
import { getTicketDetailsInfo } from "../../redux/slices/ticketOrder.slice";
import { updateConfirmationDialog } from "../../redux/slices/dialog.slice";
import {
  getCurrentEvent,
  isCashoutEnabled,
  longFormNum,
  numFormatWithMin,
  valueWithCurrency,
  validateBuyFromBankUpToEvent,
} from "../../utils/common-functions";
import {
  GameStatus,
  EventStatus,
  GameType,
  OrderDialogType,
} from "../../utils/types";
import { IsAuthenticated, IsSwopStakeWeb } from "../../services/ls";
import { toggleFollowing } from "../../redux/slices/playerFollowing.slice";
import DrawerHeader from "../layouts/DrawerHeader";

function FullTicketViewer({ onClose, data, defaultTicket, newTicketTotal }) {
  const [currentTicket, setCurrentTicket] = React.useState(defaultTicket || 0);
  const [direction, setDirection] = React.useState("right");
  const containerRef = React.useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChange = (v) => {
    if (v > currentTicket) {
      setDirection("right");
    } else {
      setDirection("left");
    }
    setCurrentTicket(v);
  };

  const { detail: gameDetail } = useSelector((state) => state.game.game);
  const { playerFollowing } = useSelector((state) => state);

  const { detail: ticketDetail } = useSelector(
    (state) => state.ticket.ticketDetails
  );
  const { detail: playerTicketSummary } = useSelector(
    (state) => state.game.playerTicket
  );
  const { ticketInfo } = useSelector((state) => state.game);
  const { response: playerDetail } = useSelector(
    (state) => state.user.playerSummary
  );

  if (data.length <= currentTicket && data.length !== 0) {
    setCurrentTicket(data.length - 1);
  }

  const curTicketId =
    data.length === 0
      ? 0
      : data.length > currentTicket
      ? data[currentTicket].ticketId
      : data[data.length - 1].ticketId;

  const buyTicket = (value) => {
    dispatch(
      updateConfirmationDialog({
        show: true,
        orderType: OrderDialogType.BUY,
        gameName: gameDetail.GameName,
        options: {
          fromPlayerId: 1,
          quantity: 1,
          requestedTickets: [ticketDetail.ticketId],
          expectedPrice: value,
        },
      })
    );
  };

  const cashoutTicket = (value) => {
    dispatch(
      updateConfirmationDialog({
        show: true,
        orderType: OrderDialogType.CASHOUT,
        gameName: gameDetail.GameName,
        options: {
          quantity: 1,
          requestedTickets: [ticketDetail.ticketId],
          expectedPrice: value,
        },
      })
    );
  };

  useEffect(() => {
    if (curTicketId !== 0) {
      dispatch(
        getTicketDetailsInfo({
          ticketId: curTicketId,
          gameID: gameDetail.GameId,
        })
      );
    }
  }, [curTicketId, playerFollowing.changingFollowing]);

  if (!ticketDetail) return <div />;

  const renderButton = () => {
    if (!ticketDetail || !ticketDetail.owner) return null;

    const currentEvent = getCurrentEvent(gameDetail);
    const { owner } = ticketDetail;

    if (gameDetail.GameStatusId === GameStatus.Suspended) {
      return { buttonText: "IN PROGRESS" };
    }

    if (!(IsAuthenticated() && playerDetail && playerDetail.playerId > 0)) {
      if (IsSwopStakeWeb()) {
        return {
          buttonText: `Login`,
          onButtonClick: () =>
            navigate("/login", { state: { from: window.location.pathname } }),
        };
      }
      return {
        buttonText: `Buy`,
      };
    }

    if (owner.playerId === 1) {
      if (
        !currentEvent ||
        gameDetail.GameTypeId === GameType.EASY_PLAY ||
        currentEvent.Event.EventStatusId !== EventStatus.Open ||
        !validateBuyFromBankUpToEvent({ game: gameDetail, event: currentEvent })
      )
        return null;
      const value =
        ticketDetail.value * (1 - currentEvent.PurchaseFromBankDiscount || 1);
      const numWithMin = numFormatWithMin(value);
      const formattedValue = longFormNum(numWithMin);
      // Dont Show $ for FreeToPlay games
      let finalValue = valueWithCurrency({
        game: gameDetail,
        value: formattedValue,
      });

      return {
        buttonText: `Buy Ticket - ${finalValue}`,
        onButtonClick: () => buyTicket(value),
      };
    }

    if (owner.playerId === playerDetail.playerId) {
      if (
        !currentEvent ||
        !isCashoutEnabled({
          game: gameDetail,
          soldTicketCount: ticketInfo.detail.soldTicketCount,
          event: currentEvent,
        }) ||
        currentEvent.Event.EventStatusId !== EventStatus.Open ||
        ticketDetail.isKnockedOut
      )
        return null;
      const value =
        ticketDetail.value * (1 - currentEvent.CashoutToBankDiscount || 1);
      const numWithMin = numFormatWithMin(value);
      const formattedValue = longFormNum(numWithMin);

      // Dont Show $ for FreeToPlay games
      let finalValue = valueWithCurrency({
        game: gameDetail,
        value: formattedValue,
      });
      return {
        buttonText: `Fold Ticket - ${finalValue}`,
        onButtonClick: () => cashoutTicket(value),
      };
    }

    return {
      buttonText: owner.isFollowing ? "Unfollow" : "Follow",
      onButtonClick: () => {
        dispatch(toggleFollowing());
      },
      disabled: playerFollowing.changingFollowing,
    };
  };

  const { buttonText, onButtonClick, disabled } = renderButton() || {};

  return (
    <Box
      sx={{
        backgroundColor: "#19292F",
        // backgroundColor: "rgba(40,183, 230, 0.05)",
        width: isMobile ? "100%" : "460px",
      }}
    >
      <Container
        maxWidth="md"
        disableGutters
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          maxHeight: "100vh",
        }}
      >
        <Box
          sx={{
            position: "sticky",
            width: "100%",
            top: 0,
            zIndex: 1,
          }}
        >
          <DrawerHeader
            onClose={onClose}
            // title={`${data.length} ${data.length === 1 ? "Ticket" : "Tickets"}`}
            title={gameDetail.GameName}
            DisplayTicketIcon
            displayBalance
          />
          <Divider />
        </Box>
        <Box
          ref={containerRef}
          sx={{
            backgroundColor: "rgba(40,183, 230, 0.05)",
            color: "text.contrastText",
            p: 2,
            height: "calc(100vh - 115px)",
            gridTemplateColumns: "100%",
            display: "grid",
            gridAutoFlow: "column",
            overflowY: "auto",
            overflowX: "hidden",
            "&::-webkit-scrollbar": {
              width: "8px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#F0F0F0",
              borderRadius: "8px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#888",
              borderRadius: "8px",
              "&:hover": {
                backgroundColor: "#555",
              },
            },
            scrollbarWidth: "thin",
            scrollbarColor: "#888 #F0F0F0",
          }}
        >
          {data.map((item, i) => (
            <Slide
              mountOnEnter
              unmountOnExit
              direction={
                i === currentTicket
                  ? direction === "left"
                    ? "right"
                    : "left"
                  : "left"
              }
              appear={false}
              in={currentTicket === i}
              container={containerRef.current}
              key={item.ticketId}
              sx={{ width: "100%", gridArea: "1/1/2/2" }}
            >
              <Box>
                <FullTicketItem
                  key={item.ticketId}
                  ticketNum={item.ticketId}
                  numOfTicket={data.length}
                />
              </Box>
            </Slide>
          ))}
        </Box>
        <BottomButtonLayout
          open
          paginate
          secondaryText={`${currentTicket + 1} of ${data?.length}`}
          showLeftButton={
            currentTicket <= (data?.length || 0) - 1 && currentTicket > 0
          }
          showRightButton={currentTicket < (data?.length || 0) - 1}
          onLeftClick={() => handleChange(currentTicket - 1)}
          onRightClick={() => handleChange(currentTicket + 1)}
          onClick={onButtonClick}
          // PaperProps={{
          //   sx: { position: "relative", width: "100%" },
          // }}
          PaperProps={{
            sx: {
              position: "relative",
              width: "100%",
            },
          }}
          buttonDisabled={onButtonClick === undefined || disabled}
        >
          {buttonText}
        </BottomButtonLayout>
      </Container>
    </Box>
  );
}
FullTicketViewer.propTypes = {
  defaultTicket: PropTypes.number,
  onClose: PropTypes.func,
  data: PropTypes.arrayOf({}),
};
FullTicketViewer.defaultProps = {
  onClose: () => ({}),
  defaultTicket: 0,
  data: [],
};

export default FullTicketViewer;
