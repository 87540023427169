/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function PlayerGameFromJSON(json) {
    return PlayerGameFromJSONTyped(json, false);
}
export function PlayerGameFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'gameId': !exists(json, 'GameId') ? undefined : json['GameId'],
        'gameStatusId': !exists(json, 'GameStatusId') ? undefined : json['GameStatusId'],
        'gameName': !exists(json, 'GameName') ? undefined : json['GameName'],
        'firstEventStartTime': !exists(json, 'FirstEventStartTime') ? undefined : (new Date(json['FirstEventStartTime'])),
        'freeToPlay': !exists(json, 'FreeToPlay') ? undefined : json['FreeToPlay'],
        'code': !exists(json, 'Code') ? undefined : json['Code'],
        'gameEvents': !exists(json, 'GameEvents') ? undefined : json['GameEvents'],
    };
}
export function PlayerGameToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'GameId': value.gameId,
        'GameStatusId': value.gameStatusId,
        'GameName': value.gameName,
        'FirstEventStartTime': value.firstEventStartTime === undefined ? undefined : (value.firstEventStartTime.toISOString()),
        'FreeToPlay': value.freeToPlay,
        'Code': value.code,
        'GameEvents': value.gameEvents,
    };
}
