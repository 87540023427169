/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function PlayerTicketSummaryFromJSON(json) {
    return PlayerTicketSummaryFromJSONTyped(json, false);
}
export function PlayerTicketSummaryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'isInGame': !exists(json, 'IsInGame') ? undefined : json['IsInGame'],
        'aliveTicketCount': !exists(json, 'AliveTicketCount') ? undefined : json['AliveTicketCount'],
        'ticketsValueCurrent': !exists(json, 'TicketsValueCurrent') ? undefined : json['TicketsValueCurrent'],
        'ticketsValueNext': !exists(json, 'TicketsValueNext') ? undefined : json['TicketsValueNext'],
        'survival': !exists(json, 'Survival') ? undefined : json['Survival'],
        'presaleTicketOrderCount': !exists(json, 'PresaleTicketOrderCount') ? undefined : json['PresaleTicketOrderCount'],
        'presaleTicketOrderCost': !exists(json, 'PresaleTicketOrderCost') ? undefined : json['PresaleTicketOrderCost'],
    };
}
export function PlayerTicketSummaryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'IsInGame': value.isInGame,
        'AliveTicketCount': value.aliveTicketCount,
        'TicketsValueCurrent': value.ticketsValueCurrent,
        'TicketsValueNext': value.ticketsValueNext,
        'Survival': value.survival,
        'PresaleTicketOrderCount': value.presaleTicketOrderCount,
        'PresaleTicketOrderCost': value.presaleTicketOrderCost,
    };
}
