/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ResponseStatusFromJSON, ResponseStatusToJSON, } from './';
export function RegisterResponseFromJSON(json) {
    return RegisterResponseFromJSONTyped(json, false);
}
export function RegisterResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'userId': !exists(json, 'UserId') ? undefined : json['UserId'],
        'sessionId': !exists(json, 'SessionId') ? undefined : json['SessionId'],
        'userName': !exists(json, 'UserName') ? undefined : json['UserName'],
        'referrerUrl': !exists(json, 'ReferrerUrl') ? undefined : json['ReferrerUrl'],
        'bearerToken': !exists(json, 'BearerToken') ? undefined : json['BearerToken'],
        'refreshToken': !exists(json, 'RefreshToken') ? undefined : json['RefreshToken'],
        'roles': !exists(json, 'Roles') ? undefined : json['Roles'],
        'permissions': !exists(json, 'Permissions') ? undefined : json['Permissions'],
        'responseStatus': !exists(json, 'ResponseStatus') ? undefined : ResponseStatusFromJSON(json['ResponseStatus']),
        'meta': !exists(json, 'Meta') ? undefined : json['Meta'],
    };
}
export function RegisterResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'UserId': value.userId,
        'SessionId': value.sessionId,
        'UserName': value.userName,
        'ReferrerUrl': value.referrerUrl,
        'BearerToken': value.bearerToken,
        'RefreshToken': value.refreshToken,
        'Roles': value.roles,
        'Permissions': value.permissions,
        'ResponseStatus': ResponseStatusToJSON(value.responseStatus),
        'Meta': value.meta,
    };
}
