import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import swopApi from "../../api";
import { GameStatus } from "../../utils/types";
import moment from "moment";

const SLICE_NAME = "gameHome";

export const getHomeGames = createAsyncThunk(
  `${SLICE_NAME}/gethomegames`,
  () => {
    return swopApi.gameApi.gameHomeRequesthomePost({
      accept: "application/json",
      body: {
        utcOffSet: moment().utcOffset(),
      },
    });
  }
);

export const getContentCard = createAsyncThunk(
  `${SLICE_NAME}/contentCard`,
  () => {
    return swopApi.contentCardApi.contentCardListRequestPost({
      accept: "application/json",
      body: {},
    });
  }
);

const gameHomeSlice = createSlice({
  name: SLICE_NAME,
  initialState: {
    gamePlayer: [],
    gameNonPlayer: [],
    loading: false,
    errorMessage: "",
    myGameResultIds: [],
    contentCard: {
      featuredContentCards: [],
      sideBannerContentCards: [],
      bottomContentCards: [],
      loading: false,
      errorMessage: "",
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getHomeGames.pending, (state) => {
        state.loading = true;
      })
      .addCase(getHomeGames.fulfilled, (state, action) => {
        state.loading = false;
        state.gameNonPlayer = action.payload.games.gameNonPlayer;
        state.gamePlayer = action.payload.games.gamePlayer;
        state.myGameResultIds = action.payload.games.gamePlayer
          .filter((g) =>
            [GameStatus.Final, GameStatus.Cancelled].includes(g.gameStatusId)
          )
          .map((r) => r.gameId);
      })
      .addCase(getHomeGames.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
      })
      .addCase(getContentCard.pending, (state) => {
        state.contentCard.loading = true;
      })
      // eslint-disable-next-line no-unused-vars
      .addCase(getContentCard.fulfilled, (state, action) => {
        state.contentCard.loading = false;

        state.contentCard.featuredContentCards =
          action.payload.cards.contentCards.filter((c) => c.Featured);

        // display = 0, display in the sidebar
        // display = 1, display at the bottom of homepage
        state.contentCard.sideBannerContentCards =
          action.payload.cards.contentCards.filter(
            (c) => !c.Featured && c.Display === 0
          );
        state.contentCard.bottomContentCards =
          action.payload.cards.contentCards.filter(
            (c) => !c.Featured && c.Display === 1
          );
      })
      .addCase(getContentCard.rejected, (state, action) => {
        state.contentCard.loading = false;
        state.contentCard.errorMessage = action.error.message;
      });
  },
});

export default gameHomeSlice.reducer;
