/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function SystemSettingsResponseFromJSON(json) {
    return SystemSettingsResponseFromJSONTyped(json, false);
}
export function SystemSettingsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'desktopImageUrl': !exists(json, 'DesktopImageUrl') ? undefined : json['DesktopImageUrl'],
        'mobileImageUrl': !exists(json, 'MobileImageUrl') ? undefined : json['MobileImageUrl'],
        'homePageUrl': !exists(json, 'HomePageUrl') ? undefined : json['HomePageUrl'],
        'onBoardingPageUrl': !exists(json, 'OnBoardingPageUrl') ? undefined : json['OnBoardingPageUrl'],
        'leaderboardCancelledImageUrl': !exists(json, 'LeaderboardCancelledImageUrl') ? undefined : json['LeaderboardCancelledImageUrl'],
        'leaderboardPausedImageUrl': !exists(json, 'LeaderboardPausedImageUrl') ? undefined : json['LeaderboardPausedImageUrl'],
        'leaderboardGameOverImageUrl': !exists(json, 'LeaderboardGameOverImageUrl') ? undefined : json['LeaderboardGameOverImageUrl'],
        'presaleLeaderboardDesktopImageUrl': !exists(json, 'PresaleLeaderboardDesktopImageUrl') ? undefined : json['PresaleLeaderboardDesktopImageUrl'],
        'presaleLeaderboardMobileImageUrl': !exists(json, 'PresaleLeaderboardMobileImageUrl') ? undefined : json['PresaleLeaderboardMobileImageUrl'],
        'gamecardPoolticketAvailablePercent': !exists(json, 'GamecardPoolticketAvailablePercent') ? undefined : json['GamecardPoolticketAvailablePercent'],
        'referFriendHeaderDesktopImageUrl': !exists(json, 'ReferFriendHeaderDesktopImageUrl') ? undefined : json['ReferFriendHeaderDesktopImageUrl'],
        'referFriendHeaderMobileImageUrl': !exists(json, 'ReferFriendHeaderMobileImageUrl') ? undefined : json['ReferFriendHeaderMobileImageUrl'],
        'maxTxPerOrder': !exists(json, 'MaxTxPerOrder') ? undefined : json['MaxTxPerOrder'],
        'maxTxPerPresaleOrder': !exists(json, 'MaxTxPerPresaleOrder') ? undefined : json['MaxTxPerPresaleOrder'],
        'maxTxPerSwop': !exists(json, 'MaxTxPerSwop') ? undefined : json['MaxTxPerSwop'],
        'promotionGameDash': !exists(json, 'PromotionGameDash') ? undefined : json['PromotionGameDash'],
        'promotionFooter': !exists(json, 'PromotionFooter') ? undefined : json['PromotionFooter'],
        'pTPEnabled': !exists(json, 'PTPEnabled') ? undefined : json['PTPEnabled'],
        'partnerId': !exists(json, 'PartnerId') ? undefined : json['PartnerId'],
        'promotionGameDashDestUrl': !exists(json, 'PromotionGameDashDestUrl') ? undefined : json['PromotionGameDashDestUrl'],
        'promotionFooterDestUrl': !exists(json, 'PromotionFooterDestUrl') ? undefined : json['PromotionFooterDestUrl'],
        'presaleContentCardImageDesktopUrl1': !exists(json, 'PresaleContentCardImageDesktopUrl1') ? undefined : json['PresaleContentCardImageDesktopUrl1'],
        'presaleContentCardImageDesktopUrl2': !exists(json, 'PresaleContentCardImageDesktopUrl2') ? undefined : json['PresaleContentCardImageDesktopUrl2'],
        'presaleContentCardImageMobileUrl1': !exists(json, 'PresaleContentCardImageMobileUrl1') ? undefined : json['PresaleContentCardImageMobileUrl1'],
        'presaleContentCardImageMobileUrl2': !exists(json, 'PresaleContentCardImageMobileUrl2') ? undefined : json['PresaleContentCardImageMobileUrl2'],
        'presaleContentCardDestinationUrl1': !exists(json, 'PresaleContentCardDestinationUrl1') ? undefined : json['PresaleContentCardDestinationUrl1'],
        'presaleContentCardDestinationUrl2': !exists(json, 'PresaleContentCardDestinationUrl2') ? undefined : json['PresaleContentCardDestinationUrl2'],
    };
}
export function SystemSettingsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'DesktopImageUrl': value.desktopImageUrl,
        'MobileImageUrl': value.mobileImageUrl,
        'HomePageUrl': value.homePageUrl,
        'OnBoardingPageUrl': value.onBoardingPageUrl,
        'LeaderboardCancelledImageUrl': value.leaderboardCancelledImageUrl,
        'LeaderboardPausedImageUrl': value.leaderboardPausedImageUrl,
        'LeaderboardGameOverImageUrl': value.leaderboardGameOverImageUrl,
        'PresaleLeaderboardDesktopImageUrl': value.presaleLeaderboardDesktopImageUrl,
        'PresaleLeaderboardMobileImageUrl': value.presaleLeaderboardMobileImageUrl,
        'GamecardPoolticketAvailablePercent': value.gamecardPoolticketAvailablePercent,
        'ReferFriendHeaderDesktopImageUrl': value.referFriendHeaderDesktopImageUrl,
        'ReferFriendHeaderMobileImageUrl': value.referFriendHeaderMobileImageUrl,
        'MaxTxPerOrder': value.maxTxPerOrder,
        'MaxTxPerPresaleOrder': value.maxTxPerPresaleOrder,
        'MaxTxPerSwop': value.maxTxPerSwop,
        'PromotionGameDash': value.promotionGameDash,
        'PromotionFooter': value.promotionFooter,
        'PTPEnabled': value.pTPEnabled,
        'PartnerId': value.partnerId,
        'PromotionGameDashDestUrl': value.promotionGameDashDestUrl,
        'PromotionFooterDestUrl': value.promotionFooterDestUrl,
        'PresaleContentCardImageDesktopUrl1': value.presaleContentCardImageDesktopUrl1,
        'PresaleContentCardImageDesktopUrl2': value.presaleContentCardImageDesktopUrl2,
        'PresaleContentCardImageMobileUrl1': value.presaleContentCardImageMobileUrl1,
        'PresaleContentCardImageMobileUrl2': value.presaleContentCardImageMobileUrl2,
        'PresaleContentCardDestinationUrl1': value.presaleContentCardDestinationUrl1,
        'PresaleContentCardDestinationUrl2': value.presaleContentCardDestinationUrl2,
    };
}
