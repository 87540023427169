/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function TicketBracketSummaryFromJSON(json) {
    return TicketBracketSummaryFromJSONTyped(json, false);
}
export function TicketBracketSummaryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'bracketId': !exists(json, 'BracketId') ? undefined : json['BracketId'],
        'numOfTickets': !exists(json, 'NumOfTickets') ? undefined : json['NumOfTickets'],
        'ticketsValueCurrent': !exists(json, 'TicketsValueCurrent') ? undefined : json['TicketsValueCurrent'],
        'ticketsValueNext': !exists(json, 'TicketsValueNext') ? undefined : json['TicketsValueNext'],
        'survival': !exists(json, 'Survival') ? undefined : json['Survival'],
        'tickets': !exists(json, 'Tickets') ? undefined : json['Tickets'],
    };
}
export function TicketBracketSummaryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'BracketId': value.bracketId,
        'NumOfTickets': value.numOfTickets,
        'TicketsValueCurrent': value.ticketsValueCurrent,
        'TicketsValueNext': value.ticketsValueNext,
        'Survival': value.survival,
        'Tickets': value.tickets,
    };
}
