/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function MyHeadersResponseFromJSON(json) {
    return MyHeadersResponseFromJSONTyped(json, false);
}
export function MyHeadersResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'headers': !exists(json, 'Headers') ? undefined : json['Headers'],
        'headersStr': !exists(json, 'HeadersStr') ? undefined : json['HeadersStr'],
        'headersDict': !exists(json, 'HeadersDict') ? undefined : json['HeadersDict'],
        'keys': !exists(json, 'Keys') ? undefined : json['Keys'],
        'values': !exists(json, 'Values') ? undefined : json['Values'],
        'remoteIp': !exists(json, 'RemoteIp') ? undefined : json['RemoteIp'],
    };
}
export function MyHeadersResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'Headers': value.headers,
        'HeadersStr': value.headersStr,
        'HeadersDict': value.headersDict,
        'Keys': value.keys,
        'Values': value.values,
        'RemoteIp': value.remoteIp,
    };
}
