/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function PlayerGreenIdFromJSON(json) {
    return PlayerGreenIdFromJSONTyped(json, false);
}
export function PlayerGreenIdFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'Id') ? undefined : json['Id'],
        'playerId': !exists(json, 'PlayerId') ? undefined : json['PlayerId'],
        'greenIdRef': !exists(json, 'GreenIdRef') ? undefined : json['GreenIdRef'],
        'greenIdVeritificationToken': !exists(json, 'GreenIdVeritificationToken') ? undefined : json['GreenIdVeritificationToken'],
        'currentStatus': !exists(json, 'CurrentStatus') ? undefined : json['CurrentStatus'],
    };
}
export function PlayerGreenIdToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'Id': value.id,
        'PlayerId': value.playerId,
        'GreenIdRef': value.greenIdRef,
        'GreenIdVeritificationToken': value.greenIdVeritificationToken,
        'CurrentStatus': value.currentStatus,
    };
}
