import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import handleGameGroupJoin from "../../services/signalr-triggers/handleGameGroupJoin";
import handlePlayerGroupJoin from "../../services/signalr-triggers/handlePlayerGroupJoin";
import PushReceivers from "../../services/signalr-triggers/pushReceivers";

// We can later change this to be a context if not enough as a middleware.
// Important: No business logic here

const getConnectionInfo = (negotiateUrl) =>
  fetch(negotiateUrl, { method: "POST" }).then((r) => r.json());

const createSignalRMiddleware = (negotiateUrl) => (store) => {
  let connection;
  let retrySecond = 0;
  function startConnection() {
    getConnectionInfo(negotiateUrl).then(
      ({ url: connectionUrl, accessToken }) => {
        connection = new HubConnectionBuilder()
          .withUrl(connectionUrl, {
            accessTokenFactory: () => accessToken,
          })
          .configureLogging(LogLevel.Information)
          .withAutomaticReconnect()
          .build();
        Object.keys(PushReceivers).forEach((k) => {
          connection.on(k, (msg) => {
            PushReceivers[k]({ msg, store });
          });
        });
        connection.onclose(() => {
          console.log("SignalR: Connection closed, reestablishing...");
          retrySecond += 1;
          setTimeout(() => {
            startConnection();
          }, 1000 * retrySecond);
        });
        connection
          .start()
          .then(() => {
            console.log("SignalR: Connection established");
          })
          .catch((e) => {
            console.log({ e });
            retrySecond += 1;
            console.log(
              `SignalR: Connection error, retrying in ${retrySecond} seconds...`
            );
            setTimeout(() => {
              startConnection();
            }, 1000 * retrySecond);
          });
      }
    );
  }

  startConnection();

  return (next) => (action) => {
    handleGameGroupJoin({ action, connection });
    handlePlayerGroupJoin({ action, connection });
    next(action);
  };
};

export default createSignalRMiddleware;
