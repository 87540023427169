/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiResultFromJSON, ApiResultToJSON, } from './';
export function ApiMultipleResultFromJSON(json) {
    return ApiMultipleResultFromJSONTyped(json, false);
}
export function ApiMultipleResultFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'extras': !exists(json, 'Extras') ? undefined : (json['Extras'].map(ApiResultFromJSON)),
        'errorNo': !exists(json, 'ErrorNo') ? undefined : json['ErrorNo'],
        'message': !exists(json, 'Message') ? undefined : json['Message'],
        'identity': !exists(json, 'Identity') ? undefined : json['Identity'],
    };
}
export function ApiMultipleResultToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'Extras': value.extras === undefined ? undefined : (value.extras.map(ApiResultToJSON)),
        'ErrorNo': value.errorNo,
        'Message': value.message,
        'Identity': value.identity,
    };
}
