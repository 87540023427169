/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { OtherPlayerInfoFromJSON, OtherPlayerInfoToJSON, TicketHistoryResultFromJSON, TicketHistoryResultToJSON, } from './';
export function TicketDetailResponseFromJSON(json) {
    return TicketDetailResponseFromJSONTyped(json, false);
}
export function TicketDetailResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'ticketId': !exists(json, 'TicketId') ? undefined : json['TicketId'],
        'owner': !exists(json, 'Owner') ? undefined : OtherPlayerInfoFromJSON(json['Owner']),
        'value': !exists(json, 'Value') ? undefined : json['Value'],
        'valueNext': !exists(json, 'ValueNext') ? undefined : json['ValueNext'],
        'probability': !exists(json, 'Probability') ? undefined : json['Probability'],
        'isKnockedOut': !exists(json, 'IsKnockedOut') ? undefined : json['IsKnockedOut'],
        'histories': !exists(json, 'Histories') ? undefined : (json['Histories'].map(TicketHistoryResultFromJSON)),
    };
}
export function TicketDetailResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'TicketId': value.ticketId,
        'Owner': OtherPlayerInfoToJSON(value.owner),
        'Value': value.value,
        'ValueNext': value.valueNext,
        'Probability': value.probability,
        'IsKnockedOut': value.isKnockedOut,
        'Histories': value.histories === undefined ? undefined : (value.histories.map(TicketHistoryResultToJSON)),
    };
}
