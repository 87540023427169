import { Box, Checkbox, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as TicketWide } from "../graphics/ticket/ticket-wide.svg";
import { ReactComponent as FoldTick } from "../graphics/market-status/foldTick.svg";
import { ReactComponent as FoldTickOutline } from "../graphics/market-status/foldTickOutline.svg";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

function SingleTicketItem({
  selected,
  children,
  id,
  setSelected,
  selectable,
  handleClick,
}) {
  const handleChange = () => {
    if (selected) {
      setSelected((prev) => [...prev.filter((v) => v !== id)]);
    } else {
      setSelected((prev) => [...prev, id]);
    }
  };
  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        transition: "0.2s ease all",
        cursor: "pointer",
        "&:hover": {
          transform: "scale(1.03)",
        },
        "&:active": {
          transform: "scale(0.98)",
        },
      }}
    >
      <TicketWide
        style={{ cursor: "pointer" }}
        onClick={selectable ? handleChange : handleClick}
      />
      <Typography
        component="p"
        variant="subtitle1"
        sx={{
          fontWeight: "600",
          pointerEvents: "none",
          position: "absolute",
          zIndex: 1,
          top: "18px",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        {children}
      </Typography>
      {selectable && (
        <Checkbox
          size="small"
          sx={{
            position: "absolute",
            zIndex: 1,
            bottom: 0,
            left: "50%",
            transform: "translateX(-50%)",
            color: (theme) => theme.palette.secondary.main,
            "&.Mui-checked": {
              color: (theme) => theme.palette.secondary.main,
            },
            "& .MuiSvgIcon-root": {
              borderRadius: "50%", // Circular shape
            },
          }}
          // color="secondary"
          checked={selected}
          checkedIcon={<FoldTick />}
          icon={<FoldTickOutline />}
          // checkedIcon={<CheckCircleIcon />}
          // icon={<RadioButtonUncheckedIcon />}
          defaultChecked={selected}
          onChange={handleChange}
        />
      )}
    </Box>
  );
}

SingleTicketItem.propTypes = {
  selected: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  setSelected: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  selectable: PropTypes.bool.isRequired,
};

export default SingleTicketItem;
