import { createSlice, createAction, createAsyncThunk } from "@reduxjs/toolkit";
import swopApi from "../../api";

const SLICE_NAME = "systemSettings";

export const responseSystemSettings = createAction(
  `${SLICE_NAME}/responseSystemSettings`,
  (payload) => ({ payload })
);

export const requestSystemSettings = createAsyncThunk(
  `${SLICE_NAME}/requestSystemSettings`,
  async (_, { dispatch }) => {
    const response =
      await swopApi.gameApi.systemSettingsRequestsystemSettingsPost({
        accept: "application/json",
      });
    dispatch(
      responseSystemSettings({
        response,
      })
    );
  }
);

const systemSettingSlice = createSlice({
  name: SLICE_NAME,
  initialState: {
    isLoading: false,
    mobileHeaderImageUrl: null,
    desktopHeaderImageUrl: null,
    homePageUrl: null,
    onBoardingPageUrl: null,
    leaderboardCancelledImageUrl: null,
    leaderboardGameOverImageUrl: null,
    leaderboardPausedImageUrl: null,
    presaleLeaderboardDesktopImageUrl: null,
    presaleLeaderboardMobileImageUrl: null,
    presaleContentCardDesktopImageUrl1: null,
    presaleContentCardDesktopImageUrl2: null,
    presaleContentCardMobileImageUrl1: null,
    presaleContentCardMobileImageUrl2: null,
    presaleContentCardDestinationUrl1: null,
    presaleContentCardDestinationUrl2: null,
    gamecardPoolticketAvailablePercent: null,
    referFriendHeaderDesktopImageUrl: null,
    referFriendHeaderMobileImageUrl: null,
    maxTxPerOrder: null,
    maxTxPerPresaleOrder: null,
    maxTxPerSwop: null,
    promotionGameDash: null,
    promotionFooter: null,
    promotionGameDashDestUrl: null,
    promotionFooterDestUrl: null,
    ptpEnabled: null,
    partnerId: null,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(requestSystemSettings.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(requestSystemSettings.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(requestSystemSettings.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(responseSystemSettings, (state, action) => {
        state.isLoading = false;
        const data = action.payload.response;
        state.mobileHeaderImageUrl = data.mobileImageUrl;
        state.desktopHeaderImageUrl = data.desktopImageUrl;
        state.homePageUrl = data.homePageUrl;
        state.onBoardingPageUrl = data.onBoardingPageUrl;
        state.leaderboardGameOverImageUrl = data.leaderboardGameOverImageUrl;
        state.leaderboardCancelledImageUrl = data.leaderboardCancelledImageUrl;
        state.leaderboardPausedImageUrl = data.leaderboardPausedImageUrl;
        state.presaleLeaderboardDesktopImageUrl =
          data.presaleLeaderboardDesktopImageUrl;
        state.presaleLeaderboardMobileImageUrl =
          data.presaleLeaderboardMobileImageUrl;
        state.presaleContentCardDesktopImageUrl1 =
          data.presaleContentCardImageDesktopUrl1;
        state.presaleContentCardDesktopImageUrl2 =
          data.presaleContentCardImageDesktopUrl2;
        state.presaleContentCardMobileImageUrl1 =
          data.presaleContentCardImageMobileUrl1;
        state.presaleContentCardMobileImageUrl2 =
          data.presaleContentCardImageMobileUrl2;
        state.gamecardPoolticketAvailablePercent =
          data.gamecardPoolticketAvailablePercent;
        state.referFriendHeaderDesktopImageUrl =
          data.referFriendHeaderDesktopImageUrl;
        state.referFriendHeaderMobileImageUrl =
          data.referFriendHeaderMobileImageUrl;
        state.maxTxPerOrder = data.maxTxPerOrder;
        state.maxTxPerPresaleOrder = data.maxTxPerPresaleOrder;
        state.maxTxPerSwop = data.maxTxPerSwop;
        state.promotionGameDash = data.promotionGameDash;
        state.promotionFooter = data.promotionFooter;
        state.ptpEnabled = data.pTPEnabled;
        state.partnerId = data.partnerId;
        state.promotionGameDashDestUrl = data.promotionGameDashDestUrl;
        state.promotionFooterDestUrl = data.promotionFooterDestUrl;
        state.presaleContentCardDestinationUrl1 =
          data.presaleContentCardDestinationUrl1;
        state.presaleContentCardDestinationUrl2 =
          data.presaleContentCardDestinationUrl2;
      });
  },
});

export default systemSettingSlice.reducer;
