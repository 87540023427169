/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function FinalFromJSON(json) {
    return FinalFromJSONTyped(json, false);
}
export function FinalFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'majorPrizeWon': !exists(json, 'MajorPrizeWon') ? undefined : json['MajorPrizeWon'],
        'prizeAmount': !exists(json, 'PrizeAmount') ? undefined : json['PrizeAmount'],
        'consolationCreditsWon': !exists(json, 'ConsolationCreditsWon') ? undefined : json['ConsolationCreditsWon'],
        'ticketId': !exists(json, 'TicketId') ? undefined : json['TicketId'],
    };
}
export function FinalToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'MajorPrizeWon': value.majorPrizeWon,
        'PrizeAmount': value.prizeAmount,
        'ConsolationCreditsWon': value.consolationCreditsWon,
        'TicketId': value.ticketId,
    };
}
