import {
  getPlayerTicketSummary,
  getTicketInfo,
  getTicketBracketSummary,
  getBankTickets,
  getGameDetail,
  updateActiveTicket,
  updateTotalPlayersInGame,
} from "../../redux/slices/game.slice";
import {
  updateConfirmationDialog,
  updateReceipt,
  getPurchasedTicketDetails,
  updatePendingDialog,
} from "../../redux/slices/dialog.slice";
import { OrderType } from "../../utils/types";
import {
  getPlayerNotifications,
  updateBadge,
} from "../../redux/slices/notification.slice";
import {
  getPendingOrders,
  getTicketDetailsInfo,
} from "../../redux/slices/ticketOrder.slice";
import { getPlayerSummary } from "../../redux/slices/user.slice";
import {
  debounce,
  getOrderDialogTypeFromOrderTypeId,
} from "../../utils/common-functions";
import { purchaseOrderStore } from "@/purchase_order_store";

const DEBOUNCE_TIME = 2000; // ms

const debouncedRefreshAfterPurchase = debounce(({ msg, store, game }) => {
  // update player summary and balance
  store.dispatch(getPlayerSummary());

  if (game && game.detail && game.detail.GameId) {
    store.dispatch(getPlayerTicketSummary({ gameId: game.detail.GameId }));
    store.dispatch(getTicketInfo({ gameId: game.detail.GameId }));
    if (store.getState().user.playerSummary.response) {
      store.dispatch(
        getTicketBracketSummary({
          gameId: game.detail.GameId,
          eventIndex: game.selectedEvent.EventIndex,
        })
      );
    }
    if (
      msg &&
      msg.TicketOrder &&
      msg.TicketOrder.OrderTypeId === OrderType.PurchaseFromBank
    ) {
      store.dispatch(
        getBankTickets({
          gameId: game.detail.GameId,
          eventIndex: game.selectedEvent.EventIndex,
        })
      );
    }
  }
}, DEBOUNCE_TIME);

const commonTicketPurchaseHandler = ({ pusEventType, msg, store }) => {
  const { game } = store.getState().game;
  const { pendingOrders, ticketDetails } = store.getState().ticket;

  console.log("Pushed Receiver commonTicketPurchaseHandler", {
    pusEventType,
    gameid: game.detail.GameId,
    msg,
    store,
    PurchaseOrderId: msg.PurchaseOrderId,
    purchaseOrders: purchaseOrderStore.getPendingPurchaseOrders(),
    isOrderContain: Object.keys(
      purchaseOrderStore.getPendingPurchaseOrders()
    ).includes(msg.PurchaseOrderId),
  });

  if (
    Object.keys(purchaseOrderStore.getPendingPurchaseOrders()).includes(
      msg.PurchaseOrderId
    )
  ) {
    purchaseOrderStore.removePurchaseOrder({
      purchaseOrderId: msg.PurchaseOrderId,
      from: "SG",
    });

    console.log(
      "Pushed Receiver commonTicketPurchaseHandler [removePurchaseOrder]",
      {
        purchaseOrderId: msg.PurchaseOrderId,
      }
    );
  } else {
    // not found as signalr faster than buy addPurchaseOrder
    purchaseOrderStore.addPurchaseOrder({
      purchaseOrderId: msg.PurchaseOrderId,
      from: "SG",
    });
    console.log(
      "commonTicketPurchaseHandler > key not found > addPurchaseOrder",
      {
        purchaseOrderId: msg.PurchaseOrderId,
      }
    );
  }

  if (pendingOrders.detail.length > 0) {
    store.dispatch(getPendingOrders());
  }
  store.dispatch(
    updatePendingDialog({
      show: false,
    })
  );
  if (msg.ErrorNo !== 0) {
    console.log("Pushed Receiver commonTicketPurchaseHandler [Order fail]", {
      purchaseOrderId: msg.PurchaseOrderId,
      msg,
      error: { message: msg.Message, errorNo: msg.ErrorNo },
    });
    const { confirmationDialog } = store.getState().dialog;
    store.dispatch(
      updateConfirmationDialog({
        show: true,
        processing: false,
        error: { message: msg.Message, errorNo: msg.ErrorNo },
        gameName: msg.GameName,
        orderType: getOrderDialogTypeFromOrderTypeId(
          msg.TicketOrder.OrderTypeId
        ),
        options: {
          ...confirmationDialog.options,
          expectedPrice: msg.Amount ?? confirmationDialog.options.expectedPrice,
          quantity:
            msg.RemainingQuantity ??
            msg.TicketOrder?.Quantity ??
            confirmationDialog.options.quantity,
        },
      })
    );
    return;
  }
  store.dispatch(updateConfirmationDialog({ show: false, processing: false }));
  store.dispatch(updateReceipt({ show: true, response: msg }));
  console.log(
    "Pushed Receiver commonTicketPurchaseHandler [Order successful]",
    {
      purchaseOrderId: msg.PurchaseOrderId,
      msg,
    }
  );
  // refresh the current ticket in the FullTicketViewer so that it show the latest button (e.g. cashout , buy ticket)
  if (
    msg.TicketOrder &&
    msg.TicketOrder.Quantity === 1 &&
    msg.TicketOrder.GameId === game.detail.GameId &&
    ticketDetails.detail &&
    ticketDetails.detail.ticketId
  ) {
    store.dispatch(
      getTicketDetailsInfo({
        ticketId: ticketDetails.detail.ticketId,
        gameID: game.detail.GameId,
      })
    );
  }

  store.dispatch(
    getPurchasedTicketDetails({
      gameId: msg.TicketOrder.GameId,
      ticketOrderId: msg.Identity,
    })
  );

  debouncedRefreshAfterPurchase({
    msg,
    store,
    game,
  });
};

const debouncedGameRefresh = debounce(({ store, gameId }) => {
  store.dispatch(getGameDetail({ gameId }));
  // update player summary and balance
  store.dispatch(getPlayerSummary());
}, 10 * 1000);

const PushReceivers = {
  // Target name as key and function as value
  InsertPlayerPrepurchaseOrder: ({ msg, store }) => {
    commonTicketPurchaseHandler({
      msg,
      store,
      pusEventType: "InsertPlayerPrepurchaseOrder",
    });
  },
  PurchaseFromPool: ({ msg, store }) => {
    commonTicketPurchaseHandler({
      msg,
      store,
      pusEventType: "PurchaseFromPool",
    });
  },
  PurchaseFromBank: ({ msg, store }) => {
    commonTicketPurchaseHandler({
      msg,
      store,
      pusEventType: "PurchaseFromBank",
    });
  },
  SwapWithPool: ({ msg, store }) => {
    commonTicketPurchaseHandler({
      msg,
      store,
      pusEventType: "SwapWithPool",
    });
  },
  OnNotificationPlayer: ({ msg, store }) => {
    console.log("OnNotificationPlayer", { msg, store });

    if (
      store.getState().user.playerSummary &&
      store.getState().user.playerSummary.response
    ) {
      store.dispatch(updateBadge({ updateSeen: false }));
      store.dispatch(getPlayerNotifications());
      if (
        msg.NotificationTypeCode &&
        msg.NotificationTypeCode.toLowerCase() === "ca"
      ) {
        // If credit allocation notification received, update the balance
        store.dispatch(getPlayerSummary());
      }
    }
  },
  OnGS: ({ msg, store }) => {
    const gameDetail = store.getState().game.game.detail;
    const incomingGameId = msg.GameId;
    if (`${gameDetail.GameId}` !== `${incomingGameId}`) return;
    debouncedGameRefresh({
      store,
      gameId: gameDetail.GameId,
    });
  },
  OnES: ({ msg, store }) => {
    console.log("On Event Status change", { msg, store });
  },
  OnCS: ({ msg, store }) => {
    console.log("On Cashout Status change", { msg, store });
  },
  OnSC: ({ msg, store }) => {
    console.log("On Swop Status change", { msg, store });
  },
  OnTC: ({ msg, store }) => {
    const gameDetail = store.getState().game.game.detail;
    const incomingGameId = msg.GameId;
    // console.log("On Active Ticket change", {
    //   msg,
    //   store,
    //   currentGameId: gameDetail.GameId,
    //   incomingGameId,
    // });
    if (`${gameDetail.GameId}` !== `${incomingGameId}`) return;
    if (!msg || !msg.ActiveTickets) return;
    store.dispatch(updateActiveTicket(Number(msg.ActiveTickets)));
    if (!msg.ActivePlayers) return;
    store.dispatch(updateTotalPlayersInGame(Number(msg.ActivePlayers)));
  },
  OnKS: ({ msg, store }) => {
    console.log("On Knockout Status Change", { msg, store });
  },
};

export default PushReceivers;
