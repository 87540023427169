// import { Avatar, Box, Divider, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import { Timeline, timelineItemClasses } from "@mui/lab";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
// import { ReactComponent as TicketIcon } from "../graphics/ticket/ticket.svg";
// import CircularSurvivalMetre from "./CircularSurvivalMetre";
// import {
//   getCurrentEvent,
//   parseTicketId,
//   numFormatWithMin,
// } from "../../utils/common-functions";
import { Typography, Box } from "@mui/material";
// import { getAvatarUrl } from "../../utils/common-functions";
import { OrderType } from "../../utils/types";

import TabPanel from "./TabPanel";
import TicketHistory from "./TicketHistory";

function HistoryTab({ value, index, ticketNum }) {
  const { detail: ticketDetail } = useSelector(
    (state) => state.ticket.ticketDetails
  );

  const game = useSelector((state) => state.game.game);

  const processHistory = (oldHistories) => {
    const histories = [];
    oldHistories.forEach((history) => {
      const tempHistory = JSON.parse(JSON.stringify(history));
      switch (history.orderTypeId) {
        case OrderType.AdminRewardFromPool:
        case OrderType.PurchaseFromPool:
        case OrderType.AdminRewardPrepurchaseFromPool:
        case OrderType.PlayerPrepurchaseFromPool:
        case OrderType.JackpotPrepurchaseFromPool:
        case OrderType.SubscriptionPrepurchaseFromPool:
        case OrderType.BankPrepurchaseFromPool:
          tempHistory.displayPlayer = history.toPlayer;
          tempHistory.displayMessage = "Purchased from Pool";
          break;
        case OrderType.AdminRefundToBank:
          tempHistory.displayPlayer = history.toPlayer;
          tempHistory.displayMessage = `${history.fromPlayer.username} had their ticket refunded`;
          break;
        case OrderType.AdminRefundToPool:
          tempHistory.displayPlayer = history.fromPlayer;
          tempHistory.displayMessage = "Refunded to pool";
          break;
        case OrderType.CashoutToBank:
          tempHistory.displayPlayer = history.toPlayer;
          tempHistory.displayMessage = `Folded by ${history.fromPlayer.username}`;
          break;
        case OrderType.PurchaseFromBank:
          tempHistory.displayPlayer = history.toPlayer;
          tempHistory.displayMessage = "Purchased from Bank";
          break;
        case OrderType.SwapWithPool:
        case OrderType.SwapBracketWithPool:
          if (history.toPlayerId) {
            tempHistory.displayPlayer = history.toPlayer;
            tempHistory.displayMessage = "Swopped from Pool";
          } else {
            tempHistory.displayPlayer = history.fromPlayer;
            tempHistory.displayMessage = "Swopped to Pool";
          }
          break;
        default:
          break;
      }
      histories.push(tempHistory);
    });
    const groupedHistories = [];
    let group = [];
    histories.forEach((history) => {
      if (group.length === 0) group.push(history);
      else if (
        group[group.length - 1].displayPlayer.playerId ===
        history.displayPlayer.playerId
      ) {
        group.push(history);
      } else {
        groupedHistories.push(group.slice(0));
        group = [history];
      }
    });

    groupedHistories.push(group.slice(0));
    return groupedHistories;
  };

  const hist = ticketDetail.histories
    ? processHistory(ticketDetail.histories)
    : [];

  return (
    <TabPanel value={value} index={index}>
      <Typography variant="subtitle1" color="text.primary">
        Ticket History
      </Typography>
      <Timeline
        sx={{
          padding: 0,
          color: "text.contrastText",
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
      >
        {hist.map((group) => (
          <TicketHistory
            key={group.ticketHistoryId}
            group={group}
            gameEvent={game.detail.GameEvents}
          />
        ))}
      </Timeline>{" "}
      <Box
        sx={{
          margin: "16px 0 -16px 0",
          textAlign: "center",
          fontSize: "0.875rem",
        }}
      >
        <Typography
          variant="subtitle1"
          color="rgba(25,37,42,0.6)"
          component="p"
          sx={{
            fontSize: "0.875rem",
          }}
        >
          Ticket #: {ticketNum}
        </Typography>
      </Box>
      {/* <div className="stepper-vertical  page-layout-mt">
        {hist.map((group) => (
          <div className="stepper-vertical__item" key={group[0].ticketOrderId}>
            <div className="stepper-vertical__step-container">
              <div className="stepper-vertical__step">
                <Avatar
                  src={getAvatarUrl(group[0].displayPlayer.avatar)}
                  alt={group[0].displayPlayer.username}
                  className="stepper-vertical__avatar"
                />
              </div>
            </div>
            <div className="stepper-vertical__details">
              <div className="stepper-vertical__step-title">
                {group[0].displayPlayer.username}
              </div>
              <table className="data-table  data-table--fixed  data-table--fullwidth">
                <colgroup>
                  <col width="40%" />
                  <col width="30%" />
                  <col width="30%" />
                </colgroup>
                <tbody>
                  {group.map((history) => {
                    const event = game.detail.GameEvents.find(
                      (e) => e.EventId === history.relatedEventId
                    );
                    const number = event ? event.EventIndex + 1 : 1;
                    return (
                      <tr
                        className="data-table__row"
                        key={history.ticketHistoryId}
                      >
                        <td className="data-table__cell">
                          {history.displayMessage}
                        </td>
                        <td className="data-table__cell  data-table__cell--nowrap">
                          Event {number}
                        </td>
                        <td className="data-table__cell  data-table__cell--nowrap  data-table__cell--text-primary">
                          {history.price.toFixed(2)}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        ))}
      </div> */}
      {/* <div>
        {hist.map((group) => (
          <div>
            <div>{group[0].ticketOrderId}</div>
            <div>{group[0].displayPlayer.username}</div>
            {group.map((history) => {
              return (
                <>
                  <div>{history.displayMessage}</div>
                  <div>{history.displayPlayer.username}</div>
                </>
              );
            })}
          </div>
        ))}
      </div> */}
    </TabPanel>
  );
}
HistoryTab.propTypes = {
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};
export default HistoryTab;
