import { FacebookLoginClient } from "@greatsumini/react-facebook-login";
import { toast } from "react-toastify";
import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import swopApi from "../../api";
import { getChannelID, saveToken } from "../../services/ls";
import { pushLoginGTMEvent } from "../../utils/common-functions";
import { getPlayerSummary } from "./user.slice";

const SLICE_NAME = "signupWizard";

const customPlayerWidgetSignupPost = async (requestParameters) => {
  const headerParameters = {};
  if (
    requestParameters.accept !== undefined &&
    requestParameters.accept !== null
  ) {
    headerParameters.Accept = String(requestParameters.accept);
  }
  const baseURL = swopApi.playerApi.configuration.configuration.basePath;
  headerParameters["x-ss-id"] =
    swopApi.playerApi.configuration.configuration.headers["x-ss-id"];

  const url = `${baseURL}/player/widgetSignup`;

  return fetch(url, {
    method: "POST",
    headers: headerParameters,
    body: requestParameters.body,
  }).then((res) => res.json());
};

const customPlayerQuickSignupPost = async (requestParameters) => {
  const headerParameters = {};
  if (
    requestParameters.accept !== undefined &&
    requestParameters.accept !== null
  ) {
    headerParameters.Accept = String(requestParameters.accept);
  }
  const baseURL = swopApi.playerApi.configuration.configuration.basePath;

  const url = `${baseURL}/player/quickSignup`;

  return fetch(url, {
    method: "POST",
    headers: headerParameters,
    body: requestParameters.body,
  }).then((res) => res.json());
};

export const customFacebookAuthPost = async (requestParameters) => {
  const headerParameters = {};
  if (
    requestParameters.accept !== undefined &&
    requestParameters.accept !== null
  ) {
    headerParameters.Accept = String(requestParameters.accept);
  }
  const baseURL = swopApi.playerApi.configuration.configuration.basePath;
  headerParameters["Content-Type"] = "application/json";

  const url = `${baseURL}/auth/facebook`;

  return fetch(url, {
    method: "POST",
    headers: headerParameters,
    body: JSON.stringify(requestParameters.body),
  }).then((res) => res.json());
};

export const ipAddressCheck = createAsyncThunk(
  `${SLICE_NAME}/ipAddressCheck`,
  async () => {
    const response = swopApi.playerApi.validateIPAddressRequestIPAddressPost({
      accept: "application/json",
    });
    return response;
  }
);

export const generateNickname = createAsyncThunk(
  `${SLICE_NAME}/generateNickname`,
  async () => {
    const response = swopApi.playerApi.randomNickNameRequestrandomNickNamePost({
      accept: "application/json",
    });
    return response;
  }
);

export const randomAvatar = createAsyncThunk(
  `${SLICE_NAME}/randomAvatar`,
  async () => {
    return swopApi.avatarApi.avatarRandomGetRequestrandomgetPost({
      accept: "application/json",
    });
  }
);
export const changePage = createAction(
  `${SLICE_NAME}/changePage`,
  (payload) => ({ payload })
);

export const updateAvatarFail = createAction(
  `${SLICE_NAME}/updateAvatarFail`,
  (payload) => ({ payload })
);

export const signupFail = createAction(
  `${SLICE_NAME}/signupFail`,
  (payload) => ({ payload })
);
export const signupSuccess = createAction(
  `${SLICE_NAME}/signupSuccess`,
  (payload) => ({ payload })
);

export const widgetFinalSubmit = createAsyncThunk(
  `${SLICE_NAME}/widgetFinalSubmit`,
  async ({ credentials, avatar }) => {
    const formData = new FormData();
    if (avatar !== undefined) {
      formData.append(credentials.Avatar, avatar, credentials.Avatar);
    }
    formData.append("Email", credentials.Email);
    formData.append("Password", credentials.Password);
    formData.append("Username", credentials.Username);
    formData.append("Avatar", credentials.Avatar);
    formData.append("ReferralCode", credentials.ReferralCode);
    formData.append("Provider", "");
    formData.append("Channel", getChannelID());
    formData.append("RemoteUsername", credentials.RemoteUsername);
    formData.append("RemoteToken", credentials.RemoteToken);
    return customPlayerWidgetSignupPost({
      accept: "application/json",
      body: formData,
    });
  }
);

export const finalSubmit = createAsyncThunk(
  `${SLICE_NAME}/finalSubmit`,
  async ({ credentials, avatar }, { dispatch }) => {
    const formData = new FormData();
    if (avatar !== undefined) {
      formData.append(credentials.Avatar, avatar, credentials.Avatar);
    }
    formData.append("Email", credentials.Email);
    formData.append("Password", credentials.Password);
    formData.append("Username", credentials.Username);
    formData.append("Avatar", credentials.Avatar);
    formData.append("ReferralCode", credentials.ReferralCode);
    formData.append("Channel", getChannelID());
    formData.append("Provider", "");
    // formData.append("RemoteUsername", credentials.RemoteUsername);
    // formData.append("RemoteToken", credentials.RemoteToken);
    // return customPlayerWidgetSignupPost({
    //   accept: "application/json",
    //   body: formData,
    // });
    customPlayerQuickSignupPost({
      accept: "application/json",
      body: formData,
    }).then((data) => {
      const signUpResult = data.Extras[0];
      if (signUpResult.ErrorNo === 0) {
        const setAvatarResult = data.Extras[1];
        if (setAvatarResult && setAvatarResult.ErrorNo > 0) {
          dispatch(updateAvatarFail({ errorMessage: setAvatarResult.Message }));
        } else {
          setTimeout(() => {
            dispatch(
              signupSuccess({
                playerId: signUpResult.Identity,
                avatar: credentials.Avatar,
              })
            );
            dispatch(changePage({ page: 3 }));
          }, 500);
        }
      } else {
        dispatch(signupFail({ errorMessage: signUpResult.Message }));
      }
    });
  }
);

export const nextPageSuccess = createAction(
  `${SLICE_NAME}/player/nextPageSuccess`,
  (payload) => ({ payload })
);

export const nextPageFail = createAction(
  `${SLICE_NAME}/player/nextPageFail`,
  (payload) => ({ payload })
);

export const nextPage = createAsyncThunk(
  `${SLICE_NAME}/nextPage`,
  async ({ email, password, userData }, { dispatch }) => {
    const response =
      await swopApi.playerApi.playerCheckAvailibilityRequestcheckAvalibilityPost(
        {
          accept: "application/json",
          body: userData,
        }
      );

    if (response.errorNo === 0) {
      dispatch(
        nextPageSuccess({
          email,
          password,
        })
      );
    } else {
      dispatch(
        nextPageFail({
          email,
          password,
          errorMessage: response.message,
        })
      );
    }
  }
);

export const sendVerificationEmail = createAsyncThunk(
  `${SLICE_NAME}/sendVerificationEmail`,
  async ({ email }) => {
    return swopApi.playerApi.sendVerificationEmailRequestverifyEmailPost({
      accept: "application/json",
      body: { email },
    });
  }
);

export const verifyEmail = createAsyncThunk(
  `${SLICE_NAME}/verifyEmail`,
  async (payload) => {
    return swopApi.playerApi.verifyPlayerEmailRequestemailVerifiedPost({
      accept: "application/json",
      body: { token: payload.token },
    });
  }
);

export const facebookSignupFail = createAction(
  `${SLICE_NAME}/facebookSignupFail`,
  (payload) => ({ payload })
);

export const facebookSignupSuccess = createAction(
  `${SLICE_NAME}/facebookSignupSuccess`,
  (payload) => ({ payload })
);

export const fbAccessToken = createAction(
  `${SLICE_NAME}/fbAccessToken`,
  (payload) => ({ payload })
);

export const emailAlreadyExists = createAction(
  `${SLICE_NAME}/emailAlreadyExists`,
  (payload) => ({ payload })
);

export const responseLogin = createAction(
  `${SLICE_NAME}/responseLogin`,
  (payload) => ({ payload })
);

export const facebookAutoSignupSuccess = createAction(
  `${SLICE_NAME}/facebookAutoSignupSuccess`,
  (payload) => ({ payload })
);

export const authenticateFBUser = createAsyncThunk(
  `${SLICE_NAME}/authenticateFBUser`,
  async ({ user, token, callback }, { dispatch }) => {
    if (!user.email) {
      dispatch(
        facebookSignupFail({
          errorMessage:
            "Please allow Email address to continue, Login again with Facebook",
        })
      );
      // logout
      FacebookLoginClient.logout();
    } else {
      let userData;
      // const pic;
      // fetch(`https://graph.facebook.com/297376476049725/picture?type=normal`).then((response))
      // http://graph.facebook.com/{your_id_goes_here}/picture?type=large&redirect=true&width=500&height=500
      if (user.picture) {
        userData = {
          name: user.name,
          email: user.email,
          id: user.id,
          cover_url: user.picture.data.url,
        };
      } else {
        userData = {
          name: user.name,
          email: user.email,
          id: user.id,
          cover_url: "",
        };
      }
      const data = await customFacebookAuthPost({
        accept: "application/json",
        body: {
          AccessToken: token,
          Meta: {
            ChannelId: getChannelID(),
          },
        },
      });

      if (data?.ResponseStatus && data?.ResponseStatus?.ErrorCode) {
        if (data?.ResponseStatus?.ErrorCode === "51103") {
          const res =
            await swopApi.playerApi.playerCheckAvailibilityRequestcheckAvalibilityGet(
              {
                accept: "application/json",
                body: {
                  Mode: 1,
                  Identifier: userData.email,
                },
              }
            );
          if (res?.errorNo === 0) {
            // fb signup step 2
            dispatch(facebookSignupSuccess({ userData }));
          } else {
            // ask for SS account password
            dispatch(emailAlreadyExists({ userData }));
          }
        } else {
          dispatch(
            facebookSignupFail({
              errorMessage: data?.ResponseStatus?.Message,
            })
          );
        }
      } else {
        saveToken(data.SessionId);
        swopApi.tokenUpdated();
        const response = await dispatch(getPlayerSummary());
        pushLoginGTMEvent(response.payload);

        dispatch(getContentCard());
        dispatch(facebookAutoSignupSuccess({ userData }));
        dispatch(responseLogin());

        // try {
        //     getiOSDeviceToken(response.payload.PlayerId)
        // } catch (error) {
        //     console.log(error)
        // }

        callback(response.payload.playerId);

        toast.success("Facebook Login Successful!", {
          delay: 100,
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }

      // error occurred on fb authentication
      if (data?.error?.ResponseStatus) {
        const errorData = data.error.ResponseStatus;
        if (errorData.ErrorCode === "51103") {
          const resp =
            await swopApi.playerApi.playerCheckAvailibilityRequestcheckAvalibilityGet(
              {
                accept: "application/json",
                body: {
                  Mode: 1,
                  Identifier: userData.email,
                },
              }
            );
          if (resp?.errorNo === 0) {
            // fb signup step 2
            dispatch(facebookSignupSuccess({ userData }));
          } else {
            // ask for SS account password
            dispatch(emailAlreadyExists({ userData }));
          }
        }
      }
    }
  }
);

export const fbFinalSignupSuccess = createAction(
  `${SLICE_NAME}/fbFinalSignupSuccess`,
  (payload) => ({ payload })
);

export const fbFinalSignupFail = createAction(
  `${SLICE_NAME}/fbFinalSignupFail`,
  (payload) => ({ payload })
);

export const fbFinalSubmit = createAsyncThunk(
  `${SLICE_NAME}/fbFinalSubmit`,
  async ({ credentials, avatar, callback }, { dispatch }) => {
    const formData = new FormData();
    if (avatar !== undefined) {
      formData.append(credentials.Avatar, avatar, credentials.Avatar);
    }
    formData.append("Email", credentials.Email);
    formData.append("Password", credentials.Password);
    formData.append("Username", credentials.Username);
    formData.append("Avatar", credentials.Avatar);
    formData.append("ReferralCode", credentials.ReferralCode);
    formData.append("Channel", getChannelID());
    formData.append("Provider", "facebook");

    const data = await customPlayerQuickSignupPost({
      accept: "application/json",
      body: formData,
    });
    if (data?.Extras) {
      const signUpResult = data.Extras[0];
      if (signUpResult.ErrorNo === 0) {
        const setAvatarResult = data.Extras[1];
        if (setAvatarResult && setAvatarResult.ErrorNo > 0) {
          dispatch(updateAvatarFail({ errorMessage: setAvatarResult.Message }));
        } else {
          setTimeout(() => {
            dispatch(
              fbFinalSignupSuccess({
                playerId: signUpResult.Identity,
                avatar: credentials.Avatar,
              })
            );
          }, 400);
          dispatch(responseLogin());
          // redirecting user
          // try {
          //   getiOSDeviceToken(data.PlayerId);
          // } catch (error) {
          //   console.log(error);
          // }
          callback(signUpResult.Identity);
        }
      } else {
        dispatch(fbFinalSignupFail({ errorMessage: signUpResult.Message }));
      }
    }
  }
);

const initialState = {
  page: 6,
  email: null,
  password: null,
  nickname: null,
  avatar: null,
  avatar_name: null,
  imageUpload: false,
  errorMessage: null,
  error: null,
  playerId: null,
  userData: {},
  playerData: {},
  isLoading: false,
  fbLoading: false,
  resendVerificationResponse: null,
  allowed: true,
  checkingAvailability: false,
  verifiedMsg: null,
  verifiedEmail: null,
  accessToken: null,
};

const signupWizardSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setNickname: (state, action) => {
      state.nickname = action.payload;
    },
    setAvatar: (state, action) => {
      state.avatar = action.payload;
      state.imageUpload = true;
    },
    reset: (state) => {
      state.page = 6;
      state.errorMessage = null;
      state.isLoading = false;
      state.nickname = "";
      state.avatar = "";
    },
  },
  extraReducers(builder) {
    builder
      .addCase(ipAddressCheck.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(ipAddressCheck.fulfilled, (state) => {
        state.isLoading = false;
        // TODO REFACTOR
        state.allowed = true;
      })
      .addCase(ipAddressCheck.rejected, (state) => {
        state.isLoading = false;
        // TODO REFACTOR
        state.allowed = true;
      })
      .addCase(generateNickname.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(generateNickname.fulfilled, (state, action) => {
        state.isLoading = false;
        state.nickname = action.payload.nickName;
      })
      .addCase(generateNickname.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(randomAvatar.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(randomAvatar.fulfilled, (state, action) => {
        state.isLoading = false;
        state.imageUpload = false;
        state.avatar = action.payload.avatar.avatarUrl;
      })
      .addCase(randomAvatar.rejected, (state) => {
        state.isLoading = false;
        state.imageUpload = false;
      })
      .addCase(changePage, (state, action) => {
        state.page = action.payload.page;
      })
      .addCase(updateAvatarFail, (state, action) => {
        state.errorMessage = action.payload.errorMessage;
      })
      .addCase(signupFail, (state, action) => {
        state.errorMessage = action.payload.errorMessage;
      })
      .addCase(signupSuccess, (state, action) => {
        //   state.page = 3;
        state.playerId = action.payload.playerId;
        state.avatar_name = action.payload.avatar;
      })
      .addCase(widgetFinalSubmit.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(widgetFinalSubmit.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(widgetFinalSubmit.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(finalSubmit.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(finalSubmit.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(finalSubmit.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(nextPageSuccess, (state, action) => {
        state.page = 2;
        state.email = action.payload.email;
        state.password = action.payload.password;
        state.errorMessage = null;
        state.checkingAvailability = false;
      })
      .addCase(nextPageFail, (state, action) => {
        state.email = action.payload.email;
        state.password = action.payload.password;
        state.errorMessage = action.payload.errorMessage;
        state.checkingAvailability = false;
      })
      .addCase(facebookSignupFail, (state, action) => {
        state.errorMessage = action.payload.errorMessage;
        state.isLoading = false;
      })
      .addCase(facebookSignupSuccess, (state, action) => {
        state.page = 4;
        state.userData = action.payload.userData;
      })
      .addCase(fbAccessToken, (state, action) => {
        state.accessToken = action.payload.token;
      })
      .addCase(emailAlreadyExists, (state, action) => {
        state.page = 5;
        state.userData = action.payload.userData;
        state.errorMessage = "Account already exists with your email address";
        state.checkingAvailability = false;
      })
      .addCase(fbFinalSubmit.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fbFinalSubmit.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(fbFinalSubmit.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(authenticateFBUser.pending, (state) => {
        state.isLoading = true;
        state.fbLoading = true;
      })
      .addCase(authenticateFBUser.fulfilled, (state) => {
        state.isLoading = false;
        state.fbLoading = false;
      })
      .addCase(authenticateFBUser.rejected, (state) => {
        state.isLoading = false;
        state.fbLoading = false;
      })
      .addCase(fbFinalSignupSuccess, (state, action) => {
        state.playerId = action.payload.playerId;
        state.avatar_name = action.payload.avatar;
      })
      .addCase(fbFinalSignupFail, (state, action) => {
        state.errorMessage = action.payload.errorMessage;
        state.isLoading = false;
      })
      .addCase(facebookAutoSignupSuccess, (state) => {
        state.page = 1;
        state.allowed = true;
        state.checkingAvailability = false;
      })
      .addCase(responseLogin, (state) => {
        state.page = 1;
        state.allowed = true;
        state.checkingAvailability = false;
      })
      .addCase(nextPage.pending, (state) => {
        state.errorMessage = null;
        state.checkingAvailability = true;
      })
      .addCase(nextPage.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(nextPage.rejected, (state, action) => {
        state.isLoading = false;
        // nextPageFail
        state.email = action.meta.arg.email;
        state.password = action.meta.arg.password;
        // state.errorMessage = action.payload.errorMessage;
        state.errorMessage = "some error occurred";
        state.checkingAvailability = false;
      })
      .addCase(sendVerificationEmail.pending, () => {})
      .addCase(sendVerificationEmail.fulfilled, (state, action) => {
        if (action.payload.errorNo === 0) {
          state.resendVerificationResponse = action.payload;
        }
      })
      .addCase(sendVerificationEmail.rejected, () => {})
      .addCase(verifyEmail.pending, () => {})
      .addCase(verifyEmail.fulfilled, (state, action) => {
        if (action.payload.errorNo > 0) {
          state.verifiedMsg = action.payload.message;
        } else {
          state.verifiedMsg = `The email ${action.payload.message} has been successfully verified`;
          state.verifiedEmail = action.payload.message;
        }
      })
      .addCase(verifyEmail.rejected, () => {});
  },
});

export const { setNickname, setAvatar, reset } = signupWizardSlice.actions;

export default signupWizardSlice.reducer;
