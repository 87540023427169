/* eslint-disable no-param-reassign */
/* eslint-disable no-bitwise */
import { createTheme } from "@mui/material";

const defaultTheme = createTheme();
function LightenDarkenColor(col, amt) {
  let usePound = false;

  if (col[0] === "#") {
    col = col.slice(1);
    usePound = true;
  }

  const num = parseInt(col, 16);

  let r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  let b = ((num >> 8) & 0x00ff) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  let g = (num & 0x0000ff) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
}

// declare module '@mui/material/ListItemButton' {
//   interface ListItemButtonBaseProps {
//     variant?: string
//   }
// }
// declare module '@mui/material/styles/createPalette' {
//   interface Palette {
//     light: PaletteColor
//   }
//   interface PaletteOptions {
//     light: PaletteColor
//   }

//   interface TypeBackground {
//     dark: string
//   }

//   interface TypeText {
//     hint: string
//     contrastText: string
//   }
// }

// declare module '@mui/material/Checkbox' {
//   interface CheckboxPropsColorOverrides {
//     light: true
//   }
// }

// declare module '@mui/material/Checkbox' {
//   interface CheckboxPropsColorOverrides {
//     light: true
//   }
// }

// declare module '@mui/material/TextField' {
//   interface TextFieldPropsColorOverrides {
//     light: true
//   }
// }

const newTheme = (colours) =>
  createTheme({
    components: {
      MuiCheckbox: {
        variants: [
          {
            props: { color: "light" },
            style: {
              "& .MuiSvgIcon-root": {
                color: "white",
              },
            },
          },
        ],
      },
      MuiDivider: {
        variants: [
          {
            props: { light: true },
            style: {
              "&.MuiDivider-root": {
                borderColor: "rgba(255, 255, 255, 0.15)",
              },
            },
          },
        ],
      },
      MuiTextField: {
        variants: [
          {
            props: { color: "light" },
            style: {
              "& .MuiInput-root": {
                color: "white",
                "&::before": {
                  borderBottom: "1px solid #ffffff2c !important",
                },
              },
              "& .MuiButtonBase-root": { color: "white" },
              "& .MuiInputLabel-root": { color: "white" },
            },
          },
        ],
        defaultProps: {
          InputLabelProps: {
            shrink: true,
          },
        },
      },
      MuiListItemButton: {
        variants: [
          {
            props: { variant: "light" },
            style: {
              "& .MuiSvgIcon-root": { color: "#fff" },
              color: "#fff",
            },
          },
        ],
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            minWidth: "40px",
          },
        },
      },
      MuiListSubheader: {
        styleOverrides: {
          root: {
            background: "unset",
          },
        },
      },
    },
    typography: {
      fontFamily: [
        "Roboto",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      // useNextVariants: true,
      h5: {
        fontWeight: 600,
      },
      h6: {
        fontWeight: 600,
      },
      subtitle1: {
        fontWeight: 500,
      },
      subtitle2: {
        fontWeight: 500,
      },
      button: {
        fontWeight: 500,
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 760,
        lg: 1000,
        xl: 1600,
      },
    },
    palette: {
      // primary: {
      //   light: LightenDarkenColor(colours.primary, 223),
      //   main: colours.primary,
      //   dark: LightenDarkenColor(colours.primary, -50),
      // },
      primary: defaultTheme.palette.augmentColor({
        color: { main: colours.primary },
        name: "primary",
      }),
      secondary: {
        light: LightenDarkenColor(colours.secondary, 223),
        main: colours.secondary,
        dark: LightenDarkenColor(colours.secondary, -50),
      },
      background: {
        paper: colours.paper,
        dark: "#19292F",
        default: colours.background,
      },
      text: {
        primary: "rgba(25,37,42,1)",
        secondary: "rgba(25,37,42,0.6)",
        disabled: "rgba(25,37,42,0.30)",
        hint: "rgba(25,37,42,0.4)",
        contrastText: "#FFFFFF",
      },
      success: {
        main: "#60BA00",
      },
      light: defaultTheme.palette.augmentColor({
        color: { main: "#fff" },
        name: "light",
      }),
      divider: "rgba(35,40,85,0.12)",
    },
  });

export default newTheme;
