/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { PlayerNotificationLogFromJSON, PlayerNotificationLogToJSON, PlayerNotificationSeenLogFromJSON, PlayerNotificationSeenLogToJSON, } from './';
export function PlayerNotificationResponseFromJSON(json) {
    return PlayerNotificationResponseFromJSONTyped(json, false);
}
export function PlayerNotificationResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'playerNotificationSeenLog': !exists(json, 'PlayerNotificationSeenLog') ? undefined : PlayerNotificationSeenLogFromJSON(json['PlayerNotificationSeenLog']),
        'playerNotificationLog': !exists(json, 'PlayerNotificationLog') ? undefined : (json['PlayerNotificationLog'].map(PlayerNotificationLogFromJSON)),
    };
}
export function PlayerNotificationResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'PlayerNotificationSeenLog': PlayerNotificationSeenLogToJSON(value.playerNotificationSeenLog),
        'PlayerNotificationLog': value.playerNotificationLog === undefined ? undefined : (value.playerNotificationLog.map(PlayerNotificationLogToJSON)),
    };
}
