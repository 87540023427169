/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { BankTicketsRequestToJSON, BankTicketsResponseFromJSON, BuyTicketFromBankRequestToJSON, BuyTicketFromPoolRequestToJSON, CashoutTicketsRequestToJSON, EventTicketResultRequestToJSON, EventTicketResultResponseFromJSON, FrontEndLeaderboardFromJSON, GameDetailsRequestToJSON, GameHomeRequestToJSON, GameHomeResponseFromJSON, GameResultFromJSON, GameResultResponseFromJSON, GameTicketInfoFromJSON, GameTicketInfoRequestToJSON, GameTotalCashoutValueResponseFromJSON, GeoRestrictionRequestToJSON, GeoRestrictionResponseFromJSON, GetGameRequestToJSON, GetGameResultsRequestToJSON, GetGameTotalCashoutValueRequestToJSON, LeaderboardRequestToJSON, LeaderboardRequestForOrgToJSON, MyTicketsRequestToJSON, MyTicketsResponseFromJSON, PlayerTicketSummaryFromJSON, PlayerTicketSummaryRequestToJSON, SwopTicketsRequestToJSON, SystemSettingsRequestToJSON, SystemSettingsResponseFromJSON, TicketBracketSummaryRequestToJSON, TicketBracketSummaryResponseFromJSON, TicketDetailRequestToJSON, TicketDetailResponseFromJSON, TicketOrderResultFromJSON, } from '../models';
/**
 *
 */
export class GameApi extends runtime.BaseAPI {
    /**
     */
    async bankTicketsRequestGameIdbankTicketsCreateRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling bankTicketsRequestGameIdbankTicketsCreate.');
        }
        if (requestParameters.gameId === null || requestParameters.gameId === undefined) {
            throw new runtime.RequiredError('gameId', 'Required parameter requestParameters.gameId was null or undefined when calling bankTicketsRequestGameIdbankTicketsCreate.');
        }
        const queryParameters = {};
        if (requestParameters.eventIndex !== undefined) {
            queryParameters['EventIndex'] = requestParameters.eventIndex;
        }
        if (requestParameters.bracketId !== undefined) {
            queryParameters['BracketId'] = requestParameters.bracketId;
        }
        if (requestParameters.valueMin !== undefined) {
            queryParameters['ValueMin'] = requestParameters.valueMin;
        }
        if (requestParameters.valueMax !== undefined) {
            queryParameters['ValueMax'] = requestParameters.valueMax;
        }
        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/game/{GameId}/bankTickets`.replace(`{${"GameId"}}`, encodeURIComponent(String(requestParameters.gameId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BankTicketsRequestToJSON(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => BankTicketsResponseFromJSON(jsonValue));
    }
    /**
     */
    async bankTicketsRequestGameIdbankTicketsCreate(requestParameters, initOverrides) {
        const response = await this.bankTicketsRequestGameIdbankTicketsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async bankTicketsRequestGameIdbankTicketsDeleteRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling bankTicketsRequestGameIdbankTicketsDelete.');
        }
        if (requestParameters.gameId === null || requestParameters.gameId === undefined) {
            throw new runtime.RequiredError('gameId', 'Required parameter requestParameters.gameId was null or undefined when calling bankTicketsRequestGameIdbankTicketsDelete.');
        }
        const queryParameters = {};
        if (requestParameters.eventIndex !== undefined) {
            queryParameters['EventIndex'] = requestParameters.eventIndex;
        }
        if (requestParameters.bracketId !== undefined) {
            queryParameters['BracketId'] = requestParameters.bracketId;
        }
        if (requestParameters.valueMin !== undefined) {
            queryParameters['ValueMin'] = requestParameters.valueMin;
        }
        if (requestParameters.valueMax !== undefined) {
            queryParameters['ValueMax'] = requestParameters.valueMax;
        }
        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/game/{GameId}/bankTickets`.replace(`{${"GameId"}}`, encodeURIComponent(String(requestParameters.gameId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => BankTicketsResponseFromJSON(jsonValue));
    }
    /**
     */
    async bankTicketsRequestGameIdbankTicketsDelete(requestParameters, initOverrides) {
        const response = await this.bankTicketsRequestGameIdbankTicketsDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async bankTicketsRequestGameIdbankTicketsGetRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling bankTicketsRequestGameIdbankTicketsGet.');
        }
        if (requestParameters.gameId === null || requestParameters.gameId === undefined) {
            throw new runtime.RequiredError('gameId', 'Required parameter requestParameters.gameId was null or undefined when calling bankTicketsRequestGameIdbankTicketsGet.');
        }
        const queryParameters = {};
        if (requestParameters.eventIndex !== undefined) {
            queryParameters['EventIndex'] = requestParameters.eventIndex;
        }
        if (requestParameters.bracketId !== undefined) {
            queryParameters['BracketId'] = requestParameters.bracketId;
        }
        if (requestParameters.valueMin !== undefined) {
            queryParameters['ValueMin'] = requestParameters.valueMin;
        }
        if (requestParameters.valueMax !== undefined) {
            queryParameters['ValueMax'] = requestParameters.valueMax;
        }
        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/game/{GameId}/bankTickets`.replace(`{${"GameId"}}`, encodeURIComponent(String(requestParameters.gameId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => BankTicketsResponseFromJSON(jsonValue));
    }
    /**
     */
    async bankTicketsRequestGameIdbankTicketsGet(requestParameters, initOverrides) {
        const response = await this.bankTicketsRequestGameIdbankTicketsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async bankTicketsRequestGameIdbankTicketsPostRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling bankTicketsRequestGameIdbankTicketsPost.');
        }
        if (requestParameters.gameId === null || requestParameters.gameId === undefined) {
            throw new runtime.RequiredError('gameId', 'Required parameter requestParameters.gameId was null or undefined when calling bankTicketsRequestGameIdbankTicketsPost.');
        }
        const queryParameters = {};
        if (requestParameters.eventIndex !== undefined) {
            queryParameters['EventIndex'] = requestParameters.eventIndex;
        }
        if (requestParameters.bracketId !== undefined) {
            queryParameters['BracketId'] = requestParameters.bracketId;
        }
        if (requestParameters.valueMin !== undefined) {
            queryParameters['ValueMin'] = requestParameters.valueMin;
        }
        if (requestParameters.valueMax !== undefined) {
            queryParameters['ValueMax'] = requestParameters.valueMax;
        }
        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/game/{GameId}/bankTickets`.replace(`{${"GameId"}}`, encodeURIComponent(String(requestParameters.gameId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BankTicketsRequestToJSON(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => BankTicketsResponseFromJSON(jsonValue));
    }
    /**
     */
    async bankTicketsRequestGameIdbankTicketsPost(requestParameters, initOverrides) {
        const response = await this.bankTicketsRequestGameIdbankTicketsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async buyTicketFromBankRequestGameIdbuyTicketFromBankCreateRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling buyTicketFromBankRequestGameIdbuyTicketFromBankCreate.');
        }
        if (requestParameters.gameId === null || requestParameters.gameId === undefined) {
            throw new runtime.RequiredError('gameId', 'Required parameter requestParameters.gameId was null or undefined when calling buyTicketFromBankRequestGameIdbuyTicketFromBankCreate.');
        }
        const queryParameters = {};
        if (requestParameters.quantity !== undefined) {
            queryParameters['Quantity'] = requestParameters.quantity;
        }
        if (requestParameters.requestedTickets !== undefined) {
            queryParameters['RequestedTickets'] = requestParameters.requestedTickets;
        }
        if (requestParameters.expectedPrice !== undefined) {
            queryParameters['ExpectedPrice'] = requestParameters.expectedPrice;
        }
        if (requestParameters.fromPlayerId !== undefined) {
            queryParameters['FromPlayerId'] = requestParameters.fromPlayerId;
        }
        if (requestParameters.locationData !== undefined) {
            queryParameters['LocationData'] = requestParameters.locationData;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/game/{GameId}/buyTicketFromBank`.replace(`{${"GameId"}}`, encodeURIComponent(String(requestParameters.gameId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BuyTicketFromBankRequestToJSON(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => TicketOrderResultFromJSON(jsonValue));
    }
    /**
     */
    async buyTicketFromBankRequestGameIdbuyTicketFromBankCreate(requestParameters, initOverrides) {
        const response = await this.buyTicketFromBankRequestGameIdbuyTicketFromBankCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async buyTicketFromBankRequestGameIdbuyTicketFromBankDeleteRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling buyTicketFromBankRequestGameIdbuyTicketFromBankDelete.');
        }
        if (requestParameters.gameId === null || requestParameters.gameId === undefined) {
            throw new runtime.RequiredError('gameId', 'Required parameter requestParameters.gameId was null or undefined when calling buyTicketFromBankRequestGameIdbuyTicketFromBankDelete.');
        }
        const queryParameters = {};
        if (requestParameters.quantity !== undefined) {
            queryParameters['Quantity'] = requestParameters.quantity;
        }
        if (requestParameters.requestedTickets !== undefined) {
            queryParameters['RequestedTickets'] = requestParameters.requestedTickets;
        }
        if (requestParameters.expectedPrice !== undefined) {
            queryParameters['ExpectedPrice'] = requestParameters.expectedPrice;
        }
        if (requestParameters.fromPlayerId !== undefined) {
            queryParameters['FromPlayerId'] = requestParameters.fromPlayerId;
        }
        if (requestParameters.locationData !== undefined) {
            queryParameters['LocationData'] = requestParameters.locationData;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/game/{GameId}/buyTicketFromBank`.replace(`{${"GameId"}}`, encodeURIComponent(String(requestParameters.gameId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => TicketOrderResultFromJSON(jsonValue));
    }
    /**
     */
    async buyTicketFromBankRequestGameIdbuyTicketFromBankDelete(requestParameters, initOverrides) {
        const response = await this.buyTicketFromBankRequestGameIdbuyTicketFromBankDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async buyTicketFromBankRequestGameIdbuyTicketFromBankGetRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling buyTicketFromBankRequestGameIdbuyTicketFromBankGet.');
        }
        if (requestParameters.gameId === null || requestParameters.gameId === undefined) {
            throw new runtime.RequiredError('gameId', 'Required parameter requestParameters.gameId was null or undefined when calling buyTicketFromBankRequestGameIdbuyTicketFromBankGet.');
        }
        const queryParameters = {};
        if (requestParameters.quantity !== undefined) {
            queryParameters['Quantity'] = requestParameters.quantity;
        }
        if (requestParameters.requestedTickets !== undefined) {
            queryParameters['RequestedTickets'] = requestParameters.requestedTickets;
        }
        if (requestParameters.expectedPrice !== undefined) {
            queryParameters['ExpectedPrice'] = requestParameters.expectedPrice;
        }
        if (requestParameters.fromPlayerId !== undefined) {
            queryParameters['FromPlayerId'] = requestParameters.fromPlayerId;
        }
        if (requestParameters.locationData !== undefined) {
            queryParameters['LocationData'] = requestParameters.locationData;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/game/{GameId}/buyTicketFromBank`.replace(`{${"GameId"}}`, encodeURIComponent(String(requestParameters.gameId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => TicketOrderResultFromJSON(jsonValue));
    }
    /**
     */
    async buyTicketFromBankRequestGameIdbuyTicketFromBankGet(requestParameters, initOverrides) {
        const response = await this.buyTicketFromBankRequestGameIdbuyTicketFromBankGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async buyTicketFromBankRequestGameIdbuyTicketFromBankPostRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling buyTicketFromBankRequestGameIdbuyTicketFromBankPost.');
        }
        if (requestParameters.gameId === null || requestParameters.gameId === undefined) {
            throw new runtime.RequiredError('gameId', 'Required parameter requestParameters.gameId was null or undefined when calling buyTicketFromBankRequestGameIdbuyTicketFromBankPost.');
        }
        const queryParameters = {};
        if (requestParameters.quantity !== undefined) {
            queryParameters['Quantity'] = requestParameters.quantity;
        }
        if (requestParameters.requestedTickets !== undefined) {
            queryParameters['RequestedTickets'] = requestParameters.requestedTickets;
        }
        if (requestParameters.expectedPrice !== undefined) {
            queryParameters['ExpectedPrice'] = requestParameters.expectedPrice;
        }
        if (requestParameters.fromPlayerId !== undefined) {
            queryParameters['FromPlayerId'] = requestParameters.fromPlayerId;
        }
        if (requestParameters.locationData !== undefined) {
            queryParameters['LocationData'] = requestParameters.locationData;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/game/{GameId}/buyTicketFromBank`.replace(`{${"GameId"}}`, encodeURIComponent(String(requestParameters.gameId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BuyTicketFromBankRequestToJSON(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => TicketOrderResultFromJSON(jsonValue));
    }
    /**
     */
    async buyTicketFromBankRequestGameIdbuyTicketFromBankPost(requestParameters, initOverrides) {
        const response = await this.buyTicketFromBankRequestGameIdbuyTicketFromBankPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async buyTicketFromPoolRequestGameIdbuyTicketFromPoolCreateRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling buyTicketFromPoolRequestGameIdbuyTicketFromPoolCreate.');
        }
        if (requestParameters.gameId === null || requestParameters.gameId === undefined) {
            throw new runtime.RequiredError('gameId', 'Required parameter requestParameters.gameId was null or undefined when calling buyTicketFromPoolRequestGameIdbuyTicketFromPoolCreate.');
        }
        const queryParameters = {};
        if (requestParameters.quantity !== undefined) {
            queryParameters['Quantity'] = requestParameters.quantity;
        }
        if (requestParameters.expectedPrice !== undefined) {
            queryParameters['ExpectedPrice'] = requestParameters.expectedPrice;
        }
        if (requestParameters.locationData !== undefined) {
            queryParameters['LocationData'] = requestParameters.locationData;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/game/{GameId}/buyTicketFromPool`.replace(`{${"GameId"}}`, encodeURIComponent(String(requestParameters.gameId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BuyTicketFromPoolRequestToJSON(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => TicketOrderResultFromJSON(jsonValue));
    }
    /**
     */
    async buyTicketFromPoolRequestGameIdbuyTicketFromPoolCreate(requestParameters, initOverrides) {
        const response = await this.buyTicketFromPoolRequestGameIdbuyTicketFromPoolCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async buyTicketFromPoolRequestGameIdbuyTicketFromPoolDeleteRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling buyTicketFromPoolRequestGameIdbuyTicketFromPoolDelete.');
        }
        if (requestParameters.gameId === null || requestParameters.gameId === undefined) {
            throw new runtime.RequiredError('gameId', 'Required parameter requestParameters.gameId was null or undefined when calling buyTicketFromPoolRequestGameIdbuyTicketFromPoolDelete.');
        }
        const queryParameters = {};
        if (requestParameters.quantity !== undefined) {
            queryParameters['Quantity'] = requestParameters.quantity;
        }
        if (requestParameters.expectedPrice !== undefined) {
            queryParameters['ExpectedPrice'] = requestParameters.expectedPrice;
        }
        if (requestParameters.locationData !== undefined) {
            queryParameters['LocationData'] = requestParameters.locationData;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/game/{GameId}/buyTicketFromPool`.replace(`{${"GameId"}}`, encodeURIComponent(String(requestParameters.gameId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => TicketOrderResultFromJSON(jsonValue));
    }
    /**
     */
    async buyTicketFromPoolRequestGameIdbuyTicketFromPoolDelete(requestParameters, initOverrides) {
        const response = await this.buyTicketFromPoolRequestGameIdbuyTicketFromPoolDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async buyTicketFromPoolRequestGameIdbuyTicketFromPoolGetRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling buyTicketFromPoolRequestGameIdbuyTicketFromPoolGet.');
        }
        if (requestParameters.gameId === null || requestParameters.gameId === undefined) {
            throw new runtime.RequiredError('gameId', 'Required parameter requestParameters.gameId was null or undefined when calling buyTicketFromPoolRequestGameIdbuyTicketFromPoolGet.');
        }
        const queryParameters = {};
        if (requestParameters.quantity !== undefined) {
            queryParameters['Quantity'] = requestParameters.quantity;
        }
        if (requestParameters.expectedPrice !== undefined) {
            queryParameters['ExpectedPrice'] = requestParameters.expectedPrice;
        }
        if (requestParameters.locationData !== undefined) {
            queryParameters['LocationData'] = requestParameters.locationData;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/game/{GameId}/buyTicketFromPool`.replace(`{${"GameId"}}`, encodeURIComponent(String(requestParameters.gameId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => TicketOrderResultFromJSON(jsonValue));
    }
    /**
     */
    async buyTicketFromPoolRequestGameIdbuyTicketFromPoolGet(requestParameters, initOverrides) {
        const response = await this.buyTicketFromPoolRequestGameIdbuyTicketFromPoolGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async buyTicketFromPoolRequestGameIdbuyTicketFromPoolPostRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling buyTicketFromPoolRequestGameIdbuyTicketFromPoolPost.');
        }
        if (requestParameters.gameId === null || requestParameters.gameId === undefined) {
            throw new runtime.RequiredError('gameId', 'Required parameter requestParameters.gameId was null or undefined when calling buyTicketFromPoolRequestGameIdbuyTicketFromPoolPost.');
        }
        const queryParameters = {};
        if (requestParameters.quantity !== undefined) {
            queryParameters['Quantity'] = requestParameters.quantity;
        }
        if (requestParameters.expectedPrice !== undefined) {
            queryParameters['ExpectedPrice'] = requestParameters.expectedPrice;
        }
        if (requestParameters.locationData !== undefined) {
            queryParameters['LocationData'] = requestParameters.locationData;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/game/{GameId}/buyTicketFromPool`.replace(`{${"GameId"}}`, encodeURIComponent(String(requestParameters.gameId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BuyTicketFromPoolRequestToJSON(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => TicketOrderResultFromJSON(jsonValue));
    }
    /**
     */
    async buyTicketFromPoolRequestGameIdbuyTicketFromPoolPost(requestParameters, initOverrides) {
        const response = await this.buyTicketFromPoolRequestGameIdbuyTicketFromPoolPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async cashoutTicketsRequestGameIdcashoutTicketsCreateRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling cashoutTicketsRequestGameIdcashoutTicketsCreate.');
        }
        if (requestParameters.gameId === null || requestParameters.gameId === undefined) {
            throw new runtime.RequiredError('gameId', 'Required parameter requestParameters.gameId was null or undefined when calling cashoutTicketsRequestGameIdcashoutTicketsCreate.');
        }
        const queryParameters = {};
        if (requestParameters.quantity !== undefined) {
            queryParameters['Quantity'] = requestParameters.quantity;
        }
        if (requestParameters.requestedTickets !== undefined) {
            queryParameters['RequestedTickets'] = requestParameters.requestedTickets;
        }
        if (requestParameters.expectedPrice !== undefined) {
            queryParameters['ExpectedPrice'] = requestParameters.expectedPrice;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/game/{GameId}/cashoutTickets`.replace(`{${"GameId"}}`, encodeURIComponent(String(requestParameters.gameId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CashoutTicketsRequestToJSON(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => TicketOrderResultFromJSON(jsonValue));
    }
    /**
     */
    async cashoutTicketsRequestGameIdcashoutTicketsCreate(requestParameters, initOverrides) {
        const response = await this.cashoutTicketsRequestGameIdcashoutTicketsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async cashoutTicketsRequestGameIdcashoutTicketsDeleteRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling cashoutTicketsRequestGameIdcashoutTicketsDelete.');
        }
        if (requestParameters.gameId === null || requestParameters.gameId === undefined) {
            throw new runtime.RequiredError('gameId', 'Required parameter requestParameters.gameId was null or undefined when calling cashoutTicketsRequestGameIdcashoutTicketsDelete.');
        }
        const queryParameters = {};
        if (requestParameters.quantity !== undefined) {
            queryParameters['Quantity'] = requestParameters.quantity;
        }
        if (requestParameters.requestedTickets !== undefined) {
            queryParameters['RequestedTickets'] = requestParameters.requestedTickets;
        }
        if (requestParameters.expectedPrice !== undefined) {
            queryParameters['ExpectedPrice'] = requestParameters.expectedPrice;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/game/{GameId}/cashoutTickets`.replace(`{${"GameId"}}`, encodeURIComponent(String(requestParameters.gameId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => TicketOrderResultFromJSON(jsonValue));
    }
    /**
     */
    async cashoutTicketsRequestGameIdcashoutTicketsDelete(requestParameters, initOverrides) {
        const response = await this.cashoutTicketsRequestGameIdcashoutTicketsDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async cashoutTicketsRequestGameIdcashoutTicketsGetRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling cashoutTicketsRequestGameIdcashoutTicketsGet.');
        }
        if (requestParameters.gameId === null || requestParameters.gameId === undefined) {
            throw new runtime.RequiredError('gameId', 'Required parameter requestParameters.gameId was null or undefined when calling cashoutTicketsRequestGameIdcashoutTicketsGet.');
        }
        const queryParameters = {};
        if (requestParameters.quantity !== undefined) {
            queryParameters['Quantity'] = requestParameters.quantity;
        }
        if (requestParameters.requestedTickets !== undefined) {
            queryParameters['RequestedTickets'] = requestParameters.requestedTickets;
        }
        if (requestParameters.expectedPrice !== undefined) {
            queryParameters['ExpectedPrice'] = requestParameters.expectedPrice;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/game/{GameId}/cashoutTickets`.replace(`{${"GameId"}}`, encodeURIComponent(String(requestParameters.gameId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => TicketOrderResultFromJSON(jsonValue));
    }
    /**
     */
    async cashoutTicketsRequestGameIdcashoutTicketsGet(requestParameters, initOverrides) {
        const response = await this.cashoutTicketsRequestGameIdcashoutTicketsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async cashoutTicketsRequestGameIdcashoutTicketsPostRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling cashoutTicketsRequestGameIdcashoutTicketsPost.');
        }
        if (requestParameters.gameId === null || requestParameters.gameId === undefined) {
            throw new runtime.RequiredError('gameId', 'Required parameter requestParameters.gameId was null or undefined when calling cashoutTicketsRequestGameIdcashoutTicketsPost.');
        }
        const queryParameters = {};
        if (requestParameters.quantity !== undefined) {
            queryParameters['Quantity'] = requestParameters.quantity;
        }
        if (requestParameters.requestedTickets !== undefined) {
            queryParameters['RequestedTickets'] = requestParameters.requestedTickets;
        }
        if (requestParameters.expectedPrice !== undefined) {
            queryParameters['ExpectedPrice'] = requestParameters.expectedPrice;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/game/{GameId}/cashoutTickets`.replace(`{${"GameId"}}`, encodeURIComponent(String(requestParameters.gameId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CashoutTicketsRequestToJSON(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => TicketOrderResultFromJSON(jsonValue));
    }
    /**
     */
    async cashoutTicketsRequestGameIdcashoutTicketsPost(requestParameters, initOverrides) {
        const response = await this.cashoutTicketsRequestGameIdcashoutTicketsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async eventTicketResultRequestGameIdeventTicketResultCreateRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling eventTicketResultRequestGameIdeventTicketResultCreate.');
        }
        if (requestParameters.gameId === null || requestParameters.gameId === undefined) {
            throw new runtime.RequiredError('gameId', 'Required parameter requestParameters.gameId was null or undefined when calling eventTicketResultRequestGameIdeventTicketResultCreate.');
        }
        const queryParameters = {};
        if (requestParameters.eventIndex !== undefined) {
            queryParameters['EventIndex'] = requestParameters.eventIndex;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/game/{GameId}/eventTicketResult`.replace(`{${"GameId"}}`, encodeURIComponent(String(requestParameters.gameId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EventTicketResultRequestToJSON(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => EventTicketResultResponseFromJSON(jsonValue));
    }
    /**
     */
    async eventTicketResultRequestGameIdeventTicketResultCreate(requestParameters, initOverrides) {
        const response = await this.eventTicketResultRequestGameIdeventTicketResultCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async eventTicketResultRequestGameIdeventTicketResultDeleteRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling eventTicketResultRequestGameIdeventTicketResultDelete.');
        }
        if (requestParameters.gameId === null || requestParameters.gameId === undefined) {
            throw new runtime.RequiredError('gameId', 'Required parameter requestParameters.gameId was null or undefined when calling eventTicketResultRequestGameIdeventTicketResultDelete.');
        }
        const queryParameters = {};
        if (requestParameters.eventIndex !== undefined) {
            queryParameters['EventIndex'] = requestParameters.eventIndex;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/game/{GameId}/eventTicketResult`.replace(`{${"GameId"}}`, encodeURIComponent(String(requestParameters.gameId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => EventTicketResultResponseFromJSON(jsonValue));
    }
    /**
     */
    async eventTicketResultRequestGameIdeventTicketResultDelete(requestParameters, initOverrides) {
        const response = await this.eventTicketResultRequestGameIdeventTicketResultDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async eventTicketResultRequestGameIdeventTicketResultGetRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling eventTicketResultRequestGameIdeventTicketResultGet.');
        }
        if (requestParameters.gameId === null || requestParameters.gameId === undefined) {
            throw new runtime.RequiredError('gameId', 'Required parameter requestParameters.gameId was null or undefined when calling eventTicketResultRequestGameIdeventTicketResultGet.');
        }
        const queryParameters = {};
        if (requestParameters.eventIndex !== undefined) {
            queryParameters['EventIndex'] = requestParameters.eventIndex;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/game/{GameId}/eventTicketResult`.replace(`{${"GameId"}}`, encodeURIComponent(String(requestParameters.gameId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => EventTicketResultResponseFromJSON(jsonValue));
    }
    /**
     */
    async eventTicketResultRequestGameIdeventTicketResultGet(requestParameters, initOverrides) {
        const response = await this.eventTicketResultRequestGameIdeventTicketResultGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async eventTicketResultRequestGameIdeventTicketResultPostRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling eventTicketResultRequestGameIdeventTicketResultPost.');
        }
        if (requestParameters.gameId === null || requestParameters.gameId === undefined) {
            throw new runtime.RequiredError('gameId', 'Required parameter requestParameters.gameId was null or undefined when calling eventTicketResultRequestGameIdeventTicketResultPost.');
        }
        const queryParameters = {};
        if (requestParameters.eventIndex !== undefined) {
            queryParameters['EventIndex'] = requestParameters.eventIndex;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/game/{GameId}/eventTicketResult`.replace(`{${"GameId"}}`, encodeURIComponent(String(requestParameters.gameId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EventTicketResultRequestToJSON(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => EventTicketResultResponseFromJSON(jsonValue));
    }
    /**
     */
    async eventTicketResultRequestGameIdeventTicketResultPost(requestParameters, initOverrides) {
        const response = await this.eventTicketResultRequestGameIdeventTicketResultPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async gameDetailsRequestdetailsGameIdCreateRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling gameDetailsRequestdetailsGameIdCreate.');
        }
        if (requestParameters.gameId === null || requestParameters.gameId === undefined) {
            throw new runtime.RequiredError('gameId', 'Required parameter requestParameters.gameId was null or undefined when calling gameDetailsRequestdetailsGameIdCreate.');
        }
        const queryParameters = {};
        if (requestParameters.utcOffSet !== undefined) {
            queryParameters['UtcOffSet'] = requestParameters.utcOffSet;
        }
        if (requestParameters.locationData !== undefined) {
            queryParameters['LocationData'] = requestParameters.locationData;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/game/details/{GameId}`.replace(`{${"GameId"}}`, encodeURIComponent(String(requestParameters.gameId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: GameDetailsRequestToJSON(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GameResultFromJSON(jsonValue));
    }
    /**
     */
    async gameDetailsRequestdetailsGameIdCreate(requestParameters, initOverrides) {
        const response = await this.gameDetailsRequestdetailsGameIdCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async gameDetailsRequestdetailsGameIdDeleteRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling gameDetailsRequestdetailsGameIdDelete.');
        }
        if (requestParameters.gameId === null || requestParameters.gameId === undefined) {
            throw new runtime.RequiredError('gameId', 'Required parameter requestParameters.gameId was null or undefined when calling gameDetailsRequestdetailsGameIdDelete.');
        }
        const queryParameters = {};
        if (requestParameters.utcOffSet !== undefined) {
            queryParameters['UtcOffSet'] = requestParameters.utcOffSet;
        }
        if (requestParameters.locationData !== undefined) {
            queryParameters['LocationData'] = requestParameters.locationData;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/game/details/{GameId}`.replace(`{${"GameId"}}`, encodeURIComponent(String(requestParameters.gameId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GameResultFromJSON(jsonValue));
    }
    /**
     */
    async gameDetailsRequestdetailsGameIdDelete(requestParameters, initOverrides) {
        const response = await this.gameDetailsRequestdetailsGameIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async gameDetailsRequestdetailsGameIdGetRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling gameDetailsRequestdetailsGameIdGet.');
        }
        if (requestParameters.gameId === null || requestParameters.gameId === undefined) {
            throw new runtime.RequiredError('gameId', 'Required parameter requestParameters.gameId was null or undefined when calling gameDetailsRequestdetailsGameIdGet.');
        }
        const queryParameters = {};
        if (requestParameters.utcOffSet !== undefined) {
            queryParameters['UtcOffSet'] = requestParameters.utcOffSet;
        }
        if (requestParameters.locationData !== undefined) {
            queryParameters['LocationData'] = requestParameters.locationData;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/game/details/{GameId}`.replace(`{${"GameId"}}`, encodeURIComponent(String(requestParameters.gameId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GameResultFromJSON(jsonValue));
    }
    /**
     */
    async gameDetailsRequestdetailsGameIdGet(requestParameters, initOverrides) {
        const response = await this.gameDetailsRequestdetailsGameIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async gameDetailsRequestdetailsGameIdPostRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling gameDetailsRequestdetailsGameIdPost.');
        }
        if (requestParameters.gameId === null || requestParameters.gameId === undefined) {
            throw new runtime.RequiredError('gameId', 'Required parameter requestParameters.gameId was null or undefined when calling gameDetailsRequestdetailsGameIdPost.');
        }
        const queryParameters = {};
        if (requestParameters.utcOffSet !== undefined) {
            queryParameters['UtcOffSet'] = requestParameters.utcOffSet;
        }
        if (requestParameters.locationData !== undefined) {
            queryParameters['LocationData'] = requestParameters.locationData;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/game/details/{GameId}`.replace(`{${"GameId"}}`, encodeURIComponent(String(requestParameters.gameId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GameDetailsRequestToJSON(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GameResultFromJSON(jsonValue));
    }
    /**
     */
    async gameDetailsRequestdetailsGameIdPost(requestParameters, initOverrides) {
        const response = await this.gameDetailsRequestdetailsGameIdPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async gameHomeRequesthomeCreateRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling gameHomeRequesthomeCreate.');
        }
        const queryParameters = {};
        if (requestParameters.utcOffSet !== undefined) {
            queryParameters['UtcOffSet'] = requestParameters.utcOffSet;
        }
        if (requestParameters.locationData !== undefined) {
            queryParameters['LocationData'] = requestParameters.locationData;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/game/home`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: GameHomeRequestToJSON(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GameHomeResponseFromJSON(jsonValue));
    }
    /**
     */
    async gameHomeRequesthomeCreate(requestParameters, initOverrides) {
        const response = await this.gameHomeRequesthomeCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async gameHomeRequesthomeDeleteRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling gameHomeRequesthomeDelete.');
        }
        const queryParameters = {};
        if (requestParameters.utcOffSet !== undefined) {
            queryParameters['UtcOffSet'] = requestParameters.utcOffSet;
        }
        if (requestParameters.locationData !== undefined) {
            queryParameters['LocationData'] = requestParameters.locationData;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/game/home`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GameHomeResponseFromJSON(jsonValue));
    }
    /**
     */
    async gameHomeRequesthomeDelete(requestParameters, initOverrides) {
        const response = await this.gameHomeRequesthomeDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async gameHomeRequesthomeGetRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling gameHomeRequesthomeGet.');
        }
        const queryParameters = {};
        if (requestParameters.utcOffSet !== undefined) {
            queryParameters['UtcOffSet'] = requestParameters.utcOffSet;
        }
        if (requestParameters.locationData !== undefined) {
            queryParameters['LocationData'] = requestParameters.locationData;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/game/home`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GameHomeResponseFromJSON(jsonValue));
    }
    /**
     */
    async gameHomeRequesthomeGet(requestParameters, initOverrides) {
        const response = await this.gameHomeRequesthomeGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async gameHomeRequesthomePostRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling gameHomeRequesthomePost.');
        }
        const queryParameters = {};
        if (requestParameters.utcOffSet !== undefined) {
            queryParameters['UtcOffSet'] = requestParameters.utcOffSet;
        }
        if (requestParameters.locationData !== undefined) {
            queryParameters['LocationData'] = requestParameters.locationData;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/game/home`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GameHomeRequestToJSON(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GameHomeResponseFromJSON(jsonValue));
    }
    /**
     */
    async gameHomeRequesthomePost(requestParameters, initOverrides) {
        const response = await this.gameHomeRequesthomePostRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async gameTicketInfoRequestGameIdticketInfoCreateRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling gameTicketInfoRequestGameIdticketInfoCreate.');
        }
        if (requestParameters.gameId === null || requestParameters.gameId === undefined) {
            throw new runtime.RequiredError('gameId', 'Required parameter requestParameters.gameId was null or undefined when calling gameTicketInfoRequestGameIdticketInfoCreate.');
        }
        const queryParameters = {};
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/game/{GameId}/ticketInfo`.replace(`{${"GameId"}}`, encodeURIComponent(String(requestParameters.gameId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: GameTicketInfoRequestToJSON(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GameTicketInfoFromJSON(jsonValue));
    }
    /**
     */
    async gameTicketInfoRequestGameIdticketInfoCreate(requestParameters, initOverrides) {
        const response = await this.gameTicketInfoRequestGameIdticketInfoCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async gameTicketInfoRequestGameIdticketInfoDeleteRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling gameTicketInfoRequestGameIdticketInfoDelete.');
        }
        if (requestParameters.gameId === null || requestParameters.gameId === undefined) {
            throw new runtime.RequiredError('gameId', 'Required parameter requestParameters.gameId was null or undefined when calling gameTicketInfoRequestGameIdticketInfoDelete.');
        }
        const queryParameters = {};
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/game/{GameId}/ticketInfo`.replace(`{${"GameId"}}`, encodeURIComponent(String(requestParameters.gameId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GameTicketInfoFromJSON(jsonValue));
    }
    /**
     */
    async gameTicketInfoRequestGameIdticketInfoDelete(requestParameters, initOverrides) {
        const response = await this.gameTicketInfoRequestGameIdticketInfoDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async gameTicketInfoRequestGameIdticketInfoGetRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling gameTicketInfoRequestGameIdticketInfoGet.');
        }
        if (requestParameters.gameId === null || requestParameters.gameId === undefined) {
            throw new runtime.RequiredError('gameId', 'Required parameter requestParameters.gameId was null or undefined when calling gameTicketInfoRequestGameIdticketInfoGet.');
        }
        const queryParameters = {};
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/game/{GameId}/ticketInfo`.replace(`{${"GameId"}}`, encodeURIComponent(String(requestParameters.gameId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GameTicketInfoFromJSON(jsonValue));
    }
    /**
     */
    async gameTicketInfoRequestGameIdticketInfoGet(requestParameters, initOverrides) {
        const response = await this.gameTicketInfoRequestGameIdticketInfoGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async gameTicketInfoRequestGameIdticketInfoPostRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling gameTicketInfoRequestGameIdticketInfoPost.');
        }
        if (requestParameters.gameId === null || requestParameters.gameId === undefined) {
            throw new runtime.RequiredError('gameId', 'Required parameter requestParameters.gameId was null or undefined when calling gameTicketInfoRequestGameIdticketInfoPost.');
        }
        const queryParameters = {};
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/game/{GameId}/ticketInfo`.replace(`{${"GameId"}}`, encodeURIComponent(String(requestParameters.gameId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GameTicketInfoRequestToJSON(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GameTicketInfoFromJSON(jsonValue));
    }
    /**
     */
    async gameTicketInfoRequestGameIdticketInfoPost(requestParameters, initOverrides) {
        const response = await this.gameTicketInfoRequestGameIdticketInfoPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async geoRestrictionRequestgeoexistsCreateRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling geoRestrictionRequestgeoexistsCreate.');
        }
        const queryParameters = {};
        if (requestParameters.game !== undefined) {
            queryParameters['Game'] = requestParameters.game;
        }
        if (requestParameters.contentCard !== undefined) {
            queryParameters['ContentCard'] = requestParameters.contentCard;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/game/geoexists`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: GeoRestrictionRequestToJSON(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GeoRestrictionResponseFromJSON(jsonValue));
    }
    /**
     */
    async geoRestrictionRequestgeoexistsCreate(requestParameters, initOverrides) {
        const response = await this.geoRestrictionRequestgeoexistsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async geoRestrictionRequestgeoexistsDeleteRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling geoRestrictionRequestgeoexistsDelete.');
        }
        const queryParameters = {};
        if (requestParameters.game !== undefined) {
            queryParameters['Game'] = requestParameters.game;
        }
        if (requestParameters.contentCard !== undefined) {
            queryParameters['ContentCard'] = requestParameters.contentCard;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/game/geoexists`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GeoRestrictionResponseFromJSON(jsonValue));
    }
    /**
     */
    async geoRestrictionRequestgeoexistsDelete(requestParameters, initOverrides) {
        const response = await this.geoRestrictionRequestgeoexistsDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async geoRestrictionRequestgeoexistsGetRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling geoRestrictionRequestgeoexistsGet.');
        }
        const queryParameters = {};
        if (requestParameters.game !== undefined) {
            queryParameters['Game'] = requestParameters.game;
        }
        if (requestParameters.contentCard !== undefined) {
            queryParameters['ContentCard'] = requestParameters.contentCard;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/game/geoexists`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GeoRestrictionResponseFromJSON(jsonValue));
    }
    /**
     */
    async geoRestrictionRequestgeoexistsGet(requestParameters, initOverrides) {
        const response = await this.geoRestrictionRequestgeoexistsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async geoRestrictionRequestgeoexistsPostRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling geoRestrictionRequestgeoexistsPost.');
        }
        const queryParameters = {};
        if (requestParameters.game !== undefined) {
            queryParameters['Game'] = requestParameters.game;
        }
        if (requestParameters.contentCard !== undefined) {
            queryParameters['ContentCard'] = requestParameters.contentCard;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/game/geoexists`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GeoRestrictionRequestToJSON(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GeoRestrictionResponseFromJSON(jsonValue));
    }
    /**
     */
    async geoRestrictionRequestgeoexistsPost(requestParameters, initOverrides) {
        const response = await this.geoRestrictionRequestgeoexistsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async getGameRequestGameIdCreateRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling getGameRequestGameIdCreate.');
        }
        if (requestParameters.gameId === null || requestParameters.gameId === undefined) {
            throw new runtime.RequiredError('gameId', 'Required parameter requestParameters.gameId was null or undefined when calling getGameRequestGameIdCreate.');
        }
        const queryParameters = {};
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/game/{GameId}`.replace(`{${"GameId"}}`, encodeURIComponent(String(requestParameters.gameId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: GetGameRequestToJSON(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response);
    }
    /**
     */
    async getGameRequestGameIdCreate(requestParameters, initOverrides) {
        const response = await this.getGameRequestGameIdCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async getGameRequestGameIdDeleteRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling getGameRequestGameIdDelete.');
        }
        if (requestParameters.gameId === null || requestParameters.gameId === undefined) {
            throw new runtime.RequiredError('gameId', 'Required parameter requestParameters.gameId was null or undefined when calling getGameRequestGameIdDelete.');
        }
        const queryParameters = {};
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/game/{GameId}`.replace(`{${"GameId"}}`, encodeURIComponent(String(requestParameters.gameId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response);
    }
    /**
     */
    async getGameRequestGameIdDelete(requestParameters, initOverrides) {
        const response = await this.getGameRequestGameIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async getGameRequestGameIdGetRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling getGameRequestGameIdGet.');
        }
        if (requestParameters.gameId === null || requestParameters.gameId === undefined) {
            throw new runtime.RequiredError('gameId', 'Required parameter requestParameters.gameId was null or undefined when calling getGameRequestGameIdGet.');
        }
        const queryParameters = {};
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/game/{GameId}`.replace(`{${"GameId"}}`, encodeURIComponent(String(requestParameters.gameId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response);
    }
    /**
     */
    async getGameRequestGameIdGet(requestParameters, initOverrides) {
        const response = await this.getGameRequestGameIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async getGameRequestGameIdPostRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling getGameRequestGameIdPost.');
        }
        if (requestParameters.gameId === null || requestParameters.gameId === undefined) {
            throw new runtime.RequiredError('gameId', 'Required parameter requestParameters.gameId was null or undefined when calling getGameRequestGameIdPost.');
        }
        const queryParameters = {};
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/game/{GameId}`.replace(`{${"GameId"}}`, encodeURIComponent(String(requestParameters.gameId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetGameRequestToJSON(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response);
    }
    /**
     */
    async getGameRequestGameIdPost(requestParameters, initOverrides) {
        const response = await this.getGameRequestGameIdPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async getGameResultsRequestresultsCreateRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling getGameResultsRequestresultsCreate.');
        }
        const queryParameters = {};
        if (requestParameters.filterStartDate !== undefined) {
            queryParameters['FilterStartDate'] = requestParameters.filterStartDate;
        }
        if (requestParameters.filterEndDate !== undefined) {
            queryParameters['FilterEndDate'] = requestParameters.filterEndDate;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/game/results`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: GetGameResultsRequestToJSON(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GameResultResponseFromJSON(jsonValue));
    }
    /**
     */
    async getGameResultsRequestresultsCreate(requestParameters, initOverrides) {
        const response = await this.getGameResultsRequestresultsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async getGameResultsRequestresultsDeleteRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling getGameResultsRequestresultsDelete.');
        }
        const queryParameters = {};
        if (requestParameters.filterStartDate !== undefined) {
            queryParameters['FilterStartDate'] = requestParameters.filterStartDate;
        }
        if (requestParameters.filterEndDate !== undefined) {
            queryParameters['FilterEndDate'] = requestParameters.filterEndDate;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/game/results`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GameResultResponseFromJSON(jsonValue));
    }
    /**
     */
    async getGameResultsRequestresultsDelete(requestParameters, initOverrides) {
        const response = await this.getGameResultsRequestresultsDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async getGameResultsRequestresultsGetRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling getGameResultsRequestresultsGet.');
        }
        const queryParameters = {};
        if (requestParameters.filterStartDate !== undefined) {
            queryParameters['FilterStartDate'] = requestParameters.filterStartDate;
        }
        if (requestParameters.filterEndDate !== undefined) {
            queryParameters['FilterEndDate'] = requestParameters.filterEndDate;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/game/results`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GameResultResponseFromJSON(jsonValue));
    }
    /**
     */
    async getGameResultsRequestresultsGet(requestParameters, initOverrides) {
        const response = await this.getGameResultsRequestresultsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async getGameResultsRequestresultsPostRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling getGameResultsRequestresultsPost.');
        }
        const queryParameters = {};
        if (requestParameters.filterStartDate !== undefined) {
            queryParameters['FilterStartDate'] = requestParameters.filterStartDate;
        }
        if (requestParameters.filterEndDate !== undefined) {
            queryParameters['FilterEndDate'] = requestParameters.filterEndDate;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/game/results`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetGameResultsRequestToJSON(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GameResultResponseFromJSON(jsonValue));
    }
    /**
     */
    async getGameResultsRequestresultsPost(requestParameters, initOverrides) {
        const response = await this.getGameResultsRequestresultsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async getGameTotalCashoutValueRequestgetGameTotalCashoutValueCreateRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling getGameTotalCashoutValueRequestgetGameTotalCashoutValueCreate.');
        }
        const queryParameters = {};
        if (requestParameters.gameId !== undefined) {
            queryParameters['GameId'] = requestParameters.gameId;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/game/getGameTotalCashoutValue`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: GetGameTotalCashoutValueRequestToJSON(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GameTotalCashoutValueResponseFromJSON(jsonValue));
    }
    /**
     */
    async getGameTotalCashoutValueRequestgetGameTotalCashoutValueCreate(requestParameters, initOverrides) {
        const response = await this.getGameTotalCashoutValueRequestgetGameTotalCashoutValueCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async getGameTotalCashoutValueRequestgetGameTotalCashoutValueDeleteRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling getGameTotalCashoutValueRequestgetGameTotalCashoutValueDelete.');
        }
        const queryParameters = {};
        if (requestParameters.gameId !== undefined) {
            queryParameters['GameId'] = requestParameters.gameId;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/game/getGameTotalCashoutValue`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GameTotalCashoutValueResponseFromJSON(jsonValue));
    }
    /**
     */
    async getGameTotalCashoutValueRequestgetGameTotalCashoutValueDelete(requestParameters, initOverrides) {
        const response = await this.getGameTotalCashoutValueRequestgetGameTotalCashoutValueDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async getGameTotalCashoutValueRequestgetGameTotalCashoutValueGetRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling getGameTotalCashoutValueRequestgetGameTotalCashoutValueGet.');
        }
        const queryParameters = {};
        if (requestParameters.gameId !== undefined) {
            queryParameters['GameId'] = requestParameters.gameId;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/game/getGameTotalCashoutValue`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GameTotalCashoutValueResponseFromJSON(jsonValue));
    }
    /**
     */
    async getGameTotalCashoutValueRequestgetGameTotalCashoutValueGet(requestParameters, initOverrides) {
        const response = await this.getGameTotalCashoutValueRequestgetGameTotalCashoutValueGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async getGameTotalCashoutValueRequestgetGameTotalCashoutValuePostRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling getGameTotalCashoutValueRequestgetGameTotalCashoutValuePost.');
        }
        const queryParameters = {};
        if (requestParameters.gameId !== undefined) {
            queryParameters['GameId'] = requestParameters.gameId;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/game/getGameTotalCashoutValue`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetGameTotalCashoutValueRequestToJSON(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GameTotalCashoutValueResponseFromJSON(jsonValue));
    }
    /**
     */
    async getGameTotalCashoutValueRequestgetGameTotalCashoutValuePost(requestParameters, initOverrides) {
        const response = await this.getGameTotalCashoutValueRequestgetGameTotalCashoutValuePostRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async leaderboardRequestForOrgleaderboardOrgCreateRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling leaderboardRequestForOrgleaderboardOrgCreate.');
        }
        const queryParameters = {};
        if (requestParameters.gameId !== undefined) {
            queryParameters['GameId'] = requestParameters.gameId;
        }
        if (requestParameters.followingOnly !== undefined) {
            queryParameters['FollowingOnly'] = requestParameters.followingOnly;
        }
        if (requestParameters.playerId !== undefined) {
            queryParameters['PlayerId'] = requestParameters.playerId;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/game/leaderboard-org`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: LeaderboardRequestForOrgToJSON(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => FrontEndLeaderboardFromJSON(jsonValue));
    }
    /**
     */
    async leaderboardRequestForOrgleaderboardOrgCreate(requestParameters, initOverrides) {
        const response = await this.leaderboardRequestForOrgleaderboardOrgCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async leaderboardRequestForOrgleaderboardOrgDeleteRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling leaderboardRequestForOrgleaderboardOrgDelete.');
        }
        const queryParameters = {};
        if (requestParameters.gameId !== undefined) {
            queryParameters['GameId'] = requestParameters.gameId;
        }
        if (requestParameters.followingOnly !== undefined) {
            queryParameters['FollowingOnly'] = requestParameters.followingOnly;
        }
        if (requestParameters.playerId !== undefined) {
            queryParameters['PlayerId'] = requestParameters.playerId;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/game/leaderboard-org`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => FrontEndLeaderboardFromJSON(jsonValue));
    }
    /**
     */
    async leaderboardRequestForOrgleaderboardOrgDelete(requestParameters, initOverrides) {
        const response = await this.leaderboardRequestForOrgleaderboardOrgDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async leaderboardRequestForOrgleaderboardOrgGetRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling leaderboardRequestForOrgleaderboardOrgGet.');
        }
        const queryParameters = {};
        if (requestParameters.gameId !== undefined) {
            queryParameters['GameId'] = requestParameters.gameId;
        }
        if (requestParameters.followingOnly !== undefined) {
            queryParameters['FollowingOnly'] = requestParameters.followingOnly;
        }
        if (requestParameters.playerId !== undefined) {
            queryParameters['PlayerId'] = requestParameters.playerId;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/game/leaderboard-org`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => FrontEndLeaderboardFromJSON(jsonValue));
    }
    /**
     */
    async leaderboardRequestForOrgleaderboardOrgGet(requestParameters, initOverrides) {
        const response = await this.leaderboardRequestForOrgleaderboardOrgGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async leaderboardRequestForOrgleaderboardOrgPostRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling leaderboardRequestForOrgleaderboardOrgPost.');
        }
        const queryParameters = {};
        if (requestParameters.gameId !== undefined) {
            queryParameters['GameId'] = requestParameters.gameId;
        }
        if (requestParameters.followingOnly !== undefined) {
            queryParameters['FollowingOnly'] = requestParameters.followingOnly;
        }
        if (requestParameters.playerId !== undefined) {
            queryParameters['PlayerId'] = requestParameters.playerId;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/game/leaderboard-org`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LeaderboardRequestForOrgToJSON(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => FrontEndLeaderboardFromJSON(jsonValue));
    }
    /**
     */
    async leaderboardRequestForOrgleaderboardOrgPost(requestParameters, initOverrides) {
        const response = await this.leaderboardRequestForOrgleaderboardOrgPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async leaderboardRequestleaderboardCreateRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling leaderboardRequestleaderboardCreate.');
        }
        const queryParameters = {};
        if (requestParameters.gameId !== undefined) {
            queryParameters['GameId'] = requestParameters.gameId;
        }
        if (requestParameters.followingOnly !== undefined) {
            queryParameters['FollowingOnly'] = requestParameters.followingOnly;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/game/leaderboard`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: LeaderboardRequestToJSON(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => FrontEndLeaderboardFromJSON(jsonValue));
    }
    /**
     */
    async leaderboardRequestleaderboardCreate(requestParameters, initOverrides) {
        const response = await this.leaderboardRequestleaderboardCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async leaderboardRequestleaderboardDeleteRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling leaderboardRequestleaderboardDelete.');
        }
        const queryParameters = {};
        if (requestParameters.gameId !== undefined) {
            queryParameters['GameId'] = requestParameters.gameId;
        }
        if (requestParameters.followingOnly !== undefined) {
            queryParameters['FollowingOnly'] = requestParameters.followingOnly;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/game/leaderboard`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => FrontEndLeaderboardFromJSON(jsonValue));
    }
    /**
     */
    async leaderboardRequestleaderboardDelete(requestParameters, initOverrides) {
        const response = await this.leaderboardRequestleaderboardDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async leaderboardRequestleaderboardGetRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling leaderboardRequestleaderboardGet.');
        }
        const queryParameters = {};
        if (requestParameters.gameId !== undefined) {
            queryParameters['GameId'] = requestParameters.gameId;
        }
        if (requestParameters.followingOnly !== undefined) {
            queryParameters['FollowingOnly'] = requestParameters.followingOnly;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/game/leaderboard`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => FrontEndLeaderboardFromJSON(jsonValue));
    }
    /**
     */
    async leaderboardRequestleaderboardGet(requestParameters, initOverrides) {
        const response = await this.leaderboardRequestleaderboardGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async leaderboardRequestleaderboardPostRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling leaderboardRequestleaderboardPost.');
        }
        const queryParameters = {};
        if (requestParameters.gameId !== undefined) {
            queryParameters['GameId'] = requestParameters.gameId;
        }
        if (requestParameters.followingOnly !== undefined) {
            queryParameters['FollowingOnly'] = requestParameters.followingOnly;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/game/leaderboard`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LeaderboardRequestToJSON(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => FrontEndLeaderboardFromJSON(jsonValue));
    }
    /**
     */
    async leaderboardRequestleaderboardPost(requestParameters, initOverrides) {
        const response = await this.leaderboardRequestleaderboardPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async myTicketsRequestGameIdmyTicketsCreateRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling myTicketsRequestGameIdmyTicketsCreate.');
        }
        if (requestParameters.gameId === null || requestParameters.gameId === undefined) {
            throw new runtime.RequiredError('gameId', 'Required parameter requestParameters.gameId was null or undefined when calling myTicketsRequestGameIdmyTicketsCreate.');
        }
        const queryParameters = {};
        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }
        if (requestParameters.cashedOut !== undefined) {
            queryParameters['CashedOut'] = requestParameters.cashedOut;
        }
        if (requestParameters.knockedOut !== undefined) {
            queryParameters['KnockedOut'] = requestParameters.knockedOut;
        }
        if (requestParameters.eventIndex !== undefined) {
            queryParameters['EventIndex'] = requestParameters.eventIndex;
        }
        if (requestParameters.bracketId !== undefined) {
            queryParameters['BracketId'] = requestParameters.bracketId;
        }
        if (requestParameters.relatedEventId !== undefined) {
            queryParameters['RelatedEventId'] = requestParameters.relatedEventId;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/game/{GameId}/myTickets`.replace(`{${"GameId"}}`, encodeURIComponent(String(requestParameters.gameId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MyTicketsRequestToJSON(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => MyTicketsResponseFromJSON(jsonValue));
    }
    /**
     */
    async myTicketsRequestGameIdmyTicketsCreate(requestParameters, initOverrides) {
        const response = await this.myTicketsRequestGameIdmyTicketsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async myTicketsRequestGameIdmyTicketsDeleteRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling myTicketsRequestGameIdmyTicketsDelete.');
        }
        if (requestParameters.gameId === null || requestParameters.gameId === undefined) {
            throw new runtime.RequiredError('gameId', 'Required parameter requestParameters.gameId was null or undefined when calling myTicketsRequestGameIdmyTicketsDelete.');
        }
        const queryParameters = {};
        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }
        if (requestParameters.cashedOut !== undefined) {
            queryParameters['CashedOut'] = requestParameters.cashedOut;
        }
        if (requestParameters.knockedOut !== undefined) {
            queryParameters['KnockedOut'] = requestParameters.knockedOut;
        }
        if (requestParameters.eventIndex !== undefined) {
            queryParameters['EventIndex'] = requestParameters.eventIndex;
        }
        if (requestParameters.bracketId !== undefined) {
            queryParameters['BracketId'] = requestParameters.bracketId;
        }
        if (requestParameters.relatedEventId !== undefined) {
            queryParameters['RelatedEventId'] = requestParameters.relatedEventId;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/game/{GameId}/myTickets`.replace(`{${"GameId"}}`, encodeURIComponent(String(requestParameters.gameId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => MyTicketsResponseFromJSON(jsonValue));
    }
    /**
     */
    async myTicketsRequestGameIdmyTicketsDelete(requestParameters, initOverrides) {
        const response = await this.myTicketsRequestGameIdmyTicketsDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async myTicketsRequestGameIdmyTicketsGetRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling myTicketsRequestGameIdmyTicketsGet.');
        }
        if (requestParameters.gameId === null || requestParameters.gameId === undefined) {
            throw new runtime.RequiredError('gameId', 'Required parameter requestParameters.gameId was null or undefined when calling myTicketsRequestGameIdmyTicketsGet.');
        }
        const queryParameters = {};
        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }
        if (requestParameters.cashedOut !== undefined) {
            queryParameters['CashedOut'] = requestParameters.cashedOut;
        }
        if (requestParameters.knockedOut !== undefined) {
            queryParameters['KnockedOut'] = requestParameters.knockedOut;
        }
        if (requestParameters.eventIndex !== undefined) {
            queryParameters['EventIndex'] = requestParameters.eventIndex;
        }
        if (requestParameters.bracketId !== undefined) {
            queryParameters['BracketId'] = requestParameters.bracketId;
        }
        if (requestParameters.relatedEventId !== undefined) {
            queryParameters['RelatedEventId'] = requestParameters.relatedEventId;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/game/{GameId}/myTickets`.replace(`{${"GameId"}}`, encodeURIComponent(String(requestParameters.gameId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => MyTicketsResponseFromJSON(jsonValue));
    }
    /**
     */
    async myTicketsRequestGameIdmyTicketsGet(requestParameters, initOverrides) {
        const response = await this.myTicketsRequestGameIdmyTicketsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async myTicketsRequestGameIdmyTicketsPostRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling myTicketsRequestGameIdmyTicketsPost.');
        }
        if (requestParameters.gameId === null || requestParameters.gameId === undefined) {
            throw new runtime.RequiredError('gameId', 'Required parameter requestParameters.gameId was null or undefined when calling myTicketsRequestGameIdmyTicketsPost.');
        }
        const queryParameters = {};
        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }
        if (requestParameters.cashedOut !== undefined) {
            queryParameters['CashedOut'] = requestParameters.cashedOut;
        }
        if (requestParameters.knockedOut !== undefined) {
            queryParameters['KnockedOut'] = requestParameters.knockedOut;
        }
        if (requestParameters.eventIndex !== undefined) {
            queryParameters['EventIndex'] = requestParameters.eventIndex;
        }
        if (requestParameters.bracketId !== undefined) {
            queryParameters['BracketId'] = requestParameters.bracketId;
        }
        if (requestParameters.relatedEventId !== undefined) {
            queryParameters['RelatedEventId'] = requestParameters.relatedEventId;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/game/{GameId}/myTickets`.replace(`{${"GameId"}}`, encodeURIComponent(String(requestParameters.gameId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MyTicketsRequestToJSON(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => MyTicketsResponseFromJSON(jsonValue));
    }
    /**
     */
    async myTicketsRequestGameIdmyTicketsPost(requestParameters, initOverrides) {
        const response = await this.myTicketsRequestGameIdmyTicketsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async playerTicketSummaryRequestGameIdplayerTicketSummaryCreateRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling playerTicketSummaryRequestGameIdplayerTicketSummaryCreate.');
        }
        if (requestParameters.gameId === null || requestParameters.gameId === undefined) {
            throw new runtime.RequiredError('gameId', 'Required parameter requestParameters.gameId was null or undefined when calling playerTicketSummaryRequestGameIdplayerTicketSummaryCreate.');
        }
        const queryParameters = {};
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/game/{GameId}/playerTicketSummary`.replace(`{${"GameId"}}`, encodeURIComponent(String(requestParameters.gameId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PlayerTicketSummaryRequestToJSON(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => PlayerTicketSummaryFromJSON(jsonValue));
    }
    /**
     */
    async playerTicketSummaryRequestGameIdplayerTicketSummaryCreate(requestParameters, initOverrides) {
        const response = await this.playerTicketSummaryRequestGameIdplayerTicketSummaryCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async playerTicketSummaryRequestGameIdplayerTicketSummaryDeleteRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling playerTicketSummaryRequestGameIdplayerTicketSummaryDelete.');
        }
        if (requestParameters.gameId === null || requestParameters.gameId === undefined) {
            throw new runtime.RequiredError('gameId', 'Required parameter requestParameters.gameId was null or undefined when calling playerTicketSummaryRequestGameIdplayerTicketSummaryDelete.');
        }
        const queryParameters = {};
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/game/{GameId}/playerTicketSummary`.replace(`{${"GameId"}}`, encodeURIComponent(String(requestParameters.gameId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => PlayerTicketSummaryFromJSON(jsonValue));
    }
    /**
     */
    async playerTicketSummaryRequestGameIdplayerTicketSummaryDelete(requestParameters, initOverrides) {
        const response = await this.playerTicketSummaryRequestGameIdplayerTicketSummaryDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async playerTicketSummaryRequestGameIdplayerTicketSummaryGetRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling playerTicketSummaryRequestGameIdplayerTicketSummaryGet.');
        }
        if (requestParameters.gameId === null || requestParameters.gameId === undefined) {
            throw new runtime.RequiredError('gameId', 'Required parameter requestParameters.gameId was null or undefined when calling playerTicketSummaryRequestGameIdplayerTicketSummaryGet.');
        }
        const queryParameters = {};
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/game/{GameId}/playerTicketSummary`.replace(`{${"GameId"}}`, encodeURIComponent(String(requestParameters.gameId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => PlayerTicketSummaryFromJSON(jsonValue));
    }
    /**
     */
    async playerTicketSummaryRequestGameIdplayerTicketSummaryGet(requestParameters, initOverrides) {
        const response = await this.playerTicketSummaryRequestGameIdplayerTicketSummaryGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async playerTicketSummaryRequestGameIdplayerTicketSummaryPostRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling playerTicketSummaryRequestGameIdplayerTicketSummaryPost.');
        }
        if (requestParameters.gameId === null || requestParameters.gameId === undefined) {
            throw new runtime.RequiredError('gameId', 'Required parameter requestParameters.gameId was null or undefined when calling playerTicketSummaryRequestGameIdplayerTicketSummaryPost.');
        }
        const queryParameters = {};
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/game/{GameId}/playerTicketSummary`.replace(`{${"GameId"}}`, encodeURIComponent(String(requestParameters.gameId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PlayerTicketSummaryRequestToJSON(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => PlayerTicketSummaryFromJSON(jsonValue));
    }
    /**
     */
    async playerTicketSummaryRequestGameIdplayerTicketSummaryPost(requestParameters, initOverrides) {
        const response = await this.playerTicketSummaryRequestGameIdplayerTicketSummaryPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async swopTicketsRequestGameIdswopTicketsCreateRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling swopTicketsRequestGameIdswopTicketsCreate.');
        }
        if (requestParameters.gameId === null || requestParameters.gameId === undefined) {
            throw new runtime.RequiredError('gameId', 'Required parameter requestParameters.gameId was null or undefined when calling swopTicketsRequestGameIdswopTicketsCreate.');
        }
        const queryParameters = {};
        if (requestParameters.quantity !== undefined) {
            queryParameters['Quantity'] = requestParameters.quantity;
        }
        if (requestParameters.eventId !== undefined) {
            queryParameters['EventId'] = requestParameters.eventId;
        }
        if (requestParameters.bracketId !== undefined) {
            queryParameters['BracketId'] = requestParameters.bracketId;
        }
        if (requestParameters.expectedPrice !== undefined) {
            queryParameters['ExpectedPrice'] = requestParameters.expectedPrice;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/game/{GameId}/swopTickets`.replace(`{${"GameId"}}`, encodeURIComponent(String(requestParameters.gameId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SwopTicketsRequestToJSON(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => TicketOrderResultFromJSON(jsonValue));
    }
    /**
     */
    async swopTicketsRequestGameIdswopTicketsCreate(requestParameters, initOverrides) {
        const response = await this.swopTicketsRequestGameIdswopTicketsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async swopTicketsRequestGameIdswopTicketsDeleteRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling swopTicketsRequestGameIdswopTicketsDelete.');
        }
        if (requestParameters.gameId === null || requestParameters.gameId === undefined) {
            throw new runtime.RequiredError('gameId', 'Required parameter requestParameters.gameId was null or undefined when calling swopTicketsRequestGameIdswopTicketsDelete.');
        }
        const queryParameters = {};
        if (requestParameters.quantity !== undefined) {
            queryParameters['Quantity'] = requestParameters.quantity;
        }
        if (requestParameters.eventId !== undefined) {
            queryParameters['EventId'] = requestParameters.eventId;
        }
        if (requestParameters.bracketId !== undefined) {
            queryParameters['BracketId'] = requestParameters.bracketId;
        }
        if (requestParameters.expectedPrice !== undefined) {
            queryParameters['ExpectedPrice'] = requestParameters.expectedPrice;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/game/{GameId}/swopTickets`.replace(`{${"GameId"}}`, encodeURIComponent(String(requestParameters.gameId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => TicketOrderResultFromJSON(jsonValue));
    }
    /**
     */
    async swopTicketsRequestGameIdswopTicketsDelete(requestParameters, initOverrides) {
        const response = await this.swopTicketsRequestGameIdswopTicketsDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async swopTicketsRequestGameIdswopTicketsGetRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling swopTicketsRequestGameIdswopTicketsGet.');
        }
        if (requestParameters.gameId === null || requestParameters.gameId === undefined) {
            throw new runtime.RequiredError('gameId', 'Required parameter requestParameters.gameId was null or undefined when calling swopTicketsRequestGameIdswopTicketsGet.');
        }
        const queryParameters = {};
        if (requestParameters.quantity !== undefined) {
            queryParameters['Quantity'] = requestParameters.quantity;
        }
        if (requestParameters.eventId !== undefined) {
            queryParameters['EventId'] = requestParameters.eventId;
        }
        if (requestParameters.bracketId !== undefined) {
            queryParameters['BracketId'] = requestParameters.bracketId;
        }
        if (requestParameters.expectedPrice !== undefined) {
            queryParameters['ExpectedPrice'] = requestParameters.expectedPrice;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/game/{GameId}/swopTickets`.replace(`{${"GameId"}}`, encodeURIComponent(String(requestParameters.gameId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => TicketOrderResultFromJSON(jsonValue));
    }
    /**
     */
    async swopTicketsRequestGameIdswopTicketsGet(requestParameters, initOverrides) {
        const response = await this.swopTicketsRequestGameIdswopTicketsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async swopTicketsRequestGameIdswopTicketsPostRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling swopTicketsRequestGameIdswopTicketsPost.');
        }
        if (requestParameters.gameId === null || requestParameters.gameId === undefined) {
            throw new runtime.RequiredError('gameId', 'Required parameter requestParameters.gameId was null or undefined when calling swopTicketsRequestGameIdswopTicketsPost.');
        }
        const queryParameters = {};
        if (requestParameters.quantity !== undefined) {
            queryParameters['Quantity'] = requestParameters.quantity;
        }
        if (requestParameters.eventId !== undefined) {
            queryParameters['EventId'] = requestParameters.eventId;
        }
        if (requestParameters.bracketId !== undefined) {
            queryParameters['BracketId'] = requestParameters.bracketId;
        }
        if (requestParameters.expectedPrice !== undefined) {
            queryParameters['ExpectedPrice'] = requestParameters.expectedPrice;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/game/{GameId}/swopTickets`.replace(`{${"GameId"}}`, encodeURIComponent(String(requestParameters.gameId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SwopTicketsRequestToJSON(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => TicketOrderResultFromJSON(jsonValue));
    }
    /**
     */
    async swopTicketsRequestGameIdswopTicketsPost(requestParameters, initOverrides) {
        const response = await this.swopTicketsRequestGameIdswopTicketsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async systemSettingsRequestsystemSettingsCreateRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling systemSettingsRequestsystemSettingsCreate.');
        }
        const queryParameters = {};
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/game/systemSettings`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SystemSettingsRequestToJSON(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => SystemSettingsResponseFromJSON(jsonValue));
    }
    /**
     */
    async systemSettingsRequestsystemSettingsCreate(requestParameters, initOverrides) {
        const response = await this.systemSettingsRequestsystemSettingsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async systemSettingsRequestsystemSettingsDeleteRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling systemSettingsRequestsystemSettingsDelete.');
        }
        const queryParameters = {};
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/game/systemSettings`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => SystemSettingsResponseFromJSON(jsonValue));
    }
    /**
     */
    async systemSettingsRequestsystemSettingsDelete(requestParameters, initOverrides) {
        const response = await this.systemSettingsRequestsystemSettingsDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async systemSettingsRequestsystemSettingsGetRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling systemSettingsRequestsystemSettingsGet.');
        }
        const queryParameters = {};
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/game/systemSettings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => SystemSettingsResponseFromJSON(jsonValue));
    }
    /**
     */
    async systemSettingsRequestsystemSettingsGet(requestParameters, initOverrides) {
        const response = await this.systemSettingsRequestsystemSettingsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async systemSettingsRequestsystemSettingsPostRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling systemSettingsRequestsystemSettingsPost.');
        }
        const queryParameters = {};
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/game/systemSettings`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SystemSettingsRequestToJSON(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => SystemSettingsResponseFromJSON(jsonValue));
    }
    /**
     */
    async systemSettingsRequestsystemSettingsPost(requestParameters, initOverrides) {
        const response = await this.systemSettingsRequestsystemSettingsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async ticketBracketSummaryRequestGameIdticketBracketSummaryCreateRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling ticketBracketSummaryRequestGameIdticketBracketSummaryCreate.');
        }
        if (requestParameters.gameId === null || requestParameters.gameId === undefined) {
            throw new runtime.RequiredError('gameId', 'Required parameter requestParameters.gameId was null or undefined when calling ticketBracketSummaryRequestGameIdticketBracketSummaryCreate.');
        }
        const queryParameters = {};
        if (requestParameters.eventIndex !== undefined) {
            queryParameters['EventIndex'] = requestParameters.eventIndex;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/game/{GameId}/ticketBracketSummary`.replace(`{${"GameId"}}`, encodeURIComponent(String(requestParameters.gameId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TicketBracketSummaryRequestToJSON(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => TicketBracketSummaryResponseFromJSON(jsonValue));
    }
    /**
     */
    async ticketBracketSummaryRequestGameIdticketBracketSummaryCreate(requestParameters, initOverrides) {
        const response = await this.ticketBracketSummaryRequestGameIdticketBracketSummaryCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async ticketBracketSummaryRequestGameIdticketBracketSummaryDeleteRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling ticketBracketSummaryRequestGameIdticketBracketSummaryDelete.');
        }
        if (requestParameters.gameId === null || requestParameters.gameId === undefined) {
            throw new runtime.RequiredError('gameId', 'Required parameter requestParameters.gameId was null or undefined when calling ticketBracketSummaryRequestGameIdticketBracketSummaryDelete.');
        }
        const queryParameters = {};
        if (requestParameters.eventIndex !== undefined) {
            queryParameters['EventIndex'] = requestParameters.eventIndex;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/game/{GameId}/ticketBracketSummary`.replace(`{${"GameId"}}`, encodeURIComponent(String(requestParameters.gameId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => TicketBracketSummaryResponseFromJSON(jsonValue));
    }
    /**
     */
    async ticketBracketSummaryRequestGameIdticketBracketSummaryDelete(requestParameters, initOverrides) {
        const response = await this.ticketBracketSummaryRequestGameIdticketBracketSummaryDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async ticketBracketSummaryRequestGameIdticketBracketSummaryGetRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling ticketBracketSummaryRequestGameIdticketBracketSummaryGet.');
        }
        if (requestParameters.gameId === null || requestParameters.gameId === undefined) {
            throw new runtime.RequiredError('gameId', 'Required parameter requestParameters.gameId was null or undefined when calling ticketBracketSummaryRequestGameIdticketBracketSummaryGet.');
        }
        const queryParameters = {};
        if (requestParameters.eventIndex !== undefined) {
            queryParameters['EventIndex'] = requestParameters.eventIndex;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/game/{GameId}/ticketBracketSummary`.replace(`{${"GameId"}}`, encodeURIComponent(String(requestParameters.gameId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => TicketBracketSummaryResponseFromJSON(jsonValue));
    }
    /**
     */
    async ticketBracketSummaryRequestGameIdticketBracketSummaryGet(requestParameters, initOverrides) {
        const response = await this.ticketBracketSummaryRequestGameIdticketBracketSummaryGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async ticketBracketSummaryRequestGameIdticketBracketSummaryPostRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling ticketBracketSummaryRequestGameIdticketBracketSummaryPost.');
        }
        if (requestParameters.gameId === null || requestParameters.gameId === undefined) {
            throw new runtime.RequiredError('gameId', 'Required parameter requestParameters.gameId was null or undefined when calling ticketBracketSummaryRequestGameIdticketBracketSummaryPost.');
        }
        const queryParameters = {};
        if (requestParameters.eventIndex !== undefined) {
            queryParameters['EventIndex'] = requestParameters.eventIndex;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/game/{GameId}/ticketBracketSummary`.replace(`{${"GameId"}}`, encodeURIComponent(String(requestParameters.gameId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TicketBracketSummaryRequestToJSON(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => TicketBracketSummaryResponseFromJSON(jsonValue));
    }
    /**
     */
    async ticketBracketSummaryRequestGameIdticketBracketSummaryPost(requestParameters, initOverrides) {
        const response = await this.ticketBracketSummaryRequestGameIdticketBracketSummaryPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async ticketDetailRequestGameIdticketTicketIdCreateRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling ticketDetailRequestGameIdticketTicketIdCreate.');
        }
        if (requestParameters.gameId === null || requestParameters.gameId === undefined) {
            throw new runtime.RequiredError('gameId', 'Required parameter requestParameters.gameId was null or undefined when calling ticketDetailRequestGameIdticketTicketIdCreate.');
        }
        if (requestParameters.ticketId === null || requestParameters.ticketId === undefined) {
            throw new runtime.RequiredError('ticketId', 'Required parameter requestParameters.ticketId was null or undefined when calling ticketDetailRequestGameIdticketTicketIdCreate.');
        }
        const queryParameters = {};
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/game/{GameId}/ticket/{TicketId}`.replace(`{${"GameId"}}`, encodeURIComponent(String(requestParameters.gameId))).replace(`{${"TicketId"}}`, encodeURIComponent(String(requestParameters.ticketId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TicketDetailRequestToJSON(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => TicketDetailResponseFromJSON(jsonValue));
    }
    /**
     */
    async ticketDetailRequestGameIdticketTicketIdCreate(requestParameters, initOverrides) {
        const response = await this.ticketDetailRequestGameIdticketTicketIdCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async ticketDetailRequestGameIdticketTicketIdDeleteRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling ticketDetailRequestGameIdticketTicketIdDelete.');
        }
        if (requestParameters.gameId === null || requestParameters.gameId === undefined) {
            throw new runtime.RequiredError('gameId', 'Required parameter requestParameters.gameId was null or undefined when calling ticketDetailRequestGameIdticketTicketIdDelete.');
        }
        if (requestParameters.ticketId === null || requestParameters.ticketId === undefined) {
            throw new runtime.RequiredError('ticketId', 'Required parameter requestParameters.ticketId was null or undefined when calling ticketDetailRequestGameIdticketTicketIdDelete.');
        }
        const queryParameters = {};
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/game/{GameId}/ticket/{TicketId}`.replace(`{${"GameId"}}`, encodeURIComponent(String(requestParameters.gameId))).replace(`{${"TicketId"}}`, encodeURIComponent(String(requestParameters.ticketId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => TicketDetailResponseFromJSON(jsonValue));
    }
    /**
     */
    async ticketDetailRequestGameIdticketTicketIdDelete(requestParameters, initOverrides) {
        const response = await this.ticketDetailRequestGameIdticketTicketIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async ticketDetailRequestGameIdticketTicketIdGetRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling ticketDetailRequestGameIdticketTicketIdGet.');
        }
        if (requestParameters.gameId === null || requestParameters.gameId === undefined) {
            throw new runtime.RequiredError('gameId', 'Required parameter requestParameters.gameId was null or undefined when calling ticketDetailRequestGameIdticketTicketIdGet.');
        }
        if (requestParameters.ticketId === null || requestParameters.ticketId === undefined) {
            throw new runtime.RequiredError('ticketId', 'Required parameter requestParameters.ticketId was null or undefined when calling ticketDetailRequestGameIdticketTicketIdGet.');
        }
        const queryParameters = {};
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/game/{GameId}/ticket/{TicketId}`.replace(`{${"GameId"}}`, encodeURIComponent(String(requestParameters.gameId))).replace(`{${"TicketId"}}`, encodeURIComponent(String(requestParameters.ticketId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => TicketDetailResponseFromJSON(jsonValue));
    }
    /**
     */
    async ticketDetailRequestGameIdticketTicketIdGet(requestParameters, initOverrides) {
        const response = await this.ticketDetailRequestGameIdticketTicketIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async ticketDetailRequestGameIdticketTicketIdPostRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling ticketDetailRequestGameIdticketTicketIdPost.');
        }
        if (requestParameters.gameId === null || requestParameters.gameId === undefined) {
            throw new runtime.RequiredError('gameId', 'Required parameter requestParameters.gameId was null or undefined when calling ticketDetailRequestGameIdticketTicketIdPost.');
        }
        if (requestParameters.ticketId === null || requestParameters.ticketId === undefined) {
            throw new runtime.RequiredError('ticketId', 'Required parameter requestParameters.ticketId was null or undefined when calling ticketDetailRequestGameIdticketTicketIdPost.');
        }
        const queryParameters = {};
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/game/{GameId}/ticket/{TicketId}`.replace(`{${"GameId"}}`, encodeURIComponent(String(requestParameters.gameId))).replace(`{${"TicketId"}}`, encodeURIComponent(String(requestParameters.ticketId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TicketDetailRequestToJSON(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => TicketDetailResponseFromJSON(jsonValue));
    }
    /**
     */
    async ticketDetailRequestGameIdticketTicketIdPost(requestParameters, initOverrides) {
        const response = await this.ticketDetailRequestGameIdticketTicketIdPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
/**
    * @export
    * @enum {string}
    */
export var BankTicketsRequestGameIdbankTicketsCreateAcceptEnum;
(function (BankTicketsRequestGameIdbankTicketsCreateAcceptEnum) {
    BankTicketsRequestGameIdbankTicketsCreateAcceptEnum["ApplicationJson"] = "application/json";
})(BankTicketsRequestGameIdbankTicketsCreateAcceptEnum || (BankTicketsRequestGameIdbankTicketsCreateAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var BankTicketsRequestGameIdbankTicketsDeleteAcceptEnum;
(function (BankTicketsRequestGameIdbankTicketsDeleteAcceptEnum) {
    BankTicketsRequestGameIdbankTicketsDeleteAcceptEnum["ApplicationJson"] = "application/json";
})(BankTicketsRequestGameIdbankTicketsDeleteAcceptEnum || (BankTicketsRequestGameIdbankTicketsDeleteAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var BankTicketsRequestGameIdbankTicketsGetAcceptEnum;
(function (BankTicketsRequestGameIdbankTicketsGetAcceptEnum) {
    BankTicketsRequestGameIdbankTicketsGetAcceptEnum["ApplicationJson"] = "application/json";
})(BankTicketsRequestGameIdbankTicketsGetAcceptEnum || (BankTicketsRequestGameIdbankTicketsGetAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var BankTicketsRequestGameIdbankTicketsPostAcceptEnum;
(function (BankTicketsRequestGameIdbankTicketsPostAcceptEnum) {
    BankTicketsRequestGameIdbankTicketsPostAcceptEnum["ApplicationJson"] = "application/json";
})(BankTicketsRequestGameIdbankTicketsPostAcceptEnum || (BankTicketsRequestGameIdbankTicketsPostAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var BuyTicketFromBankRequestGameIdbuyTicketFromBankCreateAcceptEnum;
(function (BuyTicketFromBankRequestGameIdbuyTicketFromBankCreateAcceptEnum) {
    BuyTicketFromBankRequestGameIdbuyTicketFromBankCreateAcceptEnum["ApplicationJson"] = "application/json";
})(BuyTicketFromBankRequestGameIdbuyTicketFromBankCreateAcceptEnum || (BuyTicketFromBankRequestGameIdbuyTicketFromBankCreateAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var BuyTicketFromBankRequestGameIdbuyTicketFromBankDeleteAcceptEnum;
(function (BuyTicketFromBankRequestGameIdbuyTicketFromBankDeleteAcceptEnum) {
    BuyTicketFromBankRequestGameIdbuyTicketFromBankDeleteAcceptEnum["ApplicationJson"] = "application/json";
})(BuyTicketFromBankRequestGameIdbuyTicketFromBankDeleteAcceptEnum || (BuyTicketFromBankRequestGameIdbuyTicketFromBankDeleteAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var BuyTicketFromBankRequestGameIdbuyTicketFromBankGetAcceptEnum;
(function (BuyTicketFromBankRequestGameIdbuyTicketFromBankGetAcceptEnum) {
    BuyTicketFromBankRequestGameIdbuyTicketFromBankGetAcceptEnum["ApplicationJson"] = "application/json";
})(BuyTicketFromBankRequestGameIdbuyTicketFromBankGetAcceptEnum || (BuyTicketFromBankRequestGameIdbuyTicketFromBankGetAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var BuyTicketFromBankRequestGameIdbuyTicketFromBankPostAcceptEnum;
(function (BuyTicketFromBankRequestGameIdbuyTicketFromBankPostAcceptEnum) {
    BuyTicketFromBankRequestGameIdbuyTicketFromBankPostAcceptEnum["ApplicationJson"] = "application/json";
})(BuyTicketFromBankRequestGameIdbuyTicketFromBankPostAcceptEnum || (BuyTicketFromBankRequestGameIdbuyTicketFromBankPostAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var BuyTicketFromPoolRequestGameIdbuyTicketFromPoolCreateAcceptEnum;
(function (BuyTicketFromPoolRequestGameIdbuyTicketFromPoolCreateAcceptEnum) {
    BuyTicketFromPoolRequestGameIdbuyTicketFromPoolCreateAcceptEnum["ApplicationJson"] = "application/json";
})(BuyTicketFromPoolRequestGameIdbuyTicketFromPoolCreateAcceptEnum || (BuyTicketFromPoolRequestGameIdbuyTicketFromPoolCreateAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var BuyTicketFromPoolRequestGameIdbuyTicketFromPoolDeleteAcceptEnum;
(function (BuyTicketFromPoolRequestGameIdbuyTicketFromPoolDeleteAcceptEnum) {
    BuyTicketFromPoolRequestGameIdbuyTicketFromPoolDeleteAcceptEnum["ApplicationJson"] = "application/json";
})(BuyTicketFromPoolRequestGameIdbuyTicketFromPoolDeleteAcceptEnum || (BuyTicketFromPoolRequestGameIdbuyTicketFromPoolDeleteAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var BuyTicketFromPoolRequestGameIdbuyTicketFromPoolGetAcceptEnum;
(function (BuyTicketFromPoolRequestGameIdbuyTicketFromPoolGetAcceptEnum) {
    BuyTicketFromPoolRequestGameIdbuyTicketFromPoolGetAcceptEnum["ApplicationJson"] = "application/json";
})(BuyTicketFromPoolRequestGameIdbuyTicketFromPoolGetAcceptEnum || (BuyTicketFromPoolRequestGameIdbuyTicketFromPoolGetAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var BuyTicketFromPoolRequestGameIdbuyTicketFromPoolPostAcceptEnum;
(function (BuyTicketFromPoolRequestGameIdbuyTicketFromPoolPostAcceptEnum) {
    BuyTicketFromPoolRequestGameIdbuyTicketFromPoolPostAcceptEnum["ApplicationJson"] = "application/json";
})(BuyTicketFromPoolRequestGameIdbuyTicketFromPoolPostAcceptEnum || (BuyTicketFromPoolRequestGameIdbuyTicketFromPoolPostAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var CashoutTicketsRequestGameIdcashoutTicketsCreateAcceptEnum;
(function (CashoutTicketsRequestGameIdcashoutTicketsCreateAcceptEnum) {
    CashoutTicketsRequestGameIdcashoutTicketsCreateAcceptEnum["ApplicationJson"] = "application/json";
})(CashoutTicketsRequestGameIdcashoutTicketsCreateAcceptEnum || (CashoutTicketsRequestGameIdcashoutTicketsCreateAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var CashoutTicketsRequestGameIdcashoutTicketsDeleteAcceptEnum;
(function (CashoutTicketsRequestGameIdcashoutTicketsDeleteAcceptEnum) {
    CashoutTicketsRequestGameIdcashoutTicketsDeleteAcceptEnum["ApplicationJson"] = "application/json";
})(CashoutTicketsRequestGameIdcashoutTicketsDeleteAcceptEnum || (CashoutTicketsRequestGameIdcashoutTicketsDeleteAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var CashoutTicketsRequestGameIdcashoutTicketsGetAcceptEnum;
(function (CashoutTicketsRequestGameIdcashoutTicketsGetAcceptEnum) {
    CashoutTicketsRequestGameIdcashoutTicketsGetAcceptEnum["ApplicationJson"] = "application/json";
})(CashoutTicketsRequestGameIdcashoutTicketsGetAcceptEnum || (CashoutTicketsRequestGameIdcashoutTicketsGetAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var CashoutTicketsRequestGameIdcashoutTicketsPostAcceptEnum;
(function (CashoutTicketsRequestGameIdcashoutTicketsPostAcceptEnum) {
    CashoutTicketsRequestGameIdcashoutTicketsPostAcceptEnum["ApplicationJson"] = "application/json";
})(CashoutTicketsRequestGameIdcashoutTicketsPostAcceptEnum || (CashoutTicketsRequestGameIdcashoutTicketsPostAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var EventTicketResultRequestGameIdeventTicketResultCreateAcceptEnum;
(function (EventTicketResultRequestGameIdeventTicketResultCreateAcceptEnum) {
    EventTicketResultRequestGameIdeventTicketResultCreateAcceptEnum["ApplicationJson"] = "application/json";
})(EventTicketResultRequestGameIdeventTicketResultCreateAcceptEnum || (EventTicketResultRequestGameIdeventTicketResultCreateAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var EventTicketResultRequestGameIdeventTicketResultDeleteAcceptEnum;
(function (EventTicketResultRequestGameIdeventTicketResultDeleteAcceptEnum) {
    EventTicketResultRequestGameIdeventTicketResultDeleteAcceptEnum["ApplicationJson"] = "application/json";
})(EventTicketResultRequestGameIdeventTicketResultDeleteAcceptEnum || (EventTicketResultRequestGameIdeventTicketResultDeleteAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var EventTicketResultRequestGameIdeventTicketResultGetAcceptEnum;
(function (EventTicketResultRequestGameIdeventTicketResultGetAcceptEnum) {
    EventTicketResultRequestGameIdeventTicketResultGetAcceptEnum["ApplicationJson"] = "application/json";
})(EventTicketResultRequestGameIdeventTicketResultGetAcceptEnum || (EventTicketResultRequestGameIdeventTicketResultGetAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var EventTicketResultRequestGameIdeventTicketResultPostAcceptEnum;
(function (EventTicketResultRequestGameIdeventTicketResultPostAcceptEnum) {
    EventTicketResultRequestGameIdeventTicketResultPostAcceptEnum["ApplicationJson"] = "application/json";
})(EventTicketResultRequestGameIdeventTicketResultPostAcceptEnum || (EventTicketResultRequestGameIdeventTicketResultPostAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var GameDetailsRequestdetailsGameIdCreateAcceptEnum;
(function (GameDetailsRequestdetailsGameIdCreateAcceptEnum) {
    GameDetailsRequestdetailsGameIdCreateAcceptEnum["ApplicationJson"] = "application/json";
})(GameDetailsRequestdetailsGameIdCreateAcceptEnum || (GameDetailsRequestdetailsGameIdCreateAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var GameDetailsRequestdetailsGameIdDeleteAcceptEnum;
(function (GameDetailsRequestdetailsGameIdDeleteAcceptEnum) {
    GameDetailsRequestdetailsGameIdDeleteAcceptEnum["ApplicationJson"] = "application/json";
})(GameDetailsRequestdetailsGameIdDeleteAcceptEnum || (GameDetailsRequestdetailsGameIdDeleteAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var GameDetailsRequestdetailsGameIdGetAcceptEnum;
(function (GameDetailsRequestdetailsGameIdGetAcceptEnum) {
    GameDetailsRequestdetailsGameIdGetAcceptEnum["ApplicationJson"] = "application/json";
})(GameDetailsRequestdetailsGameIdGetAcceptEnum || (GameDetailsRequestdetailsGameIdGetAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var GameDetailsRequestdetailsGameIdPostAcceptEnum;
(function (GameDetailsRequestdetailsGameIdPostAcceptEnum) {
    GameDetailsRequestdetailsGameIdPostAcceptEnum["ApplicationJson"] = "application/json";
})(GameDetailsRequestdetailsGameIdPostAcceptEnum || (GameDetailsRequestdetailsGameIdPostAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var GameHomeRequesthomeCreateAcceptEnum;
(function (GameHomeRequesthomeCreateAcceptEnum) {
    GameHomeRequesthomeCreateAcceptEnum["ApplicationJson"] = "application/json";
})(GameHomeRequesthomeCreateAcceptEnum || (GameHomeRequesthomeCreateAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var GameHomeRequesthomeDeleteAcceptEnum;
(function (GameHomeRequesthomeDeleteAcceptEnum) {
    GameHomeRequesthomeDeleteAcceptEnum["ApplicationJson"] = "application/json";
})(GameHomeRequesthomeDeleteAcceptEnum || (GameHomeRequesthomeDeleteAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var GameHomeRequesthomeGetAcceptEnum;
(function (GameHomeRequesthomeGetAcceptEnum) {
    GameHomeRequesthomeGetAcceptEnum["ApplicationJson"] = "application/json";
})(GameHomeRequesthomeGetAcceptEnum || (GameHomeRequesthomeGetAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var GameHomeRequesthomePostAcceptEnum;
(function (GameHomeRequesthomePostAcceptEnum) {
    GameHomeRequesthomePostAcceptEnum["ApplicationJson"] = "application/json";
})(GameHomeRequesthomePostAcceptEnum || (GameHomeRequesthomePostAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var GameTicketInfoRequestGameIdticketInfoCreateAcceptEnum;
(function (GameTicketInfoRequestGameIdticketInfoCreateAcceptEnum) {
    GameTicketInfoRequestGameIdticketInfoCreateAcceptEnum["ApplicationJson"] = "application/json";
})(GameTicketInfoRequestGameIdticketInfoCreateAcceptEnum || (GameTicketInfoRequestGameIdticketInfoCreateAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var GameTicketInfoRequestGameIdticketInfoDeleteAcceptEnum;
(function (GameTicketInfoRequestGameIdticketInfoDeleteAcceptEnum) {
    GameTicketInfoRequestGameIdticketInfoDeleteAcceptEnum["ApplicationJson"] = "application/json";
})(GameTicketInfoRequestGameIdticketInfoDeleteAcceptEnum || (GameTicketInfoRequestGameIdticketInfoDeleteAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var GameTicketInfoRequestGameIdticketInfoGetAcceptEnum;
(function (GameTicketInfoRequestGameIdticketInfoGetAcceptEnum) {
    GameTicketInfoRequestGameIdticketInfoGetAcceptEnum["ApplicationJson"] = "application/json";
})(GameTicketInfoRequestGameIdticketInfoGetAcceptEnum || (GameTicketInfoRequestGameIdticketInfoGetAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var GameTicketInfoRequestGameIdticketInfoPostAcceptEnum;
(function (GameTicketInfoRequestGameIdticketInfoPostAcceptEnum) {
    GameTicketInfoRequestGameIdticketInfoPostAcceptEnum["ApplicationJson"] = "application/json";
})(GameTicketInfoRequestGameIdticketInfoPostAcceptEnum || (GameTicketInfoRequestGameIdticketInfoPostAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var GeoRestrictionRequestgeoexistsCreateAcceptEnum;
(function (GeoRestrictionRequestgeoexistsCreateAcceptEnum) {
    GeoRestrictionRequestgeoexistsCreateAcceptEnum["ApplicationJson"] = "application/json";
})(GeoRestrictionRequestgeoexistsCreateAcceptEnum || (GeoRestrictionRequestgeoexistsCreateAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var GeoRestrictionRequestgeoexistsDeleteAcceptEnum;
(function (GeoRestrictionRequestgeoexistsDeleteAcceptEnum) {
    GeoRestrictionRequestgeoexistsDeleteAcceptEnum["ApplicationJson"] = "application/json";
})(GeoRestrictionRequestgeoexistsDeleteAcceptEnum || (GeoRestrictionRequestgeoexistsDeleteAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var GeoRestrictionRequestgeoexistsGetAcceptEnum;
(function (GeoRestrictionRequestgeoexistsGetAcceptEnum) {
    GeoRestrictionRequestgeoexistsGetAcceptEnum["ApplicationJson"] = "application/json";
})(GeoRestrictionRequestgeoexistsGetAcceptEnum || (GeoRestrictionRequestgeoexistsGetAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var GeoRestrictionRequestgeoexistsPostAcceptEnum;
(function (GeoRestrictionRequestgeoexistsPostAcceptEnum) {
    GeoRestrictionRequestgeoexistsPostAcceptEnum["ApplicationJson"] = "application/json";
})(GeoRestrictionRequestgeoexistsPostAcceptEnum || (GeoRestrictionRequestgeoexistsPostAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var GetGameRequestGameIdCreateAcceptEnum;
(function (GetGameRequestGameIdCreateAcceptEnum) {
    GetGameRequestGameIdCreateAcceptEnum["ApplicationJson"] = "application/json";
})(GetGameRequestGameIdCreateAcceptEnum || (GetGameRequestGameIdCreateAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var GetGameRequestGameIdDeleteAcceptEnum;
(function (GetGameRequestGameIdDeleteAcceptEnum) {
    GetGameRequestGameIdDeleteAcceptEnum["ApplicationJson"] = "application/json";
})(GetGameRequestGameIdDeleteAcceptEnum || (GetGameRequestGameIdDeleteAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var GetGameRequestGameIdGetAcceptEnum;
(function (GetGameRequestGameIdGetAcceptEnum) {
    GetGameRequestGameIdGetAcceptEnum["ApplicationJson"] = "application/json";
})(GetGameRequestGameIdGetAcceptEnum || (GetGameRequestGameIdGetAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var GetGameRequestGameIdPostAcceptEnum;
(function (GetGameRequestGameIdPostAcceptEnum) {
    GetGameRequestGameIdPostAcceptEnum["ApplicationJson"] = "application/json";
})(GetGameRequestGameIdPostAcceptEnum || (GetGameRequestGameIdPostAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var GetGameResultsRequestresultsCreateAcceptEnum;
(function (GetGameResultsRequestresultsCreateAcceptEnum) {
    GetGameResultsRequestresultsCreateAcceptEnum["ApplicationJson"] = "application/json";
})(GetGameResultsRequestresultsCreateAcceptEnum || (GetGameResultsRequestresultsCreateAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var GetGameResultsRequestresultsDeleteAcceptEnum;
(function (GetGameResultsRequestresultsDeleteAcceptEnum) {
    GetGameResultsRequestresultsDeleteAcceptEnum["ApplicationJson"] = "application/json";
})(GetGameResultsRequestresultsDeleteAcceptEnum || (GetGameResultsRequestresultsDeleteAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var GetGameResultsRequestresultsGetAcceptEnum;
(function (GetGameResultsRequestresultsGetAcceptEnum) {
    GetGameResultsRequestresultsGetAcceptEnum["ApplicationJson"] = "application/json";
})(GetGameResultsRequestresultsGetAcceptEnum || (GetGameResultsRequestresultsGetAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var GetGameResultsRequestresultsPostAcceptEnum;
(function (GetGameResultsRequestresultsPostAcceptEnum) {
    GetGameResultsRequestresultsPostAcceptEnum["ApplicationJson"] = "application/json";
})(GetGameResultsRequestresultsPostAcceptEnum || (GetGameResultsRequestresultsPostAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var GetGameTotalCashoutValueRequestgetGameTotalCashoutValueCreateAcceptEnum;
(function (GetGameTotalCashoutValueRequestgetGameTotalCashoutValueCreateAcceptEnum) {
    GetGameTotalCashoutValueRequestgetGameTotalCashoutValueCreateAcceptEnum["ApplicationJson"] = "application/json";
})(GetGameTotalCashoutValueRequestgetGameTotalCashoutValueCreateAcceptEnum || (GetGameTotalCashoutValueRequestgetGameTotalCashoutValueCreateAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var GetGameTotalCashoutValueRequestgetGameTotalCashoutValueDeleteAcceptEnum;
(function (GetGameTotalCashoutValueRequestgetGameTotalCashoutValueDeleteAcceptEnum) {
    GetGameTotalCashoutValueRequestgetGameTotalCashoutValueDeleteAcceptEnum["ApplicationJson"] = "application/json";
})(GetGameTotalCashoutValueRequestgetGameTotalCashoutValueDeleteAcceptEnum || (GetGameTotalCashoutValueRequestgetGameTotalCashoutValueDeleteAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var GetGameTotalCashoutValueRequestgetGameTotalCashoutValueGetAcceptEnum;
(function (GetGameTotalCashoutValueRequestgetGameTotalCashoutValueGetAcceptEnum) {
    GetGameTotalCashoutValueRequestgetGameTotalCashoutValueGetAcceptEnum["ApplicationJson"] = "application/json";
})(GetGameTotalCashoutValueRequestgetGameTotalCashoutValueGetAcceptEnum || (GetGameTotalCashoutValueRequestgetGameTotalCashoutValueGetAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var GetGameTotalCashoutValueRequestgetGameTotalCashoutValuePostAcceptEnum;
(function (GetGameTotalCashoutValueRequestgetGameTotalCashoutValuePostAcceptEnum) {
    GetGameTotalCashoutValueRequestgetGameTotalCashoutValuePostAcceptEnum["ApplicationJson"] = "application/json";
})(GetGameTotalCashoutValueRequestgetGameTotalCashoutValuePostAcceptEnum || (GetGameTotalCashoutValueRequestgetGameTotalCashoutValuePostAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var LeaderboardRequestForOrgleaderboardOrgCreateAcceptEnum;
(function (LeaderboardRequestForOrgleaderboardOrgCreateAcceptEnum) {
    LeaderboardRequestForOrgleaderboardOrgCreateAcceptEnum["ApplicationJson"] = "application/json";
})(LeaderboardRequestForOrgleaderboardOrgCreateAcceptEnum || (LeaderboardRequestForOrgleaderboardOrgCreateAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var LeaderboardRequestForOrgleaderboardOrgDeleteAcceptEnum;
(function (LeaderboardRequestForOrgleaderboardOrgDeleteAcceptEnum) {
    LeaderboardRequestForOrgleaderboardOrgDeleteAcceptEnum["ApplicationJson"] = "application/json";
})(LeaderboardRequestForOrgleaderboardOrgDeleteAcceptEnum || (LeaderboardRequestForOrgleaderboardOrgDeleteAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var LeaderboardRequestForOrgleaderboardOrgGetAcceptEnum;
(function (LeaderboardRequestForOrgleaderboardOrgGetAcceptEnum) {
    LeaderboardRequestForOrgleaderboardOrgGetAcceptEnum["ApplicationJson"] = "application/json";
})(LeaderboardRequestForOrgleaderboardOrgGetAcceptEnum || (LeaderboardRequestForOrgleaderboardOrgGetAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var LeaderboardRequestForOrgleaderboardOrgPostAcceptEnum;
(function (LeaderboardRequestForOrgleaderboardOrgPostAcceptEnum) {
    LeaderboardRequestForOrgleaderboardOrgPostAcceptEnum["ApplicationJson"] = "application/json";
})(LeaderboardRequestForOrgleaderboardOrgPostAcceptEnum || (LeaderboardRequestForOrgleaderboardOrgPostAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var LeaderboardRequestleaderboardCreateAcceptEnum;
(function (LeaderboardRequestleaderboardCreateAcceptEnum) {
    LeaderboardRequestleaderboardCreateAcceptEnum["ApplicationJson"] = "application/json";
})(LeaderboardRequestleaderboardCreateAcceptEnum || (LeaderboardRequestleaderboardCreateAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var LeaderboardRequestleaderboardDeleteAcceptEnum;
(function (LeaderboardRequestleaderboardDeleteAcceptEnum) {
    LeaderboardRequestleaderboardDeleteAcceptEnum["ApplicationJson"] = "application/json";
})(LeaderboardRequestleaderboardDeleteAcceptEnum || (LeaderboardRequestleaderboardDeleteAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var LeaderboardRequestleaderboardGetAcceptEnum;
(function (LeaderboardRequestleaderboardGetAcceptEnum) {
    LeaderboardRequestleaderboardGetAcceptEnum["ApplicationJson"] = "application/json";
})(LeaderboardRequestleaderboardGetAcceptEnum || (LeaderboardRequestleaderboardGetAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var LeaderboardRequestleaderboardPostAcceptEnum;
(function (LeaderboardRequestleaderboardPostAcceptEnum) {
    LeaderboardRequestleaderboardPostAcceptEnum["ApplicationJson"] = "application/json";
})(LeaderboardRequestleaderboardPostAcceptEnum || (LeaderboardRequestleaderboardPostAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var MyTicketsRequestGameIdmyTicketsCreateAcceptEnum;
(function (MyTicketsRequestGameIdmyTicketsCreateAcceptEnum) {
    MyTicketsRequestGameIdmyTicketsCreateAcceptEnum["ApplicationJson"] = "application/json";
})(MyTicketsRequestGameIdmyTicketsCreateAcceptEnum || (MyTicketsRequestGameIdmyTicketsCreateAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var MyTicketsRequestGameIdmyTicketsDeleteAcceptEnum;
(function (MyTicketsRequestGameIdmyTicketsDeleteAcceptEnum) {
    MyTicketsRequestGameIdmyTicketsDeleteAcceptEnum["ApplicationJson"] = "application/json";
})(MyTicketsRequestGameIdmyTicketsDeleteAcceptEnum || (MyTicketsRequestGameIdmyTicketsDeleteAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var MyTicketsRequestGameIdmyTicketsGetAcceptEnum;
(function (MyTicketsRequestGameIdmyTicketsGetAcceptEnum) {
    MyTicketsRequestGameIdmyTicketsGetAcceptEnum["ApplicationJson"] = "application/json";
})(MyTicketsRequestGameIdmyTicketsGetAcceptEnum || (MyTicketsRequestGameIdmyTicketsGetAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var MyTicketsRequestGameIdmyTicketsPostAcceptEnum;
(function (MyTicketsRequestGameIdmyTicketsPostAcceptEnum) {
    MyTicketsRequestGameIdmyTicketsPostAcceptEnum["ApplicationJson"] = "application/json";
})(MyTicketsRequestGameIdmyTicketsPostAcceptEnum || (MyTicketsRequestGameIdmyTicketsPostAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var PlayerTicketSummaryRequestGameIdplayerTicketSummaryCreateAcceptEnum;
(function (PlayerTicketSummaryRequestGameIdplayerTicketSummaryCreateAcceptEnum) {
    PlayerTicketSummaryRequestGameIdplayerTicketSummaryCreateAcceptEnum["ApplicationJson"] = "application/json";
})(PlayerTicketSummaryRequestGameIdplayerTicketSummaryCreateAcceptEnum || (PlayerTicketSummaryRequestGameIdplayerTicketSummaryCreateAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var PlayerTicketSummaryRequestGameIdplayerTicketSummaryDeleteAcceptEnum;
(function (PlayerTicketSummaryRequestGameIdplayerTicketSummaryDeleteAcceptEnum) {
    PlayerTicketSummaryRequestGameIdplayerTicketSummaryDeleteAcceptEnum["ApplicationJson"] = "application/json";
})(PlayerTicketSummaryRequestGameIdplayerTicketSummaryDeleteAcceptEnum || (PlayerTicketSummaryRequestGameIdplayerTicketSummaryDeleteAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var PlayerTicketSummaryRequestGameIdplayerTicketSummaryGetAcceptEnum;
(function (PlayerTicketSummaryRequestGameIdplayerTicketSummaryGetAcceptEnum) {
    PlayerTicketSummaryRequestGameIdplayerTicketSummaryGetAcceptEnum["ApplicationJson"] = "application/json";
})(PlayerTicketSummaryRequestGameIdplayerTicketSummaryGetAcceptEnum || (PlayerTicketSummaryRequestGameIdplayerTicketSummaryGetAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var PlayerTicketSummaryRequestGameIdplayerTicketSummaryPostAcceptEnum;
(function (PlayerTicketSummaryRequestGameIdplayerTicketSummaryPostAcceptEnum) {
    PlayerTicketSummaryRequestGameIdplayerTicketSummaryPostAcceptEnum["ApplicationJson"] = "application/json";
})(PlayerTicketSummaryRequestGameIdplayerTicketSummaryPostAcceptEnum || (PlayerTicketSummaryRequestGameIdplayerTicketSummaryPostAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var SwopTicketsRequestGameIdswopTicketsCreateAcceptEnum;
(function (SwopTicketsRequestGameIdswopTicketsCreateAcceptEnum) {
    SwopTicketsRequestGameIdswopTicketsCreateAcceptEnum["ApplicationJson"] = "application/json";
})(SwopTicketsRequestGameIdswopTicketsCreateAcceptEnum || (SwopTicketsRequestGameIdswopTicketsCreateAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var SwopTicketsRequestGameIdswopTicketsDeleteAcceptEnum;
(function (SwopTicketsRequestGameIdswopTicketsDeleteAcceptEnum) {
    SwopTicketsRequestGameIdswopTicketsDeleteAcceptEnum["ApplicationJson"] = "application/json";
})(SwopTicketsRequestGameIdswopTicketsDeleteAcceptEnum || (SwopTicketsRequestGameIdswopTicketsDeleteAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var SwopTicketsRequestGameIdswopTicketsGetAcceptEnum;
(function (SwopTicketsRequestGameIdswopTicketsGetAcceptEnum) {
    SwopTicketsRequestGameIdswopTicketsGetAcceptEnum["ApplicationJson"] = "application/json";
})(SwopTicketsRequestGameIdswopTicketsGetAcceptEnum || (SwopTicketsRequestGameIdswopTicketsGetAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var SwopTicketsRequestGameIdswopTicketsPostAcceptEnum;
(function (SwopTicketsRequestGameIdswopTicketsPostAcceptEnum) {
    SwopTicketsRequestGameIdswopTicketsPostAcceptEnum["ApplicationJson"] = "application/json";
})(SwopTicketsRequestGameIdswopTicketsPostAcceptEnum || (SwopTicketsRequestGameIdswopTicketsPostAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var SystemSettingsRequestsystemSettingsCreateAcceptEnum;
(function (SystemSettingsRequestsystemSettingsCreateAcceptEnum) {
    SystemSettingsRequestsystemSettingsCreateAcceptEnum["ApplicationJson"] = "application/json";
})(SystemSettingsRequestsystemSettingsCreateAcceptEnum || (SystemSettingsRequestsystemSettingsCreateAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var SystemSettingsRequestsystemSettingsDeleteAcceptEnum;
(function (SystemSettingsRequestsystemSettingsDeleteAcceptEnum) {
    SystemSettingsRequestsystemSettingsDeleteAcceptEnum["ApplicationJson"] = "application/json";
})(SystemSettingsRequestsystemSettingsDeleteAcceptEnum || (SystemSettingsRequestsystemSettingsDeleteAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var SystemSettingsRequestsystemSettingsGetAcceptEnum;
(function (SystemSettingsRequestsystemSettingsGetAcceptEnum) {
    SystemSettingsRequestsystemSettingsGetAcceptEnum["ApplicationJson"] = "application/json";
})(SystemSettingsRequestsystemSettingsGetAcceptEnum || (SystemSettingsRequestsystemSettingsGetAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var SystemSettingsRequestsystemSettingsPostAcceptEnum;
(function (SystemSettingsRequestsystemSettingsPostAcceptEnum) {
    SystemSettingsRequestsystemSettingsPostAcceptEnum["ApplicationJson"] = "application/json";
})(SystemSettingsRequestsystemSettingsPostAcceptEnum || (SystemSettingsRequestsystemSettingsPostAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var TicketBracketSummaryRequestGameIdticketBracketSummaryCreateAcceptEnum;
(function (TicketBracketSummaryRequestGameIdticketBracketSummaryCreateAcceptEnum) {
    TicketBracketSummaryRequestGameIdticketBracketSummaryCreateAcceptEnum["ApplicationJson"] = "application/json";
})(TicketBracketSummaryRequestGameIdticketBracketSummaryCreateAcceptEnum || (TicketBracketSummaryRequestGameIdticketBracketSummaryCreateAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var TicketBracketSummaryRequestGameIdticketBracketSummaryDeleteAcceptEnum;
(function (TicketBracketSummaryRequestGameIdticketBracketSummaryDeleteAcceptEnum) {
    TicketBracketSummaryRequestGameIdticketBracketSummaryDeleteAcceptEnum["ApplicationJson"] = "application/json";
})(TicketBracketSummaryRequestGameIdticketBracketSummaryDeleteAcceptEnum || (TicketBracketSummaryRequestGameIdticketBracketSummaryDeleteAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var TicketBracketSummaryRequestGameIdticketBracketSummaryGetAcceptEnum;
(function (TicketBracketSummaryRequestGameIdticketBracketSummaryGetAcceptEnum) {
    TicketBracketSummaryRequestGameIdticketBracketSummaryGetAcceptEnum["ApplicationJson"] = "application/json";
})(TicketBracketSummaryRequestGameIdticketBracketSummaryGetAcceptEnum || (TicketBracketSummaryRequestGameIdticketBracketSummaryGetAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var TicketBracketSummaryRequestGameIdticketBracketSummaryPostAcceptEnum;
(function (TicketBracketSummaryRequestGameIdticketBracketSummaryPostAcceptEnum) {
    TicketBracketSummaryRequestGameIdticketBracketSummaryPostAcceptEnum["ApplicationJson"] = "application/json";
})(TicketBracketSummaryRequestGameIdticketBracketSummaryPostAcceptEnum || (TicketBracketSummaryRequestGameIdticketBracketSummaryPostAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var TicketDetailRequestGameIdticketTicketIdCreateAcceptEnum;
(function (TicketDetailRequestGameIdticketTicketIdCreateAcceptEnum) {
    TicketDetailRequestGameIdticketTicketIdCreateAcceptEnum["ApplicationJson"] = "application/json";
})(TicketDetailRequestGameIdticketTicketIdCreateAcceptEnum || (TicketDetailRequestGameIdticketTicketIdCreateAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var TicketDetailRequestGameIdticketTicketIdDeleteAcceptEnum;
(function (TicketDetailRequestGameIdticketTicketIdDeleteAcceptEnum) {
    TicketDetailRequestGameIdticketTicketIdDeleteAcceptEnum["ApplicationJson"] = "application/json";
})(TicketDetailRequestGameIdticketTicketIdDeleteAcceptEnum || (TicketDetailRequestGameIdticketTicketIdDeleteAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var TicketDetailRequestGameIdticketTicketIdGetAcceptEnum;
(function (TicketDetailRequestGameIdticketTicketIdGetAcceptEnum) {
    TicketDetailRequestGameIdticketTicketIdGetAcceptEnum["ApplicationJson"] = "application/json";
})(TicketDetailRequestGameIdticketTicketIdGetAcceptEnum || (TicketDetailRequestGameIdticketTicketIdGetAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var TicketDetailRequestGameIdticketTicketIdPostAcceptEnum;
(function (TicketDetailRequestGameIdticketTicketIdPostAcceptEnum) {
    TicketDetailRequestGameIdticketTicketIdPostAcceptEnum["ApplicationJson"] = "application/json";
})(TicketDetailRequestGameIdticketTicketIdPostAcceptEnum || (TicketDetailRequestGameIdticketTicketIdPostAcceptEnum = {}));
