import React from "react";
import { Box, SvgIcon } from "@mui/material";
import PropTypes from "prop-types";

import { ReactComponent as AFL } from "./graphics/icons/AFL.svg";
import { ReactComponent as AmericanFootball } from "./graphics/icons/AmericanFootball.svg";
import { ReactComponent as Archery } from "./graphics/icons/Archery.svg";
import { ReactComponent as AustralianRules } from "./graphics/icons/AustralianRules.svg";
import { ReactComponent as Baseball } from "./graphics/icons/Baseball.svg";
import { ReactComponent as Basketball } from "./graphics/icons/Basketball.svg";
import { ReactComponent as Boxing } from "./graphics/icons/Boxing.svg";
import { ReactComponent as Cricket } from "./graphics/icons/Cricket.svg";
import { ReactComponent as Fallback } from "./graphics/icons/Fallback.svg";
import { ReactComponent as Golf } from "./graphics/icons/Golf.svg";
import { ReactComponent as Greyhounds } from "./graphics/icons/Greyhounds.svg";
import { ReactComponent as Handball } from "./graphics/icons/Handball.svg";
import { ReactComponent as Harness } from "./graphics/icons/Harness.svg";
import { ReactComponent as Helmet } from "./graphics/icons/Helmet.svg";
import { ReactComponent as IceHockey } from "./graphics/icons/IceHockey.svg";
import { ReactComponent as NBA } from "./graphics/icons/NBA.svg";
import { ReactComponent as NRL } from "./graphics/icons/NRL.svg";
import { ReactComponent as RugbyLeague } from "./graphics/icons/RugbyLeague.svg";
import { ReactComponent as RugbyUnion } from "./graphics/icons/RugbyUnion.svg";
import { ReactComponent as Soccer } from "./graphics/icons/Soccer.svg";
import { ReactComponent as Tennis } from "./graphics/icons/Tennis.svg";
import { ReactComponent as Thoroughbred } from "./graphics/icons/Thoroughbred.svg";

const ICONS_MAP = {
  AFL,
  AmericanFootball,
  Archery,
  AustralianRules,
  Baseball,
  Basketball,
  Boxing,
  Cricket,
  Fallback,
  Golf,
  Greyhounds,
  Handball,
  Harness,
  Helmet,
  IceHockey,
  NBA,
  NRL,
  RugbyLeague,
  RugbyUnion,
  Soccer,
  Tennis,
  Thoroughbred,
};

function SportIcon({
  imageURL,
  alt,
  imgEventType,
  eventType,
  height,
  width,
  boxHeight,
  boxWidth,
  imgHeight,
  imgWidth,
  greyout = false,
  inverse = false,
}) {
  const IconComponent = ICONS_MAP[imgEventType] || Fallback;
  // console.log("SportIcon", { imageURL, imageEventType });
  return imageURL ? (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: `${boxWidth}px`,
        height: `${boxHeight}px`,
      }}
    >
      <img
        src={imageURL}
        alt={alt}
        style={{
          width: `${imgWidth}px`,
          height: `${imgHeight}px`,
          filter: `grayscale(${greyout ? 1 : 0})`,
        }}
      />
    </Box>
  ) : (
    <Box
      sx={{
        flex: "none",
        borderRadius: 1,
        overflow: "hidden",
        position: "relative",
        width: `${boxWidth}px`,
        height: `${boxHeight}px`,
        bgcolor: inverse ? "transparent" : "#000",
      }}
    >
      <SvgIcon
        component={IconComponent}
        alt={eventType}
        width={`${width}px`}
        height={`${height}px`}
        viewBox="0 0 40 40"
        sx={{
          width,
          height,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          "& path": {
            fill: inverse ? "black" : "white",
          },
          filter: `grayscale(${greyout ? 1 : 0})`,
        }}
      />
    </Box>
  );
}

SportIcon.propTypes = {
  imageURL: PropTypes.string,
  alt: PropTypes.string,
  imgEventType: PropTypes.string,
  eventType: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  imgHeight: PropTypes.number,
  imgWidth: PropTypes.number,
  boxHeight: PropTypes.number,
  boxWidth: PropTypes.number,
};

SportIcon.defaultProps = {
  imageURL: null,
  alt: "",
  imgEventType: "",
  eventType: "",
  height: 24,
  width: 24,
  imgHeight: 40,
  imgWidth: 40,
  boxHeight: 40,
  boxWidth: 40,
};

export default SportIcon;
