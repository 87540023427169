import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import NotificationsIcon from "@mui/icons-material/Notifications";
// import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import WidgetsIcon from "@mui/icons-material/Widgets";
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Button,
  Container,
  // Dialog,
  Drawer,
  IconButton,
  SvgIcon,
  Tab,
  Tabs,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, matchPath } from "react-router-dom";
import PropTypes from "prop-types";
import Profile from "../../pages/Profile";
import { ReactComponent as SwopIcon } from "../graphics/logo.svg";
import AccountMenu from "../menu/AccountMenu";
import BottomDrawer from "./BottomDrawer";
import Footer from "./Footer";
import PopoverMenu from "./PopoverMenu";
import { getAvatarUrl } from "../../utils/common-functions";
import Receipt from "../menu/Recept";
import ConfirmPurchase from "../menu/ConfirmPurchase";
import {
  updateConfirmationDialog,
  updateFullTicketViewer,
  updateReceipt,
} from "../../redux/slices/dialog.slice";
import SwopStakesHeader from "./SwopStakesHeader";
import { IsSwopStakeWeb, IsAuthenticated } from "../../services/ls";

import {
  getPlayerNotifications,
  updateBadge,
} from "../../redux/slices/notification.slice";
import { logout } from "../../redux/slices/user.slice";
import LeftNavigationBar from "./leftNavigationBar";
import RightDisplayBanner from "./rightDisplayBannerBar";
import FullTicketViewer from "../game/FullTicketViewer";

function a11yProps(index) {
  return {
    id: `header-${index}`,
    "aria-controls": `header-panel-${index}`,
  };
}

function Layout(props) {
  const { children } = props;

  const location = useLocation();
  const navigate = useNavigate();
  // account drawer
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  // const underLgScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const isXlScreen = useMediaQuery((theme) => theme.breakpoints.up("xl"));

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [accountOpen, setAccountOpen] = React.useState(false);
  const [profileOpen, setProfileOpen] = React.useState(false);

  const dispatch = useDispatch();
  const { sideBannerContentCards: sideBanner } = useSelector(
    (state) => state.gameHome.contentCard
  );
  const { confirmationDialog, receipt, fullTicketViewer } = useSelector(
    (state) => state.dialog
  );
  const { unseenNotification, updateSeen } = useSelector(
    (state) => state.notification.badge
  );
  const { ticketDetails } = useSelector((state) => state.dialog.receipt);

  const toggleAccountDrawer = (event) => {
    if (accountOpen && event.target !== anchorEl) {
      setAccountOpen(false);
    } else {
      setAccountOpen(!accountOpen);
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAccountOpen(false);
  };

  const handleProfileClick = () => {
    handleClose();
    setProfileOpen(true);
  };

  const handleActivityClick = () => {
    navigate("/my-activity");
    handleClose();
  };

  const handleLogoutClick = () => {
    handleClose();
    dispatch(logout({ callback: () => {} }));
  };

  const handleFollowingClick = () => {
    navigate("/following");
    handleClose();
  };

  const { response: playerDetail } = useSelector(
    (state) => state.user.playerSummary
  );

  const isLoggedIn =
    IsAuthenticated() && playerDetail && playerDetail.playerId > 0;

  React.useEffect(() => {
    if (isLoggedIn) {
      dispatch(updateBadge({ updateSeen: false }));
      dispatch(getPlayerNotifications());
    }
  }, [isLoggedIn]);

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <AppBar position="static" elevation={0}>
        {IsSwopStakeWeb() && (
          <>
            <SwopStakesHeader />
            <div style={{ height: "80px" }} />
          </>
        )}
        <div style={{ height: "72px" }} />

        <Box
          sx={{
            bgcolor: "#28B7E6",
            alignItems: "center",
            justifyContent: "space-between",
            px: 0,
            display: "flex",
            flexDirection: "row",
            position: "fixed",
            top: IsSwopStakeWeb() ? 80 : 0,
            left: 0,
            right: 0,
            zIndex: 1000,
            boxShadow: "none",
          }}
        >
          <Container
            // maxWidth={IsSwopStakeWeb() ? "lg" : false}
            maxWidth={false}
            disableGutters
            sx={{
              bgcolor: "#28B7E6",
              alignItems: "center",
              justifyContent: "space-between",
              px: 2,
              width: "100%",
              display: "flex",
              flexDirection: "row",
              padding: "0px 16px",
            }}
          >
            <Box
              sx={{
                flex: isMobile && isLoggedIn ? "0" : "1 1 0",
              }}
            >
              {!isMobile && !IsSwopStakeWeb() && (
                <IconButton
                  disableElevation
                  disableRipple
                  sx={{
                    ml: 1,
                    "&.MuiButtonBase-root:hover": {
                      bgcolor: "transparent",
                    },
                  }}
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <SvgIcon
                    sx={{
                      width: "auto",
                      height: 32,
                    }}
                    component={SwopIcon}
                    viewBox="0 0 45 25"
                  />
                </IconButton>
              )}
            </Box>

            <Tabs
              value={location.pathname}
              sx={{
                "& .MuiTabs-indicator": {
                  bgcolor: (theme) => theme.palette.secondary.dark,
                },
              }}
            >
              <Tab
                component={Link}
                value="/"
                to="/"
                label="Games"
                {...a11yProps(0)}
                icon={<WidgetsIcon fontSize="small" />}
                sx={{
                  color: "white",
                  fontWeight: 500,
                  px: 0,
                  minWidth: 70,
                  textTransform: "none",
                  "&.Mui-selected": {
                    color: "white", // hard coded to white according to SS requirement
                  },
                }}
              />
              <Tab
                component={Link}
                value="/results"
                to="/results"
                label="Results"
                {...a11yProps(1)}
                icon={<EmojiEventsIcon fontSize="small" />}
                sx={{
                  color: "white",
                  fontWeight: 500,
                  px: 0,
                  minWidth: 70,
                  textTransform: "none",
                  "&.Mui-selected": {
                    color: "white", // hard coded to white according to SS requirement
                  },
                }}
              />
            </Tabs>
            <Box
              sx={{
                flex: "1",
                justifyContent: "end",
                display: "flex",
                alignItems: "center",
                "& > *:not(:last-child)": {
                  mr: 1,
                },
              }}
            >
              {isLoggedIn && (
                <Link
                  to="/notifications"
                  state={{ from: window.location.pathname }}
                >
                  <IconButton>
                    <Badge
                      badgeContent={
                        !updateSeen && unseenNotification > 0
                          ? unseenNotification
                          : null
                      }
                      color="secondary"
                    >
                      <NotificationsIcon sx={{ color: "white" }} />
                    </Badge>
                  </IconButton>
                </Link>
              )}
              {isLoggedIn && (
                <Button
                  sx={{ color: "white", minWidth: "48px", marginleft: "8px" }}
                  onClick={(e) => toggleAccountDrawer(e)}
                  size="small"
                >
                  <Avatar
                    src={getAvatarUrl(playerDetail.avatar)}
                    alt={playerDetail.username}
                    sx={{
                      border: "2px solid white",
                      height: "36px",
                      width: "36px",
                    }}
                  />
                </Button>
              )}
            </Box>
          </Container>
        </Box>
      </AppBar>
      <Box flexGrow={1}>
        <main>
          <div style={{ display: "flex" }}>
            {isXlScreen && <LeftNavigationBar />}
            {isXlScreen && IsSwopStakeWeb() && (
              <RightDisplayBanner sideBanner={sideBanner} />
            )}
            <Container maxWidth="lg" sx={{ mx: "auto", p: 2 }}>
              {children}
            </Container>

            {isMobile ? (
              <BottomDrawer
                open={accountOpen}
                onClose={() => toggleAccountDrawer(false)}
                onOpen={() => toggleAccountDrawer(true)}
              >
                <AccountMenu
                  onProfileClick={handleProfileClick}
                  onActivityClick={handleActivityClick}
                  onFollowingClick={handleFollowingClick}
                  onLogoutClick={handleLogoutClick}
                />
              </BottomDrawer>
            ) : (
              <PopoverMenu
                open={accountOpen}
                onClose={handleClose}
                anchorEl={anchorEl}
                sx={{
                  display: { xs: "none", sm: "block" },
                  "& .MuiPaper-root": {
                    width: "fit-content",
                    borderRadius: "5px",
                    boxShadow:
                      "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px",
                  },
                }}
              >
                <AccountMenu
                  onProfileClick={handleProfileClick}
                  onActivityClick={handleActivityClick}
                  onFollowingClick={handleFollowingClick}
                  onLogoutClick={handleLogoutClick}
                />
              </PopoverMenu>
            )}

            {/* {isMobile ? (
              <Drawer
                anchor="bottom"
                open={profileOpen}
                onClose={() => setProfileOpen(false)}
                sx={{
                  "& .MuiDrawer-paper": {
                    minHeight: "100vh",
                    bgcolor: "background.default",
                  },
                }}
              >
                <Profile onClose={() => setProfileOpen(false)} />
              </Drawer>
            ) : (
              <Dialog open={profileOpen} onClose={() => setProfileOpen(false)}>
                <Profile onClose={() => setProfileOpen(false)} />
              </Dialog>
            )} */}

            <Drawer
              anchor={isMobile ? "bottom" : "right"}
              open={profileOpen}
              onClose={() => setProfileOpen(false)}
              sx={{
                "& .MuiDrawer-paper": {
                  minHeight: isMobile ? "100vh" : "auto",
                  bgcolor: "background.default",
                },
              }}
              ModalProps={{ disableScrollLock: true }}
            >
              <Profile onClose={() => setProfileOpen(false)} />
            </Drawer>

            <BottomDrawer
              style={{ zIndex: 1250 }}
              open={confirmationDialog.show}
              onClose={() => {
                if (!confirmationDialog.processing)
                  dispatch(
                    updateConfirmationDialog({ show: false, processing: false })
                  );
              }}
            >
              <ConfirmPurchase />
            </BottomDrawer>
            <BottomDrawer
              style={{ zIndex: 1250 }}
              open={receipt.show}
              onClose={() => {
                dispatch(updateReceipt({ show: false }));
              }}
            >
              <Receipt
                onClickCheckTicket={(gameId, total) => {
                  console.log("check ticket clicked", {
                    gameId,
                    total,
                    pathname: location.pathname,
                  });
                  if (matchPath(`/game/${gameId}`, location.pathname)) {
                    dispatch(
                      updateFullTicketViewer({
                        ticketData: ticketDetails,
                        show: true,
                        newTicketTotal: total,
                      })
                    );
                    dispatch(updateReceipt({ show: false }));
                  } else {
                    navigate(`/game/${gameId}`);
                    dispatch(updateReceipt({ show: false }));
                  }
                }}
                closeOtherDrawers={() => {
                  if (confirmationDialog.close) {
                    confirmationDialog.close();
                  }
                }}
              />
            </BottomDrawer>
            <Drawer
              style={{ zIndex: 1249 }}
              open={fullTicketViewer.show}
              anchor={isMobile ? "bottom" : "right"}
              onClose={() => dispatch(updateFullTicketViewer({ show: false }))}
            >
              <FullTicketViewer
                onClose={() =>
                  dispatch(updateFullTicketViewer({ show: false }))
                }
                data={fullTicketViewer.ticketData}
                defaultTicket={fullTicketViewer.currentTicket}
                newTicketTotal={fullTicketViewer.newTicketTotal}
              />
            </Drawer>
          </div>
        </main>
      </Box>
      {IsSwopStakeWeb() && <Footer />}
    </Box>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
