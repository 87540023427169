/**
 * What is this?
 * This is new recommended redux way to use redux. Slice can be created using `createSlice` function, where
 * we provide name, initialState and reducers.
 * Using this reducers we are combining action and reducer in same place, for eg. increment, decrement or
 * incrementByAmount. This saves us time when we just want to change value in redux, instead of creating
 * a actiontype, actions and reducers, we just create one reducer function (e.g. increment).
 * Little bit tricky part is when we want to use thunk to call external api, in that case we'd create the method
 * using createAsyncThunk where we give name and a function (called payload creator), behind the scene
 * redux will automatically call actions like /pending /fulfilled /rejected which we need to capture in
 * extraReducers and update the state accordingly.
 *
 * Another thing to note is, we are directly mutating the state here. state.counter += 1, instead of return new value.
 * This okay okay with redux tool kit
 *
 * We can create a custom actioncreators similar to our previous projects. see e.g. incrementAsync below
 */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const SLICE_NAME = "counter";

export const getPostsFromJsonPlaceholderApi = createAsyncThunk(
  `${SLICE_NAME}/getposts`,
  async () => {
    const posts = await fetch(
      "https://jsonplaceholder.typicode.com/posts"
    ).then((r) => r.json());
    return posts;
  }
);

const counterSlice = createSlice({
  name: SLICE_NAME,
  initialState: { count: 0, status: "", posts: [], error: "" },
  reducers: {
    increment: (state) => {
      state.count += 1;
    },
    decrement: (state) => {
      state.count -= 1;
    },
    incrementByAmount: (state, action) => {
      state.count += action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getPostsFromJsonPlaceholderApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getPostsFromJsonPlaceholderApi.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.posts = state.posts.concat(action.payload);
      })
      .addCase(getPostsFromJsonPlaceholderApi.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const incrementAsync = (amount) => (dispatch, getState) => {
  console.log("incrementAsync", { dispatch, state: getState() });
  setTimeout(() => {
    dispatch(counterSlice.actions.incrementByAmount(amount));
  }, 1000);
};

export const { increment, decrement, incrementByAmount } = counterSlice.actions;

export default counterSlice.reducer;
