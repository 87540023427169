import {
  LockOutlined,
  NotificationsOutlined,
  PeopleAltOutlined,
} from "@mui/icons-material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Box,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DrawerHeader from "../components/layouts/DrawerHeader";
import Footer from "../components/layouts/Footer";
import { getAvatarUrl } from "../utils/common-functions";
import UpdateAvatar from "../components/account/UpdateAvatar";
import { IsSwopStakeWeb } from "../services/ls";

function Profile({ onClose }) {
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const { response: playerDetail } = useSelector(
    (state) => state.user.playerSummary
  );

  let playerFullName = "";
  if (playerDetail?.firstName || playerDetail?.lastName) {
    playerFullName = `${
      playerDetail.firstName ? playerDetail.firstName : ""
    }, ${playerDetail.lastName ? playerDetail.lastName[0] : ""}`;
  }

  const playerName = IsSwopStakeWeb() ? playerFullName : playerDetail.username;

  return (
    <>
      <DrawerHeader title="Profile" onClose={onClose} />

      <Stack
        direction="column"
        alignItems="center"
        sx={{
          bgcolor: "background.default",
          flex: 1,
          minWidth: "380px",
          px: 2,
        }}
      >
        <UpdateAvatar src={getAvatarUrl(playerDetail.avatar)} />
        <Box sx={{ mt: 2, px: 2, pb: 4, flex: 1 }}>
          <Paper
            sx={{
              p: 2,
              bgcolor: "background.paper",
            }}
          >
            <Typography component="p" variant="subtitle1">
              {playerDetail.username}
            </Typography>
            <Typography
              component="p"
              variant="body2"
              sx={{ color: "text.secondary" }}
            >
              {playerName}
            </Typography>
            <Divider sx={{ mt: 2 }} />
            <List dense>
              <ListItemButton
                sx={{ px: 1 }}
                onClick={() => {
                  navigate(`/following`);
                  onClose();
                }}
              >
                <ListItemIcon>
                  <PeopleAltOutlined />
                </ListItemIcon>
                <ListItemText primary="Following" sx={{ pr: 4, flex: "1" }} />
                <ChevronRightIcon />
              </ListItemButton>
              <ListItemButton
                sx={{ px: 1 }}
                onClick={() => {
                  navigate(`/notifications`, {
                    state: { from: window.location.pathname },
                  });

                  onClose();
                }}
              >
                <ListItemIcon>
                  <NotificationsOutlined />
                </ListItemIcon>
                <ListItemText
                  primary="Notifications Settings"
                  sx={{ pr: 4, flex: "1" }}
                />
                <ChevronRightIcon />
              </ListItemButton>
              {IsSwopStakeWeb() && (
                <ListItemButton
                  sx={{ px: 1 }}
                  onClick={() => {
                    navigate(`/changepassword`);
                    onClose();
                  }}
                >
                  <ListItemIcon>
                    <LockOutlined />
                  </ListItemIcon>
                  <ListItemText
                    primary="Reset Password"
                    sx={{ pr: 4, flex: "1" }}
                  />
                  <ChevronRightIcon />
                </ListItemButton>
              )}
            </List>
          </Paper>
        </Box>
        {/* <Footer /> */}
      </Stack>
      {IsSwopStakeWeb() && (isMobile ? <Footer /> : null)}
    </>
  );
}

Profile.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default Profile;
