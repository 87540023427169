/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function FinalStatusFromJSON(json) {
    return FinalStatusFromJSONTyped(json, false);
}
export function FinalStatusFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'prizeTypeId': !exists(json, 'PrizeTypeId') ? undefined : json['PrizeTypeId'],
        'isWinningTicket': !exists(json, 'IsWinningTicket') ? undefined : json['IsWinningTicket'],
        'prizeAmount': !exists(json, 'PrizeAmount') ? undefined : json['PrizeAmount'],
        'knockedoutValue': !exists(json, 'KnockedoutValue') ? undefined : json['KnockedoutValue'],
    };
}
export function FinalStatusToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'PrizeTypeId': value.prizeTypeId,
        'IsWinningTicket': value.isWinningTicket,
        'PrizeAmount': value.prizeAmount,
        'KnockedoutValue': value.knockedoutValue,
    };
}
