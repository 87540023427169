/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { ApiResultFromJSON, EmailTestToJSON, } from '../models';
/**
 *
 */
export class EmailtestApi extends runtime.BaseAPI {
    /**
     */
    async emailTestCreateRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling emailTestCreate.');
        }
        const queryParameters = {};
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/emailtest`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EmailTestToJSON(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultFromJSON(jsonValue));
    }
    /**
     */
    async emailTestCreate(requestParameters, initOverrides) {
        const response = await this.emailTestCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async emailTestDeleteRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling emailTestDelete.');
        }
        const queryParameters = {};
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/emailtest`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultFromJSON(jsonValue));
    }
    /**
     */
    async emailTestDelete(requestParameters, initOverrides) {
        const response = await this.emailTestDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async emailTestGetRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling emailTestGet.');
        }
        const queryParameters = {};
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/emailtest`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultFromJSON(jsonValue));
    }
    /**
     */
    async emailTestGet(requestParameters, initOverrides) {
        const response = await this.emailTestGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async emailTestPostRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling emailTestPost.');
        }
        const queryParameters = {};
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/emailtest`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EmailTestToJSON(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultFromJSON(jsonValue));
    }
    /**
     */
    async emailTestPost(requestParameters, initOverrides) {
        const response = await this.emailTestPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
/**
    * @export
    * @enum {string}
    */
export var EmailTestCreateAcceptEnum;
(function (EmailTestCreateAcceptEnum) {
    EmailTestCreateAcceptEnum["ApplicationJson"] = "application/json";
})(EmailTestCreateAcceptEnum || (EmailTestCreateAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var EmailTestDeleteAcceptEnum;
(function (EmailTestDeleteAcceptEnum) {
    EmailTestDeleteAcceptEnum["ApplicationJson"] = "application/json";
})(EmailTestDeleteAcceptEnum || (EmailTestDeleteAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var EmailTestGetAcceptEnum;
(function (EmailTestGetAcceptEnum) {
    EmailTestGetAcceptEnum["ApplicationJson"] = "application/json";
})(EmailTestGetAcceptEnum || (EmailTestGetAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var EmailTestPostAcceptEnum;
(function (EmailTestPostAcceptEnum) {
    EmailTestPostAcceptEnum["ApplicationJson"] = "application/json";
})(EmailTestPostAcceptEnum || (EmailTestPostAcceptEnum = {}));
