/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function CashoutTicketsRequestFromJSON(json) {
    return CashoutTicketsRequestFromJSONTyped(json, false);
}
export function CashoutTicketsRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'gameId': !exists(json, 'GameId') ? undefined : json['GameId'],
        'quantity': !exists(json, 'Quantity') ? undefined : json['Quantity'],
        'requestedTickets': !exists(json, 'RequestedTickets') ? undefined : json['RequestedTickets'],
        'expectedPrice': !exists(json, 'ExpectedPrice') ? undefined : json['ExpectedPrice'],
        'channel': !exists(json, 'Channel') ? undefined : json['Channel'],
    };
}
export function CashoutTicketsRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'GameId': value.gameId,
        'Quantity': value.quantity,
        'RequestedTickets': value.requestedTickets,
        'ExpectedPrice': value.expectedPrice,
        'Channel': value.channel,
    };
}
