/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function ValidateIPAddressRequestFromJSON(json) {
    return ValidateIPAddressRequestFromJSONTyped(json, false);
}
export function ValidateIPAddressRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'iPAddress': !exists(json, 'IPAddress') ? undefined : json['IPAddress'],
        'channel': !exists(json, 'Channel') ? undefined : json['Channel'],
    };
}
export function ValidateIPAddressRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'IPAddress': value.iPAddress,
        'Channel': value.channel,
    };
}
