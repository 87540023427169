import { EditOutlined } from "@mui/icons-material";
import {
  Avatar,
  Box,
  IconButton,
  CircularProgress,
  Button,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import {
  getPlayerSummary,
  getRandomAvatar,
  uploadAvatar,
} from "../../redux/slices/user.slice";
import { getFBPicture } from "../../utils/common-functions";

function UpdateAvatar({ src }) {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  // player summary state in store
  const { response: playerDetails, ...playerSummaryState } = useSelector(
    (state) => state.user.playerSummary
  );
  // avatar state in store
  const avatarState = useSelector((state) => state.user.avatar);

  useEffect(() => {
    const loading = avatarState.loading || playerSummaryState.loading;

    setIsLoading(loading);
  }, [avatarState, playerSummaryState]);

  const handleAvatarUpdate = (event) => {
    event.preventDefault();

    //  get the uploaded file
    const uploadedFile = event.target.files[0];
    if (uploadedFile != null) {
      const avatar = document
        .getElementById("avatar-upload")
        .value.replace("C:\\fakepath\\", "");
      const data = { Avatar: avatar };
      const file = document.getElementById("avatar-upload").files[0];
      dispatch(uploadAvatar({ data, avatar: file })).then(() => {
        dispatch(getPlayerSummary());
      });
    }
  };

  const handleRandomAvatar = () => {
    dispatch(getRandomAvatar());
  };

  const useFacebookPicture = (id) => {
    getFBPicture(id)
      .then((blobData) => {
        const file = blobData;

        // if FB image is returned
        if (file != null && file.type.includes("image")) {
          const data = { Avatar: "avatar.jpg" };
          dispatch(uploadAvatar({ data, avatar: file })).then(() => {
            dispatch(getPlayerSummary());
          });
        }
      })
      .catch((error) => {
        console.log(`error receiving image from facebook ${error}`);
      });
  };
  return (
    <>
      <Box
        sx={{
          width: "fit-content",
          position: "relative",
          paddingTop: "32px",
        }}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <Avatar src={src || undefined} sx={{ width: 80, height: 80 }} />
            <IconButton
              aria-label="upload picture"
              component="label"
              sx={{
                width: 32,
                height: 32,
                border: "none",
                borderRadius: "50%",
                bgcolor: "white",
                bottom: -10,
                right: -10,
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                boxShadow: 2,
                "&:hover": {
                  bgcolor: "white",
                },
              }}
            >
              <input
                hidden
                type="file"
                name="avatar-upload"
                id="avatar-upload"
                accept="image/*"
                onChange={handleAvatarUpdate}
              />
              <EditOutlined fontSize="small" />
            </IconButton>
          </>
        )}
      </Box>

      {/* Note that switch avatar and use FB profile picture are not in designs, will leave for in case of testing atm */}
      <Box
        sx={{
          width: "fit-content",
          position: "relative",
          marginTop: "16px",
        }}
      >
        <Button
          disableRipple
          sx={{
            "&.MuiButton-root:hover": { bgcolor: "transparent" },
            "&.MuiButton-text": {
              textTransform: "none",
              textDecoration: "underline",
            },
          }}
          variant="text"
          onClick={handleRandomAvatar}
        >
          Switch avatar
        </Button>
      </Box>
      {playerDetails.facebookId && (
        <Box
          sx={{
            marginTop: "16px",
            "&.MuiButton-root:hover": { bgcolor: "transparent" },
          }}
        >
          <Button
            disableRipple
            variant="contained"
            onClick={() => useFacebookPicture(playerDetails.facebookId)}
            sx={{ color: "white" }}
          >
            Use Facebook profile picture
          </Button>
        </Box>
      )}
    </>
  );
}

UpdateAvatar.propTypes = {
  src: PropTypes.string,
};

UpdateAvatar.defaultProps = {
  src: "",
};

export default UpdateAvatar;
