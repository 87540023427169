/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function OtherPlayerInfoFromJSON(json) {
    return OtherPlayerInfoFromJSONTyped(json, false);
}
export function OtherPlayerInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'playerId': !exists(json, 'PlayerId') ? undefined : json['PlayerId'],
        'username': !exists(json, 'Username') ? undefined : json['Username'],
        'avatar': !exists(json, 'Avatar') ? undefined : json['Avatar'],
        'isFollowing': !exists(json, 'IsFollowing') ? undefined : json['IsFollowing'],
    };
}
export function OtherPlayerInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'PlayerId': value.playerId,
        'Username': value.username,
        'Avatar': value.avatar,
        'IsFollowing': value.isFollowing,
    };
}
