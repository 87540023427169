/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { OtherPlayerInfoFromJSON, OtherPlayerInfoToJSON, } from './';
export function PlayerResponseFromJSON(json) {
    return PlayerResponseFromJSONTyped(json, false);
}
export function PlayerResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'playerList': !exists(json, 'PlayerList') ? undefined : (json['PlayerList'].map(OtherPlayerInfoFromJSON)),
    };
}
export function PlayerResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'PlayerList': value.playerList === undefined ? undefined : (value.playerList.map(OtherPlayerInfoToJSON)),
    };
}
