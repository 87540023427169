/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function ValidateIPAddressResponseFromJSON(json) {
    return ValidateIPAddressResponseFromJSONTyped(json, false);
}
export function ValidateIPAddressResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'allowed': !exists(json, 'Allowed') ? undefined : json['Allowed'],
        'countryIsoCode': !exists(json, 'CountryIsoCode') ? undefined : json['CountryIsoCode'],
        'errorMessage': !exists(json, 'ErrorMessage') ? undefined : json['ErrorMessage'],
        'checkedIPAddress': !exists(json, 'CheckedIPAddress') ? undefined : json['CheckedIPAddress'],
    };
}
export function ValidateIPAddressResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'Allowed': value.allowed,
        'CountryIsoCode': value.countryIsoCode,
        'ErrorMessage': value.errorMessage,
        'CheckedIPAddress': value.checkedIPAddress,
    };
}
