/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ContentCardListFromJSON, ContentCardListToJSON, } from './';
export function ContentCardListResponseFromJSON(json) {
    return ContentCardListResponseFromJSONTyped(json, false);
}
export function ContentCardListResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'cards': !exists(json, 'Cards') ? undefined : ContentCardListFromJSON(json['Cards']),
    };
}
export function ContentCardListResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'Cards': ContentCardListToJSON(value.cards),
    };
}
