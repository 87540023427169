import React from "react";
import { Typography, Box } from "@mui/material";
// import { ReactComponent as TicketIcon } from "../graphics/ticket/ticket.svg";
// import { ReactComponent as AttachMoney } from "../graphics/attach-money.svg";
// import TicketListItem from "./TicketListItem";
import { OrderDialogType } from "../../utils/types";

function ConfirmPurchaseMessage({
  state,
  // hitText,
  // options,
  orderType,
  error,
  // errorInTicketSection,
  // checkTicketDetail,
}) {
  let content;

  switch (state) {
    case "processing":
      content = (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "32px 0",
          }}
        >
          <Typography
            variant="body1"
            component="p"
            color="primary"
            sx={{
              fontWeight: "500",
              lineHeight: "1",
              marginBottom: "8px",
            }}
          >
            {(orderType === OrderDialogType.CASHOUT ||
              orderType === OrderDialogType.OUTCOMECASHOUT) &&
              " Processing Your Fold"}
            {orderType === OrderDialogType.BUY && "Processing Your Purchase"}
            {(orderType === OrderDialogType.SWOP ||
              orderType === OrderDialogType.SUPERSWOP) &&
              " Processing Your Swop"}
          </Typography>
          <Typography
            variant="body2"
            component="p"
            sx={{
              color: "#fff",
              opacity: 0.5,
              lineHeight: "1",
              marginBottom: "8px",
            }}
          >
            Hang tight! We&rsquo;re finalising details and will update you soon.
          </Typography>
        </Box>
      );
      break;
    case "fail":
      content =
        error && error.message ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#FF2F2F",
              padding: "8px",
              width: "fit-parent",
              borderRadius: "4px",
              margin: "20px 16px",
            }}
          >
            <Typography
              variant="caption"
              component="p"
              sx={{
                color: "#fff",
                fontSize: "1rem",
                fontWeight: "700",
                lineHeight: "21px",
                marginBottom: "8px",
                textAlign: "center",
              }}
            >
              {(orderType === OrderDialogType.CASHOUT ||
                orderType === OrderDialogType.OUTCOMECASHOUT) &&
                "Fold Failed"}
              {orderType === OrderDialogType.BUY && "Purchase Failed"}
              {(orderType === OrderDialogType.SWOP ||
                orderType === OrderDialogType.SUPERSWOP) &&
                "Swop Failed"}
            </Typography>
            <Typography
              variant="caption"
              component="p"
              sx={{
                color: "#fff",
                fontSize: "0.875rem",
                fontWeight: "500",
                lineHeight: "1",
              }}
            >
              {error.message}
            </Typography>
          </Box>
        ) : null;

      break;

    case "success":
      content = (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "32px 0",
          }}
        >
          <Typography
            variant="body1"
            component="p"
            color="primary"
            sx={{
              fontWeight: "500",
              lineHeight: "1",
              marginBottom: "8px",
            }}
          >
            {(orderType === OrderDialogType.CASHOUT ||
              orderType === OrderDialogType.OUTCOMECASHOUT) &&
              "Fold Successful"}
            {orderType === OrderDialogType.BUY && "Purchase Successful"}
            {(orderType === OrderDialogType.SWOP ||
              orderType === OrderDialogType.SUPERSWOP) &&
              "Swop Successful"}
          </Typography>
          {orderType !== OrderDialogType.CASHOUT &&
            orderType !== OrderDialogType.OUTCOMECASHOUT && (
              <Typography
                variant="body2"
                component="p"
                sx={{
                  color: "#fff",
                  opacity: 0.5,
                  lineHeight: "1",
                  marginBottom: "8px",
                }}
              >
                Your ticket has been secured. Feel free to leave or stay on the
                page.
              </Typography>
            )}
        </Box>
      );
      break;

    default:
    // content = (
    //   <>
    //     <Typography
    //       variant="body2"
    //       component="p"
    //       gutterBottom
    //       sx={{ opacity: 0.5 }}
    //     >
    //       {hitText}
    //     </Typography>
    //     <TicketListItem
    //       icon={<TicketIcon />}
    //       title="Tickets"
    //       value={options.quantity}
    //       error={
    //         error && error.message && errorInTicketSection(error.errorNo)
    //           ? error.message
    //           : null
    //       }
    //     />
    //     <TicketListItem
    //       icon={<AttachMoney />}
    //       title={
    //         orderType === OrderDialogType.CASHOUT ||
    //         orderType === OrderDialogType.OUTCOMECASHOUT
    //           ? "Total Offer"
    //           : "Total Cost"
    //       }
    //       value={options.expectedPrice.toFixed(2)}
    //       error={
    //         error && error.message && !errorInTicketSection(error.errorNo)
    //           ? error.message
    //           : null
    //       }
    //     />

    //     {checkTicketDetail && (
    //       <Typography
    //         variant="body2"
    //         component="p"
    //         sx={{
    //           color: "text.contrastText",
    //           textDecoration: "underline",
    //           cursor: "pointer",
    //         }}
    //       >
    //         Check Ticket Details
    //       </Typography>
    //     )}
    //   </>
    // );
  }

  return (
    <Box sx={{ textAlign: "center", px: 2, "& svg": { fill: "white" } }}>
      {content}
    </Box>
  );
}

export default ConfirmPurchaseMessage;
