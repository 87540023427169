import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "./slices/counter.slice";
import gameHomeReducer from "./slices/gameHome.slice";
import userReducer from "./slices/user.slice";
import gameReducer from "./slices/game.slice";
import ticketOrderReducer from "./slices/ticketOrder.slice";
import notificationReducer from "./slices/notification.slice";
import gameResultReducer from "./slices/gameResult.slice";
import signupWizardReducer from "./slices/signupWizard.slice";
import leaderboardReducer from "./slices/leaderboard.slice";
import dialogReducer from "./slices/dialog.slice";
import playerFollowingReducer from "./slices/playerFollowing.slice";
import systemSettingsReducer from "./slices/systemSettings.slice";
// import logger from "./middlewares/logger.middleware";
import createSignalRMiddleware from "./middlewares/signalr.middleware";
import businessMiddleware from "./middlewares/business.middleware";

const store = configureStore({
  reducer: {
    counter: counterReducer,
    gameHome: gameHomeReducer,
    user: userReducer,
    game: gameReducer,
    ticket: ticketOrderReducer,
    notification: notificationReducer,
    result: gameResultReducer,
    signupWizard: signupWizardReducer,
    leaderboard: leaderboardReducer,
    dialog: dialogReducer,
    playerFollowing: playerFollowingReducer,
    systemSettings: systemSettingsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      // .concat(logger)
      .concat(businessMiddleware)
      .concat(
        createSignalRMiddleware(
          `${process.env.REACT_APP_SIGNALR_API}/negotiate`
        )
      ),
});

export default store;
