import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import swopApi from "../../api";

const SLICE_NAME = "notification";

// export const updateBadge = createAction("updateBadge");

export const getPlayerNotifications = createAsyncThunk(
  `${SLICE_NAME}/getPlayerNotifications`,
  async (arg, { getState }) => {
    const { updateSeen } = getState().notification.badge;
    return swopApi.playerApi.getPlayerNotificationsgetnotificationsPost({
      accept: "application/json",
      body: {
        updateSeen,
      },
    });
  }
);

export const getNotificationSettings = createAsyncThunk(
  `${SLICE_NAME}/getNotificationSettings`,
  () => {
    return swopApi.playerApi.playerGetNotificationPreferenceRequestgetNotificationPreferencePost(
      {
        accept: "application/json",
        body: {},
      }
    );
  }
);

export const updateNotificationPreference = createAsyncThunk(
  `${SLICE_NAME}/updateNotificationPreferences`,
  async ({ preferences }, { dispatch }) => {
    const res =
      await swopApi.playerApi.playerUpdateNotificationPreferRequestupdateNotificationPreferencePost(
        {
          accept: "application/json",
          body: {
            preferences,
          },
        }
      );
    dispatch(getNotificationSettings());
    return res;
  }
);

const countUnseenNotification = (response) => {
  if (response.playerNotificationSeenLog)
    return response.playerNotificationLog.filter(
      (n) => n.sentDate >= response.playerNotificationSeenLog.viewedDate
    ).length;
  return response.playerNotificationLog.length;
};

const notificationSlice = createSlice({
  name: SLICE_NAME,
  initialState: {
    playerNotification: {
      playerNotificationSeenLog: null,
      playerNotificationLog: [],
      loading: false,
      errorMessage: "",
    },
    settings: {
      playerNotificationPreferenceList: [],
      loading: false,
      errorMessage: "",
    },
    badge: { updateSeen: false, unseenNotification: 0 },
  },
  reducers: {
    updateBadge: (state, action) => {
      state.badge.updateSeen =
        action.payload.updateSeen ?? state.badge.updateSeen;
      state.badge.unseenNotification =
        action.payload.unseenNotification ?? state.badge.unseenNotification;
    },
  },
  extraReducers(builder) {
    builder
      // .addCase(updateBadge, (state, action) => {
      //   state.badge.updateSeen =
      //     action.payload.updateSeen ?? state.badge.updateSeen;
      //   state.badge.unseenNotification =
      //     action.payload.unseenNotification ?? state.badge.unseenNotification;
      // })
      .addCase(getPlayerNotifications.pending, (state) => {
        state.playerNotification.loading = true;
      })
      .addCase(getPlayerNotifications.fulfilled, (state, action) => {
        state.playerNotification.loading = false;
        state.playerNotification.playerNotificationSeenLog =
          action.payload.playerNotificationSeenLog;
        state.playerNotification.playerNotificationLog =
          action.payload.playerNotificationLog;

        state.badge.updateSeen = false;
        state.badge.unseenNotification = countUnseenNotification(
          action.payload
        );
      })
      .addCase(getPlayerNotifications.rejected, (state, action) => {
        state.playerNotification.loading = false;
        state.playerNotification.errorMessage = action.error.message;
      })
      .addCase(getNotificationSettings.pending, (state) => {
        state.settings.loading = true;
      })
      .addCase(getNotificationSettings.fulfilled, (state, action) => {
        state.settings.loading = false;
        state.settings.playerNotificationPreferenceList =
          action.payload.playerNotificationPreferenceList;
      })
      .addCase(getNotificationSettings.rejected, (state, action) => {
        state.settings.loading = false;
        state.settings.errorMessage = action.error.message;
      });
  },
});

export const { updateBadge } = notificationSlice.actions;

export default notificationSlice.reducer;
