/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function HttpResultFromJSON(json) {
    return HttpResultFromJSONTyped(json, false);
}
export function HttpResultFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'responseText': !exists(json, 'ResponseText') ? undefined : json['ResponseText'],
        'responseStream': !exists(json, 'ResponseStream') ? undefined : json['ResponseStream'],
        'fileInfo': !exists(json, 'FileInfo') ? undefined : json['FileInfo'],
        'virtualFile': !exists(json, 'VirtualFile') ? undefined : json['VirtualFile'],
        'contentType': !exists(json, 'ContentType') ? undefined : json['ContentType'],
        'headers': !exists(json, 'Headers') ? undefined : json['Headers'],
        'cookies': !exists(json, 'Cookies') ? undefined : json['Cookies'],
        'eTag': !exists(json, 'ETag') ? undefined : json['ETag'],
        'age': !exists(json, 'Age') ? undefined : json['Age'],
        'maxAge': !exists(json, 'MaxAge') ? undefined : json['MaxAge'],
        'expires': !exists(json, 'Expires') ? undefined : (new Date(json['Expires'])),
        'lastModified': !exists(json, 'LastModified') ? undefined : (new Date(json['LastModified'])),
        'cacheControl': !exists(json, 'CacheControl') ? undefined : json['CacheControl'],
        'resultScope': !exists(json, 'ResultScope') ? undefined : json['ResultScope'],
        'allowsPartialResponse': !exists(json, 'AllowsPartialResponse') ? undefined : json['AllowsPartialResponse'],
        'location': !exists(json, 'Location') ? undefined : json['Location'],
        'options': !exists(json, 'Options') ? undefined : json['Options'],
        'status': !exists(json, 'Status') ? undefined : json['Status'],
        'statusCode': !exists(json, 'StatusCode') ? undefined : json['StatusCode'],
        'statusDescription': !exists(json, 'StatusDescription') ? undefined : json['StatusDescription'],
        'response': !exists(json, 'Response') ? undefined : json['Response'],
        'responseFilter': !exists(json, 'ResponseFilter') ? undefined : json['ResponseFilter'],
        'requestContext': !exists(json, 'RequestContext') ? undefined : json['RequestContext'],
        'view': !exists(json, 'View') ? undefined : json['View'],
        'template': !exists(json, 'Template') ? undefined : json['Template'],
        'paddingLength': !exists(json, 'PaddingLength') ? undefined : json['PaddingLength'],
        'isPartialRequest': !exists(json, 'IsPartialRequest') ? undefined : json['IsPartialRequest'],
    };
}
export function HttpResultToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'ResponseText': value.responseText,
        'ResponseStream': value.responseStream,
        'FileInfo': value.fileInfo,
        'VirtualFile': value.virtualFile,
        'ContentType': value.contentType,
        'Headers': value.headers,
        'Cookies': value.cookies,
        'ETag': value.eTag,
        'Age': value.age,
        'MaxAge': value.maxAge,
        'Expires': value.expires === undefined ? undefined : (value.expires.toISOString()),
        'LastModified': value.lastModified === undefined ? undefined : (value.lastModified.toISOString()),
        'CacheControl': value.cacheControl,
        'ResultScope': value.resultScope,
        'AllowsPartialResponse': value.allowsPartialResponse,
        'Location': value.location,
        'Options': value.options,
        'Status': value.status,
        'StatusCode': value.statusCode,
        'StatusDescription': value.statusDescription,
        'Response': value.response,
        'ResponseFilter': value.responseFilter,
        'RequestContext': value.requestContext,
        'View': value.view,
        'Template': value.template,
        'PaddingLength': value.paddingLength,
        'IsPartialRequest': value.isPartialRequest,
    };
}
