import { FilterList, MoveToInbox, AccountBalance } from "@mui/icons-material";
import {
  Box,
  Collapse,
  Container,
  Divider,
  IconButton,
  Slider,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

function Filter({
  value,
  setValue,
  valueMin,
  valueMax,
  buyMultiple,
  setBuyMultiple,
}) {
  const [open, setOpen] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ bgcolor: "background.paper" }}>
      <Container maxWidth="md">
        <Stack direction="row" alignItems="center" spacing={2} py={1}>
          <Box
            sx={{
              p: 0.5,
              border: "2px",
              display: "flex",
              borderStyle: "solid",
              borderColor: "text.primary",
              borderRadius: "100%",
            }}
          >
            <AccountBalance fontSize="small" />
          </Box>
          <Typography variant="subtitle1" component="p">
            Bank owned
          </Typography>
        </Stack>
      </Container>
      <Divider />

      <Container maxWidth="md">
        <Stack
          py={1}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack spacing={2} direction="row" alignItems="center">
            <Typography
              component="label"
              variant="body2"
              sx={{ color: "text.secondary" }}
            >
              Buy Multiple
            </Typography>
            <Switch
              color="secondary"
              checked={buyMultiple}
              onChange={() => setBuyMultiple(!buyMultiple)}
            />
          </Stack>

          <IconButton
            color={open ? "secondary" : "default"}
            onClick={() => setOpen(!open)}
          >
            <FilterList />
          </IconButton>
        </Stack>
      </Container>

      <Divider />
      <Collapse in={open}>
        <Container maxWidth="md">
          <Box px={4} py={1}>
            <Stack
              spacing={2}
              direction="row"
              alignItems="center"
              sx={{ color: "text.secondary" }}
            >
              <MoveToInbox />
              <Box>
                <Typography component="span" variant="body2">
                  Filters:&nbsp;
                </Typography>
                <Typography
                  component="span"
                  variant="body2"
                  sx={{ opacity: 0.6 }}
                >
                  Value
                </Typography>
              </Box>
            </Stack>
            <Divider sx={{ mt: 1 }} />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                pt: 3,
                pb: 2,
              }}
            >
              <Slider
                valueLabelDisplay="on"
                color="secondary"
                value={value ?? [undefined, undefined]}
                onChange={handleChange}
                min={valueMin}
                max={valueMax}
                marks={[
                  { value: valueMin, label: "Min" },
                  { value: valueMax, label: "Max" },
                ]}
              />
            </Box>
          </Box>
        </Container>
        <Divider />
      </Collapse>
    </Box>
  );
}

Filter.propTypes = {
  value: PropTypes.arrayOf({}).isRequired,
  setValue: PropTypes.func.isRequired,
  valueMin: PropTypes.number.isRequired,
  valueMax: PropTypes.number.isRequired,
  buyMultiple: PropTypes.bool.isRequired,
  setBuyMultiple: PropTypes.func.isRequired,
};

export default Filter;
