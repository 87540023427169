/* eslint-disable no-param-reassign */
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";

let reactPlugin = {};
let appInsights = {};

try {
  reactPlugin = new ReactPlugin();
  appInsights = new ApplicationInsights({
    config: {
      connectionString: process.env.REACT_APP_AI_CONNECTION_STRING,
      extensions: [reactPlugin],
      extensionConfig: {},
      enableAutoRouteTracking: true,
      disableAjaxTracking: false,
      autoTrackPageVisitTime: true,
      enableCorsCorrelation: true,
      enableRequestHeaderTracking: true,
      enableResponseHeaderTracking: true,
    },
  });
  appInsights.loadAppInsights();

  appInsights.addTelemetryInitializer((env) => {
    env.tags = env.tags || [];
    env.tags["ai.cloud.role"] = "testTag";
  });
} catch (e) {
  console.log("Error starting Application Insights", e);
  reactPlugin = {};
  appInsights = {};
}
export { reactPlugin, appInsights };
