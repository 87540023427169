/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { PlayerTicketHistoryFromJSON, PlayerTicketHistoryToJSON, } from './';
export function PlayerTicketHistoryResponseFromJSON(json) {
    return PlayerTicketHistoryResponseFromJSONTyped(json, false);
}
export function PlayerTicketHistoryResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'ticketHistoryOutcomeList': !exists(json, 'TicketHistoryOutcomeList') ? undefined : json['TicketHistoryOutcomeList'],
        'ticketHistoryList': !exists(json, 'TicketHistoryList') ? undefined : (json['TicketHistoryList'].map(PlayerTicketHistoryFromJSON)),
    };
}
export function PlayerTicketHistoryResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'TicketHistoryOutcomeList': value.ticketHistoryOutcomeList,
        'TicketHistoryList': value.ticketHistoryList === undefined ? undefined : (value.ticketHistoryList.map(PlayerTicketHistoryToJSON)),
    };
}
