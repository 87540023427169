import { createSlice, createAction, createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import swopApi from "../../api";
import { saveToken, getChannelID } from "../../services/ls";
import { GameStatus, OrderType, OrderStatus } from "../../utils/types";
import { getContentCard } from "../slices/gameHome.slice";

const SLICE_NAME = "user";

const customPlayerUpdateAvatarPost = async (requestParameters) => {
  const headerParameters = {};
  if (
    requestParameters.accept !== undefined &&
    requestParameters.accept !== null
  ) {
    headerParameters.Accept = String(requestParameters.accept);
  }
  const baseURL = swopApi.playerApi.configuration.configuration.basePath;
  headerParameters["x-ss-id"] =
    swopApi.playerApi.configuration.configuration.headers["x-ss-id"];

  const url = `${baseURL}/player/avatar/update`;

  return fetch(url, {
    method: "POST",
    headers: headerParameters,
    body: requestParameters.body,
  }).then((res) => res.json());
};

const customPlayerDownloadTransactionsPost = async (requestParameters) => {
  const headerParameters = {};
  if (
    requestParameters.accept !== undefined &&
    requestParameters.accept !== null
  ) {
    headerParameters.Accept = String(requestParameters.accept);
  }
  const baseURL = swopApi.playerApi.configuration.configuration.basePath;
  headerParameters["x-ss-id"] =
    swopApi.playerApi.configuration.configuration.headers["x-ss-id"];
  headerParameters["Content-Type"] = "application/json";

  const url = `${baseURL}/player/downloadTransaction`;

  return fetch(url, {
    method: "POST",
    headers: headerParameters,
    body: JSON.stringify(requestParameters.body),
  }).then((res) => {
    return res.blob();
  });
};

export const getPlayerSummary = createAsyncThunk(
  `${SLICE_NAME}/getPlayerSummary`,
  async () => {
    return swopApi.playerApi.playerSummaryRequestsummaryGet({
      accept: "application/json",
    });
  }
);

export const insertFTPCredits = createAsyncThunk(
  `${SLICE_NAME}/insertFTPCredits`,
  async ({ gameId }) => {
    const res =
      swopApi.playerApi.playerInsertFTPCreditsForGameRequestinsertFTPCreditsForGamePost(
        {
          accept: "application/json",
          body: {
            gameId,
          },
        }
      );
    // dispatch(getPlayerSummary());
    return res;
  }
);

export const signin = createAsyncThunk(
  `${SLICE_NAME}/signin`,
  async ({ password, userName }, { dispatch, rejectWithValue }) => {
    try {
      const res = await swopApi.authApi.authenticatePost({
        accept: "application/json",
        body: {
          userName,
          password,
          meta: {
            ChannelId: getChannelID(),
          },
        },
      });
      if (res && res.sessionId) {
        saveToken(res.sessionId);
        swopApi.tokenUpdated();
        dispatch(getPlayerSummary());
        dispatch(getContentCard());
      }
      return res;
    } catch (e) {
      if (e.status === 401) {
        const d = await e.json();
        return d;
      }
      return rejectWithValue(e.statusText);
    }
  }
);

export const logout = createAsyncThunk(
  `${SLICE_NAME}/logout`,
  async ({ callback }, { dispatch, rejectWithValue }) => {
    try {
      const data = {};
      data.Channel = getChannelID();
      const res = await fetch(`${swopApi.apiConfig.basePath}/auth/logout`, {
        method: "POST",
        headers: {
          ...swopApi.apiConfig.headers,
          Accept: "application/json",
        },
        body: JSON.stringify(data),
      });

      if (res) {
        saveToken();
        swopApi.tokenUpdated();
        dispatch(getPlayerSummary());
        window.location.href = "/";
      }
      if (callback) callback();
      return res;
    } catch (e) {
      if (e.status === 401) {
        const d = await e.json();
        return d;
      }
      return rejectWithValue(e.statusText);
    }
  }
);

export const filterGames = createAsyncThunk(
  `${SLICE_NAME}/filterGames`,
  async ({ startDate, endDate }) => {
    const res = swopApi.playerApi.filterPlayerGamesfilterMyGamesPost({
      accept: "application/json",
      body: {
        startDate,
        endDate,
      },
    });
    return res;
  }
);

export const PWTransactions = createAsyncThunk(
  `${SLICE_NAME}/getPWTransaction`,
  async ({ txType, startDate, endDate }) => {
    const res =
      swopApi.playerApi.getPlayerPWTransactionRequestgetPlayerPWTransactionPost(
        {
          accept: "application/json",
          body: {
            txType,
            startDate,
            endDate,
          },
        }
      );
    return res;
  }
);

export const resetAvatar = createAsyncThunk(
  `${SLICE_NAME}/resetAvatar`,
  async ({ avatar }) => {
    return swopApi.playerApi.resetAvatarresetAvatarPost({
      accept: "application/json",
      body: {
        avatar,
      },
    });
  }
);

export const getRandomAvatar = createAsyncThunk(
  `avatar/randomget`,
  async (_, { dispatch }) => {
    const res = await swopApi.avatarApi.avatarRandomGetRequestrandomgetPost({
      accept: "application/json",
    });

    await dispatch(resetAvatar({ avatar: res.avatar.avatarUrl }));
    dispatch(getPlayerSummary());
  }
);

export const uploadAvatar = createAsyncThunk(
  `${SLICE_NAME}/player/avatar/update`,
  async ({ data, avatar }) => {
    // const imageType = /image.*/;
    // if (!avatar.type.match(imageType)) return;
    let retValue = null;
    const formData = new FormData();

    if (avatar !== undefined) {
      formData.append(data.Avatar, avatar, data.Avatar);

      retValue = customPlayerUpdateAvatarPost({
        accept: "application/json",
        body: formData,
      });
    }

    return retValue;
  }
);

export const downloadTransactions = createAsyncThunk(
  `${SLICE_NAME}/player/downloadTransaction`,
  async (requestParams) => {
    let retValue = null;

    retValue = await customPlayerDownloadTransactionsPost({
      accept: "application/json",
      body: {
        TxType: requestParams.txType,
        StartDate: requestParams.startDate,
        EndDate: requestParams.endDate,
      },
    });

    return retValue;
  }
);

export const viewGame = createAsyncThunk(
  `${SLICE_NAME}/player/viewGame`,
  async ({ gameId }) => {
    return swopApi.playerApi.getEventsForGamegetEventsForGamePost({
      accept: "application/json",
      body: {
        gameId,
      },
    });
  }
);

export const getSeperateGameList = createAsyncThunk(
  `${SLICE_NAME}/player/getSeperateGameList`,
  async () => {
    const data = await swopApi.playerApi.getPlayerGamesgetMyGamesPost({
      accept: "application/json",
    });
    const gameList = data.playerGames;
    const currentGameList = [];
    const completedGameList = [];
    for (let i = 0; i < gameList.length; i += 1) {
      const isCurrentGame = gameList[i].code === "A";
      // const isCompletedGame = gameList[i].code === "F";

      if (isCurrentGame) {
        currentGameList.push(gameList[i]);
      } else {
        completedGameList.push(gameList[i]);
      }
    }
    return { gameList, currentGameList, completedGameList };
  }
);

export const updateMorePWTransactionHistory = createAction(
  `${SLICE_NAME}/player/updateMorePWTransactionHistory`,
  (payload) => ({ payload })
);

export const checkNextPWTransactionHistory = createAsyncThunk(
  `${SLICE_NAME}/player/checkNextPWTransactionHistory`,
  async ({ gameId, page }, { dispatch }) => {
    const response =
      await swopApi.playerApi.playerGetPWTransactionHistoryRequestgetPWTransactionHistoryPost(
        {
          accept: "application/json",
          body: { gameId, page },
        }
      );

    dispatch(
      updateMorePWTransactionHistory({
        more: response.playerPWTransactionHistoryList.length > 0,
      })
    );
  }
);

export const receivePWTransaction = createAction(
  `${SLICE_NAME}/player/receivePWTransaction`,
  (payload) => ({ payload })
);

export const getPWTransactionAction = createAsyncThunk(
  `${SLICE_NAME}/player/getPWTransactionAction`,
  async (data, { dispatch, getState }) => {
    const state = getState();
    const response =
      await swopApi.playerApi.playerGetPWTransactionHistoryRequestgetPWTransactionHistoryPost(
        {
          accept: "application/json",
          body: { gameId: data.gameId, page: data.page },
        }
      );

    console.log(response);
    const transactions = response.playerPWTransactionHistoryList;
    let transactionsPWT = [];
    console.log({ r: state.user?.PWTransactions?.response });
    if (state.user?.PWTransactions?.response) {
      // for (let i = 0; i < transactions.length; i += 1) {
      //  state.user?.PWTransactions?.response.push(transactions[i]);
      // }
      transactionsPWT = [
        ...state.user.PWTransactions.response,
        ...transactions,
      ];
    } else {
      transactionsPWT = transactions;
    }

    if (
      state.user?.filterGames?.gameToView &&
      state.user?.filterGames?.gameToView?.gameStatusId === GameStatus.Presale
    ) {
      const payload = {
        PWTransactions: transactionsPWT,
        transactionPage: data.page,
        transactionCount: transactions.length,
      };
      dispatch(receivePWTransaction(payload));
    } else {
      const response2 =
        await swopApi.playerApi.playerGetPresaleToOpenDateTimeRequestgetPresaleToOpenDateTimePost(
          {
            accept: "application/json",
            body: { gameId: data.gameId },
          }
        );
      const conversionTransactions = [];
      let indexToInsert = 0;
      if (response2 && response2.presaleToOpenDateTime !== "") {
        for (let i = 0; i < transactions.length; i += 1) {
          if (transactions[i]?.TicketOrder?.TicketOrderId) {
            const orderTypeId = transactions[i]?.TicketOrder?.OrderTypeId;
            const orderStatus = transactions[i]?.TicketOrder?.OrderStatusId;
            if (
              orderTypeId === OrderType.JackpotPrepurchaseFromPool ||
              orderTypeId === OrderType.SubscriptionPrepurchaseFromPool ||
              orderTypeId === OrderType.BankPrepurchaseFromPool ||
              orderTypeId === OrderType.PlayerPrepurchaseFromPool
            ) {
              const transactionCopy = JSON.parse(
                JSON.stringify(transactions[i])
              );
              transactionCopy.transactionDate = moment(
                response2.presaleToOpenDateTime,
                "DD/MM/yyyy HH:mm:ss"
              ).toDate();
              if (
                orderStatus === OrderStatus.Complete &&
                conversionTransactions.find(
                  (t) =>
                    t.TicketOrder?.TicketOrderId ===
                    transactions[i]?.TicketOrder?.TicketOrderId
                ) === null
              ) {
                conversionTransactions.push(transactionCopy);
              }
            }
            if (
              orderTypeId === OrderType.AdminRewardPrepurchaseFromPool &&
              transactions[i]?.Amount > 0
            ) {
              const adminRewardPrepurchaseTransactionCopy = JSON.parse(
                JSON.stringify(transactions[i])
              );
              adminRewardPrepurchaseTransactionCopy.transactionDate = moment(
                response2.presaleToOpenDateTime,
                "DD/MM/yyyy HH:mm:ss"
              ).toDate();
              if (orderStatus === OrderStatus.Complete) {
                conversionTransactions.push(
                  adminRewardPrepurchaseTransactionCopy
                );
              }
            }
          }
        }
        for (let i = 0; i < transactionsPWT.length; i += 1) {
          const isAfter = moment(
            response2.presaleToOpenDateTime,
            "DD/MM/yyyy HH:mm:ss"
          ).isAfter(moment(transactionsPWT[i].TransactionDate));
          if (isAfter) {
            indexToInsert = i;
            break;
          }
        }
      }

      const response3 = await swopApi.playerApi.playerPrizeRequestprizePost({
        accept: "application/json",
        body: { gameId: data.gameId, page: data.page },
      });

      let prizes = response3.playerPrizeList;
      let playerPrizes = [];
      let indexToInsertPrize = 0;
      if (prizes.length > 0) {
        prizes = prizes.reverse();
        if (state.user?.filterGames?.playerPrizes) {
          for (let i = 0; i < prizes.length; i += 1) {
            playerPrizes.push(prizes[i]);
          }
        } else {
          playerPrizes = prizes;
        }
        let awardedDate;
        for (let i = 0; i < playerPrizes.length; i += 1) {
          if (playerPrizes[i].awardedDate) {
            awardedDate = playerPrizes[i].awardedDate;
          }
        }
        for (let i = 0; i < transactionsPWT.length; i += 1) {
          const isAfter = moment(awardedDate).isAfter(
            moment(transactionsPWT[i].TransactionDate)
          );
          if (isAfter) {
            indexToInsertPrize = i;
            break;
          }
        }
      }
      const payload = {
        PWTransactions: transactionsPWT,
        ConversionTransactions: conversionTransactions,
        indexToInsertConversion: indexToInsert,
        indexToInsertPrize,
        playerPrizes,
        transactionPage: data.page,
        transactionCount: transactions.length,
      };
      dispatch(receivePWTransaction(payload));
    }

    dispatch(
      checkNextPWTransactionHistory({
        gameId: data.gameId,
        page: data.page + 1,
      })
    );
  }
);

export const viewPrize = createAction(
  `${SLICE_NAME}/player/viewPrize`,
  (payload) => ({ payload })
);

export const responseViewTransaction = createAction(
  `${SLICE_NAME}/player/responseViewTransaction`,
  (payload) => ({ payload })
);

export const viewTransaction = createAsyncThunk(
  `${SLICE_NAME}/player/viewTransaction`,
  async (payload, { dispatch }) => {
    if (payload?.transaction?.TicketOrderId != null) {
      const response =
        await swopApi.playerApi.getEventsForGamegetEventsForGamePost({
          accept: "application/json",
          body: {
            gameId: payload.transaction.TicketOrder.GameId,
          },
        });
      dispatch(
        responseViewTransaction({
          transactionToView: payload.transaction,
          transactionDesc: payload.transactionDesc,
          isConversion: payload.isConversion,
          gameEvents: response.gameEvents,
        })
      );
    } else {
      dispatch(
        responseViewTransaction({
          transactionToView: payload.transaction,
          transactionDesc: payload.transactionDesc,
          isConversion: payload.isConversion,
        })
      );
    }
  }
);

export const getSingleTicketOutcome = createAsyncThunk(
  `${SLICE_NAME}/player/getSingleTicketOutcome`,
  async (payload) => {
    return swopApi.playerApi.getSingleTicketOutcomegetSingledTicketOutcomePost({
      accept: "application/json",
      body: { gameId: payload.gameId, ticketId: payload.ticketId },
    });
  }
);

export const changeMyPassword = createAsyncThunk(
  `${SLICE_NAME}/player/changeMyPassword`,
  async ({ data }) => {
    return swopApi.playerApi.playerChangePasswordRequestchangePasswordPost({
      accept: "application/json",
      body: data,
    });
  }
);

export const requestForgotPassword = createAsyncThunk(
  `${SLICE_NAME}/player/requestForgotPassword`,
  async ({ email }) => {
    return swopApi.playerApi.forgotPlayerPasswordRequestpasswordforgotPost({
      accept: "application/json",
      body: { email },
    });
  }
);
export const passwordResetResponse = createAction(
  `${SLICE_NAME}/player/passwordResetResponse`,
  (payload) => ({ payload })
);

export const requestPasswordReset = createAsyncThunk(
  `${SLICE_NAME}/player/requestPasswordReset`,
  async ({ data }, { dispatch }) => {
    swopApi.playerApi
      .resetPlayerPasswordRequestpasswordresetPost({
        accept: "application/json",
        body: data,
      })
      .then((response) => {
        dispatch(passwordResetResponse({ response, data }));
        // if (response.errorNo === 0) {
        // }
      })
      .catch((error) => {
        console.log(error);
      });
  }
);

export const resetTicketHistory = createAction(
  `${SLICE_NAME}/player/resetTicketHistory`,
  (payload) => ({ payload })
);

export const receiveTicketHistory = createAction(
  `${SLICE_NAME}/player/receiveTicketHistory`,
  (payload) => ({ payload })
);

export const resetErrorMessage = createAction(
  `${SLICE_NAME}/player/resetErrorMessage`,
  (payload) => ({ payload })
);

export const resetForgotPasswordErrorMessage = createAction(
  `${SLICE_NAME}/player/resetForgotPasswordErrorMessage`,
  (payload) => ({ payload })
);

export const updateMoreTicketHistory = createAction(
  `${SLICE_NAME}/player/updateMoreTicketHistory`,
  (payload) => ({ payload })
);

export const checkNextTicketHistory = createAsyncThunk(
  `${SLICE_NAME}/player/checkNextTicketHistory`,
  async ({ gameId, ticketOrderId, page }, { dispatch }) => {
    const response =
      await swopApi.playerApi.playerTicketHistoryRequestticketHistoryPost({
        accept: "application/json",
        body: {
          gameId,
          ticketOrderId,
          page,
        },
      });

    dispatch(
      updateMoreTicketHistory({
        more: response.ticketHistoryList.length > 0,
      })
    );
  }
);

export const getTicketHistory = createAsyncThunk(
  `${SLICE_NAME}/player/getTicketHistory`,
  async (payload, { dispatch, getState }) => {
    const state = getState();
    const response =
      await swopApi.playerApi.playerTicketHistoryRequestticketHistoryPost({
        accept: "application/json",
        body: {
          gameId: state?.user?.filterGames?.gameToView?.gameId,
          ticketOrderId: payload.ticketOrderId,
          page: payload.page,
        },
      });

    let ticketHistory = [];
    let ticketOutcomes = {};
    const stateticketHistory = JSON.parse(
      JSON.stringify(state?.user?.filterGames?.ticketHistory)
    );
    const stateticketOutcomes = JSON.parse(
      JSON.stringify(state?.user?.filterGames?.ticketOutcomes)
    );
    if (
      state?.user?.filterGames?.ticketHistory &&
      state?.user?.filterGames?.ticketOutcomes
    ) {
      if (payload.page !== state?.user?.filterGames?.ticketHistoryPage) {
        for (let i = 0; i < response.ticketHistoryList.length; i += 1) {
          const ticketHistoryItemCopy = JSON.parse(
            JSON.stringify(response.ticketHistoryList[i])
          );
          stateticketHistory.push(ticketHistoryItemCopy);
          const ticketOutcomeItemCopy = JSON.parse(
            JSON.stringify(
              response.ticketHistoryOutcomeList[
                response.ticketHistoryList[i].ticketHistoryId
              ]
            )
          );
          stateticketOutcomes[response.ticketHistoryList[i].ticketHistoryId] =
            ticketOutcomeItemCopy;
        }
      }

      ticketHistory = stateticketHistory;
      ticketOutcomes = stateticketOutcomes;
    } else {
      ticketHistory = response.ticketHistoryList;
      ticketOutcomes = response.ticketHistoryOutcomeList;
    }

    if (response.ticketHistoryList.length === 10) {
      dispatch(
        checkNextTicketHistory({
          gameId: state?.user?.filterGames?.gameToView?.gameId,
          ticketOrderId: payload.ticketOrderId,
          page: payload.page + 1,
        })
      );
    } else {
      dispatch(
        updateMoreTicketHistory({
          more: false,
        })
      );
    }
    dispatch(
      receiveTicketHistory({
        ticketHistory,
        ticketOutcomes,
        ticketHistoryCount: response.ticketHistoryList.length,
        ticketHistoryPage: payload.page,
      })
    );
  }
);

export const resetActivityDetail = createAction(
  `${SLICE_NAME}/player/resetActivityDetail`,
  (payload) => ({ payload })
);

const userSlice = createSlice({
  name: SLICE_NAME,
  initialState: {
    signin: {
      loading: false,
      response: null,
    },
    playerSummary: {
      loading: false,
      response: null,
      bestTicketInfo: null,
      isLoadingTicketInfo: false,
      hasBoughtTicket: false,
    },
    filterGames: {
      loading: false,
      response: null,
      filterList: [],
      isLoadingFilteredGames: false,
      startDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      showFilterChip: false,
      isPageLoading: false,
      currentGameList: [],
      completedGameList: [],
      transactionDesc: null,
      ticketHistory: null,
      ticketOutcomes: null,
      ticketHistoryPage: 0,
      ticketHistoryCount: 0,
      transactionPage: 1,
      isLoadingMyGames: false,
      isLoadingTransaction: false,
      gameToView: null,
      gameEvents: [],
      playerPrizes: [],
      singleTicketOutcome: null,
      gameActivity: {
        isPageLoading: false,
        isMoreLoading: false,
        more: false,
      },
      gameActivityDetail: {
        isMoreLoading: false,
        more: false,
      },
    },
    PWTransactions: {
      loading: false,
      response: null,
    },
    avatar: {
      loading: false,
      isUploadingAvatar: false,
      isResetingAvatar: false,
      randomAvatar: null,
    },
    profile: {
      isRequesting: false,
      isChangingPassword: false,
      isUpdatingProfile: false,
      isUnlinkingAccount: false,
      responsibleGambling: null,
      response: null,
    },
    forgotPassword: {
      loading: false,
      email: null,
      apiResponse: null,
    },
    resetPassword: {
      isLoading: false,
      response: null,
    },
    purchaseOrders: {
      orders: {},
      loadingOrders: false,
      hasActivePurchase: false,
    },
  },
  reducers: {
    poSetActivePurchase: (state, action) => {
      state.purchaseOrders.hasActivePurchase = action.payload;
    },
    resetSignInResponse: (state) => {
      state.signin.loading = false;
      state.signin.response = null;
    },
    clearStore: (state) => {
      state.filterGames.startDate = moment()
        .subtract(7, "days")
        .format("YYYY-MM-DD");
      state.filterGames.endDate = moment().format("YYYY-MM-DD");
    },
    resetGameActivity: (state) => {
      // state.filterGames.PWTransactions = [];
      state.PWTransactions.response = null;
      state.filterGames.transactionCount = 0;
      state.filterGames.transactionPage = 1;
      state.filterGames.playerPrizes = [];
    },
  },
  extraReducers(builder) {
    builder
      .addCase(signin.pending, (state) => {
        state.signin.loading = true;
      })
      .addCase(signin.fulfilled, (state, action) => {
        state.signin.loading = false;
        state.signin.response = action.payload;
      })
      .addCase(signin.rejected, (state, action) => {
        state.signin.loading = false;
        state.signin.response = action.payload;
      })
      .addCase(getPlayerSummary.pending, (state) => {
        state.playerSummary.loading = true;
      })
      .addCase(getPlayerSummary.fulfilled, (state, action) => {
        state.playerSummary.loading = false;
        state.playerSummary.response = action.payload;
      })
      .addCase(getPlayerSummary.rejected, (state, action) => {
        state.playerSummary.loading = false;
        state.playerSummary.response = action.payload;
      })
      .addCase(filterGames.pending, (state, action) => {
        state.filterGames.loading = true;
        state.filterGames.startDate = action.meta.arg.startDate;
        state.filterGames.endDate = action.meta.arg.endDate;
      })
      .addCase(filterGames.fulfilled, (state, action) => {
        state.filterGames.loading = false;
        state.filterGames.response = action.payload;
      })
      .addCase(filterGames.rejected, (state, action) => {
        state.filterGames.loading = false;
        state.filterGames.response = action.payload;
      })
      .addCase(PWTransactions.pending, (state, action) => {
        state.PWTransactions.loading = true;
        state.filterGames.startDate = action.meta.arg.startDate;
        state.filterGames.endDate = action.meta.arg.endDate;
      })
      .addCase(PWTransactions.fulfilled, (state, action) => {
        state.PWTransactions.loading = false;
        state.PWTransactions.response = action.payload;
      })
      .addCase(PWTransactions.rejected, (state, action) => {
        state.PWTransactions.loading = false;
        state.PWTransactions.response = action.payload;
      })
      .addCase(getRandomAvatar.pending, (state) => {
        state.avatar.loading = true;
      })
      .addCase(getRandomAvatar.fulfilled, (state, action) => {
        state.avatar.loading = false;
        state.avatar.randomAvatar = action.payload;
      })
      .addCase(getRandomAvatar.rejected, (state, action) => {
        state.avatar.loading = false;
        state.avatar.randomAvatar = action.payload;
      })
      .addCase(resetAvatar.pending, (state) => {
        state.avatar.loading = true;
        state.avatar.isResetingAvatar = true;
      })
      .addCase(resetAvatar.fulfilled, (state) => {
        state.avatar.loading = false;
        state.avatar.isResetingAvatar = false;
        // state.avatar.randomAvatar = action.payload;
      })
      .addCase(resetAvatar.rejected, (state) => {
        state.avatar.loading = false;
        state.avatar.isResetingAvatar = false;
        // state.avatar.randomAvatar = action.payload;
      })
      .addCase(uploadAvatar.pending, (state) => {
        state.avatar.loading = true;
        state.avatar.isUploadingAvatar = true;
      })
      .addCase(uploadAvatar.fulfilled, (state) => {
        state.avatar.loading = false;
        state.avatar.isUploadingAvatar = false;
      })
      .addCase(uploadAvatar.rejected, (state) => {
        state.avatar.loading = false;
        state.avatar.isUploadingAvatar = false;
      })
      .addCase(viewGame.pending, (state, action) => {
        state.filterGames.loading = true;
        state.filterGames.gameToView = { gameId: action.meta.arg.gameId };
      })
      .addCase(viewGame.fulfilled, (state, action) => {
        state.filterGames.loading = false;
        state.filterGames.gameEvents = action.payload.gameEvents
          ? action.payload.gameEvents
          : [];
      })
      .addCase(viewGame.rejected, (state) => {
        state.filterGames.loading = false;
      })
      .addCase(getSeperateGameList.pending, (state) => {
        state.filterGames.loading = true;
      })
      .addCase(getSeperateGameList.fulfilled, (state, action) => {
        state.filterGames.loading = false;
        state.filterGames.gameList = action.payload.gameList;
        state.filterGames.currentGameList = action.payload.currentGameList;
        state.filterGames.completedGameList = action.payload.completedGameList;
      })
      .addCase(getSeperateGameList.rejected, (state) => {
        state.filterGames.loading = false;
      })
      .addCase(getPWTransactionAction.pending, (state, action) => {
        state.filterGames.loading = true;
        state.filterGames.transactionPage = action.meta.arg.page;
        state.filterGames.gameActivity.isPageLoading = true;
      })
      .addCase(getPWTransactionAction.fulfilled, (state) => {
        state.filterGames.loading = false;
        state.filterGames.gameActivity.isPageLoading = false;
      })
      .addCase(getPWTransactionAction.rejected, (state) => {
        state.filterGames.loading = false;
        state.filterGames.gameActivity.isPageLoading = false;
      })
      .addCase(receivePWTransaction, (state, action) => {
        state.filterGames.loading = false;
        state.filterGames.isPageLoading = false;
        state.PWTransactions.response = action.payload.PWTransactions;
        state.filterGames.ConversionTransactions =
          action.payload.ConversionTransactions;
        state.filterGames.indexToInsertConversion =
          action.payload.indexToInsertConversion;
        state.filterGames.indexToInsertPrize =
          action.payload.indexToInsertPrize;
        state.filterGames.playerPrizes = action.payload.playerPrizes;
        state.filterGames.transactionPage = action.payload.transactionPage;
        state.filterGames.transactionCount = action.payload.transactionCount;
      })
      .addCase(viewTransaction.pending, (state) => {
        state.filterGames.loading = true;
        state.filterGames.isLoadingTransaction = true;
      })
      .addCase(viewTransaction.fulfilled, (state) => {
        state.filterGames.loading = false;
      })
      .addCase(viewTransaction.rejected, (state) => {
        state.filterGames.loading = false;
      })
      .addCase(responseViewTransaction, (state, action) => {
        state.filterGames.isLoadingTransaction = false;
        state.filterGames.transactionToView = action.payload.transactionToView;
        state.filterGames.transactionDesc = action.payload.transactionDesc;
        state.filterGames.isConversion = action.payload.isConversion;
        state.filterGames.prizeToView = undefined;
        state.filterGames.gameEvents = action.payload.gameEvents
          ? action.payload.gameEvents
          : [];
      })
      .addCase(viewPrize, (state, action) => {
        state.filterGames.prizeToView = action.payload.playerPrize;
        state.filterGames.transactionDesc = action.payload.transactionDesc;
        state.filterGames.transactionToView = undefined;
      })
      .addCase(getSingleTicketOutcome.pending, (state) => {
        state.filterGames.loading = true;
      })
      .addCase(getSingleTicketOutcome.fulfilled, (state, action) => {
        state.filterGames.loading = false;
        const eventArr = action.meta.arg.ticketId.split("-");
        let outcomeString = "";
        for (let i = 0; i < eventArr.length; i += 1) {
          outcomeString += action.payload.ticketOutcome[i];
          if (i < eventArr.length - 1) outcomeString += ", ";
        }

        state.filterGames.ticketHistory = [];
        state.filterGames.ticketHistoryCount = 0;
        state.filterGames.ticketHistoryPage = 0;
        state.filterGames.singleTicketOutcome = outcomeString;
      })
      .addCase(getSingleTicketOutcome.rejected, (state) => {
        state.filterGames.loading = false;
      })
      .addCase(changeMyPassword.pending, (state) => {
        state.profile.isChangingPassword = true;
        state.profile.isRequesting = true;
      })
      .addCase(changeMyPassword.fulfilled, (state, action) => {
        state.profile.response = action.payload;
        state.profile.isChangingPassword = false;
        state.profile.isRequesting = false;
      })
      .addCase(changeMyPassword.rejected, (state) => {
        state.profile.isChangingPassword = false;
        state.profile.isRequesting = false;
      })
      .addCase(requestForgotPassword.pending, (state, action) => {
        state.forgotPassword.loading = true;
        state.forgotPassword.email = action?.meta?.arg?.email;
      })
      .addCase(requestForgotPassword.fulfilled, (state, action) => {
        state.forgotPassword.loading = false;
        state.forgotPassword.email = action.meta.arg.email;
        if (action.payload.errorNo !== 0) {
          state.forgotPassword.apiResponse = {
            errorNo: 500,
            message: "Email sent failed",
            identity: 1,
          };
        } else {
          state.forgotPassword.apiResponse = action.payload;
        }
      })
      .addCase(requestForgotPassword.rejected, (state) => {
        state.forgotPassword.loading = false;
        state.forgotPassword.apiResponse = {
          ErrorNo: 500,
          Message: "Email sent failed",
          Identity: 1,
        };
      })
      .addCase(passwordResetResponse, (state, action) => {
        state.resetPassword.isLoading = false;
        state.resetPassword.response = action.payload.response;
        state.resetPassword.data = action.payload.data;
      })
      .addCase(requestPasswordReset.pending, (state, action) => {
        state.resetPassword.isLoading = true;
        state.resetPassword.data = action?.meta?.arg?.data;
      })
      .addCase(requestPasswordReset.fulfilled, (state) => {
        state.resetPassword.isLoading = false;
      })
      .addCase(requestPasswordReset.rejected, (state) => {
        state.resetPassword.isLoading = false;
      })
      .addCase(resetTicketHistory, (state) => {
        state.filterGames.loading = false;
        state.filterGames.ticketHistory = [];
        state.filterGames.ticketHistoryCount = 0;
        state.filterGames.ticketHistoryPage = 0;
        state.filterGames.singleTicketOutcome = "";
      })

      .addCase(getTicketHistory.pending, (state, action) => {
        if (action.meta.arg.page === 1) {
          state.filterGames.loading = true;
        } else {
          state.filterGames.isPageLoading = true;
        }
      })
      .addCase(getTicketHistory.fulfilled, (state) => {
        state.filterGames.loading = false;
      })
      .addCase(getTicketHistory.rejected, (state) => {
        state.filterGames.loading = false;
      })
      .addCase(receiveTicketHistory, (state, action) => {
        state.filterGames.loading = false;
        state.filterGames.ticketHistory = action.payload.ticketHistory;
        state.filterGames.ticketOutcomes = action.payload.ticketOutcomes;
        state.filterGames.ticketHistoryPage = action.payload.ticketHistoryPage;
        state.filterGames.ticketHistoryCount =
          action.payload.ticketHistoryCount;
        state.filterGames.isPageLoading = false;
        state.filterGames.singleTicketOutcome = "";
      })
      .addCase(resetErrorMessage, (state) => {
        state.profile.response = null;
        state.profile.isChangingPassword = false;
        state.avatar.isUploadingAvatar = false;
        state.avatar.isResettingAvatar = false;
      })
      .addCase(resetForgotPasswordErrorMessage, (state) => {
        state.forgotPassword.loading = false;
        state.forgotPassword.apiResponse = null;
        state.forgotPassword.email = null;
      })
      .addCase(resetActivityDetail, (state) => {
        state.filterGames.loading = false;
        state.filterGames.ticketHistory = [];
        state.filterGames.ticketHistoryCount = 0;
        state.filterGames.ticketHistoryPage = 0;
        state.filterGames.singleTicketOutcome = "";
        state.filterGames.transactionToView = undefined;
        state.filterGames.prizeToView = undefined;
        state.filterGames.gameEvents = [];
      })
      .addCase(checkNextTicketHistory.pending, (state) => {
        state.filterGames.gameActivityDetail.isMoreLoading = true;
      })
      .addCase(checkNextTicketHistory.fulfilled, (state) => {
        state.filterGames.gameActivityDetail.isMoreLoading = false;
      })
      .addCase(checkNextTicketHistory.rejected, (state) => {
        state.filterGames.gameActivityDetail.isMoreLoading = false;
      })
      .addCase(checkNextPWTransactionHistory.pending, (state) => {
        state.filterGames.gameActivity.isMoreLoading = true;
      })
      .addCase(checkNextPWTransactionHistory.fulfilled, (state) => {
        state.filterGames.gameActivity.isMoreLoading = false;
      })
      .addCase(checkNextPWTransactionHistory.rejected, (state) => {
        state.filterGames.gameActivity.isMoreLoading = false;
      })
      .addCase(updateMoreTicketHistory, (state, action) => {
        state.filterGames.gameActivityDetail.more = action.payload.more;
      })
      .addCase(updateMorePWTransactionHistory, (state, action) => {
        state.filterGames.gameActivity.more = action.payload.more;
      });
  },
});

export const {
  resetSignInResponse,
  clearStore,
  resetGameActivity,
  poSetActivePurchase,
} = userSlice.actions;

export default userSlice.reducer;
