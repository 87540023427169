/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { PartnerDetailFromJSON, PartnerDetailToJSON, PlayerGreenIdFromJSON, PlayerGreenIdToJSON, } from './';
export function PlayerSummaryFromJSON(json) {
    return PlayerSummaryFromJSONTyped(json, false);
}
export function PlayerSummaryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'playerId': !exists(json, 'PlayerId') ? undefined : json['PlayerId'],
        'avatar': !exists(json, 'Avatar') ? undefined : json['Avatar'],
        'username': !exists(json, 'Username') ? undefined : json['Username'],
        'email': !exists(json, 'Email') ? undefined : json['Email'],
        'emailVerified': !exists(json, 'EmailVerified') ? undefined : json['EmailVerified'],
        'firstName': !exists(json, 'FirstName') ? undefined : json['FirstName'],
        'lastName': !exists(json, 'LastName') ? undefined : json['LastName'],
        'playerStatusId': !exists(json, 'PlayerStatusId') ? undefined : json['PlayerStatusId'],
        'lastNotificationViewed': !exists(json, 'LastNotificationViewed') ? undefined : (new Date(json['LastNotificationViewed'])),
        'linkedProviders': !exists(json, 'LinkedProviders') ? undefined : json['LinkedProviders'],
        'playerWallets': !exists(json, 'PlayerWallets') ? undefined : json['PlayerWallets'],
        'facebookId': !exists(json, 'FacebookId') ? undefined : json['FacebookId'],
        'signupDate': !exists(json, 'SignupDate') ? undefined : (new Date(json['SignupDate'])),
        'partnerDetails': !exists(json, 'PartnerDetails') ? undefined : (json['PartnerDetails'].map(PartnerDetailFromJSON)),
        'intercom': !exists(json, 'Intercom') ? undefined : json['Intercom'],
        'playerGreenId': !exists(json, 'PlayerGreenId') ? undefined : PlayerGreenIdFromJSON(json['PlayerGreenId']),
    };
}
export function PlayerSummaryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'PlayerId': value.playerId,
        'Avatar': value.avatar,
        'Username': value.username,
        'Email': value.email,
        'EmailVerified': value.emailVerified,
        'FirstName': value.firstName,
        'LastName': value.lastName,
        'PlayerStatusId': value.playerStatusId,
        'LastNotificationViewed': value.lastNotificationViewed === undefined ? undefined : (value.lastNotificationViewed.toISOString()),
        'LinkedProviders': value.linkedProviders,
        'PlayerWallets': value.playerWallets,
        'FacebookId': value.facebookId,
        'SignupDate': value.signupDate === undefined ? undefined : (value.signupDate.toISOString()),
        'PartnerDetails': value.partnerDetails === undefined ? undefined : (value.partnerDetails.map(PartnerDetailToJSON)),
        'Intercom': value.intercom,
        'PlayerGreenId': PlayerGreenIdToJSON(value.playerGreenId),
    };
}
