/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function PlayerResultSummaryResponseFromJSON(json) {
    return PlayerResultSummaryResponseFromJSONTyped(json, false);
}
export function PlayerResultSummaryResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'ticketValue': !exists(json, 'TicketValue') ? undefined : json['TicketValue'],
        'knockedOutEventIndex': !exists(json, 'KnockedOutEventIndex') ? undefined : json['KnockedOutEventIndex'],
        'purchasePrice': !exists(json, 'PurchasePrice') ? undefined : json['PurchasePrice'],
        'ticketPurchaseEventIndex': !exists(json, 'TicketPurchaseEventIndex') ? undefined : json['TicketPurchaseEventIndex'],
        'totalCashoutValue': !exists(json, 'TotalCashoutValue') ? undefined : json['TotalCashoutValue'],
        'totalCashoutTickets': !exists(json, 'TotalCashoutTickets') ? undefined : json['TotalCashoutTickets'],
        'knockedOutTicketId': !exists(json, 'KnockedOutTicketId') ? undefined : json['KnockedOutTicketId'],
    };
}
export function PlayerResultSummaryResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'TicketValue': value.ticketValue,
        'KnockedOutEventIndex': value.knockedOutEventIndex,
        'PurchasePrice': value.purchasePrice,
        'TicketPurchaseEventIndex': value.ticketPurchaseEventIndex,
        'TotalCashoutValue': value.totalCashoutValue,
        'TotalCashoutTickets': value.totalCashoutTickets,
        'KnockedOutTicketId': value.knockedOutTicketId,
    };
}
