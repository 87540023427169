import { Box, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as WideStackedTicket } from "../graphics/ticket/ticket-wide-stacked.svg";

function StackedTicketItem({ children, secondaryText, onClick }) {
  return (
    <Box
      onClick={onClick}
      sx={{
        position: "relative",
        transition: "0.2s ease all",
        cursor: "pointer",
        "&:hover": {
          transform: "scale(1.03)",
        },
        "&:active": {
          transform: "scale(0.98)",
        },
      }}
    >
      <WideStackedTicket />
      <Typography
        component="h6"
        variant="h6"
        fontWeight="bold"
        sx={{
          position: "absolute",
          left: "50%",
          top: 48,
          transform: "translateX(-50%)",
          userSelect: "none",
        }}
      >
        {children}
      </Typography>
      <Typography
        component="p"
        variant="body2"
        color="text.secondary"
        fontWeight="500"
        sx={{
          position: "absolute",
          bottom: 40,
          left: "50%",
          transform: "translateX(-50%)",
          userSelect: "none",
        }}
      >
        {secondaryText}
      </Typography>
    </Box>
  );
}

StackedTicketItem.propTypes = {
  children: PropTypes.node.isRequired,
  secondaryText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default StackedTicketItem;
