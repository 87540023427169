/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function GameResultFromJSON(json) {
    return GameResultFromJSONTyped(json, false);
}
export function GameResultFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'gameType': !exists(json, 'GameType') ? undefined : json['GameType'],
        'gameTypeId': !exists(json, 'GameTypeId') ? undefined : json['GameTypeId'],
        'gameStatus': !exists(json, 'GameStatus') ? undefined : json['GameStatus'],
        'gameStatusId': !exists(json, 'GameStatusId') ? undefined : json['GameStatusId'],
        'jackpotSeriesId': !exists(json, 'JackpotSeriesId') ? undefined : json['JackpotSeriesId'],
        'gameId': !exists(json, 'GameId') ? undefined : json['GameId'],
        'featureGame': !exists(json, 'FeatureGame') ? undefined : json['FeatureGame'],
        'featureGameOrder': !exists(json, 'FeatureGameOrder') ? undefined : json['FeatureGameOrder'],
        'gameName': !exists(json, 'GameName') ? undefined : json['GameName'],
        'cardMobileImageUrl': !exists(json, 'CardMobileImageUrl') ? undefined : json['CardMobileImageUrl'],
        'cardDesktopImageUrl': !exists(json, 'CardDesktopImageUrl') ? undefined : json['CardDesktopImageUrl'],
        'totalEvents': !exists(json, 'TotalEvents') ? undefined : json['TotalEvents'],
        'eventName': !exists(json, 'EventName') ? undefined : json['EventName'],
        'secondaryName': !exists(json, 'SecondaryName') ? undefined : json['SecondaryName'],
        'eventTypeId': !exists(json, 'EventTypeId') ? undefined : json['EventTypeId'],
        'eventStatusId': !exists(json, 'EventStatusId') ? undefined : json['EventStatusId'],
        'currentEventNo': !exists(json, 'CurrentEventNo') ? undefined : json['CurrentEventNo'],
        'advertisedStartTime': !exists(json, 'AdvertisedStartTime') ? undefined : (new Date(json['AdvertisedStartTime'])),
        'presaleDescription': !exists(json, 'PresaleDescription') ? undefined : json['PresaleDescription'],
        'presaleToOpen': !exists(json, 'PresaleToOpen') ? undefined : json['PresaleToOpen'],
        'cashoutavailable': !exists(json, 'Cashoutavailable') ? undefined : json['Cashoutavailable'],
        'followingCount': !exists(json, 'FollowingCount') ? undefined : json['FollowingCount'],
        'activeSoldTicketsCount': !exists(json, 'ActiveSoldTicketsCount') ? undefined : json['ActiveSoldTicketsCount'],
        'soldTicketsCount': !exists(json, 'SoldTicketsCount') ? undefined : json['SoldTicketsCount'],
        'playersWithActiveTicketsCount': !exists(json, 'PlayersWithActiveTicketsCount') ? undefined : json['PlayersWithActiveTicketsCount'],
        'currentPoolTicketPrice': !exists(json, 'CurrentPoolTicketPrice') ? undefined : json['CurrentPoolTicketPrice'],
        'currentPoolAvailableTicketCount': !exists(json, 'CurrentPoolAvailableTicketCount') ? undefined : json['CurrentPoolAvailableTicketCount'],
        'currentBankAvailableTicketCount': !exists(json, 'CurrentBankAvailableTicketCount') ? undefined : json['CurrentBankAvailableTicketCount'],
        'playerActiveTicketCount': !exists(json, 'PlayerActiveTicketCount') ? undefined : json['PlayerActiveTicketCount'],
        'playerActiveTicketTotalValue': !exists(json, 'PlayerActiveTicketTotalValue') ? undefined : json['PlayerActiveTicketTotalValue'],
        'playerChanceOfSurvival': !exists(json, 'PlayerChanceOfSurvival') ? undefined : json['PlayerChanceOfSurvival'],
        'majorPrizeWinnerCount': !exists(json, 'MajorPrizeWinnerCount') ? undefined : json['MajorPrizeWinnerCount'],
        'fTPWinnerCount': !exists(json, 'FTPWinnerCount') ? undefined : json['FTPWinnerCount'],
        'playerIsKnockOut': !exists(json, 'PlayerIsKnockOut') ? undefined : json['PlayerIsKnockOut'],
        'freeToPlay': !exists(json, 'FreeToPlay') ? undefined : json['FreeToPlay'],
        'gameDescription': !exists(json, 'GameDescription') ? undefined : json['GameDescription'],
        'originalTotalTicketCount': !exists(json, 'OriginalTotalTicketCount') ? undefined : json['OriginalTotalTicketCount'],
        'showHurryUp': !exists(json, 'ShowHurryUp') ? undefined : json['ShowHurryUp'],
        'showPoolTicketsAvailable': !exists(json, 'ShowPoolTicketsAvailable') ? undefined : json['ShowPoolTicketsAvailable'],
        'latitude': !exists(json, 'Latitude') ? undefined : json['Latitude'],
        'longitude': !exists(json, 'Longitude') ? undefined : json['Longitude'],
        'radius': !exists(json, 'Radius') ? undefined : json['Radius'],
        'geoRestrict': !exists(json, 'GeoRestrict') ? undefined : json['GeoRestrict'],
        'isDeadHeat': !exists(json, 'IsDeadHeat') ? undefined : json['IsDeadHeat'],
        'jackpotLastGame': !exists(json, 'JackpotLastGame') ? undefined : json['JackpotLastGame'],
        'nextOpenEventStartTime': !exists(json, 'NextOpenEventStartTime') ? undefined : (new Date(json['NextOpenEventStartTime'])),
        'disableCashoutSwopMinutes': !exists(json, 'DisableCashoutSwopMinutes') ? undefined : json['DisableCashoutSwopMinutes'],
        'cashoutMinTickets': !exists(json, 'CashoutMinTickets') ? undefined : json['CashoutMinTickets'],
        'maxPresaleTickets': !exists(json, 'MaxPresaleTickets') ? undefined : json['MaxPresaleTickets'],
        'gameStopped': !exists(json, 'GameStopped') ? undefined : json['GameStopped'],
        'templateCashoutToBankEnabled': !exists(json, 'TemplateCashoutToBankEnabled') ? undefined : json['TemplateCashoutToBankEnabled'],
        'swopEnabled': !exists(json, 'SwopEnabled') ? undefined : json['SwopEnabled'],
        'buyFromBankUpToEventNumber': !exists(json, 'BuyFromBankUpToEventNumber') ? undefined : json['BuyFromBankUpToEventNumber'],
        'buyFromPoolUpToEventNumber': !exists(json, 'BuyFromPoolUpToEventNumber') ? undefined : json['BuyFromPoolUpToEventNumber'],
    };
}
export function GameResultToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'GameType': value.gameType,
        'GameTypeId': value.gameTypeId,
        'GameStatus': value.gameStatus,
        'GameStatusId': value.gameStatusId,
        'JackpotSeriesId': value.jackpotSeriesId,
        'GameId': value.gameId,
        'FeatureGame': value.featureGame,
        'FeatureGameOrder': value.featureGameOrder,
        'GameName': value.gameName,
        'CardMobileImageUrl': value.cardMobileImageUrl,
        'CardDesktopImageUrl': value.cardDesktopImageUrl,
        'TotalEvents': value.totalEvents,
        'EventName': value.eventName,
        'SecondaryName': value.secondaryName,
        'EventTypeId': value.eventTypeId,
        'EventStatusId': value.eventStatusId,
        'CurrentEventNo': value.currentEventNo,
        'AdvertisedStartTime': value.advertisedStartTime === undefined ? undefined : (value.advertisedStartTime.toISOString()),
        'PresaleDescription': value.presaleDescription,
        'PresaleToOpen': value.presaleToOpen,
        'Cashoutavailable': value.cashoutavailable,
        'FollowingCount': value.followingCount,
        'ActiveSoldTicketsCount': value.activeSoldTicketsCount,
        'SoldTicketsCount': value.soldTicketsCount,
        'PlayersWithActiveTicketsCount': value.playersWithActiveTicketsCount,
        'CurrentPoolTicketPrice': value.currentPoolTicketPrice,
        'CurrentPoolAvailableTicketCount': value.currentPoolAvailableTicketCount,
        'CurrentBankAvailableTicketCount': value.currentBankAvailableTicketCount,
        'PlayerActiveTicketCount': value.playerActiveTicketCount,
        'PlayerActiveTicketTotalValue': value.playerActiveTicketTotalValue,
        'PlayerChanceOfSurvival': value.playerChanceOfSurvival,
        'MajorPrizeWinnerCount': value.majorPrizeWinnerCount,
        'FTPWinnerCount': value.fTPWinnerCount,
        'PlayerIsKnockOut': value.playerIsKnockOut,
        'FreeToPlay': value.freeToPlay,
        'GameDescription': value.gameDescription,
        'OriginalTotalTicketCount': value.originalTotalTicketCount,
        'ShowHurryUp': value.showHurryUp,
        'ShowPoolTicketsAvailable': value.showPoolTicketsAvailable,
        'Latitude': value.latitude,
        'Longitude': value.longitude,
        'Radius': value.radius,
        'GeoRestrict': value.geoRestrict,
        'IsDeadHeat': value.isDeadHeat,
        'JackpotLastGame': value.jackpotLastGame,
        'NextOpenEventStartTime': value.nextOpenEventStartTime === undefined ? undefined : (value.nextOpenEventStartTime.toISOString()),
        'DisableCashoutSwopMinutes': value.disableCashoutSwopMinutes,
        'CashoutMinTickets': value.cashoutMinTickets,
        'MaxPresaleTickets': value.maxPresaleTickets,
        'GameStopped': value.gameStopped,
        'TemplateCashoutToBankEnabled': value.templateCashoutToBankEnabled,
        'SwopEnabled': value.swopEnabled,
        'BuyFromBankUpToEventNumber': value.buyFromBankUpToEventNumber,
        'BuyFromPoolUpToEventNumber': value.buyFromPoolUpToEventNumber,
    };
}
