/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { ConfigRequestToJSON, ConfigResponseFromJSON, } from '../models';
/**
 *
 */
export class ConfigApi extends runtime.BaseAPI {
    /**
     */
    async configRequestCreateRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling configRequestCreate.');
        }
        const queryParameters = {};
        if (requestParameters.host !== undefined) {
            queryParameters['Host'] = requestParameters.host;
        }
        if (requestParameters.hostKey !== undefined) {
            queryParameters['HostKey'] = requestParameters.hostKey;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/config`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ConfigRequestToJSON(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ConfigResponseFromJSON(jsonValue));
    }
    /**
     */
    async configRequestCreate(requestParameters, initOverrides) {
        const response = await this.configRequestCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async configRequestDeleteRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling configRequestDelete.');
        }
        const queryParameters = {};
        if (requestParameters.host !== undefined) {
            queryParameters['Host'] = requestParameters.host;
        }
        if (requestParameters.hostKey !== undefined) {
            queryParameters['HostKey'] = requestParameters.hostKey;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/config`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ConfigResponseFromJSON(jsonValue));
    }
    /**
     */
    async configRequestDelete(requestParameters, initOverrides) {
        const response = await this.configRequestDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async configRequestGetRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling configRequestGet.');
        }
        const queryParameters = {};
        if (requestParameters.host !== undefined) {
            queryParameters['Host'] = requestParameters.host;
        }
        if (requestParameters.hostKey !== undefined) {
            queryParameters['HostKey'] = requestParameters.hostKey;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/config`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ConfigResponseFromJSON(jsonValue));
    }
    /**
     */
    async configRequestGet(requestParameters, initOverrides) {
        const response = await this.configRequestGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async configRequestPostRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling configRequestPost.');
        }
        const queryParameters = {};
        if (requestParameters.host !== undefined) {
            queryParameters['Host'] = requestParameters.host;
        }
        if (requestParameters.hostKey !== undefined) {
            queryParameters['HostKey'] = requestParameters.hostKey;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/config`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConfigRequestToJSON(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ConfigResponseFromJSON(jsonValue));
    }
    /**
     */
    async configRequestPost(requestParameters, initOverrides) {
        const response = await this.configRequestPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
/**
    * @export
    * @enum {string}
    */
export var ConfigRequestCreateAcceptEnum;
(function (ConfigRequestCreateAcceptEnum) {
    ConfigRequestCreateAcceptEnum["ApplicationJson"] = "application/json";
})(ConfigRequestCreateAcceptEnum || (ConfigRequestCreateAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ConfigRequestDeleteAcceptEnum;
(function (ConfigRequestDeleteAcceptEnum) {
    ConfigRequestDeleteAcceptEnum["ApplicationJson"] = "application/json";
})(ConfigRequestDeleteAcceptEnum || (ConfigRequestDeleteAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ConfigRequestGetAcceptEnum;
(function (ConfigRequestGetAcceptEnum) {
    ConfigRequestGetAcceptEnum["ApplicationJson"] = "application/json";
})(ConfigRequestGetAcceptEnum || (ConfigRequestGetAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ConfigRequestPostAcceptEnum;
(function (ConfigRequestPostAcceptEnum) {
    ConfigRequestPostAcceptEnum["ApplicationJson"] = "application/json";
})(ConfigRequestPostAcceptEnum || (ConfigRequestPostAcceptEnum = {}));
