const TOKEN_KEY = "token";
const CHANNEL_ID = "channel";
export const saveToken = (token) => {
  localStorage.setItem(TOKEN_KEY, token);
};

export const resetToken = () => {
  localStorage.removeItem(TOKEN_KEY);
};

export const saveChannelID = (channelID) => {
  localStorage.setItem(CHANNEL_ID, channelID);
};

export const getToken = () => {
  return localStorage.getItem(TOKEN_KEY);
};

export const getChannelID = () => {
  return localStorage.getItem(CHANNEL_ID);
};

export const IsSwopStakeWeb = () => {
  return getChannelID() === "1";
};

export const IsAuthenticated = () => {
  let auth = false;
  const token = getToken();
  if (token !== null && token !== undefined && token !== "undefined") {
    auth = true;
  }
  return auth;
};

export default null;
