import React from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  Box,
  IconButton,
} from "@mui/material";
import WidgetsIcon from "@mui/icons-material/Widgets";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
// import GroupAddIcon from "@mui/icons-material/GroupAdd";
// import InfoIcon from "@mui/icons-material/Info";
// import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import SvgIcon from "@mui/material/SvgIcon";
import { IsSwopStakeWeb } from "../../services/ls";
// import { ReactComponent as RacingIcon } from "../graphics/horse.svg";
import { ReactComponent as SwopIcon } from "../graphics/logo.svg";

function LeftNavigationBar() {
  if (!IsSwopStakeWeb()) {
    return null;
  }
  const navigate = useNavigate();
  const location = useLocation();
  const isBelowXL = useMediaQuery((theme) => theme.breakpoints.down("xl"));
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <div
      style={{
        width: "300px",
        height: isBelowXL ? "100vh" : "calc(100vh - 152px)",
        position: "fixed",
        top: isBelowXL ? "0" : "152px",
        backgroundColor: "#ffffff",
        boxShadow: "4px 0px 6px 0px rgba(0, 0, 0, 0.05)",
        zIndex: "1000",
        color: "#19292F",
      }}
    >
      {isBelowXL ? (
        <Box
          sx={{
            height: "80px",
            backgroundColor: "#24A7DD",
            display: "flex",
            alignItems: "center",
          }}
        >
          <IconButton
            disableElevation
            disableRipple
            sx={{
              padding: 0,
              marginLeft: "24px",
              "&.MuiButtonBase-root:hover": {
                bgcolor: "transparent",
              },
            }}
            onClick={() => {
              navigate("/");
            }}
          >
            <SvgIcon
              sx={{
                width: "auto",
                height: isMobile ? 40 : 54,
              }}
              component={SwopIcon}
              viewBox="0 0 46 24"
            />
          </IconButton>
        </Box>
      ) : (
        ""
      )}
      <List
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          "& .MuiListItemText-root .MuiTypography-root": {
            fontWeight: "500",
          },
        }}
      >
        <ListItemButton
          component={Link}
          to="/"
          sx={{
            padding: "16px 14px",
            marginLeft: "6px",
            flexGrow: 0,
            flexShrink: 1,
            "& .MuiListItemIcon-root": {
              color:
                location.pathname === "/"
                  ? (theme) => theme.palette.primary.main
                  : "initial",
            },
            "& .MuiListItemText-root .MuiTypography-root": {
              color:
                location.pathname === "/"
                  ? (theme) => theme.palette.primary.main
                  : "initial",
            },
            "&::before": {
              content: '""',
              position: "absolute",
              left: 0,
              top: "50%",
              transform: "translateY(-50%)",
              width: "5px",
              height: "36px",
              backgroundColor:
                location.pathname === "/"
                  ? (theme) => theme.palette.primary.main
                  : "transparent",
              borderRadius: "0px 3px 3px 0px",
            },
          }}
        >
          <ListItemIcon>
            <WidgetsIcon />
          </ListItemIcon>
          <ListItemText
            primary="Games"
            sx={{ "&.MuiListItemText-root": { fontWeight: 500 } }}
          />
        </ListItemButton>
        <ListItemButton
          component={Link}
          to="/results"
          sx={{
            padding: "16px 14px",
            marginLeft: "6px",
            flexGrow: 0,
            flexShrink: 1,
            "& .MuiListItemIcon-root": {
              color:
                location.pathname === "/results"
                  ? (theme) => theme.palette.primary.main
                  : "initial",
            },
            "& .MuiListItemText-root .MuiTypography-root": {
              color:
                location.pathname === "/results"
                  ? (theme) => theme.palette.primary.main
                  : "initial",
            },
            "&::before": {
              content: '""',
              position: "absolute",
              left: 0,
              top: "50%",
              transform: "translateY(-50%)",
              width: "5px",
              height: "36px",
              backgroundColor:
                location.pathname === "/results"
                  ? (theme) => theme.palette.primary.main
                  : "transparent",
              borderRadius: "0px 3px 3px 0px",
            },
          }}
        >
          <ListItemIcon>
            <EmojiEventsIcon />
          </ListItemIcon>
          <ListItemText primary="Results" />
        </ListItemButton>
        {/* <ListItemButton
          sx={{
            padding: "16px 14px",
            marginLeft: "6px",
            flexGrow: 0,
            flexShrink: 1,
          }}
        >
          <ListItemIcon>
            <GroupAddIcon sx={{ color: "#19292F" }} />
          </ListItemIcon>
          <ListItemText primary="Refer a friend" />
        </ListItemButton> */}
        {/* <ListItemButton
          sx={{
            padding: "16px 14px",
            marginLeft: "6px",
            flexGrow: 0,
            flexShrink: 1,
          }}
        >
          <ListItemIcon>
            <InfoIcon sx={{ color: "#19292F" }} />
          </ListItemIcon>
          <ListItemText primary="How to Play" />
        </ListItemButton>
        <ListItemButton
          sx={{
            padding: "16px 14px",
            marginLeft: "6px",
            flexGrow: 0,
            flexShrink: 1,
          }}
        >
          <ListItemIcon>
            <SportsSoccerIcon sx={{ color: "#19292F" }} />
          </ListItemIcon>
          <ListItemText primary="Bet on Sports" />
        </ListItemButton>
        <ListItemButton
          sx={{
            padding: "16px 14px",
            marginLeft: "6px",
            flexGrow: 0,
            flexShrink: 1,
          }}
        >
          <ListItemIcon>
            <SvgIcon component={RacingIcon} viewBox="0 0 24 24" />
          </ListItemIcon>
          <ListItemText primary="Bet on Racing" />
        </ListItemButton>
        <div style={{ flexGrow: 1 }} /> */}
        {/* {IsAuthenticated() && (
          <ListItemButton
            sx={{
              padding: "16px 14px",
              marginLeft: "6px",
              flexGrow: 0,
              flexShrink: 1,
            }}
          >
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItemButton>
        )} */}
      </List>
    </div>
  );
}

export default LeftNavigationBar;
