/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function BankTicketsRequestFromJSON(json) {
    return BankTicketsRequestFromJSONTyped(json, false);
}
export function BankTicketsRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'gameId': !exists(json, 'GameId') ? undefined : json['GameId'],
        'eventIndex': !exists(json, 'EventIndex') ? undefined : json['EventIndex'],
        'bracketId': !exists(json, 'BracketId') ? undefined : json['BracketId'],
        'valueMin': !exists(json, 'ValueMin') ? undefined : json['ValueMin'],
        'valueMax': !exists(json, 'ValueMax') ? undefined : json['ValueMax'],
        'page': !exists(json, 'Page') ? undefined : json['Page'],
        'channel': !exists(json, 'Channel') ? undefined : json['Channel'],
    };
}
export function BankTicketsRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'GameId': value.gameId,
        'EventIndex': value.eventIndex,
        'BracketId': value.bracketId,
        'ValueMin': value.valueMin,
        'ValueMax': value.valueMax,
        'Page': value.page,
        'Channel': value.channel,
    };
}
