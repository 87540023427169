/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function CurrentFromJSON(json) {
    return CurrentFromJSONTyped(json, false);
}
export function CurrentFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'position': !exists(json, 'Position') ? undefined : json['Position'],
        'isInLeaderboard': !exists(json, 'IsInLeaderboard') ? undefined : json['IsInLeaderboard'],
        'value': !exists(json, 'Value') ? undefined : json['Value'],
        'ticketId': !exists(json, 'TicketId') ? undefined : json['TicketId'],
    };
}
export function CurrentToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'Position': value.position,
        'IsInLeaderboard': value.isInLeaderboard,
        'Value': value.value,
        'TicketId': value.ticketId,
    };
}
