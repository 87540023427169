import React from "react";
import ReactDOM from "react-dom/client";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import {
  AppInsightsContext,
  AppInsightsErrorBoundary,
} from "@microsoft/applicationinsights-react-js";

import store from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import "./styles/global.css";
import Router from "./pages/Router";
import { AppContextProvider } from "./contexts/AppContext";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { reactPlugin } from "./applicationInsightService";
import Error500 from "./500";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <BrowserRouter>
        <AppContextProvider>
          <AppInsightsContext.Provider value={reactPlugin}>
            <AppInsightsErrorBoundary
              onError={() => <Error500 />}
              appInsights={reactPlugin}
            >
              <Router />
            </AppInsightsErrorBoundary>
          </AppInsightsContext.Provider>
        </AppContextProvider>
      </BrowserRouter>
    </LocalizationProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
