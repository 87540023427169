import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { getCurrentEvent } from "../../utils/common-functions";
import swopApi from "../../api";
import { EventStatus, GameStatus, SystemMessage } from "../../utils/types";
import { IsAuthenticated, getChannelID } from "../../services/ls";
import { requestLeaderboard } from "./leaderboard.slice";

const SLICE_NAME = "game";

export const getPlayerTickets = createAsyncThunk(
  `${SLICE_NAME}/getPlayerTickets`,
  ({ gameId }) => {
    return swopApi.gameApi.myTicketsRequestGameIdmyTicketsPost({
      accept: "application/json",
      gameId,
      body: {
        gameId,
        eventIndex: 0,
        cashedOut: false,
        knockedOut: false,
        bracketId: 1,
        page: 0,
      },
    });
  }
);

export const getTicketInfo = createAsyncThunk(
  `${SLICE_NAME}/getTicketInfo`,
  ({ gameId }) => {
    return swopApi.gameApi.gameTicketInfoRequestGameIdticketInfoGet({
      accept: "application/json",
      gameId,
    });
  }
);

export const getLeaderboard = createAsyncThunk(
  `${SLICE_NAME}/getLeaderboard`,
  ({ gameId }) => {
    return swopApi.gameApi.leaderboardRequestleaderboardPost({
      accept: "application/json",
      followingOnly: false,
      gameId,
    });
  }
);

export const getTotalCashout = createAsyncThunk(
  `${SLICE_NAME}/getTotalCashout`,
  ({ gameId }) => {
    return swopApi.gameApi.getGameTotalCashoutValueRequestgetGameTotalCashoutValuePost(
      {
        accept: "application/json",
        body: {
          gameId,
        },
      }
    );
  }
);

export const getPlayerTicketSummary = createAsyncThunk(
  `${SLICE_NAME}/getPlayerTicketSummary`,
  ({ gameId }) => {
    return swopApi.gameApi.playerTicketSummaryRequestGameIdplayerTicketSummaryPost(
      {
        accept: "application/json",
        gameId,
        body: {
          gameId,
        },
      }
    );
  }
);

export const getTicketBracketSummary = createAsyncThunk(
  `${SLICE_NAME}/getTicketBracketSummary`,
  ({ gameId, eventIndex }) => {
    console.log({ gameId, eventIndex });
    return swopApi.gameApi.ticketBracketSummaryRequestGameIdticketBracketSummaryPost(
      {
        accept: "application/json",
        gameId,
        body: {
          gameId,
          eventIndex,
        },
      }
    );
  }
);

export const getEventTicketResult = createAsyncThunk(
  `${SLICE_NAME}/getEventTicketResult`,
  ({ gameId, eventIndex }) => {
    console.log({ gameId, eventIndex });
    return swopApi.gameApi.eventTicketResultRequestGameIdeventTicketResultPost({
      accept: "application/json",
      gameId,
      body: {
        gameId,
        eventIndex,
      },
    });
  }
);

export const getBankTickets = createAsyncThunk(
  `${SLICE_NAME}/getBankTickets`,
  async ({ gameId, eventIndex, bracketId, valueMin, valueMax }) => {
    var result = await swopApi.gameApi.bankTicketsRequestGameIdbankTicketsPost({
      accept: "application/json",
      gameId,
      body: {
        eventIndex,
        bracketId,
        valueMin,
        valueMax,
        gameId,
        page: 0,
      },
    });
    return { gameId, result };
  }
);

export const setSelectedEvent = createAction("setSelectedEvent");

export const setSelectedTabIndex = createAction("setSelectedTabIndex");

export const updateEventLiveStatus = createAsyncThunk(
  `${SLICE_NAME}/updateEventLiveStatus`,
  async (_, { dispatch, getState }) => {
    const { selectedEvent } = getState().game.game;
    if (!selectedEvent) return;

    if (selectedEvent.Event.EventStatusId < EventStatus.Settled) {
      if (IsAuthenticated()) {
        dispatch(
          getTicketBracketSummary({
            gameId: selectedEvent.GameId,
            eventIndex: selectedEvent.EventIndex,
          })
        );
      }
    } else {
      dispatch(
        getEventTicketResult({
          gameId: selectedEvent.GameId,
          eventIndex: selectedEvent.EventIndex,
        })
      );
    }
  }
);

const getCustomKnockoutEvent = async (requestParameters) => {
  const headerParameters = {};
  headerParameters["Content-Type"] = "application/json";
  if (
    requestParameters.accept !== undefined &&
    requestParameters.accept !== null
  ) {
    headerParameters.Accept = String(requestParameters.accept);
  }
  const baseURL = swopApi.playerApi.configuration.configuration.basePath;
  headerParameters["x-ss-id"] =
    swopApi.playerApi.configuration.configuration.headers["x-ss-id"];

  const url = `${baseURL}/player/getKnockoutEvent`;

  return fetch(url, {
    method: "POST",
    headers: headerParameters,
    body: JSON.stringify(requestParameters.body),
  }).then((res) => res.json());
};

export const getKnockoutEvent = createAsyncThunk(
  `${SLICE_NAME}/getKnockoutEvent`,
  ({ gameId }) => {
    return getCustomKnockoutEvent({
      accept: "application/json",
      body: {
        GameId: gameId,
        Channel: getChannelID(),
      },
    });
  }
);

export const getGameDetail = createAsyncThunk(
  `${SLICE_NAME}/getGameDetail`,
  async ({ gameId }, { dispatch }) => {
    const gameRes = await swopApi.gameApi.getGameRequestGameIdGet({
      accept: "application/json",
      gameId,
    });
    Promise.allSettled([
      dispatch(getTicketInfo({ gameId })),
      dispatch(getPlayerTicketSummary({ gameId })),
      // dispatch(getLeaderboard({ gameId })),
      dispatch(requestLeaderboard({ GameId: gameId })),
    ]).then((values) => {
      console.log(values);
    });

    if (IsAuthenticated() && gameRes.GameStatusId !== GameStatus.Presale) {
      dispatch(getKnockoutEvent({ gameId }));
    }

    // dispatch(getPlayerTickets({ gameId }));
    const currentEvent = getCurrentEvent(gameRes);

    if (currentEvent) {
      // currentEvent = gameRes.GameEvents[gameRes.GameEvents.length - 1];
      dispatch(setSelectedEvent(currentEvent));

      dispatch(updateEventLiveStatus());
    }

    return gameRes;
  }
);
export const TAB_TYPES = {
  RESULTS: 0,
  LEADERBOARD: 1,
};

export const getGameHomeDetails = createAsyncThunk(
  `${SLICE_NAME}/getGameHomeDetails`,
  ({ gameId }) => {
    return swopApi.gameApi.gameDetailsRequestdetailsGameIdPost({
      accept: "application/json",
      gameId: gameId,
    });
  }
);
const gameSlice = createSlice({
  name: SLICE_NAME,
  initialState: {
    buyDialog: {
      open: false,
      defaultTab: 0,
    },
    game: {
      gameId: "",
      loading: false,
      detail: null,
      selectedEvent: null,
      selectedTabIndex: TAB_TYPES.RESULTS,
    },
    gameHomeDetails: {
      loading: false,
      details: null,
      response: null,
      errorMessage: "",
    },
    ticketInfo: {
      loading: false,
      detail: null,
    },
    playerTicket: {
      loading: false,
      detail: null,
    },
    ticketList: {
      loading: false,
      detail: null,
    },
    ticketBracketSummary: {
      loading: false,
      detail: null,
    },
    eventTicketResult: {
      loading: false,
      detail: [],
    },
    bankTicket: {
      loading: false,
      detail: null,
      gameId: "",
    },
    totalCashout: {
      loading: false,
      detail: null,
    },
    knockedOut: {
      loading: false,
      isPlayerInGame: false,
      knockedOutEventIndex: null,
    },
    showBuyOnLoad: false,
  },
  reducers: {
    updateActiveTicket(state, activeTicketCount) {
      if (state.ticketInfo && state.ticketInfo.detail) {
        state.ticketInfo.detail.soldActiveTicketCountOverride =
          activeTicketCount.payload;
      }
    },
    updateTotalPlayersInGame(state, totalPlayersCount) {
      if (state.ticketInfo && state.ticketInfo.detail) {
        state.ticketInfo.detail.totalPlayersInGame = totalPlayersCount.payload;
      }
    },
    updateBuyDialog(state, newBuyDialogState) {
      state.buyDialog.open = newBuyDialogState.payload;
    },
    updateBuyDialogDefaultTab(state, newTab) {
      state.buyDialog.defaultTab = newTab.payload;
    },
    updateShowBuyOnLoad(state, showBuyOnLoad) {
      state.showBuyOnLoad = showBuyOnLoad.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(setSelectedEvent, (state, action) => {
        state.game.selectedEvent = action.payload;
        // everytime a new event is selected the results tab should be selected by default
        state.game.selectedTabIndex = 0;
      })
      .addCase(setSelectedTabIndex, (state, action) => {
        state.game.selectedTabIndex = action.payload;
      })
      .addCase(getGameDetail.pending, (state) => {
        state.game.loading = true;
        state.game.selectedEvent = null;
      })
      .addCase(getGameDetail.fulfilled, (state, action) => {
        state.game.loading = false;
        state.game.detail = action.payload;
      })
      .addCase(getGameDetail.rejected, (state, action) => {
        state.game.loading = false;
        state.game.detail = action.payload;
      })
      .addCase(getGameHomeDetails.pending, (state) => {
        state.gameHomeDetails.loading = true;
      })
      .addCase(getGameHomeDetails.fulfilled, (state, action) => {
        state.gameHomeDetails.loading = false;
        state.gameHomeDetails.details = action.payload;
      })
      .addCase(getGameHomeDetails.rejected, (state, action) => {
        state.gameHomeDetails.loading = false;
        state.gameHomeDetails.errorMessage = action.error.message;
      })
      .addCase(getTicketInfo.pending, (state) => {
        state.ticketInfo.loading = true;
      })
      .addCase(getTicketInfo.fulfilled, (state, action) => {
        state.ticketInfo.loading = false;
        state.ticketInfo.detail = action.payload;
      })
      .addCase(getTicketInfo.rejected, (state, action) => {
        state.ticketInfo.loading = false;
        state.ticketInfo.detail = action.payload;
      })
      // Player ticket summary
      .addCase(getPlayerTicketSummary.pending, (state) => {
        state.playerTicket.loading = true;
      })
      .addCase(getPlayerTicketSummary.fulfilled, (state, action) => {
        state.playerTicket.loading = false;
        state.playerTicket.detail = action.payload;
      })
      .addCase(getPlayerTicketSummary.rejected, (state, action) => {
        state.playerTicket.loading = false;
        state.playerTicket.detail = action.payload;
      })
      // Player ticket in a game
      .addCase(getPlayerTickets.pending, (state) => {
        state.ticketList.loading = true;
      })
      .addCase(getPlayerTickets.fulfilled, (state, action) => {
        state.ticketList.loading = false;
        state.ticketList.detail = action.payload;
      })
      .addCase(getPlayerTickets.rejected, (state, action) => {
        state.ticketList.loading = false;
        state.ticketList.detail = action.payload;
      })
      .addCase(getTicketBracketSummary.pending, (state) => {
        state.ticketBracketSummary.loading = true;
        state.ticketBracketSummary.detail = {};
      })
      .addCase(getTicketBracketSummary.fulfilled, (state, action) => {
        state.ticketBracketSummary.loading = false;
        state.ticketBracketSummary.detail = action.payload;
      })
      .addCase(getTicketBracketSummary.rejected, (state, action) => {
        state.ticketBracketSummary.loading = false;
        state.ticketBracketSummary.detail = action.payload;
      })
      .addCase(getBankTickets.pending, (state) => {
        state.bankTicket.loading = true;
      })
      .addCase(getBankTickets.fulfilled, (state, action) => {
        state.bankTicket.loading = false;
        state.bankTicket.detail = action.payload.result;
        state.bankTicket.gameId = action.payload.gameId;
      })
      .addCase(getBankTickets.rejected, (state, action) => {
        state.bankTicket.loading = false;
        state.bankTicket.detail = action.payload;
      })
      .addCase(getEventTicketResult.pending, (state) => {
        state.eventTicketResult.loading = true;
        state.eventTicketResult.detail = [];
      })
      .addCase(getEventTicketResult.fulfilled, (state, action) => {
        state.eventTicketResult.loading = false;
        state.eventTicketResult.detail = action.payload;
      })
      .addCase(getEventTicketResult.rejected, (state, action) => {
        state.eventTicketResult.loading = false;
        state.eventTicketResult.detail = action.payload;
      })
      .addCase(getTotalCashout.pending, (state) => {
        state.totalCashout.loading = true;
      })
      .addCase(getTotalCashout.fulfilled, (state, action) => {
        state.totalCashout.loading = false;
        state.totalCashout.detail = action.payload;
      })
      .addCase(getTotalCashout.rejected, (state, action) => {
        state.totalCashout.loading = false;
        state.totalCashout.detail = action.payload;
      })
      .addCase(getKnockoutEvent.pending, (state) => {
        state.knockedOut.loading = true;
      })
      .addCase(getKnockoutEvent.fulfilled, (state, action) => {
        state.knockedOut.loading = false;
        state.knockedOut.knockedOutEventIndex =
          action.payload.ErrorNo === SystemMessage.InvalidPlayerId
            ? -1
            : action.payload.Identity;

        state.knockedOut.isPlayerInGame = action.payload.Identity !== undefined;
      })
      .addCase(getKnockoutEvent.rejected, (state) => {
        state.knockedOut.loading = false;
        state.knockedOut.knockedOutEventIndex = -1;
      });
  },
});
export const {
  updateActiveTicket,
  updateTotalPlayersInGame,
  updateBuyDialog,
  updateBuyDialogDefaultTab,
  updateShowBuyOnLoad,
} = gameSlice.actions;
export default gameSlice.reducer;
