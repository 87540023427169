import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

function TicketListItem({ icon, title, value, error }) {
  return (
    <Stack
      py={1}
      direction="row"
      spacing={2}
      alignItems="start"
      justifyContent="space-between"
      // sx={{ fill: error ? "rgba(255,255,255)" : "rgba(255,255,255, 0.5)" }}
      sx={{ fill: "#fff" }}
    >
      <Box
        sx={{
          height: "24px",
          width: "24px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {icon}
      </Box>
      <Box sx={{ flex: 1 }}>
        <Typography
          variant="body2"
          component="p"
          sx={{
            flex: 1,
            textAlign: "left",
            color: error ? "rgba(255,255,255)" : "rgba(255,255,255, 0.5)",
            lineHeight: "24px",
            minHeight: "24px",
          }}
        >
          {title}
        </Typography>
        {/* Commented below out since client wants it moved to confirmpurchase section */}
        {/* {error ? (
          <Typography
            variant="caption"
            component="p"
            sx={{
              color: "#fff",
              fontSize: "0.875rem",
              fontWeight: "700",
              backgroundColor: "#FF2F2F",
              padding: "8px",
              width: "fit-content",
              lineHeight: "1",
              borderRadius: "4px",
              marginTop: "8px",
            }}
          >
            {error}
          </Typography>
        ) : null} */}
      </Box>
      <Typography
        variant="body1"
        component="span"
        // sx={{ color: error ? "rgba(255,255,255)" : "rgba(255,255,255, 0.5)" }}
        sx={{ color: "#fff" }}
      >
        {value}
      </Typography>
    </Stack>
  );
}

TicketListItem.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
};

export default TicketListItem;
