/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { SerieGameNumberFromJSON, SerieGameNumberToJSON, } from './';
export function SubscriptionSeriesResponseFromJSON(json) {
    return SubscriptionSeriesResponseFromJSONTyped(json, false);
}
export function SubscriptionSeriesResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'subscriptionSeriesList': !exists(json, 'SubscriptionSeriesList') ? undefined : json['SubscriptionSeriesList'],
        'serieGameNumberList': !exists(json, 'SerieGameNumberList') ? undefined : (json['SerieGameNumberList'].map(SerieGameNumberFromJSON)),
    };
}
export function SubscriptionSeriesResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'SubscriptionSeriesList': value.subscriptionSeriesList,
        'SerieGameNumberList': value.serieGameNumberList === undefined ? undefined : (value.serieGameNumberList.map(SerieGameNumberToJSON)),
    };
}
