/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function GameTicketInfoFromJSON(json) {
    return GameTicketInfoFromJSONTyped(json, false);
}
export function GameTicketInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'poolTicketCount': !exists(json, 'PoolTicketCount') ? undefined : json['PoolTicketCount'],
        'totalTicketCount': !exists(json, 'TotalTicketCount') ? undefined : json['TotalTicketCount'],
        'soldTicketCount': !exists(json, 'SoldTicketCount') ? undefined : json['SoldTicketCount'],
        'soldActiveTicketCount': !exists(json, 'SoldActiveTicketCount') ? undefined : json['SoldActiveTicketCount'],
        'presoldTicketCount': !exists(json, 'PresoldTicketCount') ? undefined : json['PresoldTicketCount'],
        'totalPlayersInGame': !exists(json, 'TotalPlayersInGame') ? undefined : json['TotalPlayersInGame'],
        'poolSize': !exists(json, 'PoolSize') ? undefined : json['PoolSize'],
        'originalTicketCount': !exists(json, 'OriginalTicketCount') ? undefined : json['OriginalTicketCount'],
    };
}
export function GameTicketInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'PoolTicketCount': value.poolTicketCount,
        'TotalTicketCount': value.totalTicketCount,
        'SoldTicketCount': value.soldTicketCount,
        'SoldActiveTicketCount': value.soldActiveTicketCount,
        'PresoldTicketCount': value.presoldTicketCount,
        'TotalPlayersInGame': value.totalPlayersInGame,
        'PoolSize': value.poolSize,
        'OriginalTicketCount': value.originalTicketCount,
    };
}
