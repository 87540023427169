export const EventStatus = {
  Open: 1,
  Closed: 2,
  AwaitingApproval: 3,
  Settled: 4,
  Abandoned: 5,
  Live: 6,
};

export const PrizeType = {
  MAJOR: 1,
  CONSOLATION: 2,
  WHOLEPRIZEPOOL: 3,
};

export const GameStatus = {
  Draft: 1,
  Presale: 2,
  Open: 3,
  Suspended: 4,
  Paused: 5,
  Final: 6,
  Cancelled: 7,
  Error: 8,
  PresaleFulfilment: 21,
};

export const MasterEventType = { Racing: 1, Sports: 2 };

export const GameType = { EASY_PLAY: 1, P2O: 2, P2P: 3 };

export const SurvivalColour = { Green: 1, Red: 2, Yellow: 3 };

export const WalletType = { PTP: 1, FTP: 2, JACKPOT: 3 };

export const OutcomeResult = {
  Unknown: 0,
  Won: 1,
  Placed: 2,
  Lost: 3,
  Scratched: 4,
  LateScratched: 5,
};

export const OrderType = {
  JackpotPrepurchaseFromPool: 1,
  SubscriptionPrepurchaseFromPool: 2,
  BankPrepurchaseFromPool: 3,
  PlayerPrepurchaseFromPool: 4,
  AdminRewardPrepurchaseFromPool: 5,
  PurchaseFromPool: 10,
  AdminRewardFromPool: 11,
  AdminRefundToPool: 12,
  SwapWithPool: 13,
  SwapBracketWithPool: 14,
  PurchaseFromBank: 20,
  CashoutToBank: 21,
  AdminRefundToBank: 22,
  PlayerToPlayer: 30,
  RefundScratchedRunner: 40,
};

export const OrderStatus = {
  Prepurchase: 1,
  InProgress: 2,
  Cancelled: 3,
  Complete: 10,
};

export const SystemMessage = {
  InvalidPlayerId: 51000,
  PermissionDenied: 52000,
  InvalidGameId: 54000,
  InvalidGameStatusId: 54009,
  DuplicateTransactionDetected: 56004,
  InsufficientFunds: 56010,
  InvalidTicketOrderTypeId: 56100,
  TicketPriceChanged: 56200,
  InsufficientTicketsInPool: 56201,
  InsufficientTicketsInPresale: 56202,
  PlayerNoLongerOwnsTickets: 56203,
  Validated: 59000,
  ValidationFailed: 59001,
  MaxOrderQuantityExceeded: 56206,
  MaxPresaleOrderQuantityExceeded: 56207,
  MaxSwopQuantityExceeded: 56208,
};

export const OrderDialogType = {
  BUY: "Buy",
  CASHOUT: "Cashout",
  SWOP: "Swop",
  OUTCOMECASHOUT: "OutcomeCashout",
  SUPERSWOP: "SuperSwop",
};

export const TransactionFilterType = {
  GameActivity: "My Game Activity",
  AllTransactions: "All Transactions",
  Deposits: "Deposits",
  Withdrawals: "Withdrawals",
  Purchases: "Purchases",
  Prizes: "Prizes",
  RefundsAndAdjustments: "Refunds & Adjustments",
};

export const TransactionFilterTypeId = {
  GameActivity: "my-game-activity",
  AllTransactions: "all-transactions",
  Deposits: "deposits",
  Withdrawals: "withdrawals",
  Purchases: "purchases",
  Prizes: "prizes",
  RefundsAndAdjustments: "refunds",
};

export const LeaderboardFilters = {
  All: 1,
  Followers: 2,
  Bank: 3,
};

export const PWTransactionType = {
  TicketOrder: 1,
  GameWinnings: 2,
  GameRefund: 3,
  FTPCredit: 4,
  FTPDebit: 5,
  JackPotCredit: 6,
  ScratchedBracketRefund: 7,
  PartialScratchedBracketRefund: 8,
  ManualAdjustment: 10,
  ContraBetweenPlayers: 11,
  ContraBetweenPlayerWallets: 12,
  PromotionalBonus: 20,
  DepositBank: 101,
  WithdrawalBank: 201,
};

export const LeaderboardItemDisplayType = {
  DISPLAY_LEADERS: 1,
  DISPLAY_PLAYERS: 2,
  DISPLAY_FOLLOWINGS: 3,
};

export default null;
