/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { CurrentFromJSON, CurrentToJSON, FinalFromJSON, FinalToJSON, } from './';
export function PlayerBestTicketFromJSON(json) {
    return PlayerBestTicketFromJSONTyped(json, false);
}
export function PlayerBestTicketFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'current': !exists(json, 'Current') ? undefined : CurrentFromJSON(json['Current']),
        '_final': !exists(json, 'Final') ? undefined : FinalFromJSON(json['Final']),
    };
}
export function PlayerBestTicketToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'Current': CurrentToJSON(value.current),
        'Final': FinalToJSON(value._final),
    };
}
