/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function PlayerTicketHistoryFromJSON(json) {
    return PlayerTicketHistoryFromJSONTyped(json, false);
}
export function PlayerTicketHistoryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'isKnockedOut': !exists(json, 'IsKnockedOut') ? undefined : json['IsKnockedOut'],
        'knockedOutValue': !exists(json, 'KnockedOutValue') ? undefined : json['KnockedOutValue'],
        'knockedOutEventName': !exists(json, 'KnockedOutEventName') ? undefined : json['KnockedOutEventName'],
        'ticketHistoryId': !exists(json, 'TicketHistoryId') ? undefined : json['TicketHistoryId'],
        'gameId': !exists(json, 'GameId') ? undefined : json['GameId'],
        'ticketOrderId': !exists(json, 'TicketOrderId') ? undefined : json['TicketOrderId'],
        'ticketId': !exists(json, 'TicketId') ? undefined : json['TicketId'],
        'toPlayerId': !exists(json, 'ToPlayerId') ? undefined : json['ToPlayerId'],
        'isLatest': !exists(json, 'IsLatest') ? undefined : json['IsLatest'],
        'detail': !exists(json, 'Detail') ? undefined : json['Detail'],
        'price': !exists(json, 'Price') ? undefined : json['Price'],
        'value': !exists(json, 'Value') ? undefined : json['Value'],
        'probability': !exists(json, 'Probability') ? undefined : json['Probability'],
        'margin': !exists(json, 'Margin') ? undefined : json['Margin'],
        'profitMargin': !exists(json, 'ProfitMargin') ? undefined : json['ProfitMargin'],
        'cumulativeMargin': !exists(json, 'CumulativeMargin') ? undefined : json['CumulativeMargin'],
        'game': !exists(json, 'Game') ? undefined : json['Game'],
        'ticketOrder': !exists(json, 'TicketOrder') ? undefined : json['TicketOrder'],
    };
}
export function PlayerTicketHistoryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'IsKnockedOut': value.isKnockedOut,
        'KnockedOutValue': value.knockedOutValue,
        'KnockedOutEventName': value.knockedOutEventName,
        'TicketHistoryId': value.ticketHistoryId,
        'GameId': value.gameId,
        'TicketOrderId': value.ticketOrderId,
        'TicketId': value.ticketId,
        'ToPlayerId': value.toPlayerId,
        'IsLatest': value.isLatest,
        'Detail': value.detail,
        'Price': value.price,
        'Value': value.value,
        'Probability': value.probability,
        'Margin': value.margin,
        'ProfitMargin': value.profitMargin,
        'CumulativeMargin': value.cumulativeMargin,
        'Game': value.game,
        'TicketOrder': value.ticketOrder,
    };
}
