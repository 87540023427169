/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function ClientIntercomTokenResponseFromJSON(json) {
    return ClientIntercomTokenResponseFromJSONTyped(json, false);
}
export function ClientIntercomTokenResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'intercomToken': !exists(json, 'IntercomToken') ? undefined : json['IntercomToken'],
    };
}
export function ClientIntercomTokenResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'IntercomToken': value.intercomToken,
    };
}
