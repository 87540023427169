/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function PlayerNotificationLogFromJSON(json) {
    return PlayerNotificationLogFromJSONTyped(json, false);
}
export function PlayerNotificationLogFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'adminId': !exists(json, 'AdminId') ? undefined : json['AdminId'],
        'notificationTypeCode': !exists(json, 'NotificationTypeCode') ? undefined : json['NotificationTypeCode'],
        'extras': !exists(json, 'Extras') ? undefined : json['Extras'],
        'notificationTitle': !exists(json, 'NotificationTitle') ? undefined : json['NotificationTitle'],
        'notificationBody': !exists(json, 'NotificationBody') ? undefined : json['NotificationBody'],
        'notificationAction': !exists(json, 'NotificationAction') ? undefined : json['NotificationAction'],
        'sentDate': !exists(json, 'SentDate') ? undefined : (new Date(json['SentDate'])),
        'playerId': !exists(json, 'PlayerId') ? undefined : json['PlayerId'],
        'notificationTemplateId': !exists(json, 'NotificationTemplateId') ? undefined : json['NotificationTemplateId'],
        'isMandatory': !exists(json, 'isMandatory') ? undefined : json['isMandatory'],
        'orgId': !exists(json, 'OrgId') ? undefined : json['OrgId'],
    };
}
export function PlayerNotificationLogToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'AdminId': value.adminId,
        'NotificationTypeCode': value.notificationTypeCode,
        'Extras': value.extras,
        'NotificationTitle': value.notificationTitle,
        'NotificationBody': value.notificationBody,
        'NotificationAction': value.notificationAction,
        'SentDate': value.sentDate === undefined ? undefined : (value.sentDate.toISOString()),
        'PlayerId': value.playerId,
        'NotificationTemplateId': value.notificationTemplateId,
        'isMandatory': value.isMandatory,
        'OrgId': value.orgId,
    };
}
