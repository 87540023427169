import { createSlice, createAction, createAsyncThunk } from "@reduxjs/toolkit";
import swopApi from "../../api";

const SLICE_NAME = "dialog";

export const updateConfirmationDialog = createAction(
  "updateConfirmationDialog"
);

export const updateReceipt = createAction("updateReceipt");

export const updatePendingDialog = createAction("updatePendingDialog");

export const updateFullTicketViewer = createAction("updateFullTicketViewer");

export const getPurchasedTicketDetails = createAsyncThunk(
  `${SLICE_NAME}/player/ticketHistory`,
  async ({ gameId, ticketOrderId }) => {
    const res =
      await swopApi.playerApi.playerTicketHistoryRequestticketHistoryPost({
        accept: "application/json",
        gameId,
        ticketOrderId,
        onlyActiveTickets: true,
        page: 1,
      });
    return res;
  }
);

const dialogSlice = createSlice({
  name: SLICE_NAME,
  initialState: {
    confirmationDialog: {
      show: false,
      processing: false,
      orderType: null,
      gameName: "",
      options: {},
      error: {
        message: "",
        errorNo: null,
      },
      checkTicketDetail: true,
      close: () => {},
    },
    receipt: {
      show: false,
      response: null,
      ticketDetails: [],
      ticketDetailsLoading: false,
      gameName: "",
    },
    pendingDialog: {
      show: false,
      gameId: null,
    },
    fullTicketViewer: {
      show: false,
      ticketData: [],
      currentTicket: 0,
      newTicketTotal: null,
    },
  },
  extraReducers(builder) {
    builder
      .addCase(updateConfirmationDialog, (state, action) => {
        state.confirmationDialog = {
          ...state.confirmationDialog,
          show: action.payload.show ?? state.confirmationDialog.show,
          processing:
            action.payload.processing ?? state.confirmationDialog.processing,
          orderType:
            action.payload.orderType ?? state.confirmationDialog.orderType,
          options: action.payload.options ?? state.confirmationDialog.options,
          error:
            action.payload.show !== false && action.payload.error
              ? action.payload.error
              : {},
          checkTicketDetail: action.payload.checkTicketDetail ?? true,
          gameName:
            action.payload.gameName ?? state.confirmationDialog.gameName,
          close: action.payload.close ?? state.confirmationDialog.close,
        };
        // console.log({ confirmationDialog: state.confirmationDialog });
      })
      .addCase(updateReceipt, (state, action) => {
        state.receipt.show = action.payload.show;
        state.receipt.orderType = action.payload.orderType;
        state.receipt.response = action.payload.response;
      })
      .addCase(updatePendingDialog, (state, action) => {
        state.pendingDialog.show = action.payload.show;
        state.pendingDialog.gameId = action.payload.gameId;
      })
      .addCase(updateFullTicketViewer, (state, action) => {
        state.fullTicketViewer.show = action.payload.show;
        state.fullTicketViewer.ticketData = action.payload.ticketData ?? [];
        state.fullTicketViewer.currentTicket =
          action.payload.currentTicket ?? 0;
        state.fullTicketViewer.newTicketTotal = action.payload.newTicketTotal;
      })
      .addCase(getPurchasedTicketDetails.pending, (state) => {
        state.receipt.ticketDetailsLoading = true;
        state.receipt.ticketDetails = [];
      })
      .addCase(getPurchasedTicketDetails.fulfilled, (state, action) => {
        state.receipt.ticketDetailsLoading = false;
        state.receipt.ticketDetails = action.payload.ticketHistoryList;
      })
      .addCase(getPurchasedTicketDetails.rejected, (state) => {
        state.receipt.ticketDetailsLoading = false;
      });
  },
});

export default dialogSlice.reducer;
