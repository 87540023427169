/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function TicketPurchaseOrderFromJSON(json) {
    return TicketPurchaseOrderFromJSONTyped(json, false);
}
export function TicketPurchaseOrderFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'ticketPurchaseOrderId': !exists(json, 'TicketPurchaseOrderId') ? undefined : json['TicketPurchaseOrderId'],
        'orderDateTime': !exists(json, 'OrderDateTime') ? undefined : (new Date(json['OrderDateTime'])),
        'orderTypeId': !exists(json, 'OrderTypeId') ? undefined : json['OrderTypeId'],
        'playerId': !exists(json, 'PlayerId') ? undefined : json['PlayerId'],
        'purchaseOrderStatus': !exists(json, 'PurchaseOrderStatus') ? undefined : json['PurchaseOrderStatus'],
        'purchaseOrderFinalisedDateTime': !exists(json, 'PurchaseOrderFinalisedDateTime') ? undefined : (new Date(json['PurchaseOrderFinalisedDateTime'])),
        'share': !exists(json, 'Share') ? undefined : json['Share'],
        'quantity': !exists(json, 'Quantity') ? undefined : json['Quantity'],
        'gameId': !exists(json, 'GameId') ? undefined : json['GameId'],
        'resultMessage': !exists(json, 'ResultMessage') ? undefined : json['ResultMessage'],
        'ticketOrderId': !exists(json, 'TicketOrderId') ? undefined : json['TicketOrderId'],
        'price': !exists(json, 'Price') ? undefined : json['Price'],
        'gameName': !exists(json, 'GameName') ? undefined : json['GameName'],
    };
}
export function TicketPurchaseOrderToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'TicketPurchaseOrderId': value.ticketPurchaseOrderId,
        'OrderDateTime': value.orderDateTime === undefined ? undefined : (value.orderDateTime.toISOString()),
        'OrderTypeId': value.orderTypeId,
        'PlayerId': value.playerId,
        'PurchaseOrderStatus': value.purchaseOrderStatus,
        'PurchaseOrderFinalisedDateTime': value.purchaseOrderFinalisedDateTime === undefined ? undefined : (value.purchaseOrderFinalisedDateTime.toISOString()),
        'Share': value.share,
        'Quantity': value.quantity,
        'GameId': value.gameId,
        'ResultMessage': value.resultMessage,
        'TicketOrderId': value.ticketOrderId,
        'Price': value.price,
        'GameName': value.gameName,
    };
}
