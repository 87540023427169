import { useEffect, useState } from "react";
import * as R from "ramda";
// import config from "./appConfig";
import { FacebookLoginClient } from "@greatsumini/react-facebook-login";
import moment from "moment";
import {
  EventStatus,
  GameStatus,
  GameType,
  OutcomeResult,
  OrderType,
  OrderDialogType,
} from "./types";

export const longFormNum = (num) => {
  const number = Number(num);

  // Specifying a specific locale (e.g., en-US)
  const formattedNumberUS = number.toLocaleString("en-US");

  // Specifying options for formatting
  const options = {
    style: "decimal", // Other options: 'currency', 'percent', etc.
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };
  const formattedWithOptions = number.toLocaleString("en-US", options);
  return formattedWithOptions;
};

export const displayDateTime = (isoDate) => {
  let retValue;
  const format = "ddd, MMM Do YYYY, h:mm a";
  const dateToDisplay = moment(isoDate).format(format);
  if (moment(dateToDisplay, format).isSame(new Date(), "day")) {
    retValue = `Today ${dateToDisplay.split(", ")[2]}/`;
  } else {
    retValue = `${dateToDisplay.split(", ")[0]}, ${
      dateToDisplay.split(", ")[1]
    }, /${dateToDisplay.split(", ")[2]}`;
  }
  return retValue;
};

export const eventGroupCount = (events) => {
  let retValue;
  const defaultGroup = events.filter((e) => e.EventGroup === 0);
  // if all of them are not in group
  if (defaultGroup.length === events.length) {
    retValue = events.length;
  }
  // if there is not even one default group
  if (defaultGroup.length === 0) {
    // groupBy will return json with key to be EventGroup
    const distinctGroups = R.groupBy((e) => e.EventGroup.toString())(events);
    // Count number of key on that object
    retValue = Object.keys(distinctGroups).length;
  } else {
    // groupBy will return json with key to be EventGroup
    const distinctGroups = R.groupBy((e) => e.EventGroup.toString())(events);
    // Count number of key on that object
    // -1 for key 0, where EventGroup=0
    retValue = Object.keys(distinctGroups).length - 1 + defaultGroup.length;
  }

  return retValue;
};
export const getConsolationPrizePlayers = (game) => {
  const playerPrizes = [];
  const PlayerIds = [];
  if (game.PlayerPrizes) {
    Object.values(game.PlayerPrizes).forEach((playerPrize) => {
      if (
        (playerPrize.PrizeTypeId === 2 || playerPrize.PrizeTypeId === 3) &&
        PlayerIds.indexOf(playerPrize.PlayerId) === -1
      ) {
        playerPrizes.push(playerPrize);
        PlayerIds.push(playerPrize.PlayerId);
      }
    });
  }
  return playerPrizes;
};

export const numWithShortFormForValue = (num) => {
  let result = "";
  const value = num;
  if (value === undefined || Number.isNaN(value)) result = "-";
  else {
    if (value < 100000) {
      result = value.toFixed(2);
    } else if (value < 1000000) {
      result = `${(value / 1000).toFixed(2)}K`;
    } else {
      result = `${(value / 1000000).toFixed(3)}M`;
    }
    result = result.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return result;
};

export const lastSurvivedEventIndex = ({ game, ticket }) => {
  let survivedEventIndex = -1;

  for (let i = 0; i < ticket.length; i++) {
    const gameEvent = game.GameEvents.find((ge) => ge.EventIndex === i);
    if (
      !gameEvent ||
      !gameEvent.Event ||
      gameEvent.Event.EventStatusId == EventStatus.Open
    ) {
      return -1;
    }
    if (
      ![EventStatus.Settled, EventStatus.Abandoned].includes(
        gameEvent.Event.EventStatusId
      )
    ) {
      return -1;
    }
    const bracket = gameEvent.Brackets.find((b) => b.BracketId === ticket[i]);

    const outcomes = bracket.OutcomeIds.map((outcomeId) =>
      gameEvent.Event.Outcomes.find((o) => o.OutcomeId === outcomeId)
    );

    const hasWinningOutcome = R.any(
      (o) => o.OutcomeResultId === OutcomeResult.Won
    )(outcomes);

    if (hasWinningOutcome) {
      survivedEventIndex = i;
    } else {
      return survivedEventIndex;
    }
  }
  return survivedEventIndex;
};

export const numWithShortForm = (num) => {
  let result = "";
  const value = num;
  if (value === undefined || Number.isNaN(value)) result = "-";
  else {
    if (value < 100) {
      result = value.toFixed(0);
    } else if (value < 1000) {
      result = value.toFixed(0);
    } else if (value < 1000000) {
      result = `${(value / 1000).toFixed(2)}K`;
    } else {
      result = `${(value / 1000000).toFixed(3)}M`;
    }
    result = result.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return result;
};

export const fbInit = async () => {
  FacebookLoginClient.clear();
  await FacebookLoginClient.loadSdk("en_US");
  FacebookLoginClient.init({
    appId: process.env.REACT_APP_FB_APPID,
    version: "v16.0",
    xfbml: true,
    cookie: true,
    localStorage: true,
  });
};

export const pushLoginGTMEvent = (playerSummary) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: "swopstakes",
      DLV: {
        loginstatus: "loggedin",
        customerID: `${playerSummary.playerId}`,
        email: playerSummary.email,
        name: playerSummary.username,
        signup: moment(playerSummary.signupDate).unix(),
        intercom: playerSummary.intercom,
      },
    });
  }
};

export const checkEmail = (email) => {
  if (email.trim().length < 5) return false;
  // var re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i; // to accept unicode
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const getTitleFromPWTransaction = (txType, transaction) => {
  let retValue;
  if (txType === "purchases") {
    if (transaction.TicketOrder && !!transaction.TicketOrder.OrderTypeId) {
      switch (transaction.TicketOrder.OrderTypeId) {
        case OrderType.PurchaseFromPool:
          retValue = `Purchase of ${transaction.TicketOrder.Quantity} tickets from the pool`;
          break;
        case OrderType.PurchaseFromBank:
          retValue = `Purchase of ${transaction.TicketOrder.Quantity} tickets from the bank`;
          break;
        case OrderType.SwapWithPool:
          retValue = `Swop of ${transaction.TicketOrder.Quantity} tickets`;
          break;
        case OrderType.SwapBracketWithPool:
          retValue = `SuperSwop of ${transaction.TicketOrder.Quantity} tickets`;
          break;
        case OrderType.SubscriptionPrepurchaseFromPool:
          retValue = `Subscription Prepurchase of ${transaction.TicketOrder.Quantity} tickets`;
          break;
        case OrderType.PlayerPrepurchaseFromPool:
          retValue = `Prepurchase of ${transaction.TicketOrder.Quantity} tickets`;
          break;
        default:
          break;
      }
    }
  } else if (txType === "prizes") {
    if (transaction.PWTransactionTypeId === 2) retValue = "Winning prize";
    else if (
      transaction.PWTransactionTypeId === 1 &&
      transaction.TicketOrder.OrderTypeId === 21
    ) {
      retValue = `Fold of ${transaction.TicketOrder.Quantity} tickets to the pool`;
    }
  } else if (txType === "refunds") {
    retValue = transaction.PWTransactionType.PWTransactionType;
  }
  return retValue;
};

export const displayMyGameDateTime = (isoDate) => {
  const format = "dddd, Do MMMM h:mm:ss a (Z)";
  const dateToDisplay = moment(isoDate).format(format);
  return dateToDisplay;
};

export const displayPresaleTime = (isoDate) => {
  const format = "ddd, MMM Do YYYY, h:mm:ss a";
  const dateToDisplay = moment(isoDate).format(format);
  if (moment(dateToDisplay, format).isSame(new Date(), "day")) {
    return dateToDisplay.split(", ")[2];
  }
  return moment(isoDate).format("Do MMM");
};

export const displayNextEventDateTime = (isoDate) => {
  const format = "MMM D";
  const dateToDisplay = moment(isoDate).format(format);
  return dateToDisplay;
};

export const displayTransaction = (transaction) => {
  let transactionDesc = "";
  if (transaction.TicketOrderId) {
    if (transaction.PWTransactionTypeId === 3) {
      transactionDesc = transaction.PWTransactionType.PWTransactionType;
      return transactionDesc;
    }
    let freePresaleTicket = "";
    let freeTicket = "";
    let preSaleTicket = "";
    let ticket = "";
    // let ticketQuantity = numWithShortForm(transaction.TicketOrder.Quantity);
    const ticketQuantity = transaction.TicketOrder.Quantity;
    if (transaction.TicketOrder.Quantity > 1) {
      preSaleTicket = " pre-sale tickets";
      ticket = " tickets";
      freePresaleTicket = " free pre-sale tickets";
      freeTicket = " free tickets";
    } else {
      preSaleTicket = " pre-sale ticket";
      ticket = " ticket";
      freePresaleTicket = " free pre-sale ticket";
      freeTicket = " free ticket";
    }
    switch (transaction.TicketOrder.OrderTypeId) {
      case OrderType.JackpotPrepurchaseFromPool:
        if (transaction.Amount > 0) {
          transactionDesc = "Jackpot wallet bonus paid";
        } else {
          transactionDesc = `Jackpot wallet purchase of ${ticketQuantity}${ticket} from the pool`;
        }
        return transactionDesc;
      case OrderType.SubscriptionPrepurchaseFromPool:
        transactionDesc = `Subscription purchase of ${ticketQuantity}${ticket} from the pool`;
        return transactionDesc;
      case OrderType.BankPrepurchaseFromPool:
        transactionDesc = `Purchase of ${ticketQuantity}${preSaleTicket} from the pool (Bank)`;
        return transactionDesc;
      case OrderType.PlayerPrepurchaseFromPool:
        transactionDesc = `Purchase of ${ticketQuantity}${preSaleTicket} from the pool`;
        return transactionDesc;
      case OrderType.AdminRewardPrepurchaseFromPool:
        transactionDesc = `${ticketQuantity}${freePresaleTicket} gifted to you by Swopstakes`;
        return transactionDesc;
      case OrderType.PurchaseFromPool:
        transactionDesc = `Purchase of ${ticketQuantity}${ticket} from the pool`;
        return transactionDesc;
      case OrderType.AdminRewardFromPool:
        transactionDesc = `${ticketQuantity}${freeTicket} gifted to you by Swopstakes`;
        return transactionDesc;
      case OrderType.AdminRefundToPool:
        transactionDesc = `Admin refund ${ticketQuantity}${ticket} to pool`;
        return transactionDesc;
      case OrderType.SwapWithPool:
        transactionDesc = `Swop of ${ticketQuantity}${ticket}`;
        return transactionDesc;
      case OrderType.SwapBracketWithPool:
        transactionDesc = `SuperSwop of ${ticketQuantity}${ticket}`;
        return transactionDesc;
      case OrderType.PurchaseFromBank:
        transactionDesc = `Purchase of ${ticketQuantity}${ticket} from bank`;
        return transactionDesc;
      case OrderType.CashoutToBank:
        transactionDesc = `Fold of ${ticketQuantity}${ticket}  to the bank`;
        return transactionDesc;
      case OrderType.AdminRefundToBank:
        transactionDesc = `Admin refund ${ticketQuantity}${ticket} to bank`;
        return transactionDesc;
      default:
        break;
    }
  } else {
    transactionDesc = transaction.PWTransactionType.PWTransactionType;
  }

  return transactionDesc;
};

export const containsWhitespace = (str) => {
  return /\s/.test(str);
};

export const isDesktop = () => {
  let retValue = false;
  try {
    const agent = navigator.userAgent;
    if (
      (agent.match(/(mobile)/i) && agent.match(/(android)/i)) ||
      agent.match(/(iphone|ipod|windows phone|meego)/i)
    ) {
      console.log("not desktop");
    } else {
      retValue = true;
    }
  } catch (e) {
    retValue = true;
  }

  return retValue;
};

// export function safechain(args) {
//   if (args.length < 3) return false;
//   let result = args[0];
//   for (let i = 1; i < args.length - 1; i += 1) {
//     try {
//       if (result.hasOwnProperty(args[i])) {
//         result = result[args[i]];
//       } else {
//         throw new Error();
//       }
//     } catch (e) {
//       return args[args.length - 1];
//     }
//   }
//   return result;
// }

export const numWithShortFormForLeaderboard = (num) => {
  if (!num) return "";
  let result = "";
  const value = typeof num === "string" ? Number(num) : num;
  if (value === undefined || Number.isNaN(value)) result = "-";
  else {
    if (value < 10) {
      result = value.toFixed(2);
    } else if (value < 100000) {
      result = value.toFixed(0);
    } else if (value < 1000000) {
      result = `${(value / 1000).toFixed(2)}K`;
    } else {
      result = `${(value / 1000000).toFixed(3)}M`;
    }
    result = result.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return result;
};

export const getFBPicture = (id) =>
  fetch(
    `https://graph.facebook.com/v7.0/${id}/picture?type=large`
    // `https://graph.facebook.com/v7.0/180510917736282/picture?type=large`
  ).then((response) => (response.ok ? response.blob() : null));

export const calculateMinutes = (date = "") => {
  // console.log("calculateMinutes", { date });
  const startDate = moment.utc(date);
  const currentDate = moment().toISOString();
  const diff = moment.duration(startDate.diff(currentDate));
  return diff.asSeconds();
};

export const getAvatarUrl = (avatar = "") => {
  return `https://ss-dev.imgix.net/${avatar}?w=400&h=400&fit=facearea&faceindex=1&facepad=2&mask=ellipse&fm=png`;
};

export const numWithComma = (num = "") =>
  num.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const currentTicketPrice = (game) => {
  if (!game || !game.GameEvents || game.GameEvents.length === 0) return -1;
  const currentEvent = game.GameEvents.find(
    (ge) => ge.Event.EventStatusId === EventStatus.Open
  );
  const res =
    currentEvent &&
    (currentEvent.EventIndex === 0
      ? game.StartingTicketPrice
      : currentEvent.TicketPrice);
  return res;
};

export const parseTicketId = (ticket = "") =>
  ticket.split("-").map((s) => Number.parseInt(s, 10));

export const isCashoutEnabled = ({ game, soldTicketCount, event }) => {
  const enableCashoutMinTickets = game.EnableCashoutMinTicketsOverride
    ? game.EnableCashoutMinTicketsOverride
    : game.EnableCashoutMinTicketsCalculated;
  return (
    game &&
    game.GameTypeId === GameType.P2O &&
    event &&
    event.Event.EventStatus.EventStatus !== "Closed" &&
    event.CashoutToBankEnabled &&
    event.DisableCashoutSwopMinutes <
      calculateMinutes(event.Event.AdvertisedStartTime.toString()) / 60 &&
    enableCashoutMinTickets <= soldTicketCount
  );
};

export const GetCashoutStatus = ({
  gameStatusId,
  advertisedStartTime,
  disableCashoutSwopMinutes,
  cashoutMinTickets,
  soldTicketsCount,
  cashoutavailable,
  gameTypeId,
}) => {
  //cashoutavailable returned from API is the same as CashoutToBankEnabled
  //advertisedStartTime return from API is UTC date format
  return (
    gameTypeId &&
    cashoutMinTickets &&
    soldTicketsCount &&
    disableCashoutSwopMinutes &&
    advertisedStartTime &&
    gameTypeId === GameType.P2O &&
    gameStatusId === GameStatus.Open &&
    cashoutavailable &&
    disableCashoutSwopMinutes <
      calculateMinutes(moment(advertisedStartTime.toString()).toISOString()) /
        60 &&
    cashoutMinTickets <= soldTicketsCount
  );
};

export const isCashoutEnabledForPipsAndHowToPlay = ({ game, event }) => {
  return (
    game &&
    game.GameTypeId === GameType.P2O &&
    event &&
    event.CashoutToBankEnabled &&
    event.DisableCashoutSwopMinutes <
      calculateMinutes(event.Event.AdvertisedStartTime.toString()) / 60
  );
};

export const isCashoutEnabledForHowToPlay = ({ game, event }) => {
  return (
    game &&
    game.GameTypeId === GameType.P2O &&
    event &&
    event.CashoutToBankEnabled
  );
};

export const isSwopEnabled = ({ ticketCount, game, event }) => {
  return (
    game &&
    event &&
    game.SwopEnabled &&
    ticketCount > 0 &&
    event.EventIndex + 1 <= game.SwopUpToEventNumber
  );
};

export const isBuyFromBankEnabled = ({ game, event }) => {
  return (
    game.GameTypeId === GameType.P2O &&
    game.GameStatusId === GameStatus.Open &&
    validateBuyFromBankUpToEvent({ game, event })
  );
};

export const validateBuyFromPoolUpToEvent = ({ game, event }) => {
  return (
    game.BuyFromPoolUpToEventNumber === undefined ||
    game.BuyFromPoolUpToEventNumber === null ||
    event.EventIndex + 1 <= game.BuyFromPoolUpToEventNumber
  );
};

export const validateBuyFromBankUpToEvent = ({ game, event }) => {
  return (
    game.BuyFromBankUpToEventNumber === undefined ||
    game.BuyFromBankUpToEventNumber === null ||
    event.EventIndex + 1 <= game.BuyFromBankUpToEventNumber
  );
};

export const isOutcomeScratched = (outcome) => {
  if (outcome !== undefined) {
    return (
      outcome.OutcomeResultId === OutcomeResult.Scratched ||
      outcome.OutcomeResultId === OutcomeResult.LateScratched
    );
  }
  return true;
};

export const getEventStatusForSubway = ({ events = [], currentEvent }) => {
  const firstOpenEvent = events.find(
    (e) => e.Event.EventStatusId === EventStatus.Open
  );
  const status =
    currentEvent.EventStatusId > EventStatus.AwaitingApproval
      ? "resulted"
      : firstOpenEvent && currentEvent.EventId === firstOpenEvent.EventId
      ? "in-progress"
      : "upcoming";
  return status;
};

export const mapGameStatus = (status) => {
  switch (status) {
    case "Presale":
    case "PresaleFulfilment":
      return "presale";
    case "Open":
      return "live";
    case "Suspended":
      return "in-progress";
    case "Final":
      return "resulted";
    case "Gameover":
      return "game-over";
    case "Cancelled":
      return "cancelled";
    default:
      return status;
  }
};

export function mappedGameType(gt = "") {
  let retValue;
  switch (gt) {
    case "Greyhounds":
    case "Thoroughbred":
    case "Harness":
      retValue = gt;
      break;
    case "NRL":
    case "NBA":
      retValue = gt;
      break;
    case "AFL":
    case "Australian Rules":
      retValue = "AFL";
      break;

    // case "NFL":
    // case "American Football":
    //   retValue = "NFL";
    //   break;
    default:
      // Soccerball Icon
      retValue = "Fallback";
  }

  return retValue;
}

export const getEventTypeIconStringFromEventType = (eventType) => {
  switch (eventType) {
    case "Thoroughbred":
      return "Thoroughbred";
    case "Harness":
      return "Harness";
    case "Greyhounds":
      return "Greyhounds";
    case "AFL":
      return "AFL";
    case "Rugby League":
      return "RugbyLeague";
    case "Rugby Union":
      return "RugbyUnion";
    case "Football":
      return "Soccer";
    case "Cricket":
      return "Cricket";
    case "Mixed Martial Arts":
      return "Boxing";
    case "Baseball":
      return "Baseball";
    case "Tennis":
      return "Tennis";
    case "American Football":
      return "AmericanFootball";
    case "Basketball":
      return "Basketball";
    // case "Olympics":
    //   return "Olympics";
    case "Aussie Rules":
      return "AustralianRules";
    case "Womens Basketball":
      return "Basketball";
    case "Soccer":
      return "Soccer";
    case "Darts":
      return "Archery";
    case "Boxing":
      return "Boxing";
    case "Motor Sport":
      return "Helmet";
    case "Golf":
      return "Golf";
    case "Ice Hockey":
      return "IceHockey";
    case "Motorcycling":
      return "Helmet";
    // case "Snooker":
    //   return "Snooker";
    case "Australian Rules":
      return "AustralianRules";
    case "Handball":
      return "Handball";
    // case "Cycling":
    //   return "Cycling";
    // case "ESports":
    //   return "ESports";
    // case "Tournaments":
    //   return "Tournaments";
    // case "Sports Specials":
    //   return "SportsSpecials";
    case "Volleyball":
      return "Handball";
    // case "Netball":
    //   return "Netball";
    // case "Racing Specials":
    //   return "RacingSpecials";
    // case "Multi Specials":
    //   return "MultiSpecials";
    // case "Kentucky Derby":
    //   return "KentuckyDerby";
    // case "Jockey Challenge":
    //   return "JockeyChallenge";
    // case "Sports Futures":
    //   return "SportsFutures";
    case "Melbourne Cup Greyhounds":
      return "Greyhounds";
    case "Horse Racing Specials":
      return "Thoroughbred";
    // case "Team Of The Week":
    //   return "TeamOfTheWeek";
    // case "Feature Racing":
    //   return "FeatureRacing";
    // case "Politics":
    //   return "Politics";
    // case "TS":
    //   return "TS";
    // case "Entertainment":
    //   return "Entertainment";
    // case "Surfing":
    //   return "Surfing";
    // case "Yacht Racing":
    //   return "YachtRacing";
    case "Martial Arts/UFC":
      return "Boxing";
    default:
      return "Fallback";
  }
};

export const getEventTypeFromEventTypeId = (eventTypeId) => {
  switch (eventTypeId) {
    case 1:
      return "Thoroughbred";
    case 2:
      return "Harness";
    case 3:
      return "Greyhounds";
    case 4:
      return "AFL";
    case 5:
      return "Rugby League";
    case 6:
      return "Rugby Union";
    case 7:
      return "Football";
    case 8:
      return "Cricket";
    case 9:
      return "Mixed Martial Arts";
    case 10:
      return "Baseball";
    case 11:
      return "Tennis";
    case 12:
      return "American Football";
    case 13:
      return "Basketball";
    case 14:
      return "Olympics";
    case 15:
      return "Aussie Rules";
    case 17:
      return "Womens Basketball";
    case 19:
      return "Soccer";
    case 21:
      return "Darts";
    case 23:
      return "Boxing";
    case 25:
      return "Motor Sport";
    case 27:
      return "Golf";
    case 29:
      return "Ice Hockey";
    case 31:
      return "Motorcycling";
    case 33:
      return "Snooker";
    case 35:
      return "Australian Rules";
    case 36:
      return "Handball";
    case 37:
      return "Cycling";
    case 38:
      return "ESports";
    case 40:
      return "Tournaments";
    case 41:
      return "Sports Specials";
    case 43:
      return "Volleyball";
    case 45:
      return "Netball";
    case 47:
      return "Racing Specials";
    case 49:
      return "Multi Specials";
    case 51:
      return "Kentucky Derby";
    case 53:
      return "Jockey Challenge";
    case 55:
      return "Sports Futures";
    case 56:
      return "Melbourne Cup Greyhounds";
    case 58:
      return "Horse Racing Specials";
    case 60:
      return "Team Of The Week";
    case 61:
      return "Feature Racing";
    case 63:
      return "Politics";
    case 65:
      return "TS";
    case 101:
      return "Entertainment";
    case 102:
      return "Surfing";
    case 103:
      return "Yacht Racing";
    case 1001:
      return "Basketball";
    case 1002:
      return "Ice Hockey";
    case 1003:
      return "American Football";
    case 1004:
      return "Tennis";
    case 1005:
      return "Golf";
    case 1006:
      return "Cricket";
    case 1007:
      return "Football";
    case 1008:
      return "Baseball";
    case 1009:
      return "Darts";
    case 1010:
      return "Rugby Union";
    case 1011:
      return "Motor Sport";
    case 1012:
      return "Martial Arts/UFC";
    case 1014:
      return "Volleyball";
    case 1018:
      return "Rugby League";
    case 1023:
      return "Australian Rules";
    default:
      return "Fallback";
  }
};

export const getGameStatusFromStatusId = (statusId) => {
  return Object.keys(GameStatus).find((k) => statusId === GameStatus[k]);
};

export const getGameStatus = (statusId) => {
  let status = "";
  if (
    [
      GameStatus.Open,
      GameStatus.Paused,
      GameStatus.Suspended,
      GameStatus.Presale,
    ].includes(statusId)
  )
    status = "open";
  else if ([GameStatus.Cancelled, GameStatus.Final].includes(statusId))
    status = "resulted";
  return status;
};

export const getCurrentEvent = (game) => {
  let currentEvent = game.GameEvents.find(
    (ge) =>
      ge.Event.EventStatusId === EventStatus.Open ||
      ge.Event.EventStatusId === EventStatus.Closed ||
      ge.Event.EventStatusId === EventStatus.AwaitingApproval
  );
  if (currentEvent === undefined) {
    if (game.GameStatusId === GameStatus.Presale) {
      currentEvent = game.GameEvents.length > 0 ? game.GameEvents[0] : null;
    }
    if (
      game.GameStatusId === GameStatus.Open ||
      game.GameStatusId === GameStatus.Suspended ||
      game.GameStatusId === GameStatus.Paused
    ) {
      currentEvent = game.GameEvents[game.GameEvents.length - 1];
    }
  }
  return currentEvent;
};

export const calculateSwopFeeForABracket = (
  curTicketPrice,
  gameRake,
  swopFee,
  ticketsCount,
  bracketProbability,
  bracketValue
) => {
  if (swopFee === 0) {
    return 0;
  }

  const tp = curTicketPrice;
  const gr = gameRake;
  const sf = swopFee;
  const nBt = ticketsCount;
  const cBt = bracketProbability;
  const vBt = bracketValue;
  // console.log("current ticket price", tp, "game rake", gr, "swop fee", sf, "tickets count", nBt, "bracket probability", cBt, "bracket value next", vBt);
  const gameAvgTicketVal = tp * (1 - gr);
  const rakeIfSold = nBt * tp * gr;
  const avgRakeAfterSwop = rakeIfSold * cBt;
  const avgRakeLoss = rakeIfSold - avgRakeAfterSwop;
  const premiumForRakeLoss = avgRakeLoss * sf;
  const potentialValueLoss = nBt * gameAvgTicketVal - vBt;
  const premiumForValueLoss =
    potentialValueLoss > 0 ? potentialValueLoss * (1 - cBt) * (1 + sf) : 0;

  return avgRakeLoss + premiumForRakeLoss + premiumForValueLoss;
};

export const useDebounce = ({ value, delay = 500 }) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
};

export const numFormatWithMin = (num, useMin = true) => {
  if (num === undefined || Number.isNaN(num)) return "-";

  if (num.toFixed(2) === "0.00" && useMin) return "0.01";

  return num.toFixed(2);
};

export const valueWithFPOrCurrency = ({ game, value }) => {
  // Dont Show $ for FreeToPlay games
  // TODO: look into returning FP icon
  let retValue = game?.FreeToPlay ? `FP ${value}` : `$${value}`;
  return retValue;
};

export const valueWithCurrency = ({ game, value }) => {
  // Dont Show $ for FreeToPlay games
  let retValue = game?.FreeToPlay ? `${value}` : `$${value}`;
  return retValue;
};

export const displayTicketValueByGameType = (
  f2p,
  ticketValue,
  useMin = true,
  showLongForm = false
) => {
  let retValue;
  let numWithMin;
  if (f2p) {
    numWithMin = numFormatWithMin(ticketValue, useMin);
    retValue = showLongForm ? longFormNum(numWithMin) : numWithMin;
    return retValue;
  }
  numWithMin = numFormatWithMin(ticketValue, useMin);
  retValue = showLongForm ? `$${longFormNum(numWithMin)}` : `$${numWithMin}`;
  return retValue;
};

export const checkIfIframe = () => {
  const isInIframe = window.location !== window.parent.location;
  // console.log("checkIfIframe", {
  //   wl: window.location,
  //   wpl: window.parent.location,
  //   same: window.location === window.parent.location,
  // });
  return isInIframe;
};

export const getHostUrl = () => {
  let parentUrl = window.location.hostname;
  if (checkIfIframe()) {
    parentUrl = document.referrer;
  }
  if (
    parentUrl.startsWith("http://localhost:") ||
    parentUrl.startsWith("https://localhost:")
  ) {
    parentUrl = "localhost";
  }
  // console.log("HostUrl", parentUrl, document.referrer);
  return parentUrl;
};

// Cleanse host url to fetch correct theme.json file
export const cleanseHostUrl = (hUrl = "") => {
  if (!hUrl) return "";
  const result = hUrl
    .replace("http://", "")
    .replace("https://", "")
    .replace("/", "")
    .replace(":", "");
  return result;
};

export const cleanUrlForGetReq = (url) => {
  if (!url) return "";
  return encodeURIComponent(url);
};

export const getWinningTicketCountDisplay = (game) => {
  if (game.GameStatusId === GameStatus.Cancelled) {
    return 0;
  }

  let countDisplay = 1;
  let settlementCount = 0;
  for (let i = 0; i < game.GameEvents.length; i += 1) {
    if (
      game.GameEvents[i].Event.EventStatusId === EventStatus.Settled ||
      game.GameEvents[i].Event.EventStatusId === EventStatus.Abandoned
    ) {
      settlementCount += 1;
      let winningOutcomeCount = 0;
      for (let j = 0; j < game.GameEvents[i].Brackets.length; j += 1) {
        const bracket = game.GameEvents[i].Brackets[j];
        if (bracket.BracketId !== -1) {
          let isWinningBracket = false;
          for (let x = 0; x < bracket.OutcomeIds.length; x += 1) {
            const outcome = game.GameEvents[i].Event.Outcomes.find(
              (o) => o.OutcomeId === bracket.OutcomeIds[x]
            );
            if (outcome.OutcomeResultId === OutcomeResult.Won) {
              isWinningBracket = true;
              break;
            }
          }

          if (isWinningBracket) {
            winningOutcomeCount += 1;
          }
        }
      }
      countDisplay *= winningOutcomeCount;
    }
  }

  if (settlementCount === 0) {
    return 1;
  }
  return numWithComma(`${countDisplay}`);
};

export const filterScratchedBracket = (bracket) => {
  if (!bracket) return false;
  return bracket.BracketId > 0;
};

export function debounce(func, delay) {
  let timeoutId;

  return function (...args) {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}

export const getOutcomesForBracket = (event, bracket) => {
  return bracket.OutcomeIds.map((outcomeId) =>
    event.Event.Outcomes.find((o) => o.OutcomeId === outcomeId)
  );
};

export const isBracketScratched = (event, bracket) => {
  const outcomes = getOutcomesForBracket(event, bracket);
  return outcomes.every((outcome) => isOutcomeScratched(outcome));
};

export const getEarlyKOBracket = (game, ticket) => {
  let earlyKOBrackets = ticket.map((bracketId, eventIndex) =>
    game.GameEvents[eventIndex].Brackets.find((b) => b.BracketId === bracketId)
  );
  earlyKOBrackets = earlyKOBrackets.filter((b) =>
    isBracketScratched(
      game.GameEvents.find((e) => e.EventId === b.EventId),
      b
    )
  );
  earlyKOBrackets = earlyKOBrackets.sort(
    (b1, b2) =>
      new Date(b1.EarlyKnockOutTime).getTime() -
      new Date(b2.EarlyKnockOutTime).getTime()
  );
  if (earlyKOBrackets.length) {
    return earlyKOBrackets[0];
  }
  return null;
};

export const getSettlementKOEventIndex = (game, ticket) => {
  for (let eventIndex = 0; eventIndex < ticket.length; eventIndex += 1) {
    const gameEvent = game.GameEvents.find(
      (ge) => ge.EventIndex === eventIndex
    );
    switch (gameEvent.Event.EventStatusId) {
      case EventStatus.Open:
        return -1; // first open event
      case EventStatus.Abandoned:
      case EventStatus.Settled:
        {
          const bracket = gameEvent.Brackets.find(
            (b) => b.BracketId === ticket[eventIndex]
          );
          const outcomes = bracket.OutcomeIds.map((outcomeId) =>
            gameEvent.Event.Outcomes.find((o) => o.OutcomeId === outcomeId)
          );
          // if (
          //   // R.any <
          //   // Outcome >
          //   // ((o) => o.OutcomeResultId === OutcomeResult.Won)(outcomes)
          //   true
          // ) {
          // } else
          if (
            outcomes.find((o) => o.OutcomeResultId === OutcomeResult.Won) ===
            undefined
          ) {
            return eventIndex;
          }
        }
        break;
      default:
        break;
    }
  }
  return -1;
};

export const knockedOutEventIndex = (game, ticket) => {
  const earlyKOBracket = getEarlyKOBracket(game, ticket);
  const settlementKOeventIndex = getSettlementKOEventIndex(game, ticket);
  if (earlyKOBracket != null) {
    const earlyKOeventIndex = game.GameEvents.find(
      (ge) => ge.EventId === earlyKOBracket.EventId
    ).EventIndex;
    const earlyKOatEventIndex =
      earlyKOBracket.EarlyKnockOutEventIndex === undefined
        ? earlyKOeventIndex
        : earlyKOBracket.EarlyKnockOutEventIndex;
    // this is for the situation when a bracket is scratched after a settlement ko has already occured for the ticket.
    if (
      settlementKOeventIndex > -1 &&
      settlementKOeventIndex < earlyKOatEventIndex
    )
      return settlementKOeventIndex;
    return earlyKOeventIndex;
  }
  return settlementKOeventIndex;
};

export const getOrderDialogTypeFromOrderTypeId = (orderTypeId) => {
  switch (orderTypeId) {
    case OrderType.AdminRewardFromPool:
    case OrderType.PurchaseFromPool:
    case OrderType.AdminRewardPrepurchaseFromPool:
    case OrderType.PlayerPrepurchaseFromPool:
    case OrderType.JackpotPrepurchaseFromPool:
    case OrderType.SubscriptionPrepurchaseFromPool:
    case OrderType.BankPrepurchaseFromPool:
    case OrderType.PurchaseFromBank:
      return OrderDialogType.BUY;
    case OrderType.CashoutToBank:
      return OrderDialogType.CASHOUT;
    case OrderType.SwapWithPool:
      return OrderDialogType.SWOP;
    case OrderType.SwapBracketWithPool:
      return OrderDialogType.SUPERSWOP;
    default:
      return null;
  }
};

export const displayAmount = ({
  val,
  isFtp = true,
  shortForm = true,
  showExact = false,
}) => {
  const amount = Math.abs(val);
  let result = "";
  if (!amount || Number.isNaN(amount)) {
    result = "-";
  }
  if (showExact) {
    result = amount.toFixed(2);
    result = result.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else if (shortForm) {
    if (amount < 100) {
      result = amount.toFixed(2);
    } else if (amount < 1000) {
      result = amount.toFixed(0);
    } else if (amount < 1000000) {
      result = (amount / 1000).toFixed(2) + "K";
    } else {
      result = (amount / 1000000).toFixed(3) + "M";
    }
    result = result.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    if (amount < 100) {
      result = amount.toFixed(2);
    } else {
      result = amount.toFixed(0);
    }
    result = result.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  if (!isFtp) {
    result = "$" + result;
  }
  if (val < 0) {
    result = "-" + result;
  }
  return result;
};

export const marketDiscountNumber = (num) => {
  return (num * 100).toFixed(0);
};

export const getGameType = ({ TemplateCashoutToBankEnabled, SwopEnabled }) => {
  //     Three game-type chips to be used
  // Sweepstake - when a game does not have Swops or cashout available
  // EasyPlay - when a game has Swops only enabled
  // Challenge - when a game has both Swops and Fold enabled
  if (!TemplateCashoutToBankEnabled && !SwopEnabled) return "Sweepstake";
  else if (!TemplateCashoutToBankEnabled && SwopEnabled) return "EasyPlay";
  else if (TemplateCashoutToBankEnabled && SwopEnabled) return "Challenge";
};

export default null;
