import React from "react";
import { Button, Typography, Box } from "@mui/material";
import {
  AddShoppingCart,
  Done,
  Close,
  MoveToInbox,
  Autorenew,
} from "@mui/icons-material";
import { ReactComponent as LoaderIcon } from "../graphics/loader.svg";

function ConfirmPurchaseButton({
  state,
  buttonText,
  onConfirm,
  iconType,
  onClose,
}) {
  let content;
  let StartIcon;

  switch (iconType) {
    case "cart":
      StartIcon = <AddShoppingCart />;
      break;
    case "inbox":
      StartIcon = <MoveToInbox />;
      break;
    case "swop":
      StartIcon = <Autorenew />;
      break;
    default:
      StartIcon = null;
  }

  switch (state) {
    case "processing":
      content = (
        <>
          <Box
            sx={{
              color: "white",
              height: "24px",
              width: "24px",
              "@keyframes spin": {
                "0%": {
                  transform: "rotate(0deg)",
                },
                "100%": {
                  transform: "rotate(-360deg)",
                },
              },
              animation: "spin 2s cubic-bezier(0.4, 0, 0.3, 1) infinite",
            }}
          >
            <LoaderIcon />
          </Box>
          <Typography
            component="p"
            sx={{
              color: "#fff",
              lineHeight: "1",
              fontWeight: "500",
              textTransform: "none",
              marginLeft: "8px",
            }}
          >
            {" "}
            Processing{" "}
          </Typography>
        </>
      );
      break;
    case "fail":
      content = (
        <>
          <Close />
          <Typography
            component="p"
            sx={{
              color: "#fff",
              lineHeight: "1",
              fontWeight: "500",
              textTransform: "none",
              marginLeft: "8px",
            }}
          >
            {" "}
            Close{" "}
          </Typography>
        </>
      );
      break;
    case "success":
      content = (
        <>
          <Done />
          <Typography
            component="p"
            sx={{
              color: "#fff",
              lineHeight: "1",
              fontWeight: "500",
              textTransform: "none",
              marginLeft: "8px",
            }}
          >
            {" "}
            Done{" "}
          </Typography>
        </>
      );
      break;
    default:
      content = <span>{buttonText}</span>;
  }

  return (
    <Button
      variant="contained"
      startIcon={!state && StartIcon}
      onClick={state === "default" ? onConfirm : onClose}
      sx={{ color: "#ffffff" }}
    >
      {content}
    </Button>
  );
}

export default ConfirmPurchaseButton;
