import {
  Configuration,
  AuthApi,
  AvatarApi,
  ContentCardApi,
  EmailtestApi,
  GameApi,
  PlayerApi,
  SystemStatusApi,
  ConfigApi,
} from "./swop-api";
import { getChannelID, getToken } from "./services/ls";

const middlewareInjectChannelId = async (ctx) => {
  if (ctx && ctx.init && ctx.init.body !== undefined) {
    const currentBody = JSON.parse(ctx.init.body);
    currentBody.Channel = getChannelID();
    ctx.init.body = JSON.stringify(currentBody);
  } else {
    const originalUrl = ctx.url;
    const separator = originalUrl.indexOf("?") !== -1 ? "&" : "?";
    ctx.url = `${originalUrl + separator}channel=${getChannelID()}`;
    // console.log("ctx", ctx);
  }
  return ctx;
};

const getApiConfig = () =>
  new Configuration({
    basePath: process.env.REACT_APP_API_BASE,
    // basePath: "http://localhost:5001",
    headers: {
      "x-ss-id": getToken(),
    },
  });

class SwopstakesWebApi {
  constructor() {
    this.setupApis();
  }

  setupApis() {
    this.apiConfig = getApiConfig();
    this.token = getToken();
    // console.log({ apiConfig: this.apiConfig });
    this.authApi = new AuthApi(this.apiConfig).withPreMiddleware(
      middlewareInjectChannelId
    );
    this.avatarApi = new AvatarApi(this.apiConfig).withPreMiddleware(
      middlewareInjectChannelId
    );
    this.contentCardApi = new ContentCardApi(this.apiConfig).withPreMiddleware(
      middlewareInjectChannelId
    );
    this.emailTestApi = new EmailtestApi(this.apiConfig).withPreMiddleware(
      middlewareInjectChannelId
    );
    this.gameApi = new GameApi(this.apiConfig).withPreMiddleware(
      middlewareInjectChannelId
    );
    this.playerApi = new PlayerApi(this.apiConfig).withPreMiddleware(
      middlewareInjectChannelId
    );
    this.systemStatusApi = new SystemStatusApi(
      this.apiConfig
    ).withPreMiddleware(middlewareInjectChannelId);
    this.configApi = new ConfigApi(this.apiConfig);
  }

  tokenUpdated() {
    this.setupApis();
  }

  hasToken() {
    const { token } = this;
    if (token !== null && token !== undefined && token !== "undefined") {
      return true;
    }
    return false;
  }
}

const swopApi = new SwopstakesWebApi();

export default swopApi;
