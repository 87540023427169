import { Box, Divider, Typography } from "@mui/material";
import { React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  buyTicketFromPool,
  buyTicketFromBank,
  cashoutTickets,
  swopTickets,
  getPendingOrders,
  updatePendingOrders,
} from "../../redux/slices/ticketOrder.slice";
import {
  updateConfirmationDialog,
  updatePendingDialog,
  updateFullTicketViewer,
} from "../../redux/slices/dialog.slice";
// import { ReactComponent as TicketIcon } from "../graphics/ticket/ticket.svg";
import TermsOfService from "../TermsOfService";
// import TicketListItem from "./TicketListItem";
import { OrderDialogType } from "../../utils/types";
import { getPlayerSummary } from "../../redux/slices/user.slice";
import ConfirmPurchaseButton from "./ConfirmPurchaseButton";
import ConfirmPurchaseMessage from "./ConfirmPurchaseMessage";
import { ReactComponent as TicketIcon } from "../graphics/ticket/ticket.svg";
import { ReactComponent as FpCreditIcon } from "../graphics/fpCreditIcon.svg";
import TicketListItem from "./TicketListItem";
import { longFormNum } from "@/utils/common-functions";
import swopApi from "../../api";
import {
  getPlayerTicketSummary,
  getTicketInfo,
  getTicketBracketSummary,
  updateBuyDialog,
} from "../../redux/slices/game.slice";
import { IconButton } from "@mui/material";
import { Clear } from "@mui/icons-material";

function ConfirmPurchase() {
  const dispatch = useDispatch();
  const { detail: gameDetail, selectedEvent } = useSelector(
    (state) => state.game.game
  );
  const { response: playerDetail } = useSelector(
    (state) => state.user.playerSummary
  );
  const {
    show,
    processing,
    orderType,
    options,
    error,
    checkTicketDetail,
    gameName,
    close,
  } = useSelector((state) => state.dialog.confirmationDialog);

  const [purchaseTimeout, setPurchaseTimeout] = useState(null);
  const [purchaseState, setPurchaseState] = useState("default");

  useEffect(() => {
    // Cleanup function to cancel the timeout and state when the component is not show

    if (!show) {
      setPurchaseState("default");
      if (purchaseTimeout) {
        clearTimeout(purchaseTimeout);
        setPurchaseTimeout(null);
      }
    } else if (error && (error.message || error.errorNo)) {
      setPurchaseState("fail");
      if (purchaseTimeout) {
        clearTimeout(purchaseTimeout);
        setPurchaseTimeout(null);
      }
    }
  }, [show, error]);

  const handleBuyFromPool = () => {
    dispatch(
      buyTicketFromPool({
        expectedPrice: options.expectedPrice,
        gameId: gameDetail.GameId,
        quantity: options.quantity,
      })
    ).then(() => {
      dispatch(getPlayerSummary());
    });
  };

  if (!show) return <div />;

  console.log("Confirm Purchase", { processing, orderType, options, error });

  const handleBuyFromBank = () => {
    dispatch(
      buyTicketFromBank({
        expectedPrice: options.expectedPrice,
        gameId: gameDetail.GameId,
        quantity: options.quantity,
        requestedTickets: options.requestedTickets,
      })
    );
  };

  const handleCashout = () => {
    dispatch(
      cashoutTickets({
        expectedPrice: options.expectedPrice,
        gameId: gameDetail.GameId,
        quantity: options.quantity,
        requestedTickets: options.requestedTickets,
      })
    );
  };

  const handleSwop = () => {
    dispatch(
      swopTickets({
        expectedPrice: options.expectedPrice,
        gameId: gameDetail.GameId,
        quantity: options.quantity,
        eventId: options.eventId,
        bracketId: options.bracketId,
      })
    );
  };
  const dismissDialogAndRefreshPage = () => {
    dispatch(
      updatePendingDialog({
        show: false,
      })
    );
    dispatch(updateConfirmationDialog({ show: false, processing: false }));
    dispatch(getPlayerSummary());

    if (gameDetail && gameDetail.GameId) {
      dispatch(getPlayerTicketSummary({ gameId: gameDetail.GameId }));
      dispatch(getTicketInfo({ gameId: gameDetail.GameId }));
      if (playerDetail) {
        dispatch(
          getTicketBracketSummary({
            gameId: gameDetail.GameId,
            eventIndex: selectedEvent.EventIndex,
          })
        );
      }
    }
  };

  const confirmAction = () => {
    // close other drawers
    if (close) {
      close();
    }
    dispatch(updateConfirmationDialog({ processing: true, error: {} }));
    const timeout = setTimeout(() => {
      dispatch(updateConfirmationDialog({ processing: false }));
      setPurchaseState("processing");
      dispatch(getPendingOrders());
    }, 5000);
    setPurchaseTimeout(timeout);
    switch (orderType) {
      case OrderDialogType.BUY:
        if (options && options.fromPlayerId === 1) {
          handleBuyFromBank();
        } else {
          handleBuyFromPool();
        }
        break;
      case OrderDialogType.CASHOUT:
      case OrderDialogType.OUTCOMECASHOUT:
        handleCashout();
        break;
      case OrderDialogType.SWOP:
      case OrderDialogType.SUPERSWOP:
        handleSwop();
        break;

      default:
    }
  };

  const onClose = () => {
    dispatch(updateFullTicketViewer({ show: false }));
    if (!processing)
      dispatch(updateConfirmationDialog({ show: false, processing: false }));
    dispatch(updateBuyDialog(false));
  };

  const getText = () => {
    switch (orderType) {
      case OrderDialogType.BUY:
        return { header: "Confirm Ticket Purchase", buttonText: "Buy" };
      case OrderDialogType.CASHOUT:
        return {
          header: "Confirm Fold",
          buttonText: "Fold",
          hitText:
            "Are you sure you want to fold your tickets on this outcome?",
        };
      case OrderDialogType.SWOP:
        return {
          header: "Confirm Ticket Swop",
          buttonText: "Swop All",
          hitText:
            "Are you sure you want to swop all of your tickets in this game?",
        };
      case OrderDialogType.SUPERSWOP:
        return {
          header: "Confirm Ticket SuperSwop",
          buttonText: "Swop All",
          hitText:
            "Are you sure you want to Swop all of your tickets for this bracket?",
        };
      case OrderDialogType.OUTCOMECASHOUT:
        return {
          header: "Confirm Outcome Fold",
          buttonText: "Fold",
          hitText:
            "Are you sure you want to fold your tickets on this outcome?",
        };
      default:
        return {};
    }
  };

  const errorInTicketSection = (errorNo) => {
    if (!errorNo) return false;

    return [56201, 56202, 56203, 54009].some((e) => e === errorNo);
  };

  const { header, buttonText, hitText } = getText();

  const onCloseButton = () => {
    if (processing) return;
    if (purchaseState === "default") {
      dispatch(updateConfirmationDialog({ show: false, processing: false }));
    } else {
      onClose();
    }
  };

  return (
    <Box sx={{ bgcolor: "background.dark", color: "text.contrastText", py: 2 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="subtitle1" component="p" sx={{ px: 2 }}>
          {header}
        </Typography>
        <IconButton onClick={onCloseButton}>
          <Clear
            sx={{
              color: "text.contrastText",
            }}
          />
        </IconButton>
      </Box>
      <Divider light sx={{ my: 1 }} />
      <Typography component="p" variant="subtitle2" sx={{ px: 2 }}>
        {gameName}
      </Typography>
      <Divider light sx={{ my: 1 }} />
      <Box sx={{ mx: 2 }}>
        <Typography
          variant="body2"
          component="p"
          gutterBottom
          sx={{ opacity: 0.5 }}
        >
          {hitText}
        </Typography>
        <TicketListItem
          icon={<TicketIcon />}
          title={options.quantity > 1 ? "Tickets" : "Ticket"}
          value={options.quantity}
          // error={
          //   error && error.message && errorInTicketSection(error.errorNo)
          //     ? error.message
          //     : null
          //   // "Insufficient funds to purchase"
          // }
        />
        <TicketListItem
          icon={<FpCreditIcon width={16} height={16} />}
          title={
            orderType === OrderDialogType.CASHOUT ||
            orderType === OrderDialogType.OUTCOMECASHOUT
              ? "Total Offer"
              : "Total Cost"
          }
          value={
            error && error.errorNo
              ? "0.00"
              : longFormNum(options.expectedPrice.toFixed(2))
          }
          // error={
          //   error && error.message && !errorInTicketSection(error.errorNo)
          //     ? error.message
          //     : null
          // }
        />
      </Box>
      <Divider light sx={{ my: 2 }} />
      {/* This message has 4 states: default, processing, fail and success */}

      <ConfirmPurchaseMessage
        state={processing ? "default" : purchaseState}
        hitText={hitText}
        options={options}
        orderType={orderType}
        error={error}
        errorInTicketSection={errorInTicketSection}
        checkTicketDetail={checkTicketDetail}
      />

      <Box sx={{ textAlign: "center", px: 2 }}>
        {/* <Button
            variant="contained"
            startIcon={!processing && <AddShoppingCart />}
            onClick={confirmAction}
          >
            {!processing ? (
              <CircularProgress
                variant="indeterminate"
                size={24}
                sx={{ color: "white" }}
              />
            ) : (
              buttonText
            )}
          </Button> */}

        {/* This button has 4 states: default, processing, fail and success */}

        <ConfirmPurchaseButton
          state={processing ? "processing" : purchaseState}
          // state="default"
          buttonText={buttonText}
          onConfirm={confirmAction}
          onClose={processing ? null : onClose}
          iconType="cart"
        />

        {/* {orderType !== OrderDialogType.CASHOUT &&
          orderType !== OrderDialogType.OUTCOMECASHOUT && <TermsOfService />} */}
      </Box>
    </Box>
  );
}

export default ConfirmPurchase;
