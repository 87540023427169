import { Box } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`best-ticket-${index}`}
      aria-labelledby={`best-ticket-${index}`}
      {...other}
      style={{
        background: "white",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        flexGrow: value === index ? 1 : 0,
        borderBottomLeftRadius: "4px",
        borderBottomRightRadius: "4px",
      }}
    >
      {value === index && (
        <Box
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            height: "100%",
            flexGrow: "1",
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

export default TabPanel;
