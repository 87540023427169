export default function Error500() {
  return (
    <div>
      <h1>Error occured</h1>
      <p>Something unexpected happen.</p>
      <p>
        Please <a href="/">reload</a>
      </p>
    </div>
  );
}
