/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { AssignRolesToJSON, AssignRolesResponseFromJSON, AuthenticateToJSON, AuthenticateResponseFromJSON, RegisterToJSON, RegisterResponseFromJSON, UnAssignRolesToJSON, UnAssignRolesResponseFromJSON, } from '../models';
/**
 *
 */
export class AuthApi extends runtime.BaseAPI {
    /**
     */
    async assignRolesPostRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling assignRolesPost.');
        }
        const queryParameters = {};
        if (requestParameters.userName !== undefined) {
            queryParameters['UserName'] = requestParameters.userName;
        }
        if (requestParameters.permissions !== undefined) {
            queryParameters['Permissions'] = requestParameters.permissions;
        }
        if (requestParameters.roles !== undefined) {
            queryParameters['Roles'] = requestParameters.roles;
        }
        if (requestParameters.meta !== undefined) {
            queryParameters['Meta'] = requestParameters.meta;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/assignroles`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AssignRolesToJSON(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => AssignRolesResponseFromJSON(jsonValue));
    }
    /**
     */
    async assignRolesPost(requestParameters, initOverrides) {
        const response = await this.assignRolesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Sign In
     * Sign In
     */
    async authenticateRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling authenticate.');
        }
        const queryParameters = {};
        if (requestParameters.provider !== undefined) {
            queryParameters['provider'] = requestParameters.provider;
        }
        if (requestParameters.state !== undefined) {
            queryParameters['State'] = requestParameters.state;
        }
        if (requestParameters.oauthToken !== undefined) {
            queryParameters['oauth_token'] = requestParameters.oauthToken;
        }
        if (requestParameters.oauthVerifier !== undefined) {
            queryParameters['oauth_verifier'] = requestParameters.oauthVerifier;
        }
        if (requestParameters.userName !== undefined) {
            queryParameters['UserName'] = requestParameters.userName;
        }
        if (requestParameters.password !== undefined) {
            queryParameters['Password'] = requestParameters.password;
        }
        if (requestParameters.rememberMe !== undefined) {
            queryParameters['RememberMe'] = requestParameters.rememberMe;
        }
        if (requestParameters.errorView !== undefined) {
            queryParameters['ErrorView'] = requestParameters.errorView;
        }
        if (requestParameters.nonce !== undefined) {
            queryParameters['nonce'] = requestParameters.nonce;
        }
        if (requestParameters.uri !== undefined) {
            queryParameters['uri'] = requestParameters.uri;
        }
        if (requestParameters.response !== undefined) {
            queryParameters['response'] = requestParameters.response;
        }
        if (requestParameters.qop !== undefined) {
            queryParameters['qop'] = requestParameters.qop;
        }
        if (requestParameters.nc !== undefined) {
            queryParameters['nc'] = requestParameters.nc;
        }
        if (requestParameters.cnonce !== undefined) {
            queryParameters['cnonce'] = requestParameters.cnonce;
        }
        if (requestParameters.accessToken !== undefined) {
            queryParameters['AccessToken'] = requestParameters.accessToken;
        }
        if (requestParameters.accessTokenSecret !== undefined) {
            queryParameters['AccessTokenSecret'] = requestParameters.accessTokenSecret;
        }
        if (requestParameters.scope !== undefined) {
            queryParameters['scope'] = requestParameters.scope;
        }
        if (requestParameters.returnUrl !== undefined) {
            queryParameters['ReturnUrl'] = requestParameters.returnUrl;
        }
        if (requestParameters.meta !== undefined) {
            queryParameters['Meta'] = requestParameters.meta;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/auth`,
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
            body: AuthenticateToJSON(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => AuthenticateResponseFromJSON(jsonValue));
    }
    /**
     * Sign In
     * Sign In
     */
    async authenticate(requestParameters, initOverrides) {
        const response = await this.authenticateRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Sign In
     * Sign In
     */
    async authenticateDeleteRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling authenticateDelete.');
        }
        const queryParameters = {};
        if (requestParameters.provider !== undefined) {
            queryParameters['provider'] = requestParameters.provider;
        }
        if (requestParameters.state !== undefined) {
            queryParameters['State'] = requestParameters.state;
        }
        if (requestParameters.oauthToken !== undefined) {
            queryParameters['oauth_token'] = requestParameters.oauthToken;
        }
        if (requestParameters.oauthVerifier !== undefined) {
            queryParameters['oauth_verifier'] = requestParameters.oauthVerifier;
        }
        if (requestParameters.userName !== undefined) {
            queryParameters['UserName'] = requestParameters.userName;
        }
        if (requestParameters.password !== undefined) {
            queryParameters['Password'] = requestParameters.password;
        }
        if (requestParameters.rememberMe !== undefined) {
            queryParameters['RememberMe'] = requestParameters.rememberMe;
        }
        if (requestParameters.errorView !== undefined) {
            queryParameters['ErrorView'] = requestParameters.errorView;
        }
        if (requestParameters.nonce !== undefined) {
            queryParameters['nonce'] = requestParameters.nonce;
        }
        if (requestParameters.uri !== undefined) {
            queryParameters['uri'] = requestParameters.uri;
        }
        if (requestParameters.response !== undefined) {
            queryParameters['response'] = requestParameters.response;
        }
        if (requestParameters.qop !== undefined) {
            queryParameters['qop'] = requestParameters.qop;
        }
        if (requestParameters.nc !== undefined) {
            queryParameters['nc'] = requestParameters.nc;
        }
        if (requestParameters.cnonce !== undefined) {
            queryParameters['cnonce'] = requestParameters.cnonce;
        }
        if (requestParameters.accessToken !== undefined) {
            queryParameters['AccessToken'] = requestParameters.accessToken;
        }
        if (requestParameters.accessTokenSecret !== undefined) {
            queryParameters['AccessTokenSecret'] = requestParameters.accessTokenSecret;
        }
        if (requestParameters.scope !== undefined) {
            queryParameters['scope'] = requestParameters.scope;
        }
        if (requestParameters.returnUrl !== undefined) {
            queryParameters['ReturnUrl'] = requestParameters.returnUrl;
        }
        if (requestParameters.meta !== undefined) {
            queryParameters['Meta'] = requestParameters.meta;
        }
        const headerParameters = {};
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/auth`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => AuthenticateResponseFromJSON(jsonValue));
    }
    /**
     * Sign In
     * Sign In
     */
    async authenticateDelete(requestParameters, initOverrides) {
        const response = await this.authenticateDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Sign In
     * Sign In
     */
    async authenticateGetRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling authenticateGet.');
        }
        const queryParameters = {};
        if (requestParameters.provider !== undefined) {
            queryParameters['provider'] = requestParameters.provider;
        }
        if (requestParameters.state !== undefined) {
            queryParameters['State'] = requestParameters.state;
        }
        if (requestParameters.oauthToken !== undefined) {
            queryParameters['oauth_token'] = requestParameters.oauthToken;
        }
        if (requestParameters.oauthVerifier !== undefined) {
            queryParameters['oauth_verifier'] = requestParameters.oauthVerifier;
        }
        if (requestParameters.userName !== undefined) {
            queryParameters['UserName'] = requestParameters.userName;
        }
        if (requestParameters.password !== undefined) {
            queryParameters['Password'] = requestParameters.password;
        }
        if (requestParameters.rememberMe !== undefined) {
            queryParameters['RememberMe'] = requestParameters.rememberMe;
        }
        if (requestParameters.errorView !== undefined) {
            queryParameters['ErrorView'] = requestParameters.errorView;
        }
        if (requestParameters.nonce !== undefined) {
            queryParameters['nonce'] = requestParameters.nonce;
        }
        if (requestParameters.uri !== undefined) {
            queryParameters['uri'] = requestParameters.uri;
        }
        if (requestParameters.response !== undefined) {
            queryParameters['response'] = requestParameters.response;
        }
        if (requestParameters.qop !== undefined) {
            queryParameters['qop'] = requestParameters.qop;
        }
        if (requestParameters.nc !== undefined) {
            queryParameters['nc'] = requestParameters.nc;
        }
        if (requestParameters.cnonce !== undefined) {
            queryParameters['cnonce'] = requestParameters.cnonce;
        }
        if (requestParameters.accessToken !== undefined) {
            queryParameters['AccessToken'] = requestParameters.accessToken;
        }
        if (requestParameters.accessTokenSecret !== undefined) {
            queryParameters['AccessTokenSecret'] = requestParameters.accessTokenSecret;
        }
        if (requestParameters.scope !== undefined) {
            queryParameters['scope'] = requestParameters.scope;
        }
        if (requestParameters.returnUrl !== undefined) {
            queryParameters['ReturnUrl'] = requestParameters.returnUrl;
        }
        if (requestParameters.meta !== undefined) {
            queryParameters['Meta'] = requestParameters.meta;
        }
        const headerParameters = {};
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/auth`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => AuthenticateResponseFromJSON(jsonValue));
    }
    /**
     * Sign In
     * Sign In
     */
    async authenticateGet(requestParameters, initOverrides) {
        const response = await this.authenticateGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Sign In
     * Sign In
     */
    async authenticatePostRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling authenticatePost.');
        }
        const queryParameters = {};
        if (requestParameters.provider !== undefined) {
            queryParameters['provider'] = requestParameters.provider;
        }
        if (requestParameters.state !== undefined) {
            queryParameters['State'] = requestParameters.state;
        }
        if (requestParameters.oauthToken !== undefined) {
            queryParameters['oauth_token'] = requestParameters.oauthToken;
        }
        if (requestParameters.oauthVerifier !== undefined) {
            queryParameters['oauth_verifier'] = requestParameters.oauthVerifier;
        }
        if (requestParameters.userName !== undefined) {
            queryParameters['UserName'] = requestParameters.userName;
        }
        if (requestParameters.password !== undefined) {
            queryParameters['Password'] = requestParameters.password;
        }
        if (requestParameters.rememberMe !== undefined) {
            queryParameters['RememberMe'] = requestParameters.rememberMe;
        }
        if (requestParameters.errorView !== undefined) {
            queryParameters['ErrorView'] = requestParameters.errorView;
        }
        if (requestParameters.nonce !== undefined) {
            queryParameters['nonce'] = requestParameters.nonce;
        }
        if (requestParameters.uri !== undefined) {
            queryParameters['uri'] = requestParameters.uri;
        }
        if (requestParameters.response !== undefined) {
            queryParameters['response'] = requestParameters.response;
        }
        if (requestParameters.qop !== undefined) {
            queryParameters['qop'] = requestParameters.qop;
        }
        if (requestParameters.nc !== undefined) {
            queryParameters['nc'] = requestParameters.nc;
        }
        if (requestParameters.cnonce !== undefined) {
            queryParameters['cnonce'] = requestParameters.cnonce;
        }
        if (requestParameters.accessToken !== undefined) {
            queryParameters['AccessToken'] = requestParameters.accessToken;
        }
        if (requestParameters.accessTokenSecret !== undefined) {
            queryParameters['AccessTokenSecret'] = requestParameters.accessTokenSecret;
        }
        if (requestParameters.scope !== undefined) {
            queryParameters['scope'] = requestParameters.scope;
        }
        if (requestParameters.returnUrl !== undefined) {
            queryParameters['ReturnUrl'] = requestParameters.returnUrl;
        }
        if (requestParameters.meta !== undefined) {
            queryParameters['Meta'] = requestParameters.meta;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/auth`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthenticateToJSON(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => AuthenticateResponseFromJSON(jsonValue));
    }
    /**
     * Sign In
     * Sign In
     */
    async authenticatePost(requestParameters, initOverrides) {
        const response = await this.authenticatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Sign In
     * Sign In
     */
    async authenticateproviderRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling authenticateprovider.');
        }
        if (requestParameters.provider === null || requestParameters.provider === undefined) {
            throw new runtime.RequiredError('provider', 'Required parameter requestParameters.provider was null or undefined when calling authenticateprovider.');
        }
        const queryParameters = {};
        if (requestParameters.state !== undefined) {
            queryParameters['State'] = requestParameters.state;
        }
        if (requestParameters.oauthToken !== undefined) {
            queryParameters['oauth_token'] = requestParameters.oauthToken;
        }
        if (requestParameters.oauthVerifier !== undefined) {
            queryParameters['oauth_verifier'] = requestParameters.oauthVerifier;
        }
        if (requestParameters.userName !== undefined) {
            queryParameters['UserName'] = requestParameters.userName;
        }
        if (requestParameters.password !== undefined) {
            queryParameters['Password'] = requestParameters.password;
        }
        if (requestParameters.rememberMe !== undefined) {
            queryParameters['RememberMe'] = requestParameters.rememberMe;
        }
        if (requestParameters.errorView !== undefined) {
            queryParameters['ErrorView'] = requestParameters.errorView;
        }
        if (requestParameters.nonce !== undefined) {
            queryParameters['nonce'] = requestParameters.nonce;
        }
        if (requestParameters.uri !== undefined) {
            queryParameters['uri'] = requestParameters.uri;
        }
        if (requestParameters.response !== undefined) {
            queryParameters['response'] = requestParameters.response;
        }
        if (requestParameters.qop !== undefined) {
            queryParameters['qop'] = requestParameters.qop;
        }
        if (requestParameters.nc !== undefined) {
            queryParameters['nc'] = requestParameters.nc;
        }
        if (requestParameters.cnonce !== undefined) {
            queryParameters['cnonce'] = requestParameters.cnonce;
        }
        if (requestParameters.accessToken !== undefined) {
            queryParameters['AccessToken'] = requestParameters.accessToken;
        }
        if (requestParameters.accessTokenSecret !== undefined) {
            queryParameters['AccessTokenSecret'] = requestParameters.accessTokenSecret;
        }
        if (requestParameters.scope !== undefined) {
            queryParameters['scope'] = requestParameters.scope;
        }
        if (requestParameters.returnUrl !== undefined) {
            queryParameters['ReturnUrl'] = requestParameters.returnUrl;
        }
        if (requestParameters.meta !== undefined) {
            queryParameters['Meta'] = requestParameters.meta;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/auth/{provider}`.replace(`{${"provider"}}`, encodeURIComponent(String(requestParameters.provider))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
            body: AuthenticateToJSON(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => AuthenticateResponseFromJSON(jsonValue));
    }
    /**
     * Sign In
     * Sign In
     */
    async authenticateprovider(requestParameters, initOverrides) {
        const response = await this.authenticateproviderRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Sign In
     * Sign In
     */
    async authenticateproviderDeleteRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling authenticateproviderDelete.');
        }
        if (requestParameters.provider === null || requestParameters.provider === undefined) {
            throw new runtime.RequiredError('provider', 'Required parameter requestParameters.provider was null or undefined when calling authenticateproviderDelete.');
        }
        const queryParameters = {};
        if (requestParameters.state !== undefined) {
            queryParameters['State'] = requestParameters.state;
        }
        if (requestParameters.oauthToken !== undefined) {
            queryParameters['oauth_token'] = requestParameters.oauthToken;
        }
        if (requestParameters.oauthVerifier !== undefined) {
            queryParameters['oauth_verifier'] = requestParameters.oauthVerifier;
        }
        if (requestParameters.userName !== undefined) {
            queryParameters['UserName'] = requestParameters.userName;
        }
        if (requestParameters.password !== undefined) {
            queryParameters['Password'] = requestParameters.password;
        }
        if (requestParameters.rememberMe !== undefined) {
            queryParameters['RememberMe'] = requestParameters.rememberMe;
        }
        if (requestParameters.errorView !== undefined) {
            queryParameters['ErrorView'] = requestParameters.errorView;
        }
        if (requestParameters.nonce !== undefined) {
            queryParameters['nonce'] = requestParameters.nonce;
        }
        if (requestParameters.uri !== undefined) {
            queryParameters['uri'] = requestParameters.uri;
        }
        if (requestParameters.response !== undefined) {
            queryParameters['response'] = requestParameters.response;
        }
        if (requestParameters.qop !== undefined) {
            queryParameters['qop'] = requestParameters.qop;
        }
        if (requestParameters.nc !== undefined) {
            queryParameters['nc'] = requestParameters.nc;
        }
        if (requestParameters.cnonce !== undefined) {
            queryParameters['cnonce'] = requestParameters.cnonce;
        }
        if (requestParameters.accessToken !== undefined) {
            queryParameters['AccessToken'] = requestParameters.accessToken;
        }
        if (requestParameters.accessTokenSecret !== undefined) {
            queryParameters['AccessTokenSecret'] = requestParameters.accessTokenSecret;
        }
        if (requestParameters.scope !== undefined) {
            queryParameters['scope'] = requestParameters.scope;
        }
        if (requestParameters.returnUrl !== undefined) {
            queryParameters['ReturnUrl'] = requestParameters.returnUrl;
        }
        if (requestParameters.meta !== undefined) {
            queryParameters['Meta'] = requestParameters.meta;
        }
        const headerParameters = {};
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/auth/{provider}`.replace(`{${"provider"}}`, encodeURIComponent(String(requestParameters.provider))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => AuthenticateResponseFromJSON(jsonValue));
    }
    /**
     * Sign In
     * Sign In
     */
    async authenticateproviderDelete(requestParameters, initOverrides) {
        const response = await this.authenticateproviderDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Sign In
     * Sign In
     */
    async authenticateproviderGetRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling authenticateproviderGet.');
        }
        if (requestParameters.provider === null || requestParameters.provider === undefined) {
            throw new runtime.RequiredError('provider', 'Required parameter requestParameters.provider was null or undefined when calling authenticateproviderGet.');
        }
        const queryParameters = {};
        if (requestParameters.state !== undefined) {
            queryParameters['State'] = requestParameters.state;
        }
        if (requestParameters.oauthToken !== undefined) {
            queryParameters['oauth_token'] = requestParameters.oauthToken;
        }
        if (requestParameters.oauthVerifier !== undefined) {
            queryParameters['oauth_verifier'] = requestParameters.oauthVerifier;
        }
        if (requestParameters.userName !== undefined) {
            queryParameters['UserName'] = requestParameters.userName;
        }
        if (requestParameters.password !== undefined) {
            queryParameters['Password'] = requestParameters.password;
        }
        if (requestParameters.rememberMe !== undefined) {
            queryParameters['RememberMe'] = requestParameters.rememberMe;
        }
        if (requestParameters.errorView !== undefined) {
            queryParameters['ErrorView'] = requestParameters.errorView;
        }
        if (requestParameters.nonce !== undefined) {
            queryParameters['nonce'] = requestParameters.nonce;
        }
        if (requestParameters.uri !== undefined) {
            queryParameters['uri'] = requestParameters.uri;
        }
        if (requestParameters.response !== undefined) {
            queryParameters['response'] = requestParameters.response;
        }
        if (requestParameters.qop !== undefined) {
            queryParameters['qop'] = requestParameters.qop;
        }
        if (requestParameters.nc !== undefined) {
            queryParameters['nc'] = requestParameters.nc;
        }
        if (requestParameters.cnonce !== undefined) {
            queryParameters['cnonce'] = requestParameters.cnonce;
        }
        if (requestParameters.accessToken !== undefined) {
            queryParameters['AccessToken'] = requestParameters.accessToken;
        }
        if (requestParameters.accessTokenSecret !== undefined) {
            queryParameters['AccessTokenSecret'] = requestParameters.accessTokenSecret;
        }
        if (requestParameters.scope !== undefined) {
            queryParameters['scope'] = requestParameters.scope;
        }
        if (requestParameters.returnUrl !== undefined) {
            queryParameters['ReturnUrl'] = requestParameters.returnUrl;
        }
        if (requestParameters.meta !== undefined) {
            queryParameters['Meta'] = requestParameters.meta;
        }
        const headerParameters = {};
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/auth/{provider}`.replace(`{${"provider"}}`, encodeURIComponent(String(requestParameters.provider))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => AuthenticateResponseFromJSON(jsonValue));
    }
    /**
     * Sign In
     * Sign In
     */
    async authenticateproviderGet(requestParameters, initOverrides) {
        const response = await this.authenticateproviderGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Sign In
     * Sign In
     */
    async authenticateproviderPostRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling authenticateproviderPost.');
        }
        if (requestParameters.provider === null || requestParameters.provider === undefined) {
            throw new runtime.RequiredError('provider', 'Required parameter requestParameters.provider was null or undefined when calling authenticateproviderPost.');
        }
        const queryParameters = {};
        if (requestParameters.state !== undefined) {
            queryParameters['State'] = requestParameters.state;
        }
        if (requestParameters.oauthToken !== undefined) {
            queryParameters['oauth_token'] = requestParameters.oauthToken;
        }
        if (requestParameters.oauthVerifier !== undefined) {
            queryParameters['oauth_verifier'] = requestParameters.oauthVerifier;
        }
        if (requestParameters.userName !== undefined) {
            queryParameters['UserName'] = requestParameters.userName;
        }
        if (requestParameters.password !== undefined) {
            queryParameters['Password'] = requestParameters.password;
        }
        if (requestParameters.rememberMe !== undefined) {
            queryParameters['RememberMe'] = requestParameters.rememberMe;
        }
        if (requestParameters.errorView !== undefined) {
            queryParameters['ErrorView'] = requestParameters.errorView;
        }
        if (requestParameters.nonce !== undefined) {
            queryParameters['nonce'] = requestParameters.nonce;
        }
        if (requestParameters.uri !== undefined) {
            queryParameters['uri'] = requestParameters.uri;
        }
        if (requestParameters.response !== undefined) {
            queryParameters['response'] = requestParameters.response;
        }
        if (requestParameters.qop !== undefined) {
            queryParameters['qop'] = requestParameters.qop;
        }
        if (requestParameters.nc !== undefined) {
            queryParameters['nc'] = requestParameters.nc;
        }
        if (requestParameters.cnonce !== undefined) {
            queryParameters['cnonce'] = requestParameters.cnonce;
        }
        if (requestParameters.accessToken !== undefined) {
            queryParameters['AccessToken'] = requestParameters.accessToken;
        }
        if (requestParameters.accessTokenSecret !== undefined) {
            queryParameters['AccessTokenSecret'] = requestParameters.accessTokenSecret;
        }
        if (requestParameters.scope !== undefined) {
            queryParameters['scope'] = requestParameters.scope;
        }
        if (requestParameters.returnUrl !== undefined) {
            queryParameters['ReturnUrl'] = requestParameters.returnUrl;
        }
        if (requestParameters.meta !== undefined) {
            queryParameters['Meta'] = requestParameters.meta;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/auth/{provider}`.replace(`{${"provider"}}`, encodeURIComponent(String(requestParameters.provider))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthenticateToJSON(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => AuthenticateResponseFromJSON(jsonValue));
    }
    /**
     * Sign In
     * Sign In
     */
    async authenticateproviderPost(requestParameters, initOverrides) {
        const response = await this.authenticateproviderPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Sign Up
     * Sign Up
     */
    async registerCreateRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling registerCreate.');
        }
        const queryParameters = {};
        if (requestParameters.userName !== undefined) {
            queryParameters['UserName'] = requestParameters.userName;
        }
        if (requestParameters.firstName !== undefined) {
            queryParameters['FirstName'] = requestParameters.firstName;
        }
        if (requestParameters.lastName !== undefined) {
            queryParameters['LastName'] = requestParameters.lastName;
        }
        if (requestParameters.displayName !== undefined) {
            queryParameters['DisplayName'] = requestParameters.displayName;
        }
        if (requestParameters.email !== undefined) {
            queryParameters['Email'] = requestParameters.email;
        }
        if (requestParameters.password !== undefined) {
            queryParameters['Password'] = requestParameters.password;
        }
        if (requestParameters.confirmPassword !== undefined) {
            queryParameters['ConfirmPassword'] = requestParameters.confirmPassword;
        }
        if (requestParameters.autoLogin !== undefined) {
            queryParameters['AutoLogin'] = requestParameters.autoLogin;
        }
        if (requestParameters.errorView !== undefined) {
            queryParameters['ErrorView'] = requestParameters.errorView;
        }
        if (requestParameters.meta !== undefined) {
            queryParameters['Meta'] = requestParameters.meta;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/register`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RegisterToJSON(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => RegisterResponseFromJSON(jsonValue));
    }
    /**
     * Sign Up
     * Sign Up
     */
    async registerCreate(requestParameters, initOverrides) {
        const response = await this.registerCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Sign Up
     * Sign Up
     */
    async registerPostRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling registerPost.');
        }
        const queryParameters = {};
        if (requestParameters.userName !== undefined) {
            queryParameters['UserName'] = requestParameters.userName;
        }
        if (requestParameters.firstName !== undefined) {
            queryParameters['FirstName'] = requestParameters.firstName;
        }
        if (requestParameters.lastName !== undefined) {
            queryParameters['LastName'] = requestParameters.lastName;
        }
        if (requestParameters.displayName !== undefined) {
            queryParameters['DisplayName'] = requestParameters.displayName;
        }
        if (requestParameters.email !== undefined) {
            queryParameters['Email'] = requestParameters.email;
        }
        if (requestParameters.password !== undefined) {
            queryParameters['Password'] = requestParameters.password;
        }
        if (requestParameters.confirmPassword !== undefined) {
            queryParameters['ConfirmPassword'] = requestParameters.confirmPassword;
        }
        if (requestParameters.autoLogin !== undefined) {
            queryParameters['AutoLogin'] = requestParameters.autoLogin;
        }
        if (requestParameters.errorView !== undefined) {
            queryParameters['ErrorView'] = requestParameters.errorView;
        }
        if (requestParameters.meta !== undefined) {
            queryParameters['Meta'] = requestParameters.meta;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/register`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RegisterToJSON(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => RegisterResponseFromJSON(jsonValue));
    }
    /**
     * Sign Up
     * Sign Up
     */
    async registerPost(requestParameters, initOverrides) {
        const response = await this.registerPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async unAssignRolesPostRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling unAssignRolesPost.');
        }
        const queryParameters = {};
        if (requestParameters.userName !== undefined) {
            queryParameters['UserName'] = requestParameters.userName;
        }
        if (requestParameters.permissions !== undefined) {
            queryParameters['Permissions'] = requestParameters.permissions;
        }
        if (requestParameters.roles !== undefined) {
            queryParameters['Roles'] = requestParameters.roles;
        }
        if (requestParameters.meta !== undefined) {
            queryParameters['Meta'] = requestParameters.meta;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/unassignroles`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UnAssignRolesToJSON(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => UnAssignRolesResponseFromJSON(jsonValue));
    }
    /**
     */
    async unAssignRolesPost(requestParameters, initOverrides) {
        const response = await this.unAssignRolesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
/**
    * @export
    * @enum {string}
    */
export var AssignRolesPostAcceptEnum;
(function (AssignRolesPostAcceptEnum) {
    AssignRolesPostAcceptEnum["ApplicationJson"] = "application/json";
})(AssignRolesPostAcceptEnum || (AssignRolesPostAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var AuthenticateAcceptEnum;
(function (AuthenticateAcceptEnum) {
    AuthenticateAcceptEnum["ApplicationJson"] = "application/json";
})(AuthenticateAcceptEnum || (AuthenticateAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var AuthenticateDeleteAcceptEnum;
(function (AuthenticateDeleteAcceptEnum) {
    AuthenticateDeleteAcceptEnum["ApplicationJson"] = "application/json";
})(AuthenticateDeleteAcceptEnum || (AuthenticateDeleteAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var AuthenticateGetAcceptEnum;
(function (AuthenticateGetAcceptEnum) {
    AuthenticateGetAcceptEnum["ApplicationJson"] = "application/json";
})(AuthenticateGetAcceptEnum || (AuthenticateGetAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var AuthenticatePostAcceptEnum;
(function (AuthenticatePostAcceptEnum) {
    AuthenticatePostAcceptEnum["ApplicationJson"] = "application/json";
})(AuthenticatePostAcceptEnum || (AuthenticatePostAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var AuthenticateproviderAcceptEnum;
(function (AuthenticateproviderAcceptEnum) {
    AuthenticateproviderAcceptEnum["ApplicationJson"] = "application/json";
})(AuthenticateproviderAcceptEnum || (AuthenticateproviderAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var AuthenticateproviderDeleteAcceptEnum;
(function (AuthenticateproviderDeleteAcceptEnum) {
    AuthenticateproviderDeleteAcceptEnum["ApplicationJson"] = "application/json";
})(AuthenticateproviderDeleteAcceptEnum || (AuthenticateproviderDeleteAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var AuthenticateproviderGetAcceptEnum;
(function (AuthenticateproviderGetAcceptEnum) {
    AuthenticateproviderGetAcceptEnum["ApplicationJson"] = "application/json";
})(AuthenticateproviderGetAcceptEnum || (AuthenticateproviderGetAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var AuthenticateproviderPostAcceptEnum;
(function (AuthenticateproviderPostAcceptEnum) {
    AuthenticateproviderPostAcceptEnum["ApplicationJson"] = "application/json";
})(AuthenticateproviderPostAcceptEnum || (AuthenticateproviderPostAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var RegisterCreateAcceptEnum;
(function (RegisterCreateAcceptEnum) {
    RegisterCreateAcceptEnum["ApplicationJson"] = "application/json";
})(RegisterCreateAcceptEnum || (RegisterCreateAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var RegisterPostAcceptEnum;
(function (RegisterPostAcceptEnum) {
    RegisterPostAcceptEnum["ApplicationJson"] = "application/json";
})(RegisterPostAcceptEnum || (RegisterPostAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var UnAssignRolesPostAcceptEnum;
(function (UnAssignRolesPostAcceptEnum) {
    UnAssignRolesPostAcceptEnum["ApplicationJson"] = "application/json";
})(UnAssignRolesPostAcceptEnum || (UnAssignRolesPostAcceptEnum = {}));
