import { Box, Stack, Typography, SvgIcon, Avatar } from "@mui/material";
import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
// import { ReactComponent as TicketIcon } from "../graphics/ticket/ticket.svg";
import { ReactComponent as KnockOut } from "../graphics/market-status/knockOut.svg";
import { ReactComponent as Trophy } from "../graphics/ticket/trophy.svg";
import { ReactComponent as Medal } from "../graphics/ticket/medal.svg";
import { ReactComponent as Bank } from "../graphics/bank.svg";
import { ReactComponent as Starburst } from "../graphics/starburst.svg";
import CircularSurvivalMetre from "./CircularSurvivalMetre";
import {
  getCurrentEvent,
  parseTicketId,
  displayTicketValueByGameType,
} from "../../utils/common-functions";
import { GameStatus, PrizeType, EventStatus } from "../../utils/types";
// import TicketHistory from "./TicketHistory";
import {
  getAvatarUrl,
  marketDiscountNumber,
} from "../../utils/common-functions";
import HistoryTab from "./HistoryTab";
import OutcomesTab from "./OutcomesTab";
// import OutcomesTabV1 from "./OutcomesTabV1";

function a11yProps(index) {
  return {
    id: `best-ticket-${index}`,
    "aria-controls": `best-ticket-${index}`,
  };
}

function Flex({ children, sx }) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={sx}
    >
      {children}
    </Stack>
  );
}
Flex.propTypes = {
  children: PropTypes.node.isRequired,
  sx: PropTypes.shape({}).isRequired,
};

function FullTicketItem({ ticketNum }) {
  const [value, setValue] = React.useState(0);
  const { detail: ticketDetail } = useSelector(
    (state) => state.ticket.ticketDetails
  );
  const { detail: gameDetail } = useSelector((state) => state.game.game);

  const { response: playerDetail } = useSelector(
    (state) => state.user.playerSummary
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  console.log(ticketDetail);

  /* const { game } = useSelector((state) => state.game);
  console.log(game);
  const { response: ticketData } = useSelector((state) => state.ticket);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getTicketDetailsInfo({
        ticketId: ticketNum,
        gameID: game.detail.GameId,
      })
    );
  }, []); */

  if (ticketDetail.ticketId !== ticketNum) return <div>Loading...</div>;

  const currentEvent = getCurrentEvent(gameDetail);
  const ticketIds = parseTicketId(ticketDetail.ticketId);

  const playerPrize =
    gameDetail.PlayerPrizes &&
    gameDetail.PlayerPrizes.find((pr) => pr.TicketId === ticketDetail.ticketId);

  let ticketBracket;
  if (currentEvent) {
    const curBracketId = ticketIds[currentEvent.EventIndex];
    ticketBracket = currentEvent.Brackets.find(
      (b) => b.BracketId === curBracketId
    );
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "calc(100vh - 147px)",
      }}
    >
      {ticketDetail.owner && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            background: "#fff",
            borderRadius: "4px 4px 0 0",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
              py: "8px",
              width: "100%",
              position: "relative",
            }}
          >
            {/* This should either display player avatar or bank - depending on who this is owned by */}
            {ticketDetail.owner.playerId !== 1 ? (
              <Avatar
                src={getAvatarUrl(ticketDetail.owner.avatar)}
                alt={ticketDetail.owner.username}
                sx={{
                  border: "2px solid rgb(36, 167, 221)",
                  height: "36px",
                  width: "36px",
                  marginRight: "8px",
                }}
              />
            ) : (
              <Box
                sx={{
                  border: "2px solid #19292F",
                  height: "36px",
                  width: "36px",
                  marginRight: "8px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "100px",
                }}
              >
                <SvgIcon
                  component={Bank}
                  viewBox="0 0 64 64"
                  sx={{ width: "20px", height: "20px" }}
                />
              </Box>
            )}
            {/* This should also either display player name or just the word "bank" - depending on who this is owned by */}
            <Typography
              variant="subtitle2"
              component="p"
              lineHeight="1"
              fontWeight="700"
              fontSize="1rem"
              sx={{
                color: "#19292F",
              }}
            >
              {ticketDetail.owner.username}
            </Typography>

            {/* This is the component that renders out the discount starburst */}
            {ticketDetail.owner.playerId == 1 &&
            currentEvent &&
            currentEvent.Event.EventStatusId == EventStatus.Open &&
            currentEvent.PurchaseFromBankDiscount > 0 &&
            !ticketDetail.isKnockedOut ? (
              <Box
                sx={{
                  position: "absolute",
                  right: "12px",
                  top: "18px",
                  width: "52px",
                  height: "52px",
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "52px",
                  }}
                >
                  <SvgIcon
                    component={Starburst}
                    viewBox="0 0 44 44"
                    sx={{ width: "52px", height: "52px", position: "absolute" }}
                  />
                  <Typography
                    variant="subtitle2"
                    component="p"
                    lineHeight="1"
                    fontWeight="700"
                    fontSize="0.875rem"
                    sx={{
                      color: "white",
                      zIndex: "2",
                      maxWidth: "36px",
                      textAlign: "center",
                      // top: "4px",
                      // left: "4px",
                    }}
                  >
                    {marketDiscountNumber(
                      currentEvent.PurchaseFromBankDiscount
                    )}
                    % Off
                  </Typography>
                </Box>
              </Box>
            ) : null}
          </Box>
        </Box>
      )}
      {gameDetail.GameStatusId !== GameStatus.Final && (
        <Flex
          sx={{
            px: 2,
            pt: 2,
            pb: 1,
            width: "100%",
            background: "#fff",
            borderTop: "1px solid rgba(35, 40, 85, 0.12)",
          }}
        >
          <Box>
            <Typography
              color="text.primary"
              variant="h4"
              component="h4"
              fontWeight="600"
            >
              {ticketDetail.isKnockedOut
                ? displayTicketValueByGameType(
                    gameDetail.FreeToPlay,
                    0,
                    false,
                    true
                  )
                : "Now " +
                  displayTicketValueByGameType(
                    gameDetail.FreeToPlay,
                    ticketDetail.value,
                    true,
                    true
                  )}
            </Typography>
            <Typography variant="body2" component="p" color="text.secondary">
              {ticketDetail.isKnockedOut ? (
                <>
                  Last Round:{" "}
                  <Typography
                    variant="subtitle2"
                    component="span"
                    color="text.primary"
                  >
                    {displayTicketValueByGameType(
                      gameDetail.FreeToPlay,
                      ticketDetail.value,
                      true,
                      true
                    )}
                  </Typography>
                </>
              ) : (
                <>
                  Next Round forecast:{" "}
                  <Typography
                    variant="subtitle2"
                    component="span"
                    color="text.primary"
                  >
                    {displayTicketValueByGameType(
                      gameDetail.FreeToPlay,
                      ticketDetail.valueNext,
                      true,
                      true
                    )}
                  </Typography>
                </>
              )}
            </Typography>
          </Box>
          {!ticketDetail.isKnockedOut && ticketBracket && (
            <CircularSurvivalMetre
              value={ticketBracket.Probability * 100}
              size={50}
              fontSize={18}
              sx={{}}
            />
          )}
          {ticketDetail.isKnockedOut && (
            <SvgIcon
              component={KnockOut}
              viewBox="0 0 148 148"
              sx={{ width: "52px", height: "52px" }}
            />
          )}
        </Flex>
      )}
      {/* F2P Grand Prize*/}
      {gameDetail.GameStatusId === GameStatus.Final &&
        gameDetail.FreeToPlay &&
        playerPrize &&
        playerPrize.IsWinningTicket &&
        playerPrize.PrizeTypeId == PrizeType.MAJOR && (
          <Flex
            sx={{
              px: 2,
              pt: 2,
              pb: 1,
              width: "100%",
              background: "#fff",
              borderTop: "1px solid rgba(35, 40, 85, 0.12)",
            }}
          >
            <Box>
              <Typography
                color="text.primary"
                variant="h4"
                component="h4"
                fontWeight="600"
              >
                Won {gameDetail.MajorPrizeDescription}
              </Typography>
            </Box>

            {
              <SvgIcon
                component={Trophy}
                viewBox="0 0 24 24"
                sx={{ width: "52px", height: "52px" }}
              />
            }
          </Flex>
        )}
      {/* F2P consolation Prize*/}
      {gameDetail.GameStatusId === GameStatus.Final &&
        gameDetail.FreeToPlay &&
        playerPrize &&
        ((playerPrize.IsWinningTicket &&
          playerPrize.PrizeTypeId == PrizeType.CONSOLATION) ||
          !playerPrize.IsWinningTicket) && (
          <Flex
            sx={{
              px: 2,
              pt: 2,
              pb: 1,
              width: "100%",
              background: "#fff",
              borderTop: "1px solid rgba(35, 40, 85, 0.12)",
            }}
          >
            <Box>
              <Typography
                color="text.primary"
                variant="h4"
                component="h4"
                fontWeight="600"
              >
                Won{" "}
                {displayTicketValueByGameType(
                  gameDetail.FreeToPlay,
                  playerPrize.PrizeAmount,
                  false,
                  true
                )}{" "}
                credits
              </Typography>
            </Box>

            {
              <SvgIcon
                component={Medal}
                viewBox="0 0 24 24"
                sx={{ width: "52px", height: "52px" }}
              />
            }
          </Flex>
        )}
      {/* P2P Grand Prize*/}
      {gameDetail.GameStatusId === GameStatus.Final &&
        !gameDetail.FreeToPlay &&
        playerPrize &&
        playerPrize.IsWinningTicket &&
        playerPrize.PrizeTypeId == PrizeType.MAJOR && (
          <Flex
            sx={{
              px: 2,
              pt: 2,
              pb: 1,
              width: "100%",
              background: "#fff",
              borderTop: "1px solid rgba(35, 40, 85, 0.12)",
            }}
          >
            <Box>
              <Typography
                color="text.primary"
                variant="h4"
                component="h4"
                fontWeight="600"
              >
                Won{" "}
                {displayTicketValueByGameType(
                  gameDetail.FreeToPlay,
                  playerPrize.PrizeAmount,
                  false,
                  true
                )}
              </Typography>
            </Box>

            {
              <SvgIcon
                component={Trophy}
                viewBox="0 0 24 24"
                sx={{ width: "52px", height: "52px" }}
              />
            }
          </Flex>
        )}
      {/* P2P Jackpot?*/}
      {gameDetail.GameStatusId === GameStatus.Final &&
        !gameDetail.FreeToPlay &&
        gameDetail.PlayerPrizes.find(
          (pr) =>
            pr.TicketId === ticketDetail.ticketId &&
            pr.PrizeTypeId !== PrizeType.MAJOR
        ) && (
          <Flex
            sx={{
              px: 2,
              pt: 2,
              pb: 1,
              width: "100%",
              background: "#fff",
              borderTop: "1px solid rgba(35, 40, 85, 0.12)",
            }}
          >
            <Box>
              <Typography
                color="text.primary"
                variant="h4"
                component="h4"
                fontWeight="600"
              >
                Won{" "}
                {displayTicketValueByGameType(
                  gameDetail.FreeToPlay,
                  gameDetail.PlayerPrizes.find(
                    (pr) =>
                      pr.TicketId === ticketDetail.ticketId &&
                      pr.PrizeTypeId !== PrizeType.MAJOR
                  ).PrizeAmount,
                  false,
                  true
                )}{" "}
                {gameDetail.JackpotSeriesId > 0 &&
                  !gameDetail.JackpotLastGame &&
                  gameDetail.PlayerPrizes.find(
                    (pr) =>
                      pr.TicketId === ticketDetail.ticketId &&
                      pr.PrizeTypeId === PrizeType.MAJOR
                  ) && (
                    <>
                      and $
                      {displayTicketValueByGameType(
                        gameDetail.FreeToPlay,
                        gameDetail.PlayerPrizes.find(
                          (pr) =>
                            pr.TicketId === ticketDetail.ticketId &&
                            pr.PrizeTypeId === PrizeType.MAJOR
                        ).PrizeAmount,
                        false,
                        true
                      )}{" "}
                      in jackpot credits
                    </>
                  )}
              </Typography>
            </Box>

            {
              <SvgIcon
                component={Medal}
                viewBox="0 0 24 24"
                sx={{ width: "52px", height: "52px" }}
              />
            }
          </Flex>
        )}
      {/* Knocked out*/}
      {gameDetail.GameStatusId === GameStatus.Final && !playerPrize && (
        <Flex
          sx={{
            px: 2,
            pt: 2,
            pb: 1,
            width: "100%",
            background: "#fff",
            borderTop: "1px solid rgba(35, 40, 85, 0.12)",
          }}
        >
          <Box>
            <Typography
              color="text.primary"
              variant="h4"
              component="h4"
              fontWeight="600"
            >
              {displayTicketValueByGameType(
                gameDetail.FreeToPlay,
                0,
                false,
                true
              )}
            </Typography>
          </Box>
          {
            <SvgIcon
              component={KnockOut}
              viewBox="0 0 148 148"
              sx={{ width: "52px", height: "52px" }}
            />
          }
        </Flex>
      )}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          flexGrow: "1",
        }}
      >
        <Box px={4} sx={{ background: "#fff" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="best ticket"
            textColor="inherit"
            indicatorColor="secondary"
            variant="fullWidth"
            centered
            sx={{
              "& .MuiTab-root": {
                fontWeight: "600",
                color: "text.primary",
              },
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <Tab label="Outcomes" {...a11yProps(0)} />
            <Tab label="History" {...a11yProps(1)} />
          </Tabs>
        </Box>
        {/* Ticket Tear */}
        <Box display="flex">
          <Box
            sx={{
              display: "block",
              width: "20px",
              height: "20px",
              overflow: "hidden",
              position: "relative",
              "&:after": {
                content: '""',
                width: "80px",
                height: "80px",
                borderRadius: "50%",
                background: "0 0",
                position: "absolute",
                top: "50%",
                left: "0",
                transform: "translate(-50%,-50%)",
                border: "30px solid #fff",
              },
            }}
          />
          <Box
            position="relative"
            display="flex"
            sx={{
              flex: 1,
              background: "#fff",
              marginLeft: "-10px",
              marginRight: "-10px",
              zIndex: "3",
              position: "relative",

              "&:after": {
                content: '""',
                position: "absolute",
                top: "9px",
                height: "1px",
                width: "100%",
                // margin: "auto",
                borderBottom: "2px dashed lightgrey",
                zIndex: "1",
              },
              // "&::before": {
              //   content: '""',
              //   position: "absolute",
              //   height: "1px",
              //   width: "100%",
              //   top: "10px",
              //   borderBottom: "1px solid #19292F",
              //   zIndex: "2",
              // },
            }}
          />
          <Box
            sx={{
              display: "block",
              width: "20px",
              height: "20px",
              overflow: "hidden",
              position: "relative",
              "&:after": {
                content: '""',
                width: "80px",
                height: "80px",
                borderRadius: "50%",
                background: "0 0",
                position: "absolute",
                top: "50%",
                right: "0",
                transform: "translate(50%,-50%)",
                border: "30px solid #fff",
              },
            }}
          />
        </Box>
        <OutcomesTab value={value} index={0} ticketNum={ticketNum} />
        <HistoryTab value={value} index={1} ticketNum={ticketNum} />
        {/* <Box
          sx={{
            padding: "16px 0",
            textAlign: "center",
            fontSize: "0.875rem",
          }}
        >
          <Typography
            variant="subtitle1"
            color="rgba(25,37,42,0.6)"
            component="p"
            sx={{
              fontSize: "0.875rem",
            }}
          >
            Ticket #: {ticketNum}
          </Typography>
        </Box> */}
      </Box>
    </Box>
  );
}

FullTicketItem.propTypes = {
  ticketNum: PropTypes.string.isRequired,
  numOfTicket: PropTypes.number.isRequired,
};

export default FullTicketItem;
