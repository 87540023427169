import { getGameDetail } from "../../redux/slices/game.slice";
import {
  sCreateGroupIdForGame,
  performWhenConnectionAvailable,
} from "../../utils/signalr-utils";

export default function handleGameGroupJoin({ action, connection }) {
  // Only proceed for gamedetail result
  if (action.type !== getGameDetail.fulfilled().type) return;
  performWhenConnectionAvailable({
    connection,
    cb: () => {
      console.log("handleGameGroupJoin", {
        type: action.type,
        getGameDetail: getGameDetail.fulfilled().type,
      });
      const groupId = sCreateGroupIdForGame({ gameId: action.payload.GameId });
      connection.invoke("joinGroup", connection.connectionId, groupId);
    },
  });
}
