/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ConsolationPrizeFromJSON, ConsolationPrizeToJSON, LeaderboardPersonFromJSON, LeaderboardPersonToJSON, MajorPrizeFromJSON, MajorPrizeToJSON, PlayerBestTicketFromJSON, PlayerBestTicketToJSON, } from './';
export function FrontEndLeaderboardFromJSON(json) {
    return FrontEndLeaderboardFromJSONTyped(json, false);
}
export function FrontEndLeaderboardFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'gameStatusId': !exists(json, 'GameStatusId') ? undefined : json['GameStatusId'],
        'gameId': !exists(json, 'GameId') ? undefined : json['GameId'],
        'headerImageUrl': !exists(json, 'HeaderImageUrl') ? undefined : json['HeaderImageUrl'],
        'freeToPlay': !exists(json, 'FreeToPlay') ? undefined : json['FreeToPlay'],
        'majorPrize': !exists(json, 'MajorPrize') ? undefined : MajorPrizeFromJSON(json['MajorPrize']),
        'consolationPrize': !exists(json, 'ConsolationPrize') ? undefined : ConsolationPrizeFromJSON(json['ConsolationPrize']),
        'playerBestTicket': !exists(json, 'PlayerBestTicket') ? undefined : PlayerBestTicketFromJSON(json['PlayerBestTicket']),
        'items': !exists(json, 'Items') ? undefined : (json['Items'].map(LeaderboardPersonFromJSON)),
    };
}
export function FrontEndLeaderboardToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'GameStatusId': value.gameStatusId,
        'GameId': value.gameId,
        'HeaderImageUrl': value.headerImageUrl,
        'FreeToPlay': value.freeToPlay,
        'MajorPrize': MajorPrizeToJSON(value.majorPrize),
        'ConsolationPrize': ConsolationPrizeToJSON(value.consolationPrize),
        'PlayerBestTicket': PlayerBestTicketToJSON(value.playerBestTicket),
        'Items': value.items === undefined ? undefined : (value.items.map(LeaderboardPersonToJSON)),
    };
}
