/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { TicketSummaryFromJSON, TicketSummaryToJSON, } from './';
export function MyTicketsResponseFromJSON(json) {
    return MyTicketsResponseFromJSONTyped(json, false);
}
export function MyTicketsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'ticketSummaries': !exists(json, 'TicketSummaries') ? undefined : (json['TicketSummaries'].map(TicketSummaryFromJSON)),
        'ticketsCount': !exists(json, 'TicketsCount') ? undefined : json['TicketsCount'],
        'filteredTicketsCount': !exists(json, 'FilteredTicketsCount') ? undefined : json['FilteredTicketsCount'],
        'ticketCountByBracket': !exists(json, 'TicketCountByBracket') ? undefined : json['TicketCountByBracket'],
        'hasNextPage': !exists(json, 'HasNextPage') ? undefined : json['HasNextPage'],
    };
}
export function MyTicketsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'TicketSummaries': value.ticketSummaries === undefined ? undefined : (value.ticketSummaries.map(TicketSummaryToJSON)),
        'TicketsCount': value.ticketsCount,
        'FilteredTicketsCount': value.filteredTicketsCount,
        'TicketCountByBracket': value.ticketCountByBracket,
        'HasNextPage': value.hasNextPage,
    };
}
