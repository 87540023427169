/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { PlayerGameFromJSON, PlayerGameToJSON, } from './';
export function PlayerGamesResponseFromJSON(json) {
    return PlayerGamesResponseFromJSONTyped(json, false);
}
export function PlayerGamesResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'playerGames': !exists(json, 'PlayerGames') ? undefined : (json['PlayerGames'].map(PlayerGameFromJSON)),
    };
}
export function PlayerGamesResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'PlayerGames': value.playerGames === undefined ? undefined : (value.playerGames.map(PlayerGameToJSON)),
    };
}
