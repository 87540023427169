/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function ContentCardListFromJSON(json) {
    return ContentCardListFromJSONTyped(json, false);
}
export function ContentCardListFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'promotionalBanners': !exists(json, 'PromotionalBanners') ? undefined : json['PromotionalBanners'],
        'contentCards': !exists(json, 'ContentCards') ? undefined : json['ContentCards'],
    };
}
export function ContentCardListToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'PromotionalBanners': value.promotionalBanners,
        'ContentCards': value.contentCards,
    };
}
