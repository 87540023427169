/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function TicketOrderResultFromJSON(json) {
    return TicketOrderResultFromJSONTyped(json, false);
}
export function TicketOrderResultFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'ticketOrder': !exists(json, 'TicketOrder') ? undefined : json['TicketOrder'],
        'amount': !exists(json, 'Amount') ? undefined : json['Amount'],
        'remainingQuantity': !exists(json, 'RemainingQuantity') ? undefined : json['RemainingQuantity'],
        'gameName': !exists(json, 'GameName') ? undefined : json['GameName'],
        'purchaseOrderId': !exists(json, 'PurchaseOrderId') ? undefined : json['PurchaseOrderId'],
        'errorNo': !exists(json, 'ErrorNo') ? undefined : json['ErrorNo'],
        'message': !exists(json, 'Message') ? undefined : json['Message'],
        'identity': !exists(json, 'Identity') ? undefined : json['Identity'],
    };
}
export function TicketOrderResultToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'TicketOrder': value.ticketOrder,
        'Amount': value.amount,
        'RemainingQuantity': value.remainingQuantity,
        'GameName': value.gameName,
        'PurchaseOrderId': value.purchaseOrderId,
        'ErrorNo': value.errorNo,
        'Message': value.message,
        'Identity': value.identity,
    };
}
