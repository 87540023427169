/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { ExternalTokenRequestToJSON, ExternalTokenResponseFromJSON, } from '../models';
/**
 *
 */
export class ExternalApi extends runtime.BaseAPI {
    /**
     */
    async externalTokenRequesttokenCreateRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling externalTokenRequesttokenCreate.');
        }
        const queryParameters = {};
        if (requestParameters.apiKey !== undefined) {
            queryParameters['ApiKey'] = requestParameters.apiKey;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/external/token`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ExternalTokenRequestToJSON(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ExternalTokenResponseFromJSON(jsonValue));
    }
    /**
     */
    async externalTokenRequesttokenCreate(requestParameters, initOverrides) {
        const response = await this.externalTokenRequesttokenCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async externalTokenRequesttokenDeleteRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling externalTokenRequesttokenDelete.');
        }
        const queryParameters = {};
        if (requestParameters.apiKey !== undefined) {
            queryParameters['ApiKey'] = requestParameters.apiKey;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/external/token`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ExternalTokenResponseFromJSON(jsonValue));
    }
    /**
     */
    async externalTokenRequesttokenDelete(requestParameters, initOverrides) {
        const response = await this.externalTokenRequesttokenDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async externalTokenRequesttokenGetRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling externalTokenRequesttokenGet.');
        }
        const queryParameters = {};
        if (requestParameters.apiKey !== undefined) {
            queryParameters['ApiKey'] = requestParameters.apiKey;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/external/token`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ExternalTokenResponseFromJSON(jsonValue));
    }
    /**
     */
    async externalTokenRequesttokenGet(requestParameters, initOverrides) {
        const response = await this.externalTokenRequesttokenGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async externalTokenRequesttokenPostRaw(requestParameters, initOverrides) {
        if (requestParameters.accept === null || requestParameters.accept === undefined) {
            throw new runtime.RequiredError('accept', 'Required parameter requestParameters.accept was null or undefined when calling externalTokenRequesttokenPost.');
        }
        const queryParameters = {};
        if (requestParameters.apiKey !== undefined) {
            queryParameters['ApiKey'] = requestParameters.apiKey;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }
        const response = await this.request({
            path: `/external/token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExternalTokenRequestToJSON(requestParameters.body),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ExternalTokenResponseFromJSON(jsonValue));
    }
    /**
     */
    async externalTokenRequesttokenPost(requestParameters, initOverrides) {
        const response = await this.externalTokenRequesttokenPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
/**
    * @export
    * @enum {string}
    */
export var ExternalTokenRequesttokenCreateAcceptEnum;
(function (ExternalTokenRequesttokenCreateAcceptEnum) {
    ExternalTokenRequesttokenCreateAcceptEnum["ApplicationJson"] = "application/json";
})(ExternalTokenRequesttokenCreateAcceptEnum || (ExternalTokenRequesttokenCreateAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ExternalTokenRequesttokenDeleteAcceptEnum;
(function (ExternalTokenRequesttokenDeleteAcceptEnum) {
    ExternalTokenRequesttokenDeleteAcceptEnum["ApplicationJson"] = "application/json";
})(ExternalTokenRequesttokenDeleteAcceptEnum || (ExternalTokenRequesttokenDeleteAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ExternalTokenRequesttokenGetAcceptEnum;
(function (ExternalTokenRequesttokenGetAcceptEnum) {
    ExternalTokenRequesttokenGetAcceptEnum["ApplicationJson"] = "application/json";
})(ExternalTokenRequesttokenGetAcceptEnum || (ExternalTokenRequesttokenGetAcceptEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ExternalTokenRequesttokenPostAcceptEnum;
(function (ExternalTokenRequesttokenPostAcceptEnum) {
    ExternalTokenRequesttokenPostAcceptEnum["ApplicationJson"] = "application/json";
})(ExternalTokenRequesttokenPostAcceptEnum || (ExternalTokenRequesttokenPostAcceptEnum = {}));
