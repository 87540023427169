import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import { Avatar, Stack, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import {
  getAvatarUrl,
  displayTicketValueByGameType,
} from "../../utils/common-functions";

function TicketHistory({ group, gameEvent }) {
  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot
          sx={{
            padding: 0,
            borderColor: "text.primary",
            margin: "0px auto",
          }}
        >
          <Avatar
            src={getAvatarUrl(group[0].displayPlayer.avatar)}
            alt={group[0].displayPlayer.username}
          />
        </TimelineDot>
        {!group[0].isLatest && (
          <TimelineConnector sx={{ bgcolor: "text.primary" }} />
        )}
      </TimelineSeparator>
      <TimelineContent
        sx={{
          pl: 2,
          pr: 0,
          pt: 0,
          pb: 2,
          color: "text.primary",
        }}
      >
        <Typography
          variant="subtitle1"
          component="p"
          lineHeight="1.4"
          fontWeight="600"
        >
          {group[0].displayPlayer.username}
        </Typography>
        {group.map((history) => {
          const event = gameEvent.find(
            (e) => e.EventId === history.relatedEventId
          );
          const number = event ? event.EventIndex + 1 : 1;
          return (
            <Stack
              direction="row"
              spacing={2}
              justifyContent="space-between"
              key={history.ticketHistoryId}
            >
              <Stack direction="row" spacing={2}>
                <Typography
                  variant="caption"
                  component="p"
                  color="text.secondary"
                >
                  {history.displayMessage}
                </Typography>
                <Typography
                  variant="caption"
                  component="p"
                  color="text.secondary"
                >
                  Event {number}
                </Typography>
              </Stack>
              <Typography
                variant="caption"
                component="p"
                color="primary.main"
                style={{ fontWeight: 500 }}
              >
                {displayTicketValueByGameType(true, history.price, true, true)}
              </Typography>
            </Stack>
          );
        })}
      </TimelineContent>
    </TimelineItem>
  );
}

export default TicketHistory;

TicketHistory.propTypes = {
  group: PropTypes.arrayOf({}).isRequired,
  gameEvent: PropTypes.arrayOf({}).isRequired,
};
