import {
  AppBar,
  Box,
  CircularProgress,
  Container,
  Skeleton,
  Stack,
} from "@mui/material";
import React, { lazy, Suspense } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import Layout from "../components/layouts/Layout";
import SwopStakesOnlyRoute from "../components/SwopStakesOnlyRoute";

const LazyHome = lazy(() => import("./home/home_page"));
const LazyNotifications = lazy(() => import("./Notifications"));
const LazyResults = lazy(() => import("./Results"));
const LazyPlayground = lazy(() => import("./Playground"));
const LazyGame = lazy(() => import("./game/Game"));
const LazyAutoSignupWizard = lazy(() => import("./signup/AutoSignupWizard"));
const LazySignupWizard = lazy(() => import("./signup/SignupWizard"));
const LazyOnboard = lazy(() => import("./Onboard"));
const LazyComponents = lazy(() => import("./UIComponents"));
const LazyResulted = lazy(() => import("./game/Resulted"));
const LazyTestingNewFeatures = lazy(() => import("./Testing"));
const LazyLogin = lazy(() => import("./Login"));
const LazyBuyTicket = lazy(() => import("./BuyTicket"));
const LazyRefer = lazy(() => import("./Refer"));
const LazyFollowingLayout = lazy(() => import("./FollowingLayout"));
const LazyActivityLayout = lazy(() => import("./my_activity/ActivityLayout"));
const LazyGameActivityLayout = lazy(() =>
  import("./my_activity/GameActivityLayout")
);
const LazyEmailVerification = lazy(() => import("./signup/EmailVerification"));
const LazyGameActivityDetail = lazy(() =>
  import("./my_activity/GameActivityDetail")
);
const LazyForgotPassword = lazy(() => import("./ForgotPassword"));
const LazyResetPassword = lazy(() => import("./ResetPassword"));
const LazyChangePassword = lazy(() => import("./ChangePassword"));

function SkeletonFrame() {
  return (
    <Container maxWidth="md" disableGutters>
      <Stack direction="column" spacing={2}>
        <Skeleton variant="rounded" width="100%" height={120} />
        <Skeleton variant="rounded" width="100%" height={120} />
        <Skeleton variant="rounded" width="100%" height={120} />
        <Skeleton variant="rounded" width="100%" height={120} />
        <Skeleton variant="rounded" width="100%" height={120} />
        <Skeleton variant="rounded" width="100%" height={120} />
        <Skeleton variant="rounded" width="100%" height={120} />
      </Stack>
    </Container>
  );
}

function Spinner() {
  return (
    <Box
      sx={{
        height: "100vh",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
}

function AppRouter() {
  return (
    <Routes>
      <Route
        path="/login"
        element={
          <SwopStakesOnlyRoute>
            <Suspense fallback={<Spinner />}>
              <LazyLogin />
            </Suspense>
          </SwopStakesOnlyRoute>
        }
      />
      <Route
        path="/signup"
        element={
          <SwopStakesOnlyRoute>
            <Suspense fallback={<Spinner />}>
              <LazySignupWizard />
            </Suspense>
          </SwopStakesOnlyRoute>
        }
      />
      <Route
        path="/emailverification/:token?"
        element={
          <SwopStakesOnlyRoute>
            <Suspense fallback={<Spinner />}>
              <LazyEmailVerification />
            </Suspense>
          </SwopStakesOnlyRoute>
        }
      />
      <Route
        path="/forgotpassword"
        element={
          <Suspense fallback={<Spinner />}>
            <LazyForgotPassword />
          </Suspense>
        }
      />
      <Route
        path="/resetpassword/:token?"
        element={
          <Suspense fallback={<Spinner />}>
            <LazyResetPassword />
          </Suspense>
        }
      />
      <Route
        path="/wsignup"
        element={
          <Suspense fallback={<Spinner />}>
            <LazyAutoSignupWizard />
          </Suspense>
        }
      />
      <Route
        path="/testing"
        element={
          <Suspense fallback={<Spinner />}>
            <LazyTestingNewFeatures />
          </Suspense>
        }
      />
      <Route
        path="/onboard"
        element={
          <Suspense fallback={<Spinner />}>
            <LazyOnboard />
          </Suspense>
        }
      />
      <Route
        path="/"
        element={
          <Layout>
            <Outlet />
          </Layout>
        }
      >
        <Route
          exact
          path="/"
          element={
            <Suspense fallback={<SkeletonFrame />}>
              <LazyHome />
            </Suspense>
          }
        />
        <Route
          path="/components"
          element={
            <Suspense fallback={<Spinner />}>
              <LazyComponents />
            </Suspense>
          }
        />
        <Route
          path="/refer"
          element={
            <Suspense fallback={<Spinner />}>
              <LazyRefer />
            </Suspense>
          }
        />
        <Route
          path="/results"
          element={
            <Suspense fallback={<SkeletonFrame />}>
              <LazyResults />
            </Suspense>
          }
        />
        {/* Game Page */}
        <Route
          path="/game/:gameId"
          element={
            <Suspense fallback={<SkeletonFrame />}>
              <LazyGame />
            </Suspense>
          }
        />
        <Route
          path="/resulted"
          element={
            <Suspense fallback={<SkeletonFrame />}>
              <LazyResulted />
            </Suspense>
          }
        />
        <Route path="*" element={<p>Nothing Found</p>} />
        <Route
          path="/following"
          element={
            <Suspense fallback={<SkeletonFrame />}>
              <LazyFollowingLayout />
            </Suspense>
          }
        />
        <Route
          path="/changepassword"
          element={
            <Suspense fallback={<SkeletonFrame />}>
              <LazyChangePassword />
            </Suspense>
          }
        />
        <Route
          path="/my-activity"
          element={
            <Suspense fallback={<SkeletonFrame />}>
              <LazyActivityLayout />
            </Suspense>
          }
        />
        <Route
          path="/my-game-activity"
          element={
            <Suspense fallback={<SkeletonFrame />}>
              <LazyGameActivityLayout />
            </Suspense>
          }
        />
        <Route
          path="/my-game-activity-detail"
          element={
            <Suspense fallback={<SkeletonFrame />}>
              <LazyGameActivityDetail />
            </Suspense>
          }
        />
      </Route>

      <Route
        path="/notifications"
        element={
          <Suspense
            fallback={
              <>
                <AppBar
                  position="static"
                  sx={{
                    bgcolor: "background.dark",
                    width: "100%",
                    height: 56,
                  }}
                />
                <Container maxWidth="md">
                  <SkeletonFrame />
                </Container>
              </>
            }
          >
            <LazyNotifications />
          </Suspense>
        }
      />
      <Route
        path="/buy-ticket"
        element={
          <Suspense fallback={<SkeletonFrame />}>
            <LazyBuyTicket />
          </Suspense>
        }
      />
      <Route
        path="/playground"
        element={
          <Suspense fallback={<SkeletonFrame />}>
            <LazyPlayground />
          </Suspense>
        }
      />
    </Routes>
  );
}

export default AppRouter;
