import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  IconButton,
  SvgIcon,
  useMediaQuery,
  Drawer,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
// import MenuIcon from "@mui/icons-material/Menu";
import { ReactComponent as SwopIcon } from "../graphics/logo.svg";
import { IsAuthenticated } from "../../services/ls";
import LeftNavigationBar from "./leftNavigationBar";
import { logout } from "../../redux/slices/user.slice";

function SwopStakesHeader() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isBelowXL = useMediaQuery((theme) => theme.breakpoints.down("xl"));
  const { response: playerDetail } = useSelector(
    (state) => state.user.playerSummary
  );
  const [isMenuOpen, setMenuOpen] = useState(false);
  const isNotLoggedIn = !(
    IsAuthenticated() &&
    playerDetail &&
    playerDetail.playerId > 0
  );

  const handleSignup = (e) => {
    e.preventDefault();
    navigate("/signup");
  };

  return (
    <Box
      sx={{
        height: 80,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        px: 2,
        flexDirection: "row",
        backgroundColor: "#24A7DD",
        color: "white",
        position: "fixed",
        top: 0,
        width: "100%",
        zIndex: 1000,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {/* {isBelowXL && (
          <IconButton
            onClick={() => {
              setMenuOpen(!isMenuOpen);
            }}
            sx={{ marginLeft: "8px", marginRight: "8px" }}
          >
            <MenuIcon sx={{ color: "#ffffff" }} />
          </IconButton>
        )} */}
        {isBelowXL && (
          <Drawer
            anchor="left"
            open={isMenuOpen}
            onClose={() => setMenuOpen(false)}
            ModalProps={{ disableScrollLock: true }}
          >
            <LeftNavigationBar />
          </Drawer>
        )}
        <IconButton
          disableElevation
          disableRipple
          sx={{
            padding: 0,
            marginLeft: "16px",
            "&.MuiButtonBase-root:hover": {
              bgcolor: "transparent",
            },
          }}
          onClick={() => {
            navigate("/");
          }}
        >
          <SvgIcon
            sx={{
              width: "auto",
              height: isMobile ? 40 : 54,
            }}
            component={SwopIcon}
            viewBox="0 0 46 24"
          />
        </IconButton>
      </Box>
      {isNotLoggedIn ? (
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Button
            variant="contained"
            disableElevation
            onClick={(e) => handleSignup(e)}
            sx={{
              minWidth: 80,
              height: 32,
              background: "#F70588",
              color: "white",
              borderRadius: "24px 0px 0px 24px",
              textTransform: "none",
              "&:hover": {
                background: "#F70588",
              },
            }}
          >
            Join
          </Button>

          <Button
            onClick={() =>
              navigate("/login", {
                state: { from: window.location.pathname },
              })
            }
            variant="contained"
            disableElevation
            sx={{
              minWidth: isMobile ? 60 : 80,
              height: 32,
              background: "#19292F",
              color: "white",
              borderRadius: "0px 24px 24px 0px",
              textTransform: "none",
              "&:hover": {
                background: "#19292F",
              },
            }}
          >
            Login
          </Button>
        </Box>
      ) : (
        <Button
          onClick={() => dispatch(logout({ callback: () => {} }))}
          variant="contained"
          disableElevation
          sx={{
            minWidth: isMobile ? 60 : 80,
            height: 32,
            background: "#ffffff",
            color: "#19292F",
            borderRadius: "24px",
            textTransform: "none",
            display: "flex",
            alignItems: "center",
            "&:hover": {
              background: "#ffffff",
            },
          }}
        >
          <LogoutIcon fontSize="small" sx={{ marginRight: "4px" }} />
          <span style={{ lineHeight: "1" }}>Logout</span>
        </Button>
      )}
    </Box>
  );
}

export default SwopStakesHeader;
