import {
  Box,
  Button,
  Container,
  Stack,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CostTicketInput from "../CostTicketInput";
import DrawerHeader from "../layouts/DrawerHeader";
import TermsOfService from "../TermsOfService";
import Filter from "../Filter";
import BankOwnedTicket from "../game/BankOwnedTicket";
import { updateConfirmationDialog } from "../../redux/slices/dialog.slice";
import { getBankTickets } from "../../redux/slices/game.slice";
import {
  validateBuyFromPoolUpToEvent,
  isBuyFromBankEnabled,
  useDebounce,
  getCurrentEvent,
} from "../../utils/common-functions";
import { OrderDialogType, SystemMessage } from "../../utils/types";
import BottomButtonLayout from "../layouts/BottomButtonLayout";
import { IsAuthenticated, IsSwopStakeWeb } from "../../services/ls";

export const Tickets = [
  {
    id: "1",
    survivalValue: 56,
    isFav: false,
    horse: [
      {
        name: "Athletism",
        jockey: "K M Evoy",
      },
    ],
    tickets: [
      { amount: "22.00", id: "1", survivalValue: 72 },
      { amount: "19.30", id: "2", survivalValue: 42 },
      { amount: "18.65", id: "3", survivalValue: 56 },
      { amount: "17.50", id: "4", survivalValue: 12 },
      { amount: "16.40", id: "5", survivalValue: 67 },
      { amount: "13.40", id: "6", survivalValue: 19 },
      { amount: "12.90", id: "7", survivalValue: 55 },
      { amount: "11.20", id: "8", survivalValue: 60 },
      { amount: "22.00", id: "9", survivalValue: 72 },
      { amount: "19.30", id: "10", survivalValue: 42 },
      { amount: "18.65", id: "13", survivalValue: 56 },
      { amount: "17.50", id: "14", survivalValue: 12 },
      { amount: "16.40", id: "15", survivalValue: 67 },
      { amount: "13.40", id: "16", survivalValue: 19 },
      { amount: "12.90", id: "17", survivalValue: 55 },
      { amount: "11.20", id: "18", survivalValue: 60 },
    ],
  },
  {
    id: "3",
    survivalValue: 33,
    isFav: false,
    horse: [
      {
        name: "Athletism",
        jockey: "K M Evoy",
      },
      {
        name: "Athletism",
        jockey: "K M Evoy",
      },
      {
        name: "Athletism",
        jockey: "K M Evoy",
      },
    ],
    tickets: [
      { amount: "22.00", id: "1", survivalValue: 72 },
      { amount: "19.30", id: "2", survivalValue: 42 },
      { amount: "18.65", id: "3", survivalValue: 56 },
    ],
  },
];

// eslint-disable-next-line no-unused-vars
const UnavailableTickets = [
  {
    id: "2",
    survivalValue: 17,
    isFav: false,
    horse: [
      {
        name: "Athletism",
        jockey: "K M Evoy",
      },
    ],
    tickets: [],
  },
];

function a11yProps(index) {
  return {
    id: `best-ticket-${index}`,
    "aria-controls": `best-ticket-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`best-ticket-${index}`}
      aria-labelledby={`best-ticket-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

function BuyTickets({ defaultTab = 0, onClose, defaultBracketId = false }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { detail: gameDetail, selectedEvent } = useSelector(
    (state) => state.game.game
  );
  const { detail: bankTicketDetail, gameId: bankTicketGameId } = useSelector(
    (state) => state.game.bankTicket
  );
  const { response: playerDetail } = useSelector(
    (state) => state.user.playerSummary
  );
  const { error } = useSelector((state) => state.dialog.confirmationDialog);

  const [cost, setCost] = React.useState("0.00");
  const [numOfTicket, setNumOfTicket] = React.useState(0);

  const [expanded, setExpanded] = React.useState(defaultBracketId);
  // const [openReceipt, setOpenReceipt] = React.useState(false);
  // const [openConfirm, setOpenConfirm] = React.useState(false);
  const [value, setValue] = React.useState();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const debouncedValue = useDebounce({ value, delay: 500 });
  const [buyMultiple, setBuyMultiple] = React.useState(false);

  const currentTicketPrice = gameDetail.CurrentPoolTicketPrice;
  const currentEvent = getCurrentEvent(gameDetail);

  const buyFromBankEnabled = isBuyFromBankEnabled({
    game: gameDetail,
    event: currentEvent,
  });

  const buyFromPoolEnabled = validateBuyFromPoolUpToEvent({
    game: gameDetail,
    event: currentEvent,
  });

  const [tab, setTab] = React.useState(() => {
    if (!buyFromPoolEnabled) return 1;
    return defaultTab;
  });

  const [selected, setSelected] = React.useState([]);

  React.useEffect(() => {
    if (gameDetail && buyFromBankEnabled && selectedEvent)
      dispatch(
        getBankTickets({
          gameId: gameDetail.GameId,
          eventIndex: selectedEvent.EventIndex,
          valueMin: debouncedValue ? debouncedValue[0] : null,
          valueMax: debouncedValue ? debouncedValue[1] : null,
        })
      );
  }, [debouncedValue, selectedEvent]);

  React.useEffect(() => {
    if (!bankTicketDetail || !bankTicketDetail.ticketSummaries) return;
    const filteredTicketId = bankTicketDetail.ticketSummaries.map(
      (t) => t.ticketId
    );
    setSelected(selected.filter((s) => filteredTicketId.includes(s)));
  }, [bankTicketDetail]);

  React.useEffect(() => {
    if (
      error &&
      error.errorNo &&
      (error.errorNo === SystemMessage.InsufficientTicketsInPool ||
        error.errorNo === SystemMessage.InsufficientTicketsInPresale)
    ) {
      setNumOfTicket(0);
    }
  }, [error]);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const getOutComesForABracket = (outcomeIds = []) => {
    return outcomeIds.map((oid) =>
      selectedEvent.Event.Outcomes.find((o) => o.OutcomeId === oid)
    );
  };

  // const handleBuyFromBank = ({ ticket = [] }) => {
  //   dispatch(
  //     buyTicketFromBank({
  //       expectedPrice:
  //         ticket.map((t) => t.Value).reduce((total, v) => total + v) *
  //         (1 - selectedEvent.PurchaseFromBankDiscount),
  //       gameId: gameDetail.GameId,
  //       quantity: ticket.length,
  //       requestedTickets: ticket.map((t) => t.TicketId),
  //     })
  //   );
  // };

  const avaliableBrackets = [];

  const nonAvaliableBrackets = [];
  let bankTicketsByBracket = {};

  if (
    bankTicketDetail &&
    buyFromBankEnabled &&
    bankTicketGameId === gameDetail.GameId
  ) {
    if (!value) {
      setValue([
        bankTicketDetail?.ticketValueMin,
        bankTicketDetail?.ticketValueMax,
      ]);
    }

    if (!!selectedEvent) {
      bankTicketsByBracket = bankTicketDetail.ticketSummaries.reduce(
        (grp, ticket) => {
          const bracketId =
            ticket.ticketId.split("-")[selectedEvent.EventIndex];

          // eslint-disable-next-line no-param-reassign
          (grp[bracketId] = grp[bracketId] || []).push(ticket);
          return grp;
        },
        {}
      );
    }

    selectedEvent?.Brackets.forEach((b) => {
      if (bankTicketsByBracket[b.BracketId] !== undefined) {
        avaliableBrackets.push(b);
      } else nonAvaliableBrackets.push(b);
    });
  }

  const renderPoolButton = () => {
    const buyTicketText =
      numOfTicket === 0
        ? "Buy Ticket"
        : numOfTicket === 1
        ? "Buy 1 Ticket"
        : `Buy ${numOfTicket} Tickets`;

    if (!(IsAuthenticated() && playerDetail && playerDetail.playerId > 0)) {
      if (IsSwopStakeWeb()) {
        return {
          buttonText: `Login to ${buyTicketText}`,
          onButtonClick: () => {
            onClose();
            navigate("/login", { state: { from: window.location.pathname } });
          },
        };
      }
      return {
        buttonText: buyTicketText,
      };
    }

    return {
      buttonText: buyTicketText,
      onButtonClick:
        numOfTicket === 0
          ? undefined
          : () =>
              dispatch(
                updateConfirmationDialog({
                  show: true,
                  orderType: OrderDialogType.BUY,
                  gameName: gameDetail.GameName,
                  options: {
                    quantity: numOfTicket,
                    expectedPrice: numOfTicket * currentTicketPrice,
                  },
                  checkTicketDetail: false,
                })
              ),
    };
  };

  const { buttonText, onButtonClick } = renderPoolButton() || {};

  return (
    <Box
      sx={{
        bg: "background.default",
        height: "100%",
        width: isMobile ? "100%" : "460px",
      }}
    >
      <DrawerHeader
        title={gameDetail.GameName}
        onClose={onClose}
        displayBalance
      />

      <Box
        px={4}
        py={1}
        sx={{
          bgcolor: "background.dark",
          color: "text.contrastText",
        }}
      >
        <Container maxWidth="md" disableGutters>
          <Tabs
            value={tab}
            onChange={handleChange}
            aria-label="buy-tickets"
            textColor="inherit"
            indicatorColor="secondary"
            variant="fullWidth"
            centered
            sx={{ "& .MuiTab-root": { fontWeight: "600" } }}
          >
            {buyFromPoolEnabled && (
              <Tab label="Buy from pool" value={0} {...a11yProps(0)} />
            )}
            {buyFromBankEnabled && (
              <Tab label="Buy from bank" value={1} {...a11yProps(1)} />
            )}
          </Tabs>
        </Container>
      </Box>
      <TabPanel value={tab} index={0}>
        <CostTicketInput
          cost={cost}
          setCost={setCost}
          numOfTicket={numOfTicket}
          setNumOfTicket={setNumOfTicket}
          currentTicketPrice={currentTicketPrice}
          isFreeToPlay={gameDetail.FreeToPlay}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Button
            size="large"
            variant="contained"
            sx={{ textTransform: "none", my: 3, color: "white" }}
            disabled={onButtonClick === undefined}
            onClick={onButtonClick}
          >
            {buttonText}
          </Button>
          {/* <TermsOfService /> */}
        </Box>
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <Filter
          value={value}
          setValue={setValue}
          valueMin={bankTicketDetail?.ticketValueMin}
          valueMax={bankTicketDetail?.ticketValueMax}
          buyMultiple={buyMultiple}
          setBuyMultiple={setBuyMultiple}
        />
        <Container maxWidth="md" sx={{ mt: 2 }}>
          <Stack direction="column" spacing={2}>
            <Typography variant="caption" component="p">
              Available
            </Typography>
            {avaliableBrackets.map((b) => (
              <BankOwnedTicket
                outcomes={getOutComesForABracket(b.OutcomeIds)}
                probability={b.Probability}
                key={b.BracketId}
                expanded={expanded}
                setExpanded={setExpanded}
                bracketId={b.BracketId}
                tickets={bankTicketsByBracket[b.BracketId]}
                selectable={buyMultiple}
                selected={selected}
                setSelected={setSelected}
              />
            ))}
            {/* <Typography variant="caption" component="p">
              Not Currently Available
            </Typography>
            {UnavailableTickets.map((data) => (
              <BankOwnedTicket
                data={data}
                key={data.id}
                expanded={expanded}
                setExpanded={setExpanded}
              />
            ))} */}
            {/* <TermsOfService /> */}
          </Stack>
          <BottomButtonLayout
            // open when a ticket is selected
            open={buyMultiple}
            sx={{
              "& .MuiDrawer-paper": {
                minHeight: "56px !important",
                maxHeight: "56px !important",
                width: "100% !important",
                height: "unset",
                "@media (min-width: 600px)": {
                  width: "460px !important",
                  marginLeft: "auto  !important",
                  marginRight: "0px  !important",
                },
              },
            }}
            buttonDisabled={selected.length <= 0}
            onClose={() => setBuyMultiple(false)}
            onClick={() => {
              const selectTicket = bankTicketDetail.ticketSummaries.filter(
                (t) => selected.includes(t.ticketId)
              );
              dispatch(
                updateConfirmationDialog({
                  show: true,
                  orderType: OrderDialogType.BUY,
                  gameName: gameDetail.GameName,
                  options: {
                    fromPlayerId: 1,
                    quantity: selectTicket.length,
                    requestedTickets: selectTicket.map((t) => t.ticketId),
                    expectedPrice:
                      selectTicket
                        .map((t) => t.value)
                        .reduce((total, v) => total + v) *
                      (1 - selectedEvent.PurchaseFromBankDiscount),
                  },
                })
              );
            }}
          >
            Buy Selected Tickets
          </BottomButtonLayout>
        </Container>
      </TabPanel>
    </Box>
  );
}

BuyTickets.propTypes = {
  defaultTab: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default BuyTickets;
