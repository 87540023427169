/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { GameResultsFromJSON, GameResultsToJSON, } from './';
export function GameResultResponseFromJSON(json) {
    return GameResultResponseFromJSONTyped(json, false);
}
export function GameResultResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'gameResults': !exists(json, 'GameResults') ? undefined : (json['GameResults'].map(GameResultsFromJSON)),
    };
}
export function GameResultResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'GameResults': value.gameResults === undefined ? undefined : (value.gameResults.map(GameResultsToJSON)),
    };
}
