/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function GetPlayerPWTransactionRequestFromJSON(json) {
    return GetPlayerPWTransactionRequestFromJSONTyped(json, false);
}
export function GetPlayerPWTransactionRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'page': !exists(json, 'Page') ? undefined : json['Page'],
        'txType': !exists(json, 'TxType') ? undefined : json['TxType'],
        'startDate': !exists(json, 'StartDate') ? undefined : json['StartDate'],
        'endDate': !exists(json, 'EndDate') ? undefined : json['EndDate'],
        'channel': !exists(json, 'Channel') ? undefined : json['Channel'],
    };
}
export function GetPlayerPWTransactionRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'Page': value.page,
        'TxType': value.txType,
        'StartDate': value.startDate,
        'EndDate': value.endDate,
        'Channel': value.channel,
    };
}
