/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function GetGameResultsRequestFromJSON(json) {
    return GetGameResultsRequestFromJSONTyped(json, false);
}
export function GetGameResultsRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'filterStartDate': !exists(json, 'FilterStartDate') ? undefined : json['FilterStartDate'],
        'filterEndDate': !exists(json, 'FilterEndDate') ? undefined : json['FilterEndDate'],
        'channel': !exists(json, 'Channel') ? undefined : json['Channel'],
    };
}
export function GetGameResultsRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'FilterStartDate': value.filterStartDate,
        'FilterEndDate': value.filterEndDate,
        'Channel': value.channel,
    };
}
