/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function RegisterFromJSON(json) {
    return RegisterFromJSONTyped(json, false);
}
export function RegisterFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'userName': !exists(json, 'UserName') ? undefined : json['UserName'],
        'firstName': !exists(json, 'FirstName') ? undefined : json['FirstName'],
        'lastName': !exists(json, 'LastName') ? undefined : json['LastName'],
        'displayName': !exists(json, 'DisplayName') ? undefined : json['DisplayName'],
        'email': !exists(json, 'Email') ? undefined : json['Email'],
        'password': !exists(json, 'Password') ? undefined : json['Password'],
        'confirmPassword': !exists(json, 'ConfirmPassword') ? undefined : json['ConfirmPassword'],
        'autoLogin': !exists(json, 'AutoLogin') ? undefined : json['AutoLogin'],
        'errorView': !exists(json, 'ErrorView') ? undefined : json['ErrorView'],
        'meta': !exists(json, 'Meta') ? undefined : json['Meta'],
    };
}
export function RegisterToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'UserName': value.userName,
        'FirstName': value.firstName,
        'LastName': value.lastName,
        'DisplayName': value.displayName,
        'Email': value.email,
        'Password': value.password,
        'ConfirmPassword': value.confirmPassword,
        'AutoLogin': value.autoLogin,
        'ErrorView': value.errorView,
        'Meta': value.meta,
    };
}
