import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Stack,
  Typography,
} from "@mui/material";
import React, { useRef } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import CircularSurvivalMetre from "./CircularSurvivalMetre";
import SingleTicketItem from "./SingleTicketItem";
import {
  numFormatWithMin,
  getEventTypeIconStringFromEventType,
  longFormNum,
} from "../../utils/common-functions";
import { updateFullTicketViewer } from "../../redux/slices/dialog.slice";
import SportIcon from "../SportIcon";
import { OutcomeResult } from "@/utils/types";

function BankOwnedTicket({
  isFav = false,
  bracketId = 0,
  probability = 0,
  outcomes = [],
  expanded,
  setExpanded,
  tickets = [],
  selectable = false,
  selected,
  setSelected,
}) {
  // const [openViewer, setOpenViewer] = React.useState(false);
  const { selectedEvent } = useSelector((state) => state.game.game);

  const accordionRef = React.useRef(null);

  const handleChange = () => {
    if (tickets && tickets.length > 0) {
      if (expanded === bracketId) {
        setExpanded(false);
      } else {
        setExpanded(bracketId);
        setTimeout(() => {
          if (accordionRef.current) {
            accordionRef.current.scrollIntoView({
              behavior: "smooth",
              block: "nearest",
            });
          }
        }, 300);
      }
    }
  };

  const dispatch = useDispatch();

  // const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Accordion
      ref={accordionRef}
      disableGutters
      elevation={2}
      expanded={expanded === bracketId}
      onChange={handleChange}
      sx={{
        boxShadow: "0px 0px 6px 1px #00000012",
        border: "1px solid lightgray",
        "&.MuiPaper-rounded": {
          borderRadius: "4px",
        },
        "&:before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        sx={{
          p: 0,
          "& .MuiAccordionSummary-content": {
            m: 0,
          },
        }}
      >
        <Stack direction="row" alignItems="start" spacing={4} flex={1} p={2}>
          <Stack direction="column" spacing={2}>
            {outcomes.map((o, index) => {
              const imgEventType = getEventTypeIconStringFromEventType(
                selectedEvent?.Event?.EventType?.EventType
              );

              const eventType = selectedEvent?.Event?.EventType?.EventType;

              const isScratched =
                o.OutcomeResult &&
                (o.OutcomeResult.OutcomeResultId === OutcomeResult.Scratched ||
                  o.OutcomeResult.OutcomeResultId ===
                    OutcomeResult.LateScratched);
              return (
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  key={o.OutcomeId}
                >
                  {/* <img
                  src={o.SilksImageUrl}
                  alt="Athletism"
                  style={{ width: 32, height: 32 }}
                /> */}
                  <SportIcon
                    imageURL={o?.SilksImageUrl}
                    alt={o?.SilksImageUrl ? "SilkImage" : eventType}
                    imgEventType={imgEventType}
                    eventType={eventType}
                    height={24}
                    width={24}
                    boxHeight={36}
                    boxWidth={36}
                    imgHeight={32}
                    imgWidth={32}
                    inverse
                    greyout={isScratched}
                  />
                  <Box>
                    <Typography
                      variant="subtitle1"
                      component="p"
                      sx={{
                        opacity: isScratched ? 0.5 : 1,
                      }}
                    >
                      {o.OutcomeName}
                      {isFav && index === 0 && (
                        <Chip
                          label="Fav"
                          color="secondary"
                          size="small"
                          sx={{ ml: 2 }}
                        />
                      )}
                    </Typography>
                    <Typography
                      lineHeight="1"
                      variant="body2"
                      component="p"
                      color="text.secondary"
                      sx={{
                        opacity: isScratched ? 0.5 : 1,
                      }}
                    >
                      {isScratched ? "Scratched" : o.Jockey_Driver}
                    </Typography>
                  </Box>
                </Stack>
              );
            })}
          </Stack>

          <CircularSurvivalMetre
            value={probability * 100}
            sx={{ marginLeft: "auto !important" }}
          />
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <Box
          sx={{
            px: 2,
            pt: 1,
            pb: 2,
            bgcolor: "grey.300",
            maxWidth: "100%",
            overflowX: "auto",
          }}
        >
          <Typography
            variant="caption"
            component="p"
            gutterBottom
            color="text.secondary"
          >
            {tickets && tickets.length} Ticket
            {tickets && tickets.length !== 1 ? "s" : ""}
          </Typography>
          <Box
            sx={
              tickets && tickets.length
                ? {
                    display: "flex",
                  }
                : {}
            }
          >
            {tickets &&
              tickets.length &&
              tickets.map((t, i) => {
                const numWithMin = numFormatWithMin(t.value);
                const formattedValue = longFormNum(numWithMin);
                return (
                  <SingleTicketItem
                    key={t.ticketId}
                    id={t.ticketId}
                    setSelected={setSelected}
                    selected={selected.includes(t.ticketId)}
                    selectable={selectable}
                    // handleClick={() => setOpenViewer(i)}
                    handleClick={() =>
                      dispatch(
                        updateFullTicketViewer({
                          ticketData: tickets,
                          currentTicket: i,
                          show: true,
                        })
                      )
                    }
                  >
                    {formattedValue}
                  </SingleTicketItem>
                );
              })}
          </Box>
        </Box>
        {/* <Drawer
          open={openViewer !== false}
          anchor={isMobile ? "bottom" : "right"}
          onClose={() => setOpenViewer(false)}
          ModalProps={{ disableScrollLock: true }}
        >
          <FullTicketViewer
            onClose={() => setOpenViewer(false)}
            data={tickets}
            defaultTicket={openViewer}
          />
        </Drawer> */}
      </AccordionDetails>
    </Accordion>
  );
}

BankOwnedTicket.propTypes = {
  expanded: PropTypes.bool.isRequired,
  setExpanded: PropTypes.func.isRequired,
  outcomes: PropTypes.arrayOf({}).isRequired,
  probability: PropTypes.number.isRequired,
  bracketId: PropTypes.number.isRequired,
  tickets: PropTypes.arrayOf({}).isRequired,
  isFav: PropTypes.bool.isRequired,
  selectable: PropTypes.bool.isRequired,
  selected: PropTypes.arrayOf({}).isRequired,
  setSelected: PropTypes.func.isRequired,
};

export default BankOwnedTicket;
