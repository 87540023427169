import {
  createSlice,
  createAsyncThunk,
  createAction,
  current,
} from "@reduxjs/toolkit";
import swopApi from "../../api";
import {
  getPurchasedTicketDetails,
  updateConfirmationDialog,
  updateReceipt,
  updatePendingDialog,
} from "./dialog.slice";
import { updateBuyDialog, updateBuyDialogDefaultTab } from "./game.slice";
import { purchaseOrderStore } from "@/purchase_order_store";
import { getOrderDialogTypeFromOrderTypeId } from "../../utils/common-functions";
import { getPlayerSummary } from "../../redux/slices/user.slice";
import { getGameDetail } from "../../redux/slices/game.slice";

const SLICE_NAME = "ticket";

const commonPurchaseHandler = ({ result, purchaseType }) => {
  if (
    Object.keys(purchaseOrderStore.getPendingPurchaseOrders()).includes(
      result.purchaseOrderId
    )
  ) {
    purchaseOrderStore.removePurchaseOrder({
      purchaseOrderId: result.purchaseOrderId,
      from: "BY",
    });
    // signal r faster than removePurchaseOrder
    console.log(`${purchaseType} > key found > removePurchaseOrder`, {
      purchaseOrderId: result.purchaseOrderId,
    });
  } else {
    // not found than buy add
    purchaseOrderStore.addPurchaseOrder({
      purchaseOrderId: result.purchaseOrderId,
      from: "BY",
    });
    console.log(`${purchaseType} > addPurchaseOrder`, result.purchaseOrderId);
  }
};

export const buyTicketFromPool = createAsyncThunk(
  `${SLICE_NAME}/buyTicketFromPool`,
  async ({ gameId, quantity, expectedPrice }, { dispatch }) => {
    const result =
      await swopApi.gameApi.buyTicketFromPoolRequestGameIdbuyTicketFromPoolPost(
        {
          accept: "application/json",
          gameId,
          body: {
            expectedPrice,
            gameId,
            quantity,
          },
        }
      );
    if (result.errorNo > 0) {
      dispatch(
        updateConfirmationDialog({
          show: true,
          processing: false,
          error: { message: result.message, errorNo: result.errorNo },
        })
      );
    } else {
      commonPurchaseHandler({ result, purchaseType: "buyTicketFromPool" });

      dispatch(updateBuyDialog(false));
      dispatch(updateBuyDialogDefaultTab(0));
      // dispatch(
      //   updateConfirmationDialog({
      //     // processing: false,
      //     error: {},
      //   })
      // );
    }
    return result;
  }
);

export const buyTicketFromBank = createAsyncThunk(
  `${SLICE_NAME}/buyTicketFromBank`,
  async (
    { gameId, quantity, expectedPrice, requestedTickets },
    { dispatch }
  ) => {
    const result =
      await swopApi.gameApi.buyTicketFromBankRequestGameIdbuyTicketFromBankPost(
        {
          accept: "application/json",
          gameId,
          body: {
            fromPlayerId: 1,
            expectedPrice,
            gameId,
            requestedTickets,
            quantity,
          },
        }
      );

    if (result.errorNo > 0) {
      dispatch(
        updateConfirmationDialog({
          show: true,
          processing: false,
          error: { message: result.message, errorNo: result.errorNo },
        })
      );
    } else {
      commonPurchaseHandler({ result, purchaseType: "buyTicketFromBank" });

      dispatch(updateBuyDialog(false));
      dispatch(updateBuyDialogDefaultTab(0));
      // dispatch(
      //   updateConfirmationDialog({
      //     // processing: false,
      //     error: {},
      //   })
      // );
    }
    return result;
  }
);

export const cashoutTickets = createAsyncThunk(
  `${SLICE_NAME}/cashoutTickets`,
  async ({ gameId, quantity, expectedPrice, requestedTickets }) => {
    const result =
      await swopApi.gameApi.cashoutTicketsRequestGameIdcashoutTicketsPost({
        accept: "application/json",
        gameId,
        body: {
          expectedPrice,
          gameId,
          requestedTickets,
          quantity,
        },
      });

    commonPurchaseHandler({ result, purchaseType: "cashoutTickets" });

    return result;
  }
);

export const swopTickets = createAsyncThunk(
  `${SLICE_NAME}/swopTickets`,
  async ({ gameId, quantity, expectedPrice, eventId, bracketId }) => {
    const result =
      await swopApi.gameApi.swopTicketsRequestGameIdswopTicketsPost({
        accept: "application/json",
        gameId,
        body: {
          expectedPrice,
          gameId,
          eventId,
          bracketId,
          quantity,
        },
      });

    commonPurchaseHandler({ result, purchaseType: "swopTickets" });

    return result;
  }
);

export const getTicketDetailsInfo = createAsyncThunk(
  `${SLICE_NAME}/getTicketDetailsInfo`,
  async ({ ticketId, gameID }) => {
    return swopApi.gameApi.ticketDetailRequestGameIdticketTicketIdPost({
      accept: "application/json",
      gameId: gameID,
      ticketId,
      body: {
        gameID,
        ticketId,
      },
    });
  }
);

export const getPendingOrders = createAsyncThunk(
  `${SLICE_NAME}/player/ticketpurchaseorders`,
  async (_, { dispatch, getState }) => {
    const pendingOrderRes =
      await swopApi.playerApi.playerTicketPurchaseOrderRequestticketpurchaseordersPost(
        {
          accept: "application/json",
        }
      );
    if (pendingOrderRes) {
      pendingOrderRes.forEach((po) =>
        purchaseOrderStore.addPurchaseOrder({
          purchaseOrderId: po.ticketPurchaseOrderId,
          from: "PO",
        })
      );
      console.log("ticketpurchaseorders > addPurchaseOrder", pendingOrderRes);
    }
    // if there is any pending orders
    setTimeout(async () => {
      const { confirmationDialog } = getState().dialog;
      for (let key of Object.keys(
        purchaseOrderStore.getPendingPurchaseOrders()
      )) {
        try {
          // skip if the purchase order is completed before
          if (
            purchaseOrderStore.isPurchaseOrderProcessed({
              purchaseOrderId: key,
            }) === true
          )
            continue;
          // check purchase orderstatus
          const currentStatusRes =
            await swopApi.playerApi.playerTicketPurchaseOrderByIdRequestticketpurchaseordersTicketPurchaseOrderIdPost(
              {
                accept: "application/json",
                ticketPurchaseOrderId: key,
              }
            );

          console.log(
            "Ticket Order polling [Checking if purchase order is processed]",
            {
              currentStatusRes,
              purchaseOrderId: key,
              purchaseOrders: window.purchaseOrders,
              isProcessed: purchaseOrderStore.isPurchaseOrderProcessed({
                purchaseOrderId: key,
              }),
            }
          );
          if (
            currentStatusRes &&
            currentStatusRes.purchaseOrderStatus === "finalised"
          ) {
            // show receipt
            setTimeout(() => {
              if (
                currentStatusRes.ticketOrderId === 0 ||
                !!currentStatusRes.resultMessage
              ) {
                console.log("Ticket Order polling [Order fail]", {
                  purchaseOrderId: key,
                  currentStatusRes,
                  error: {
                    message: currentStatusRes.resultMessage,
                  },
                });
                dispatch(
                  updateConfirmationDialog({
                    show: true,
                    processing: false,
                    error: {
                      message: currentStatusRes.resultMessage,
                      errorNo: !!currentStatusRes.resultMessage ? 1 : 0,
                    },
                    gameName: currentStatusRes.gameName,
                    orderType: getOrderDialogTypeFromOrderTypeId(
                      currentStatusRes.orderTypeId
                    ),
                    options: {
                      ...confirmationDialog.options,
                      expectedPrice:
                        currentStatusRes.price ??
                        confirmationDialog.options.expectedPrice,
                      quantity:
                        currentStatusRes.quantity ??
                        confirmationDialog.options.quantity,
                    },
                  })
                );
              } else {
                console.log("Ticket Order polling [Order successful]", {
                  purchaseOrderId: key,
                  currentStatusRes,
                });
                dispatch(
                  updatePendingDialog({
                    show: false,
                  })
                );
                dispatch(
                  updateConfirmationDialog({ show: false, processing: false })
                );

                dispatch(
                  updateReceipt({
                    show: true,
                    response: {
                      Amount: currentStatusRes.price,
                      ErrorNo: !!currentStatusRes.resultMessage ? 1 : 0,
                      GameName: currentStatusRes.gameName,
                      Identity: currentStatusRes.ticketOrderId,
                      Message: !!currentStatusRes.resultMessage
                        ? currentStatusRes.resultMessage
                        : null,
                      PurchaseOrderId: null,
                      RemainingQuantity: null,
                      TicketOrder: {
                        // BracketId: null,
                        // FromPlayer: null,
                        // FromPlayerId: null,
                        GameId: currentStatusRes.gameId,
                        // OrderDateTime: "0001-01-01T00:00:00",
                        // OrderStatus: null,
                        // OrderStatusId: 2,
                        // OrderType: null,
                        OrderTypeId: currentStatusRes.orderTypeId,
                        // PWTransactions: null,
                        Quantity: currentStatusRes.quantity,
                        // RelatedEventId: 6783,
                        Share: currentStatusRes.share,
                        TicketHistories: null,
                        TicketOrderId: currentStatusRes.ticketOrderId,
                        // ToPlayerId: 11,
                      },
                    },
                  })
                );
                dispatch(
                  getPurchasedTicketDetails({
                    gameId: currentStatusRes.gameId,
                    ticketOrderId: currentStatusRes.ticketOrderId,
                  })
                );
                //refresh player tickets and game
                dispatch(
                  getGameDetail({
                    gameId: currentStatusRes.gameId,
                  })
                );
                dispatch(getPlayerSummary());
              }
            }, 5000);
            // remove from this store
            purchaseOrderStore.removePurchaseOrder({
              purchaseOrderId: key,
              from: "PO",
            });
            console.log("Ticket Order polling [removePurchaseOrder]", {
              purchaseOrderId: key,
            });
          }
        } catch (e) {
          console.log("Error", e);
        }
      }
    }, 100);
    if (pendingOrderRes && pendingOrderRes.length > 0) {
      setTimeout(() => {
        dispatch(getPendingOrders());
      }, 5000);
    }
    return pendingOrderRes;
  }
);

export const updatePendingOrders = createAction("updatePendingOrders");

const ticketOrderSlice = createSlice({
  name: SLICE_NAME,
  initialState: {
    order: {
      loading: false,
      response: null,
    },
    ticketDetails: {
      loading: false,
      detail: null,
    },
    pendingOrders: {
      loading: false,
      detail: [],
    },
  },
  extraReducers(builder) {
    builder
      .addCase(buyTicketFromPool.pending, (state) => {
        state.order.loading = true;
      })
      .addCase(buyTicketFromPool.fulfilled, (state, action) => {
        state.order.loading = false;
        state.order.response = action.payload;
      })
      .addCase(buyTicketFromPool.rejected, (state, action) => {
        state.order.loading = false;
        state.order.response = action.payload;
        state.confirmationDialog.loading = false;
      })
      .addCase(buyTicketFromBank.pending, (state) => {
        state.order.loading = true;
      })
      .addCase(buyTicketFromBank.fulfilled, (state, action) => {
        state.order.loading = false;
        state.order.response = action.payload;
      })
      .addCase(buyTicketFromBank.rejected, (state, action) => {
        state.order.loading = false;
        state.order.response = action.payload;
      })
      .addCase(cashoutTickets.pending, (state) => {
        state.order.loading = true;
      })
      .addCase(cashoutTickets.fulfilled, (state, action) => {
        state.order.loading = false;
        state.order.response = action.payload;
      })
      .addCase(cashoutTickets.rejected, (state, action) => {
        state.order.loading = false;
        state.order.response = action.payload;
      })
      .addCase(swopTickets.pending, (state) => {
        state.order.loading = true;
      })
      .addCase(swopTickets.fulfilled, (state, action) => {
        state.order.loading = false;
        state.order.response = action.payload;
      })
      .addCase(swopTickets.rejected, (state, action) => {
        state.order.loading = false;
        state.order.response = action.payload;
      })
      .addCase(getTicketDetailsInfo.pending, (state) => {
        state.ticketDetails.loading = true;
      })
      .addCase(getTicketDetailsInfo.fulfilled, (state, action) => {
        state.ticketDetails.loading = false;
        state.ticketDetails.detail = action.payload;
      })
      .addCase(getTicketDetailsInfo.rejected, (state, action) => {
        state.ticketDetails.loading = false;
        state.ticketDetails.detail = action.payload;
      })
      .addCase(getPendingOrders.pending, (state) => {
        state.pendingOrders.loading = true;
      })
      .addCase(getPendingOrders.fulfilled, (state, action) => {
        state.pendingOrders.loading = false;
        state.pendingOrders.detail = action.payload;
      })
      .addCase(getPendingOrders.rejected, (state, action) => {
        state.pendingOrders.loading = false;
        state.pendingOrders.detail = action.payload;
      })
      .addCase(updatePendingOrders, (state, action) => {
        console.log(action);
        state.pendingOrders.detail = action.payload;
      });
  },
});

export default ticketOrderSlice.reducer;
