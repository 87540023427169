/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function AuthenticateFromJSON(json) {
    return AuthenticateFromJSONTyped(json, false);
}
export function AuthenticateFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'provider': !exists(json, 'provider') ? undefined : json['provider'],
        'state': !exists(json, 'State') ? undefined : json['State'],
        'oauthToken': !exists(json, 'oauth_token') ? undefined : json['oauth_token'],
        'oauthVerifier': !exists(json, 'oauth_verifier') ? undefined : json['oauth_verifier'],
        'userName': !exists(json, 'UserName') ? undefined : json['UserName'],
        'password': !exists(json, 'Password') ? undefined : json['Password'],
        'rememberMe': !exists(json, 'RememberMe') ? undefined : json['RememberMe'],
        'errorView': !exists(json, 'ErrorView') ? undefined : json['ErrorView'],
        'nonce': !exists(json, 'nonce') ? undefined : json['nonce'],
        'uri': !exists(json, 'uri') ? undefined : json['uri'],
        'response': !exists(json, 'response') ? undefined : json['response'],
        'qop': !exists(json, 'qop') ? undefined : json['qop'],
        'nc': !exists(json, 'nc') ? undefined : json['nc'],
        'cnonce': !exists(json, 'cnonce') ? undefined : json['cnonce'],
        'accessToken': !exists(json, 'AccessToken') ? undefined : json['AccessToken'],
        'accessTokenSecret': !exists(json, 'AccessTokenSecret') ? undefined : json['AccessTokenSecret'],
        'scope': !exists(json, 'scope') ? undefined : json['scope'],
        'returnUrl': !exists(json, 'ReturnUrl') ? undefined : json['ReturnUrl'],
        'meta': !exists(json, 'Meta') ? undefined : json['Meta'],
    };
}
export function AuthenticateToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'provider': value.provider,
        'State': value.state,
        'oauth_token': value.oauthToken,
        'oauth_verifier': value.oauthVerifier,
        'UserName': value.userName,
        'Password': value.password,
        'RememberMe': value.rememberMe,
        'ErrorView': value.errorView,
        'nonce': value.nonce,
        'uri': value.uri,
        'response': value.response,
        'qop': value.qop,
        'nc': value.nc,
        'cnonce': value.cnonce,
        'AccessToken': value.accessToken,
        'AccessTokenSecret': value.accessTokenSecret,
        'scope': value.scope,
        'ReturnUrl': value.returnUrl,
        'Meta': value.meta,
    };
}
