import { Typography } from "@mui/material";
import React from "react";

function TermsOfService() {
  return (
    <Typography
      component="p"
      variant="caption"
      sx={{ opacity: 0.6, my: 1.5, textAlign: "center" }}
    >
      All tickets purchased in accordance with&nbsp;
      <Typography
        component="a"
        href="/#"
        sx={{ color: "primary.main", fontSize: "inherit" }}
      >
        SwopStakes Terms of Service
      </Typography>
      <br />
      If gambling&apos;s a problem call 1800 858 858
    </Typography>
  );
}

export default TermsOfService;
