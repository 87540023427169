/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function GeoRestrictionResponseFromJSON(json) {
    return GeoRestrictionResponseFromJSONTyped(json, false);
}
export function GeoRestrictionResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'game': !exists(json, 'Game') ? undefined : json['Game'],
        'contentCard': !exists(json, 'ContentCard') ? undefined : json['ContentCard'],
    };
}
export function GeoRestrictionResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'Game': value.game,
        'ContentCard': value.contentCard,
    };
}
