import { BackspaceOutlined } from "@mui/icons-material";
import { Box, Container, InputBase, InputLabel } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import StackedTicketItem from "./game/StackedTicketItem";
import { displayAmount, longFormNum } from "../utils/common-functions";

function CostTicketInput({
  currentTicketPrice,
  numOfTicket,
  setNumOfTicket,
  isFreeToPlay,
}) {
  const handleAdd = (v) => {
    setNumOfTicket(v + numOfTicket);
  };

  const totalCost = longFormNum((numOfTicket * currentTicketPrice).toFixed(2));

  return (
    <>
      <Box
        sx={{
          bgcolor: "background.paper",
          p: 2,
          mb: 3,
        }}
      >
        <Container maxWidth="xs">
          <InputLabel>Total Cost</InputLabel>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              fontSize: "28px",
              fontWeight: "700",
            }}
          >
            {isFreeToPlay ? "" : "$"}
            <InputBase
              value={totalCost}
              sx={{
                ml: 0.5,
                flex: 1,
                fontSize: "30px",
                fontWeight: "700",
                "& input": {
                  transition: "color 0.15s ease",
                  "&:focus": {
                    color: "secondary.main",
                  },
                },
              }}
              placeholder="0.00"
              inputProps={{
                "aria-label": "cost",
                pattern: "[0-9]+",
                type: "currency",
              }}
            />
            <BackspaceOutlined
              tabindex="0"
              onClick={() => setNumOfTicket(0)}
              sx={{
                "&:focus": {
                  outline: "none",
                },
                transition: "color 0.15s ease",
                cursor: "pointer",
                "&:hover": {
                  color: ({ palette }) => palette.secondary.main,
                },
              }}
            />
          </Box>
        </Container>
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "120px 120px 120px",
          gridTemplateRows: "130px 130px",
          placeItems: "center",
          width: "fit-content",
          margin: "auto",
        }}
      >
        {[1, 2, 5, 10, 50, 100].map((num) => {
          const currentPrice = num * currentTicketPrice;

          const formatedPrice = `${longFormNum(currentPrice)}`;

          return (
            <StackedTicketItem
              key={num}
              secondaryText={`${formatedPrice}`}
              onClick={() => handleAdd(num)}
            >
              +{num}
            </StackedTicketItem>
          );
        })}
      </Box>
    </>
  );
}
CostTicketInput.propTypes = {
  numOfTicket: PropTypes.number,
  setNumOfTicket: PropTypes.func,
  currentTicketPrice: PropTypes.number,
};
CostTicketInput.defaultProps = {
  numOfTicket: 0,
  setNumOfTicket: () => console.log(),
  currentTicketPrice: 1,
};
export default CostTicketInput;
