/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { AvatarFromJSON, AvatarToJSON, } from './';
export function AvatarRandomGetResponseFromJSON(json) {
    return AvatarRandomGetResponseFromJSONTyped(json, false);
}
export function AvatarRandomGetResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'avatar': !exists(json, 'Avatar') ? undefined : AvatarFromJSON(json['Avatar']),
    };
}
export function AvatarRandomGetResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'Avatar': AvatarToJSON(value.avatar),
    };
}
