/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { TicketSummaryFromJSON, TicketSummaryToJSON, } from './';
export function BankTicketsResponseFromJSON(json) {
    return BankTicketsResponseFromJSONTyped(json, false);
}
export function BankTicketsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'ticketSummaries': !exists(json, 'TicketSummaries') ? undefined : (json['TicketSummaries'].map(TicketSummaryFromJSON)),
        'bankTicketsCount': !exists(json, 'BankTicketsCount') ? undefined : json['BankTicketsCount'],
        'filteredTicketsCount': !exists(json, 'FilteredTicketsCount') ? undefined : json['FilteredTicketsCount'],
        'ticketCountByBracket': !exists(json, 'TicketCountByBracket') ? undefined : json['TicketCountByBracket'],
        'ticketValueMin': !exists(json, 'TicketValueMin') ? undefined : json['TicketValueMin'],
        'ticketValueMax': !exists(json, 'TicketValueMax') ? undefined : json['TicketValueMax'],
    };
}
export function BankTicketsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'TicketSummaries': value.ticketSummaries === undefined ? undefined : (value.ticketSummaries.map(TicketSummaryToJSON)),
        'BankTicketsCount': value.bankTicketsCount,
        'FilteredTicketsCount': value.filteredTicketsCount,
        'TicketCountByBracket': value.ticketCountByBracket,
        'TicketValueMin': value.ticketValueMin,
        'TicketValueMax': value.ticketValueMax,
    };
}
