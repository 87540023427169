/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function TicketSummaryFromJSON(json) {
    return TicketSummaryFromJSONTyped(json, false);
}
export function TicketSummaryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'ticketId': !exists(json, 'TicketId') ? undefined : json['TicketId'],
        'playerId': !exists(json, 'PlayerId') ? undefined : json['PlayerId'],
        'value': !exists(json, 'Value') ? undefined : json['Value'],
        'valueNext': !exists(json, 'ValueNext') ? undefined : json['ValueNext'],
        'probability': !exists(json, 'Probability') ? undefined : json['Probability'],
        'isInLeaderBoard': !exists(json, 'IsInLeaderBoard') ? undefined : json['IsInLeaderBoard'],
        'valueKnockout': !exists(json, 'ValueKnockout') ? undefined : json['ValueKnockout'],
    };
}
export function TicketSummaryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'TicketId': value.ticketId,
        'PlayerId': value.playerId,
        'Value': value.value,
        'ValueNext': value.valueNext,
        'Probability': value.probability,
        'IsInLeaderBoard': value.isInLeaderBoard,
        'ValueKnockout': value.valueKnockout,
    };
}
