/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function PlayerNotificationSeenLogFromJSON(json) {
    return PlayerNotificationSeenLogFromJSONTyped(json, false);
}
export function PlayerNotificationSeenLogFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'playerId': !exists(json, 'PlayerId') ? undefined : json['PlayerId'],
        'channelId': !exists(json, 'ChannelId') ? undefined : json['ChannelId'],
        'viewedDate': !exists(json, 'ViewedDate') ? undefined : (new Date(json['ViewedDate'])),
        'notificationCount': !exists(json, 'NotificationCount') ? undefined : json['NotificationCount'],
    };
}
export function PlayerNotificationSeenLogToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'PlayerId': value.playerId,
        'ChannelId': value.channelId,
        'ViewedDate': value.viewedDate === undefined ? undefined : (value.viewedDate.toISOString()),
        'NotificationCount': value.notificationCount,
    };
}
