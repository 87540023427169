/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { OtherPlayerInfoFromJSON, OtherPlayerInfoToJSON, } from './';
export function PlayerPrizeWithTransactionIdFromJSON(json) {
    return PlayerPrizeWithTransactionIdFromJSONTyped(json, false);
}
export function PlayerPrizeWithTransactionIdFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'pWTransactionId': !exists(json, 'PWTransactionId') ? undefined : json['PWTransactionId'],
        'playerPrizeId': !exists(json, 'PlayerPrizeId') ? undefined : json['PlayerPrizeId'],
        'gameId': !exists(json, 'GameId') ? undefined : json['GameId'],
        'playerId': !exists(json, 'PlayerId') ? undefined : json['PlayerId'],
        'ticketId': !exists(json, 'TicketId') ? undefined : json['TicketId'],
        'prizeTypeId': !exists(json, 'PrizeTypeId') ? undefined : json['PrizeTypeId'],
        'ticketOwnershipShare': !exists(json, 'TicketOwnershipShare') ? undefined : json['TicketOwnershipShare'],
        'isWinningTicket': !exists(json, 'IsWinningTicket') ? undefined : json['IsWinningTicket'],
        'prizeAmount': !exists(json, 'PrizeAmount') ? undefined : json['PrizeAmount'],
        'prizeDescription': !exists(json, 'PrizeDescription') ? undefined : json['PrizeDescription'],
        'awardedDate': !exists(json, 'AwardedDate') ? undefined : (new Date(json['AwardedDate'])),
        'notes': !exists(json, 'Notes') ? undefined : json['Notes'],
        'game': !exists(json, 'Game') ? undefined : json['Game'],
        'player': !exists(json, 'Player') ? undefined : OtherPlayerInfoFromJSON(json['Player']),
        'prizeType': !exists(json, 'PrizeType') ? undefined : json['PrizeType'],
    };
}
export function PlayerPrizeWithTransactionIdToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'PWTransactionId': value.pWTransactionId,
        'PlayerPrizeId': value.playerPrizeId,
        'GameId': value.gameId,
        'PlayerId': value.playerId,
        'TicketId': value.ticketId,
        'PrizeTypeId': value.prizeTypeId,
        'TicketOwnershipShare': value.ticketOwnershipShare,
        'IsWinningTicket': value.isWinningTicket,
        'PrizeAmount': value.prizeAmount,
        'PrizeDescription': value.prizeDescription,
        'AwardedDate': value.awardedDate === undefined ? undefined : (value.awardedDate.toISOString()),
        'Notes': value.notes,
        'Game': value.game,
        'Player': OtherPlayerInfoToJSON(value.player),
        'PrizeType': value.prizeType,
    };
}
