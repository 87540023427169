import { Clear, KeyboardArrowDown } from "@mui/icons-material";
import { Box, Container, IconButton, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as TicketIcon } from "../graphics/ticket/ticket.svg";
import { ReactComponent as SmallDollar } from "../graphics/smallDollar.svg";
import { ReactComponent as FpCreditIcon } from "../graphics/fpCreditIcon.svg";
import { displayTicketValueByGameType } from "../../utils/common-functions";
import { useSelector } from "react-redux";
import { IsAuthenticated } from "../../services/ls";

function DrawerHeader({
  title,
  onClose,
  DisplayTicketIcon = false,
  displayBalance = false,
}) {
  // console.log({ displayBalance });
  const { detail: gameDetail } = useSelector((state) => state.game.game);

  const { response: playerDetail } = useSelector(
    (state) => state.user.playerSummary
  );
  const isLoggedIn =
    IsAuthenticated() && playerDetail && playerDetail.playerId > 0;
  const isFreeToPlay = gameDetail?.FreeToPlay ?? true;

  const topbettaWallet = playerDetail?.playerWallets?.find(
    (w) => w.WalletTypeId == 1 && w.PartnerId == 1
  );
  const ftpWallet = playerDetail?.playerWallets?.find(
    (w) => w.WalletTypeId == 2
  );

  return (
    <Box
      sx={{
        bgcolor: "background.dark",
        // opacity: "0.94",
        borderBottom: "1px solid rgba(255, 255, 255, 0.50)",
        color: "text.contrastText",
        width: "100%",
        py: 1.5,
        px: 2,
      }}
    >
      <Container disableGutters>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              // gap: "12px",
            }}
          >
            {DisplayTicketIcon ? (
              <Box
                sx={{
                  border: "1px solid white",
                  borderRadius: "100%",
                  display: "flex",
                  p: 0.5,
                  "& svg": {
                    fill: "white",
                  },
                }}
              >
                <TicketIcon width={18} height={18} />
              </Box>
            ) : null}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                paddingLeft: "12px",
              }}
            >
              <Typography
                variant="h6"
                component="h6"
                sx={{
                  fontWeight: "600",
                  fontSize: "1rem",
                  lineHeight: "20px",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: "1",
                }}
              >
                {title}
              </Typography>
              {displayBalance &&
                isLoggedIn &&
                (topbettaWallet || ftpWallet) && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <Typography
                      variant="h6"
                      component="h6"
                      sx={{
                        fontWeight: "500",
                        fontSize: "0.875rem",
                        lineHeight: "16px",
                        marginRight: "4px",
                      }}
                    >
                      Balance:
                    </Typography>
                    <FpCreditIcon width={16} height={16} />
                    <Typography
                      variant="h6"
                      component="h6"
                      sx={{
                        fontWeight: "400",
                        fontSize: "0.875rem",
                        marginLeft: "4px",
                        lineHeight: "16px",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: "1",
                      }}
                    >
                      {displayTicketValueByGameType(
                        isFreeToPlay,
                        isFreeToPlay
                          ? ftpWallet.Balance
                          : topbettaWallet?.Balance ?? 0,
                        false,
                        true
                      )}
                    </Typography>
                  </Box>
                )}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <IconButton onClick={onClose} size="small">
              <Clear
                sx={{
                  color: "text.contrastText",
                  display: { xs: "none", sm: "block" },
                }}
              />
              <KeyboardArrowDown
                sx={{
                  color: "text.contrastText",
                  display: { xs: "block", sm: "none" },
                }}
              />
            </IconButton>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

DrawerHeader.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

DrawerHeader.defaultProps = {
  title: null,
};

export default DrawerHeader;
