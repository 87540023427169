/* tslint:disable */
/* eslint-disable */
/**
 * SwopStakes.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function MajorPrizeFromJSON(json) {
    return MajorPrizeFromJSONTyped(json, false);
}
export function MajorPrizeFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'genericImage': !exists(json, 'GenericImage') ? undefined : json['GenericImage'],
        'description': !exists(json, 'Description') ? undefined : json['Description'],
        'desktopImageUrl': !exists(json, 'DesktopImageUrl') ? undefined : json['DesktopImageUrl'],
        'mobileImageUrl': !exists(json, 'MobileImageUrl') ? undefined : json['MobileImageUrl'],
    };
}
export function MajorPrizeToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'GenericImage': value.genericImage,
        'Description': value.description,
        'DesktopImageUrl': value.desktopImageUrl,
        'MobileImageUrl': value.mobileImageUrl,
    };
}
