import swopApi from "../../api";
import { getGameDetail } from "../slices/game.slice";
import { getPlayerSummary, poSetActivePurchase } from "../slices/user.slice";

const freeCreditsHandler = async (store) => {
  const { user, game } = store.getState();
  // Only proceed if logged in and game detail available
  if (
    user &&
    user.playerSummary &&
    user.playerSummary.response &&
    user.playerSummary.response.playerId &&
    game &&
    game.game &&
    game.game.detail &&
    game.game.detail.FreeToPlay &&
    game.game.detail.GameId
  ) {
    console.log("freeCreditsHandler -> Inserting FTP Credits");
    await swopApi.playerApi.playerInsertFTPCreditsForGameRequestinsertFTPCreditsForGamePost(
      {
        accept: "application/json",
        body: {
          gameId: game.game.detail.GameId,
        },
      }
    );
  }
};

// const purchaseOrdersHandlers = async (store, action) => {
//   console.log("purchaseOrdersHandlers", { store, action });
//   const res =
//     await swopApi.playerApi.playerTicketPurchaseOrderRequestticketpurchaseordersCreate(
//       { accept: "application/json", gameId }
//     );
// };

const businessMiddleware = (store) => (next) => (action) => {
  next(action);
  if (
    [
      getGameDetail.fulfilled().type,
      getPlayerSummary.fulfilled().type,
    ].includes(action.type)
  ) {
    freeCreditsHandler(store, action);
  }
  if (
    [getPlayerSummary.fulfilled().type, poSetActivePurchase.type].includes(
      action.type
    )
  ) {
    // purchaseOrdersHandlers(store, action);
  }
};
export default businessMiddleware;
